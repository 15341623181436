export const Styles = Theme => ({
    counters: {
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        margin: '15px 0',
        alignItems: 'center',
        '& p': {  // Direct styling for p tag under counters
            color: '#000',
            opacity: '0.5',
            fontSize: '10px',
            marginTop: '5px'
        },
        '& h3' : {
            fontSize: '18px',
            fontWeight : '700'
        },
        '& div' : {
            textAlign: 'center'
        },
        "@media(max-width:1440px)": {
            gap: '10px',
        },
    },
    colon: {
        color: '#000',
        fontSize: '18px',
        fontWeight: '700'
    }
});
