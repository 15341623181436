import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FlashDealCouponListSlice = createSlice({
    name: 'FlashDealCouponList',
    initialState,
    reducers: {

        FlashDealCouponList_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        FlashDealCouponList_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        FlashDealCouponList_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_FlashDealCouponList_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  FlashDealCouponList_request, FlashDealCouponList_success,FlashDealCouponList_failure, Clear_FlashDealCouponList_Cart } = FlashDealCouponListSlice.actions

export default FlashDealCouponListSlice.reducer