import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Styles } from "./Style";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ItemQuantity_success } from "../../redux/reducers/Modifier/ItemQuantitySlice";
import minimizeIcon from './../../images/minimizeIcon.svg';
import plusIcon from './../../images/plusIcon.svg';
import { UpdateItemQuantityAPI } from "../../redux/Action/LoginCart/UpdateItemQuantity";
import { Filterid_cartmatch_success } from "../../redux/reducers/Filteridcartmatch";
import { Addtocart_Refresh_success } from "../../redux/reducers/Addtocartrefresh";

const QuantityCount = (props) => {
    const { classes, itemQuantity, item_price, cart_item_id } = props;
    const [counter, setCounter] = useState(1);
    const dispatch = useDispatch();
    const addtocart = localStorage.getItem("addtocart");
    const Logindetails = localStorage.getItem("Logindetails")
    const cart_identifier = localStorage.getItem("cart_identifier")
    const item_id = localStorage.getItem("item_id")
    const GetLoginCart = useSelector(state => state?.GetLoginCartSlice?.value)
    //    console.log("itemQuantity",itemQuantity)
    const [pluscountercheck, setpluscountercheck] = useState(false)
    const [minuscounter, setminuscounter] = useState(1)
    const [pluscounter, setpluscounter] = useState(1)
    const [refreshpage, setrefreshpage] = useState(0)
    useEffect(() => {
        setCounter(itemQuantity != undefined ? itemQuantity : 1)
        dispatch(ItemQuantity_success(itemQuantity != undefined ? itemQuantity : 1));
    }, [itemQuantity])
    const increase = () => {
        // setCounter(counter + 1);
        dispatch(ItemQuantity_success(counter + 1));
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cart_item_id,
            item_quantity: counter,
            item_price: item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []
        dispatch(Addtocart_Refresh_success(refreshpage + 167))
        setrefreshpage(refreshpage + 167)
        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id == cart_item_id ? { ...item, itemQuantity: counter  } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        //    console.log("addtocarts",changeaddtocarts)

    };
    // useEffect(() => {
    //     const filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
    //     dispatch(Filterid_cartmatch_success(filteridmatch))
    // }, [counter])
    const decrease = () => {
        if (counter === 1) {
            return;
        }
        // setCounter(counter - 1);
        dispatch(ItemQuantity_success(counter - 1));
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cart_item_id,
            item_quantity: counter,
            item_price: item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []
        dispatch(Addtocart_Refresh_success(refreshpage + 127))
        setrefreshpage(refreshpage + 127)
        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id == cart_item_id ? { ...item, itemQuantity: counter  } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        //    console.log("addtocarts",changeaddtocarts)
    };
    // console.log("itemQuantity", itemQuantity)
    useEffect(() => {

    }, [counter])
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {


                decrease();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [minuscounter]);
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {

                increase();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [pluscounter]);
    return (
        <div className={classes.countBox}>
            <NavLink onClick={() => {
                if (counter > 1) {

                    setpluscountercheck(true)
                    setCounter(counter - 1);
                    setminuscounter(minuscounter + 1)
                }
                // decrease()
            }}><img src={minimizeIcon} alt="Not Found" /></NavLink>
            <span className={classes.countNumber}>{counter}</span>
            <NavLink onClick={() => {
                  setpluscounter(pluscounter + 1)
                            setpluscountercheck(true)
                            setCounter(counter + 1);
                // increase()
                
                }}><img src={plusIcon} alt="Not Found" /></NavLink>
        </div>
    );
};
export default withStyles(Styles)(QuantityCount);
