import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const UpdateLoginCartSlice = createSlice({
  name: 'UpdateLoginCart',
  initialState,
  reducers: {
 
    Update_Login_Cart_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Update_Login_Cart_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
    Update_Login_Cart_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.value={};
    }
  }
})

// Action creators are generated for each case reducer function
export const {     Update_Login_Cart_request ,    Update_Login_Cart_success,    Update_Login_Cart_failure} = UpdateLoginCartSlice.actions

export default UpdateLoginCartSlice.reducer