
import { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import SmallButton from "../SmallButton/SmallButton";
import { NavLink, useNavigate } from "react-router-dom";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import plusIcon from '../../images/plusIcon.svg';
import minimizeIcon from '../../images/minimizeIcon.svg';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { DeleteLoginCartAPI } from "../../redux/Action/LoginCart/DeleteLoginCart";
import { GetLoginCartItemAPI } from "../../redux/Action/LoginCart/Getlogincartitem";
import { UpdateItemQuantityAPI } from "../../redux/Action/LoginCart/UpdateItemQuantity";
import { State_Change_success } from "../../redux/reducers/StateChangeSlice";
import { Totalamount_withoutlogin_success } from "../../redux/reducers/Totalamountwithoulogin";
import { AddLoginCartAPI } from "../../redux/Action/LoginCart/LoginCartAction";
import { useSyncRefHeight } from "../../CustomHooks/ElementSameHeight";
import GrayButton from "../GrayButton/GrayButton";
import CustomButton from "../CustomButton/CustomButton";
import CustomModal from "../CustomModal/CustomModal";
import StrConstent from "../../Common/StrConstent";
import RoundedDrawer from "../BottomDrawer/RoundedDrawer";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import BorderButton from "../BorderButton/BorderButton";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import { useTranslation } from 'react-i18next';
const OrderBox = (props) => {
    const { classes, url, heading, description, price, quantity, orderagain, datalength, AddtocartRefresh } = props;
    const [counter, setCounter] = useState(0);
    const navigate = useNavigate();
    const addtocart = localStorage.getItem("addtocart");
    const dispatch = useDispatch();
    const Logindetails = localStorage.getItem("Logindetails")
    const cart_identifier = localStorage.getItem("cart_identifier")
    const outlet_mode = localStorage.getItem("outlet_mode")
    const outletID = localStorage.getItem("outletID")
    const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []
    const [addtocartpopup, setaddtocartpopup] = useState(false);
    const device = useBreakpoint();
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const [pluscountercheck, setpluscountercheck] = useState(false)
    const [minuscounter, setminuscounter] = useState(1)
    const [pluscounter, setpluscounter] = useState(1)

    // const AddtocartRefresh = useSelector(state => state?.AddtocartRefreshSlice?.value)
    const { t } = useTranslation();
    useEffect(() => {
        setCounter(quantity)
    }, [])
    useEffect(() => {
        setCounter(quantity)
    }, [AddtocartRefresh, quantity])
    const increase = () => {
        // setCounter(counter + 1);
        dispatch(State_Change_success(counter + 1))
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: description?.cart_item_id,
            item_quantity: counter,
            item_price: description?.item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
    };

    const decrease = () => {
        // if (counter === 1) {
        //     return;
        // }
        setCounter(counter - 1);
        dispatch(State_Change_success(counter))
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: description?.cart_item_id,
            item_quantity: counter,
            item_price: description?.item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
    };
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {


                decrease();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [minuscounter]);
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {

                increase();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [pluscounter]);
    // useEffect(() => {
    //     const obj = {
    //         cart_id: cart_identifier,
    //         cart_item_id: description?.cart_item_id,
    //         item_quantity: counter,
    //         item_price: description?.item_price
    //     }
    //     console.log("obj", obj)
    //     dispatch(UpdateItemQuantityAPI(obj))
    // }, [])

    useEffect(() => {

        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id == description?.unique_id ? { ...item, itemQuantity: counter } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        // console.log("changeaddtocarts", changeaddtocarts)
        dispatch(Totalamount_withoutlogin_success(changeaddtocarts))
    }, [counter])

    const handleEdit = (id) => {
        const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
        if (Logindetail) {
            dispatch(GetLoginCartItemAPI(id?.cart_item_id))
            navigate(`/select-outlet/${id?.cart_item_id}`)
        } else {

            // console.log("handleEdit", id)
            navigate(`/select-outlet/${id?.unique_id}`)
            window.location.reload()
        }
    }

    const handleDelete = (id) => {
        const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
        if (Logindetail) {
            dispatch(DeleteLoginCartAPI(id?.cart_item_id))
        }
        else {
            // console.log("handleDelete", id?.unique_id)
            const deleteitem = JSON.parse(addtocart)
            const deleteitemdata = deleteitem?.filter((item) => item?.unique_id !== id?.unique_id)
            // console.log("deleteitemdata", deleteitemdata)
            localStorage.setItem("addtocart", JSON.stringify(deleteitemdata));
            window.location.reload()
        }

    }

    const handleaddtocart = () => {
        // console.log("outletid",datalength,+outletID,description?.outlet_id)
        if ((+datalength > 0 && +outletID == description?.outlet_id) || (+datalength == 0)) {

            setaddtocartpopup(false)
            // dispatch(Drawer_success(false))
            const itemdetailsdata = {
                "order_mode": outlet_mode,
                "cart_id": cart_identifier,
                "cart_item_id": description?.cart_item_id,
                "outlet_id": description?.outlet_id,
                "address_id": null,
                "id": description?.id,
                "name": description?.name,
                "url": description?.url,
                "modifier": description?.modifier,
                "totalamount": price * counter,
                "selectedsize": description?.selectedSize,
                "item_price": description?.item_price,
                "item_one_quantity_price": +description?.selectedsize?.price,
                "itemQuantity": counter,
                "sugarlevel": description?.sugarlevel,
                "size": description?.size,
                "description": description?.description,
                "is_sugar_enabled": description?.is_sugar_enabled,

            }
            dispatch(AddLoginCartAPI([itemdetailsdata]))
            return navigate("/cart")

        }
        else {
            setaddtocartpopup(true)
            // dispatch(Drawer_success(true))
        }
    }
    // console.log("description", description);
    const address_id = localStorage.getItem("address_id")

    const handleCartClear = () => {
        const itemdetailsdata = {
            "order_mode": outlet_mode,
            "cart_id": cart_identifier,
            "cart_item_id": description?.cart_item_id,
            "outlet_id": description?.outlet_id,
            "address_id": outlet_mode == "Schedule Delivery" ? address_id : null,
            "id": description?.id,
            "name": description?.name,
            "url": description?.url,
            "modifier": description?.modifier,
            "totalamount": price * counter,
            "selectedsize": description?.selectedSize,
            "item_price": description?.item_price,
            "item_one_quantity_price": +description?.selectedsize?.price,
            "itemQuantity": counter,
            "sugarlevel": description?.sugarlevel,
            "size": description?.size,
            "description": description?.description,
            "is_sugar_enabled": description?.is_sugar_enabled,

        }
        localStorage.setItem("outletID", description?.outlet_id)
        dispatch(AddLoginCartAPI([itemdetailsdata]))
        return navigate("/cart")
    }

    // box height
    const boxHeightRef = useRef(null);
    useSyncRefHeight(
        [
            ['boxHeight', boxHeightRef],
        ],
        // trigger hook when items of footerItems changes, since it may change height
        [price],
    );
    const itemprice = price * counter
    return (
        <>
            <div className={classes.order}>
                <div className={`${CommonStyle.flexCenterStart} ${classes.topBox}`}>
                    <div className={classes.imgContent}>
                        <img src={url} alt="Not Found" />
                    </div>
                    <div className={classes.textContent} ref={boxHeightRef}>
                        <div className={CommonStyle.flexCenterStart}>
                            <div className={classes.productName}>
                                <h4>{heading}</h4>
                                <p className={classes.mt10}>{description?.selectedsize?.size} / {description?.modifier?.map((data) => {
                                    return " " + data?.modifier_quantity > 0 ? data?.modifier_name + " " + data?.modifier_quantity + " / " : ""
                                })}{description?.sugarlevel ? "sugarlevel " + description?.sugarlevel : ""}</p>
                            </div>
                            {
                                orderagain ? "" :
                                    <NavLink to="" className={classes.deleteIcon}><DeleteIcon onClick={() => handleDelete(description)} /></NavLink>
                            }
                        </div>
                        <div className={classes.counter}>
                            <NavLink onClick={() => {
                                if (counter > 1) {

                                    setpluscountercheck(true)
                                    setCounter(counter - 1);
                                    setminuscounter(minuscounter + 1)
                                }
                                // decrease()
                            }
                            } className={classes.btn}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                            <span className={classes.counterNumber}>{counter}</span>
                            <NavLink onClick={() => {
                            setpluscounter(pluscounter + 1)
                            setpluscountercheck(true)
                            setCounter(counter + 1);
                            // increase()
                        }} className={classes.btn}><img src={plusIcon} alt="Not Found" /></NavLink>
                        </div>
                    </div>
                </div>
                <div className={`${CommonStyle.flexSpaceBetween} ${classes.bottonBox}`}>
                    {orderagain ? <SmallButton label={t("Add to Cart")} icon={<ModeEditIcon />} clickHandler={() => handleaddtocart()} />
                        :
                        <SmallButton label={t("Edit")} icon={<ModeEditIcon />} clickHandler={() => handleEdit(description)} />
                    }
                    <h3>{companydata?.currency_symbol}{itemprice?.toFixed(2)}</h3>
                </div>
            </div>


            {device == "sm" ? <>
                {
                    addtocartpopup == true && (
                        <RoundedDrawer
                            truekey={addtocartpopup}
                            setruekey={setaddtocartpopup}
                        >
                            <h3 className={CommonStyle.textCenter}>Item outlet is diffrent. your cart will be clear?</h3>
                            <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                                <BorderButton label={"Cancel"} clickHandler={() => setaddtocartpopup(false)} />
                                <CustomButton label={"Confirm"} clickHandler={handleCartClear} />
                            </div>
                        </RoundedDrawer>
                    )
                }
            </> : <>


                <CustomModal
                    open={addtocartpopup}
                    title={""}
                    handleClose={() => setaddtocartpopup(false)}
                    description={""}>
                    <h3 className={CommonStyle.textCenter}>Item outlet is diffrent. your cart will be clear?</h3>
                    <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                        <BorderButton label={"Cancel"} clickHandler={() => setaddtocartpopup(false)} />
                        <CustomButton label={"Confirm"} clickHandler={() => handleCartClear()} />
                    </div>
                </CustomModal>

            </>}



        </>
    )
}

export default withStyles(Styles)(OrderBox);