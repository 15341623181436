import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ApplyCouponSlice = createSlice({
    name: 'ApplyCoupon',
    initialState,
    reducers: {
        Apply_Coupon_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Apply_Coupon_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Apply_Coupon_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Apply_Coupon: (state,action)=>{
            state.error = {};
            state.value = {};
            state.loading = false;  
        }
    }
})

// Action creators are generated for each case reducer function
export const { Apply_Coupon_request, Apply_Coupon_success, Apply_Coupon_failure ,Clear_Apply_Coupon} = ApplyCouponSlice.actions

export default ApplyCouponSlice.reducer