import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import check from '../../images/checkIcon.svg';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useState } from 'react';
const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    background: ColorTheme.gray,
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === ColorTheme.yellow ? ColorTheme.yellow : '',
    },
    "input:disabled ~ &": {
        background:
            theme.palette.mode === "dark"
                ? "rgba(57,75,89,.5)"
                : "rgba(206,217,224,.5)",
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: ColorTheme.yellow,
    "&:before": {
        display: "block",
        width: 20,
        height: 20,
        // backgroundImage: `url(${check})`,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const CustomizedCheckbox = (props) => {
    const { isDefaultSelected = false, onChange } = props;
    const [isChecked, setIsChecked] = useState(isDefaultSelected);
    const handleOnChange = () => {
        setIsChecked((pre) => !pre);
        // console.log(`checked: ${!isChecked}`);
        onChange(!isChecked, props?.name);
    };
    return (
        <div>
            <BpCheckbox label="Label" checked={isChecked} onChange={handleOnChange} />
        </div>
    );
}

export default withStyles(Styles)(CustomizedCheckbox);