import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI"
import { Item_details_failure, Item_details_request, Item_details_success } from "../../reducers/Itemdetails/ItemdetailsSlice"

export const ItemdetailsfetchAPI = (payload) => async (dispatch, getState) => {

    dispatch(Item_details_request())
    try {
        const itemid = localStorage.getItem("itemId")
        const { data } = await axios.get(`${APIURL?.itemdetails}/${payload}?item=${itemid}`, {
            headers: Headers
        })
        // console.log("itemdetails", data?.data)
        localStorage.setItem("itemdetails",JSON.stringify( data?.data))
        dispatch(Item_details_success(data?.data));
    } catch (error) {
        // console.log("Error", error)
        dispatch(Item_details_failure(error))
    }
}