import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    promotionalOrder: {
        "@media(max-width:768px)": {
            height: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: ' space-between',
        },
    },
    bottomBtn: {
        "@media(max-width:768px)": {
            display: 'block',
        },
        "& button": {
            "@media(max-width:768px)": {
                width: '100%',
            },
            "&:first-child": {
                marginRight: '15px',
                "@media(max-width:768px)": {
                    marginRight: '0',
                    marginTop: '15px',
                }
            }
        }
    },
    notificationsOuter: {
        marginBottom: '2rem',
        "& ul": {
            margin: "20px 0",
        },
        "& li": {
            marginBottom: "20px",
        }
    },
    notificationBox: {
        background: ColorTheme.white,
        padding: '20px',
        borderRadius: '10px',
        borderLeft: `3px solid ${ColorTheme.yellow}`,
        "& h5": {
            fontFamily: 'var(--sub-heading-family)',
        },
        "& p": {
            padding: '12px 0',
        }
    },
    grayBorder: {
        borderLeft: "3px solid rgba(63, 68, 68, 0.3)",
    },
})
