import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import shoppingCart from '../../images/shoppingCart.svg';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { NavLink } from 'react-router-dom';
import SmallButton from '../SmallButton/SmallButton';
import { useDispatch, useSelector } from 'react-redux';
import { Cart_Drawer_Open_success } from '../../redux/reducers/CartdraweropenSlice';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const CardModal = (props) => {
  const { classes, clickHandle } = props;
  const [anchor, setAnchor] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const HeaderHeightSlice = useSelector(state => state?.HeaderHeightSlice?.value)
  const CartItemAddedSlice = useSelector(state => state?.CartItemAddedSlice)
  const [cartitems, setcartitems] = React.useState("")
  const { t } = useTranslation();
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (CartItemAddedSlice?.value?.length > 0) {
      setOpen(true)
      setcartitems(CartItemAddedSlice?.value[0])
    } else {
      setOpen(false)

    }
  }, [CartItemAddedSlice])

  return (
    <div>
      {/* <Button ref={setAnchor} onClick={() => setOpen((o) => !o)} type="button">
        Toggle Popup
      </Button> */}
      <span ref={setAnchor}>
      </span>
      {/* <NavLink to="/cart"><div className={classes.toggleBtn} ref={setAnchor} onClick={() => setOpen((o) => !o)}>
        <img src={shoppingCart} alt="Not Found" />      
     <sup className={classes.itemNumber}>{1}</sup>
      </div></NavLink> */}
      {/* <BasePopup anchor={anchor} strategy="fixed" style={{ paddingTop: `${HeaderHeightSlice - 25}px`, zIndex: 999 }} open={true} withTransition className={classes.popupContent}></BasePopup> */}
      <BasePopup anchor={anchor} strategy="fixed" open={open} withTransition className={classes.popupContent}>
        {(props) => (
          <PopAnimation {...props}>
            <PopupBody className={classes.cardView}>
              <h6 className={CommonStyle.mb20}>{t("Item(s) added")}</h6>
              <div className={`${CommonStyle.flex} ${CommonStyle.flex} ${CommonStyle.pb20}`}>
                <div className={classes.proImgContent}>
                  <img src={cartitems?.url} alt="Not Found" />
                </div>
                <h5>{cartitems?.name}</h5>
              </div>
              <div className={classes.addCard} onClick={() => dispatch(Cart_Drawer_Open_success(true))}><SmallButton clickHandle="" label={t("View Cart")} /></div>
            </PopupBody>
          </PopAnimation>
        )}
      </BasePopup>
    </div>
  );
}

function Animated(props) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div
      onAnimationEnd={handleAnimationEnd}
      className={className + (requestOpen ? ' open' : ' close')}
    >
      {children}
    </div>
  );
}

Animated.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onEnter: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  requestOpen: PropTypes.bool.isRequired,
};

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const PopupBody = styled('div')(
  ({ theme }) => `
    padding: 0.5rem 1rem;
    border-radius: 0px 0px 5px 5px;
    min-height: 3rem;
    display: flex;
    align-items: center;
    box-shadow:rgba(0, 0, 0, 0.2) 0px 0px 8px;
`,
);

export default withStyles(Styles)(CardModal);