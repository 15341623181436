import React, { useEffect, useState } from 'react'
import { Button } from 'react-scroll'
import { withStyles } from "@mui/styles";
import CustomButton from '../CustomButton/CustomButton'
import { Styles } from "./Style";
import { NavLink, useNavigate } from 'react-router-dom';
import LargeBtn from '../LargeBtn/LargeBtn';
import CommonStyle from '../../Common/CommonCss/Common.module.css'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../CustomModal/CustomModal';
import RoundedDrawer from '../BottomDrawer/RoundedDrawer';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import { ContinueasguestAPI } from '../../redux/Action/Continueasguest/Continueasguest';
import { FormLabel } from '@mui/material';
import BorderButton from '../BorderButton/BorderButton';
import { Login_Drawer_success } from '../../redux/reducers/LoginDrawer';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
const Reservationbtn = (props) => {
  const { classes } = props;
  const Logindetails = localStorage.getItem("Logindetails");
  const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
  const navigate = useNavigate()
  const [guestnumber, setguestnumber] = useState("")
  const [guestlengtherror, setguestlengtherror] = useState(false);
  const [guesterror, setguesterror] = useState(false);
  const dispatch = useDispatch()
  const [paymaentModal, setPaymaentModal] = useState(false);
  const [signdrawer, setsigndrawer] = useState(false);
  const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
  const { t } = useTranslation();
  const companydata = JSON.parse(localStorage.getItem("companydata"))
  const Continueasguest = useSelector(state => state?.ContinueasguestSlice?.value);
  const device = useBreakpoint();
  const [withoutlogintotalamount, setwithoutlogintotalamount] = useState(0)
  const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
   const checknavigationroute= localStorage.getItem("checknavigationroute")
  
   const handleNavigate = () => {
    localStorage.setItem("checknavigationroute",'reservation')
    if (Logindetail) {
      navigate("/reservation")
    } else {
      // navigate("/login")
      setsigndrawer(true)
      dispatch(Login_Drawer_success(true))
      
    }
  }
  const handlecontinueasguestsubmit = () => {
    if (guestnumber?.length >= 8 && guestnumber?.length <= 10) {

        dispatch(ContinueasguestAPI(guestnumber))
        setguesterror(false)
        setguestlengtherror(false)
    } else if (guestnumber?.length < 8 || guestnumber?.length > 10) {
        setguesterror(false)
        setguestlengtherror(true)

    } else if (guestnumber == "") {
        setguesterror(true)
        setguestlengtherror(false)

    }
    // console.log("guestnumber", guestnumber)
    // navigate("/proceed-to-pay")
}
const handleClickguest = () => {
  // setGuestModal(false);
  setsigndrawer(false);
  setPaymaentModal(true);
}
  const handleKeyDown = (event) => {
    if (event.keyCode === 69 || event.keyCode == 45 || event.keyCode == 95 || event.key == "-") {
      event.preventDefault();
    }
  };
  const phonemethod = () => {
    return <>
        <div className={classes.topBox}>
            <div className={classes.productImg}>
                <img src="./images/product-1.png" alt="Not Found" />
            </div>
            <div className={classes.spacingTop}>
                <CustomButton variant="text" label={t("Sign in")} clickHandler={() => {
                  setsigndrawer(false)
                    localStorage.setItem("loginredirectstatus", 3)
                    navigate("/login")
                }} />
            </div>
            <p classes={CommonStyle.flexJustifyCenter} onClick={() => { localStorage.setItem("loginredirectstatus", 2) }}>{t("New here?")} <NavLink to="/login" className={CommonStyle.links}>{t("Register")}</NavLink> {t("to get $5 off your first order")}</p>
        </div>
        <div className={classes.bottomBox}>
            <div className={classes.productImg}>
                <img src="./images/product-12.png" alt="Not Found" />
            </div>
            <div className={classes.spacingTop}>
                <BorderButton label={t("Continue as guest")} clickHandler={() => handleClickguest()} />
            </div>
        </div>
    </>
}
useEffect(() => {
  // console.log("Continueasguest", Continueasguest)
  if (Continueasguest?.success == true && checknavigationroute=="reservation") {
      localStorage.setItem("Continueasguest", JSON.stringify(Continueasguest))
      navigate("/reservation")
      setTimeout(()=>{
        dispatch(Clear_Continue_as_guest())
      },1000)
  }
}, [Continueasguest])
  return (
    <>
      <Button className={classes.reservationbtn} onClick={() => handleNavigate()} >
        {t("Book a table")}
      </Button>


      {
        device == "sm" ? <>
          {signdrawer == true && (
            <RoundedDrawer>
              {phonemethod()}
            </RoundedDrawer>
          )}
        </> : <>
          {signdrawer == true && (
            <>
              {
                signdrawer == true && <CustomModal
                  open={true}
                  handleClose={() =>   setsigndrawer(false)}
                  description={""}>
                  <div className={CommonStyle.smallModal}>
                    {phonemethod()}
                  </div>
                </CustomModal>
              }
            </>
          )}
        </>
      }


      {
        device == "sm" ? <>
          {paymaentModal == true && (
            <RoundedDrawer 
            opendrawer={paymaentModal}
            >
              <div className={classes.drawerOuter}>
                <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                  <FormLabel>{t("Phone Number*")}</FormLabel>
                  <p className={CommonStyle.mb20}>{t("This is for us to notify you when your order is ready for collection")} </p>
                  <input type="number" name="number" placeholder={t("Enter your mobile no.")} maxlength="10" minLength="10" value={guestnumber} onChange={(e) => {
                    setguesterror(false)
                    setguestlengtherror(false)
                    setguestnumber(e.target.value)

                  }}
                    onInput={(e) => {
                      if (e?.target?.value != "") {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }
                    }}
                  />
                  {guesterror && (

                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("This field is required.")} </p>
                  )}
                  {guestlengtherror && (

                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("Phone number length must be in between 8 to 10 digits.")} </p>
                  )}
                </div>
                <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                  <LargeBtn  label={t("Continue to Payment")}  clickHandler={() => { handlecontinueasguestsubmit() }} />
                </div>
              </div>
            </RoundedDrawer>
          )}
        </> : <>
          {paymaentModal == true && (
            <>
              { <CustomModal
                open={true}
                // title={StrConstent.cart.cardClear.heading}
                handleClose={() => setPaymaentModal(false)}
                description={""}>
                <div className={classes.drawerOuter}>
                  <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                    <FormLabel>{t("Phone Number*")}</FormLabel>
                    <p className={CommonStyle.mb20}>{t("This is for us to notify you when your order is ready for collection")} </p>
                    <input type="number" name="number" placeholder={t("Enter your mobile no.")} maxlength="10" minLength="10" value={guestnumber} onChange={(e) => {
                      setguesterror(false)
                      setguestlengtherror(false)
                      setguestnumber(e.target.value)
                    }} onKeyDown={handleKeyDown} onInput={(e) => {
                      if (e?.target?.value != "") {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }
                    }} />
                    {guesterror && (

                      <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("This field is required.")} </p>
                    )}
                    {guestlengtherror && (

                      <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("Phone number length must be in between 8 to 10 digits.")} </p>
                    )}
                  </div>
                  <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${classes.continuePayment}`}>
                    <LargeBtn number={""} label={t("Continue to Payment")} clickHandler={() => { handlecontinueasguestsubmit() }} />
                  </div>
                </div>
              </CustomModal>}
            </>
          )}
        </>
      }
    </>
  )
}

export default withStyles(Styles)(Reservationbtn);
