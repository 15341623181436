import React, { useEffect } from "react";
import Slider from "react-slick";
import { withStyles } from "@mui/styles";
import makeStyles from '@mui/styles/makeStyles';
import { Styles } from "./Style";
import CustomButtom from "../CustomButton/CustomButton";
import ContainerBox from "../ContainerBox/ContainerBox";
import { Container } from "@mui/material";
import burger from '../../images/burger.png';
import { Link as Linkscroll } from "react-scroll";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useTranslation } from 'react-i18next';
const HeroSlider = (props) => {
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const { classes } = props;
    const useStyles = makeStyles((theme) => ({
        root: {
            // background: "#000",
        },
        dots: {
            "& li.slick-active button": {
                backgroundColor:`${ColorTheme.yellow} !important`,
                opacity: 1,
                height:'12px',
                width:'12px',
            },
        },
      }));
    const Styles = useStyles();
    var settings = {
        dots: true,
        arrows: false,
        navbar: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: `slick-dots ${Styles.dots}`,
    };
    const { t } = useTranslation();
    const handleClick = () => {

    }
    return (
        <>
            <div className={classes.hero}>
                <Slider {...settings}>
                    {
                        companydata?.banners?.map((data) => {
                            return <div className={classes.slickItems} key={data?.image_url}>
                                <div className={classes.imgContainer} style={{ backgroundImage: `url(${data?.image_url})` }}>
                                    <Container maxWidth="xl">
                                        {/* <img src={burger} alt="Not Found" /> */}
                                        <Linkscroll
                                            activeClass="active"
                                            to="viewmores"
                                            spy={true}
                                            smooth={true}
                                            offset={0}
                                            duration={100}
                                        >
                                            <CustomButtom label={t("order now")} clickHandler={handleClick} />
                                        </Linkscroll>
                                    </Container>
                                </div>
                            </div>
                        })
                    }

                    {/* <div className={classes.slickItems}>
                        <div className={classes.imgContainer} style={{ backgroundImage: `url(${heroImg})` }}>
                            <ContainerBox maxWidth="xl"> <img src={burger} alt="Not Found" /><CustomButtom label="order now" clickHandler={handleClick} /></ContainerBox>
                        </div>
                    </div>
                    <div className={classes.slickItems}>
                        <div className={classes.imgContainer} style={{ backgroundImage: `url(${heroImg})` }}>
                            <ContainerBox maxWidth="xl"> <img src={burger} alt="Not Found" /><CustomButtom label="order now" clickHandler={handleClick} /></ContainerBox>
                        </div>
                    </div> */}
                </Slider>
            </div>
        </>
    )
}
export default withStyles(Styles)(HeroSlider);