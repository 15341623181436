import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    orderOuter: {
        padding: '6rem 0 2rem',
    },
    orderInner: {
        textAlign: 'center',
    },
    checkBox: {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        objectFit: 'cover',
        backgroundColor: ColorTheme.green,
        margin: '20px auto 25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media(max-width:640px)": {
            width: '65px',
            height: '65px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
            maxWidth: '55px',
            "@media(max-width:640px)": {
                maxWidth: '40px',
            },
        }
    },
    time: {
        color: ColorTheme.fontColor,
    },
    imgContent: {
        maxWidth: '400px',
        height: 'auto',
        objectFit: 'cover',
        margin: '25px auto',
        "@media(max-width:640px)": {
            maxWidth: '300px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
            objectFit: 'cover',
        }
    }
})