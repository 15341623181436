import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    p16: {
        "@media(max-width:640px)": {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
        }
    },
    listing: {
        "& li": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 0',
            borderBottom: '1.09px solid #D5D4DC',
            "&:last-child": {
                borderBottom: 'none',
                paddingBottom: '0',
            }
        },
    },
    btnContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        "@media(max-width:640px)": {
            display: 'block',
        },
        "& div": {
            width: '100%',
            "&:last-child": {
                marginLeft: '10px',
                "@media(max-width:640px)": {
                    marginLeft: '0',
                }
            }
        },
        "& button": {
            margin: '12px 0',
            width: '100%',
            "@media(max-width:640px)": {
                margin: '5px 0',
            },
            "& img": {
                height: "100%",
                width: "65px",
                "@media(max-width:991px)": {
                    width: "50px",
                }
            },
        },
    },
    leftContent: {
        width: '100%',
        paddingRight:'10px',
        // "@media(max-width:768px)": {
        //     width: '60%',
        // },
        // "@media(max-width:575px)": {
        //     width: '40%',
        // },
    },
    title: {
        color: ColorTheme.fontColor,
    },
    rightContent: {
        width: '30%',
        textAlign: 'right',
        marginLeft: '10px !important',
        justifyContent: 'flex-end',
        "@media(max-width:768px)": {
            width: '40%',
        },
        "& p": {
            textAlign: 'right',
            color: ColorTheme.fontColor,
        },
        "& button": {
            float: 'right',
            marginLeft: '5px',
            marginTop: '8px',
        }
    },
    formContainer: {
        width: '100%',
    },
    width50: {
        width: '50%'
    },
    cart: {
        width: '100%',
        "& div": {
            "&:last-child": {
                marginBottom: '0',
            }
        }
    },
    cartBox: {
        backgroundColor: ColorTheme.lightBrown,
        padding: '20px',
        borderRadius: '10px',
        marginBottom: '20px',
        "@media(max-width:640px)": {
            backgroundColor: ColorTheme.white,
        }
    },
    inputField: {
        padding: "15px 20px",
        borderRadius: "30px",
        background: "#fff",
        border: "1px solid #eee !important",
        "@media(max-width:768px)": {
            border: "none !important",
        }
    },
    errorMessage: {
        color: ColorTheme.red,
        fontWeight: '700',
        paddingTop:'5px',
    },
    accordionContainer: {
        "& > div": {
            background: ColorTheme.lightBrown,
        }
    },
    processToPay: {
        "@media(max-width:640px)": {
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
        }
    },
    imgContent: {
        height: '65px',
        width: '65px',
        borderRadius: '10px',
        "& img": {
            height: '100%',
            width: '100%',
            maxWidth: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    txtContent: {
        paddingLeft: '10px',
        textAlign: 'left',
        "& h5":{
            marginTop:'5px',
        }
    },
})