import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    outer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-around',
        flexDirection: 'column',
        width: '100vw',
        height: '100%',
        overflow: 'hidden',
    },
    curve: {
        position: "relative",
        textAlign: 'center',
        height: '50%',
        width: '100%',
        "@media(max-width:640px)": {
            height: '40%',
        },
        "@media(max-height:640px)": {
            height: '40%',
        },
        "&::before": {
            content: "''",
            position: 'absolute',
            bottom: '0',
            left: '50%',
            width: '100vw',
            height: '50vh',
            background: ColorTheme.yellow,
            transformOrigin: 'bottom',
            transform: 'translateX(-50%) scale(8)',
            borderRadius: "0% 0% 0% 55%",
        },
        "& img": {
            position: 'absolute',
            left: '50%',
            top: '20%',
            transform: 'translate(-50%, 10px)',
            maxWidth: '200px',
            "@media(max-width:768px)": {
                maxWidth: '150px',
            },
            "@media(max-width:575px)": {
                maxWidth: '100px',
            },
        }
    },
    modes: {
        padding: '2rem 0',
        alignItems: 'stretch !important',
        "@media(max-width:640px)": {
            display: 'block',
        },
    },
    content: {
        minWidth: '350px',
        "@media(max-width:991px)": {
            minWidth: '300px',
        },
        "@media(max-width:768px)": {
            minWidth: '250px',
        },
        "@media(max-width:640px)": {
            minWidth: '200px',
            paddingLeft: '0px  !important',
            paddingBottom:'25px',
        },
        "@media(max-width:575px)": {
            minWidth: '200px',
        },
        "@media(max-width:475px)": {
            minWidth: 'auto',
            // width: '50%',
        },
        "& h2": {
            "@media(max-width:400px)": {
                fontSize: '1.5rem',
            },
        },
        "& a": {
            background: `${ColorTheme.lightYellow}`,
            borderRadius: '50px',
            padding: '40px',
            display: 'block',
            textAlign: 'center',
            boxSizing: 'border-box',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            "@media(max-width:768px)": {
                padding: '30px',
            },
            "@media(max-width:640px)": {
                padding: '20px',
                height: 'auto',
            },
            "& img": {
                width: 'auto',
                // maxWidth: '300px',
                minHeight: '200px',
                maxHeight: '200px',
                "@media(max-width:991px)": {
                    maxWidth: '180px',
                },
                "@media(max-height:768px)": {
                    maxWidth: '120px',
                },
                "@media(max-width:768px)": {
                    maxWidth: '120px',
                },
                "@media(max-width:575px)": {
                    maxWidth: '100px',
                },
                "@media(max-width:640px)": {
                    maxWidth: '120px',
                    minHeight: 'auto',
                }
            }
        },
    },
})