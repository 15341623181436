import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Recomended_Order_failure, Recomended_Order_request, Recomended_Order_success } from "../../reducers/recomendedorder/RecomendedOrder";
import { Headers } from "../../../APIHandler/HeaderAPI";

export const RecomendedOrderAPI = (payload) => async (dispatch, getState) => {
    dispatch(Recomended_Order_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
   

    try {

        const { data } = await axios.post(`${APIURL.recomendedorder}`, {
            itemIds: payloads?.itemId,
            outlet_id: payloads?.outletID
        }, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Recomended_Order_success(data))


    } catch (error) {
        dispatch(Recomended_Order_failure(error?.response?.data))
    }
}
