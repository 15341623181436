import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    cartItem: {
        background: ColorTheme.lightYellow,
        padding: '20px',
        borderRadius: '35px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    imgContent: {
        height: '100px',
        width: '100px',
        overflow: 'hidden',
        borderRadius: '20px',
        margin: '0 auto 20px',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        }
    },
    price: {
        marginTop: '15px',
        fontFamily: 'var(--sub-heading-family)',
        color: ColorTheme.darkGray,
    }
})

