import React from 'react'
import CommonStyle from "../../../Common/CommonCss/Common.module.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import nextIcon from '../../../images/nextArrow.png'
import prevIcon from '../../../images/prevArrow.png'
import FdIcon from '../../../images/fdIcon.png'
import Product from '../../../images/productImg.png'
import CountdownTimer from '../../../Components/FlashDeal/Counter/CountdownTimer';
import { useNavigate } from "react-router-dom";

import { Styles } from './Style'
import { withStyles } from "@mui/styles";
import { useBreakpoint } from '../../../CustomHooks/useBreakpoint';

const ListSlider = (props) => {
    const { classes,FlashDealLimitSlice } = props;
    const navigate = useNavigate();
    const Breakpoint=useBreakpoint();
    const handleClick = (id) => {
        navigate(`/fd-detail/${id}`)
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        centerPadding: 20,
        nextArrow: <NextArrow classes={classes} />,
        prevArrow: <PrevArrow classes={classes} />,
        responsive: [
          {
            breakpoint: 2169, // breakpoint at which these settings will apply
            settings: {
              slidesToShow:  FlashDealLimitSlice?.length==1 ? 1 : FlashDealLimitSlice?.length==2 ?2:3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1369, // breakpoint at which these settings will apply
            settings: {
              slidesToShow:  FlashDealLimitSlice?.length==1 ? 1 : FlashDealLimitSlice?.length==2 ?2:3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024, // breakpoint at which these settings will apply
            settings: {
              slidesToShow: FlashDealLimitSlice?.length==1? 1 : 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640, // breakpoint at which these settings will apply
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      function NextArrow(props) {
        const { onClick, classes } = props;
        return (
            <img src={nextIcon} alt="Next" className={`${classes.arrow} ${classes.nextArrow}`} onClick={onClick} />
        );
    }

    // Custom arrow component for previous button
    function PrevArrow(props) {
        const { onClick } = props;
        return (
            <img src={prevIcon} alt="Previous" className={`${classes.arrow} ${classes.prevArrow}`} onClick={onClick} />
        );
    }
  return (
    <div 
    style={{ width: (Breakpoint=="lg") ? (FlashDealLimitSlice?.length==1? "33%":  FlashDealLimitSlice?.length==2 ?"66%":""): Breakpoint=="md"?(FlashDealLimitSlice?.length==1? "66%":"100%"): "100%"}}
    >
      <Slider {...settings}>
              {
                FlashDealLimitSlice?.map((data)=>{
                    return <div className={`${classes.itemContainer} ${CommonStyle.cursorPointer}`}  onClick={(e) => handleClick(data?.id)}>
                    <div className={classes.itemSlider} >
                        <div className={classes.sliderItemTop}>
                            <div className={`${CommonStyle.relative}`}>
                                <img src={data?.original} alt='Product Icon' className={`${classes.allProdImg} ${classes.rounded20}`} />
                                <div className={`${classes.digit} ${classes.flexDimension} ${CommonStyle.absolute}`}>
                                    <p className={classes.flexDimension}>{data?.remaining_quantity}</p>
                                </div>
                            </div>
                            <div className={CommonStyle.ml10}>
                                <img src={FdIcon} className={classes.fdProdIcon} alt="Icon" />
                                <h3 className={classes.sliderHeading}> {data?.name} </h3>
                                <p className={classes.sliderContent}>{data?.description}</p>
                            </div>
                        </div>
                        <p className={classes.borderDash}></p>
                        <div>
                            <CountdownTimer initialTime={data?.end_date} />
                        </div>
                    </div>
                </div>
                })
              }
                
                
            </Slider>
    </div>
  )
}

export default withStyles(Styles)(ListSlider)
