import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    datapicker: {
        border: '1px solid #f1eedb',
        borderRadius: '32px',
        width: '100%',
        "&  div": {
            width: '100%',
            padding: '0',
            borderRadius: '32px',
        },
        "& > div": {
            background: ColorTheme.white,
            position: 'relative',
            padding: '3px',
            "& input": {
                border: 'none',
                fontSize: '14px',
                fontWeight: '500',
                color: ColorTheme.secondaryColor,
            }
        },
        "& input": {
            minHeight: 'auto',
            width: '100%',
            paddingRight: '50px',
            fontSize: '0.875rem',
            lineHeight: '1.188rem',
            color: ColorTheme.fontColor,
        },
        "& button": {
            position: 'absolute',
            right: '20px',
            opacity: '0',
            width: '80px',
            height: '50px',
        },
        "& .MuiOutlinedInput-root ": {
            paddingRight: '0',
        },
        "& img": {
            position: 'absolute',
            right: '30px',
            top: '20px',
            pointerEvents: 'none',
            margin: '0  !important',
        },
    }
})
