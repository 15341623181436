import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import { useNavigate } from 'react-router';
import StrConstent from "../../Common/StrConstent";
import BackArrow from '../../Components/BackArrow/BackArrow';
import { NavLink } from 'react-router-dom';
import ContainerBox from '../../Components/ContainerBox/ContainerBox';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BorderButton from '../../Components/BorderButton/BorderButton';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { NotificationAPI } from '../../redux/Action/Notification/Notification';
import { convertDDMMYYY } from '../../Common/utlis/utlis';
import { Button } from '@mui/material';
import Loader from '../../KioskComponents/Loader/Loader';
import CustomButton from '../../Components/CustomButton/CustomButton';
const Notifications = (props) => {
    const { classes, } = props
    let navigate = useNavigate();
    const [page, setPage] = useState(1);
    const NotificationSlice = useSelector(state => state?.NotificationSlice)
    const dispatch = useDispatch()
    const [notiData, setNotiData] = useState([]);
    useEffect(() => {
        dispatch(NotificationAPI(page))
    }, [])
    const loadMoreHandler = () => {
        const nextPage = page + 1;
        setPage(nextPage);
    };
    useEffect(() => {
        if (NotificationSlice?.value?.data != null && NotificationSlice?.value?.data != undefined) {
            if (notiData?.length > 0) {
                if (page > 1) {

                    // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                    setNotiData([...notiData, ...NotificationSlice?.value?.data?.notifications]);
                }
            } else {
                setNotiData(NotificationSlice?.value?.data?.notifications);
            }
        }
    }, [NotificationSlice]);
    //   useEffect(() => {
    //     if (page === 1) {
    //       setNotiData([]);
    //     }

    //   }, [page]);
    useEffect(() => {
        dispatch(NotificationAPI(page))
    }, [page])
    // console.log("NotificationSlice", NotificationSlice)
    return (
        <>
            {NotificationSlice?.loading && (
                <Loader />
            )}
            <ContainerBox>
                <BackArrow clickHandler={() => navigate("-1")} />
                <h2 className={CommonStyle.mb20}>{StrConstent.notifications.heading}</h2>
                <div className={classes.notificationsOuter}>
                    <ul>
                        {notiData?.map((data) => {
                            return <li>
                                <div className={`${classes.notificationBox} ${classes.grayBorder}`}>
                                    <h5 className={CommonStyle.fw700}>{data?.title}</h5>
                                    {/* <p>Your order no. #123456 for 100 Credits, has beed completed successfully</p> */}
                                    <p>{data?.message}</p>
                                    <div className={CommonStyle.flexSpaceBetween}>
                                        <BorderButton label="Order details" clickHandler={() => navigate("/order-details")} />
                                        {/* <p>23 Feb 2022</p> */}
                                        {convertDDMMYYY(data?.created_at)}
                                    </div>
                                </div>
                            </li>
                        })}
                        {
                            NotificationSlice?.value?.data?.pagination?.lastPage === page
                                ?
                                null :

                                // <Button onClick={() => loadMoreHandler()}>AddMore</Button>
                                // <div className={}>
                                <div className={`${CommonStyle.flex} ${CommonStyle.JustifyCenter}`}>
                                    <CustomButton label="Add More" clickHandler={() => loadMoreHandler()} />
                                </div>
                        }
                        {/* {NotificationSlice?.value?.data?.pagination?.lastPage === page ||
                            // NotificationSlice?.value?.data?.pagination?.count === notiData.length ||
                            notiData.length < 1 ? null : (
                            <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
                        )} */}

                    </ul>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(Notifications);