import axios from "axios"
import { Headers, Hipster_Base_URL } from "../../../APIHandler/HeaderAPI"
import { APIURL } from "../../../APIHandler/APIURL"
import { Categories_without_login_failure, Categories_without_login_request, Categories_without_login_success } from "../../reducers/CategoriesWithoutLogin/CategoriesWitoutlogin"



export const Categorieswithout=(payload)=>async(dispatch,getState)=>{
  
  dispatch(Categories_without_login_request())  
  try {
      const {data}= await axios.get(`${APIURL.categories}`,{
        headers:Headers
      })  
     dispatch(Categories_without_login_success(data)) 
    } catch (error) {
      dispatch(Categories_without_login_failure(error)) 
    }
}