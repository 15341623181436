import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Reservation_Outlet_failure, Reservation_Outlet_request, Reservation_Outlet_success } from "../../reducers/reservationOutlets/ReservationOutlets";

export const ReservationoutletAPI = (payload) => async (dispatch, getState) => {
    dispatch(Reservation_Outlet_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
   

    try {

        const { data } = await axios.get(`${APIURL.reservationoutlet}`,{
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Reservation_Outlet_success(data))


    } catch (error) {
        dispatch(Reservation_Outlet_failure(error?.response?.data))
    }
}
