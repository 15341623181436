import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    loginWrapper: {
        height: 'calc(100% - 400px)',
        display: 'flex',
        alignItems: 'center',
        "@media(max-width:640px)": {
            height: 'auto',
            display: 'block',
        },
        "@media(max-height:768px)": {
            height: 'auto',
        }
    },
    accOuter: {
        width: '100%',
        maxHeight: '400px',
        margin: '0 auto',
        padding: '2rem',
        boxSizing: 'border-box',
        borderRadius: '25px',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: ColorTheme.lightBrown,
        overflow: 'auto',
        '@media(max-width:640px)': {
            background: ColorTheme.transparent,
            padding: '0',
            height: '100%',
            maxWidth: '100%',
            minHeight: '150px',
            marginBottom: '0',
            overflow: 'inherit',
        }
    }
})