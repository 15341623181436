import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
    loading: false,
    
}

export const CartDraweOpenSlice = createSlice({
    name: 'CartDraweOpenSlice',
    initialState,
    reducers: {
        Cart_Drawer_Open_request: (state, action) => {
            state.value = false;
            state.loading = true;
        },
        Cart_Drawer_Open_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
     
        },
        Cart_Drawer_Open_failure: (state, action) => {
          
            state.loading = false;
            state.value = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Cart_Drawer_Open_request, Cart_Drawer_Open_success ,Cart_Drawer_Open_failure} = CartDraweOpenSlice.actions

export default CartDraweOpenSlice.reducer