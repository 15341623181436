import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import SmallButton from '../SmallButton/SmallButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Cart_Drawer_Open_success } from '../../redux/reducers/CartdraweropenSlice';
const CardItem = (props) => {
    const { classes, clickHandle } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchor, setAnchor] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const HeaderHeightSlice = useSelector(state => state?.HeaderHeightSlice?.value)
    const CartItemAddedSlice = useSelector(state => state?.CartItemAddedSlice)
    const [cartitems, setcartitems] = React.useState("")
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const handleClick = (event) => {
        setOpen(true)
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false)
        setAnchor(null);
    };
    React.useEffect(() => {
        if (CartItemAddedSlice?.value?.length > 0) {
            setOpen(true)
            setcartitems(CartItemAddedSlice?.value[0])
        } else {
            setOpen(false)

        }
    }, [CartItemAddedSlice])

    // const opens = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    const opens = Boolean(anchorEl);
    const id = opens ? 'simple-popover' : undefined;

    return (
        <div>
            {/* <Button  variant="contained" onClick={handleClick}>
                Open Popover
            </Button> */}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableScrollLock={true}
                className="card-popover"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={classes.cardItem}>
                    <h6 className={CommonStyle.mb20}>{t("Item(s) added")}</h6>
                    <div className={`${CommonStyle.flex} ${CommonStyle.flex} ${CommonStyle.pb20}`}>
                        <div className={classes.proImgContent}>
                            <img src={cartitems?.url} alt="Not Found" />
                        </div>
                        <h5>{cartitems?.name}</h5>
                    </div>
                    <div className={classes.addCard} onClick={() => dispatch(Cart_Drawer_Open_success(true))}><SmallButton clickHandle="" label={t("View Cart")} /></div>
                </div>
            </Popover>
        </div>
    );
}

export default withStyles(Styles)(CardItem);