import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Navbar from "../Navbar/Navbar";
import Container from "@mui/material/Container";
import { Styles } from "./Style";
import { styled } from '@mui/material/styles';
import { Helmet } from "react-helmet";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StarIcon from '@mui/icons-material/Star';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ColorTheme from "../../Common/CommonCss/ColorTheme";
import blackLogo from '../../images/blackLogo.svg';
import shoppingGif from '../../images/shopping.gif';
import shopping from '../../images/shopping.png';
import closeIcon from '../../images/closeIcon.svg';
import user from '../../images/user.svg';
import toggleWhite from '../../images/toggleWhite.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from "react-redux";
import { RewardsPonitsAPI } from "../../redux/Action/RewardPoints/RewardPonits";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import RoundedDrawer from "../BottomDrawer/RoundedDrawer";
import CustomModal from "../CustomModal/CustomModal";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import CustomButton from "../CustomButton/CustomButton";
import GrayButton from "../GrayButton/GrayButton";
import LogoutRoundedDrawer from "../BottomDrawer/LogoutRoundedDrawer";
import { Logout_Drawer_success } from "../../redux/reducers/LogoutDrawerSlice";
import { OrderHistoryAPI } from "../../redux/Action/Orderhistory/OrderHistory";
import { OrderDetailAPI } from "../../redux/Action/OrderDetails/OrderDetails";
import { CartClearAPI } from "../../redux/Action/CartClear/CartClear";
import StrConstent from "../../Common/StrConstent";
import BorderButton from "../../KioskComponents/BorderButton/BorderButton";
import { Header_Height_success } from "../../redux/reducers/HeaderHeight";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import MultipleSelectLanguage from "../MultipleSelectLanguage/MultipleSelectLanguage";
import { Cart_Drawer_Open_success } from "../../redux/reducers/CartdraweropenSlice";
import DrawerRightCardItem from "../DrawerRightCardItem/DrawerRightCardItem";
import CardModal from "./CardModal";
import { IconButton } from "@mui/material";
import CardItem from "./CardItem";
import { Clear_Login_Cart } from "../../redux/reducers/LoginCart/GetLoginCartSlice";
import { LatLongLocationAPI } from "../../redux/Action/LatLongLocation";
import { GetLoginCartAPI } from "../../redux/Action/LoginCart/GetLoginCartAction";
import { Cart_Clear_count_success } from "../../redux/reducers/CartClearslicecount";
const Header = (props) => {
    const { classes, cancelHandler } = props;
    const device = useBreakpoint();
    const [age, setAge] = React.useState('');
    const [scrollPosition, setScrollPosition] = useState(0);
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const Logindetails = localStorage.getItem("Logindetails")
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const [state, setState] = useState({
        right: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const AddtocartRefresh = useSelector(state => state?.AddtocartRefreshSlice?.value)
    const RewardsPoints = useSelector(state => state?.RewardsPointsSlice)
    // const companydata=localStorage.getItem("companydata")
    const url = window.location.href
    const kiosk = url?.includes("kiosk") ? "1" : url?.includes("qr") ? "2" : "3"
    const urlsucesspage = url?.includes("success") || url?.includes("cart") || url?.includes("login") || url?.includes("proceed-to-pay") ? true : false
    const outlet_mode = localStorage.getItem("outlet_mode")
    const outletname = localStorage.getItem("outletname")
    const urlsuccesscheck=url?.includes("success") || url?.includes("choose-mode")
    const DrawerSlice = useSelector(state => state?.LogoutDrawerSlice?.value)
    const [pickuppopup, setpickuppopup] = useState(false)
    const [choosemodepopup, setchoosemodepopup] = useState(false)
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const addtocart = localStorage.getItem("addtocart")
    const addtocarts = addtocart ? JSON.parse(addtocart) : []
    const [nodata, setnodata] = useState([])
    const companyoutletcount = localStorage.getItem("companyoutletcount")
    const [cartitem, setcartitem] = useState();
    const SubcategoriesSlice = useSelector(state => state?.SubcategoriesSlice?.value)
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const urlnew = window.location.href;
    const urlnewmatch = urlnew?.includes("baby-on-board")
    const [count, setcount] = useState(0)
    const urlresetpasswordcheck = url?.includes("reset-password")

    const homeurl=url.charAt(url.length - 1);
    useEffect(() => {
        setnodata(addtocartlogin?.value ? addtocartlogin?.value : (addtocart ? JSON.parse(addtocart) : []))
        localStorage.setItem("cartcount", addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items?.length : (addtocart ? JSON.parse(addtocart)?.length : 0))
    }, [])
    useEffect(() => {
        setnodata(addtocartlogin?.value ? addtocartlogin?.value : (addtocart ? JSON.parse(addtocart) : []))
        if (Logindetail && addtocartlogin?.value?.data?.cart_items?.length != undefined && addtocartlogin?.value?.data?.cart_items?.length != null) {

            setcartitem(addtocartlogin?.value?.data?.cart_items)

            localStorage.setItem("cartcount", addtocartlogin?.value?.data?.cart_items?.length)
        } else if (!Logindetail) {
            setcartitem((addtocart ? JSON.parse(addtocart) : []))

            localStorage.setItem("cartcount", (addtocart ? JSON.parse(addtocart)?.length : 0))

        } else if (addtocartlogin?.error?.success == false) {
            dispatch(Clear_Login_Cart())
            setcartitem(0)
        }

    }, [addtocartlogin, addtocart, AddtocartRefresh])

    const handleChange = (event) => {
        if ((!Logindetail && addtocarts?.length == 0) || (Logindetail && nodata?.data?.cart_items?.length == 0)) {

            if (event.target.value == 10) {
                localStorage.removeItem("addtocart")
                if (companyoutletcount == "1" || companyoutletcount == 1) {
                } else {

                    localStorage.removeItem("outletname")
                    localStorage.removeItem("address_id")
                    localStorage.removeItem("outletID")
                }
                navigate("/")
            } else if (event.target.value == 20) {
                localStorage.removeItem("addtocart")
                localStorage.removeItem("outlet_mode")
                localStorage.removeItem("address_id")
                if (companyoutletcount == "1" || companyoutletcount == 1) {
                } else {

                    localStorage.removeItem("outletname")
                    localStorage.removeItem("outletID")
                } navigate("/")

            }
        } else {
            if (event.target.value == 10) {
                setpickuppopup(true)
            } else if (event.target.value == 20) {
                setchoosemodepopup(true)

            }
        }
    };


    const handleCartClear = () => {

        localStorage.removeItem("addtocart")
        localStorage.removeItem("outlet_mode")
        if (companyoutletcount == "1" || companyoutletcount == 1) {
        } else {

            localStorage.removeItem("outletname")
            localStorage.removeItem("outletID")
        } dispatch(CartClearAPI())
        setchoosemodepopup(false);
        if (Logindetail) {

            navigate("/");
            dispatch(LatLongLocationAPI(SubcategoriesSlice, 1, ""))
            dispatch(Cart_Clear_count_success(count + 112))
            setcount(count + 112)
            dispatch(GetLoginCartAPI())
        } else {
            navigate("/");
            window.location.reload();
        }


    }
    const handlepickupCartClear = () => {

        localStorage.removeItem("addtocart")
        // localStorage.removeItem("outlet_mode")
        if (companyoutletcount == "1" || companyoutletcount == 1) {
        } else {

            localStorage.removeItem("outletname")
            localStorage.removeItem("outletID")
        }
        dispatch(CartClearAPI())
        setpickuppopup(false)
        if (Logindetail) {
            navigate("/");
            dispatch(LatLongLocationAPI(SubcategoriesSlice, 1, ""))

        } else {

            navigate("/");
            // window.location.reload();
        }

    }
    const handleClose = () => {
        setpickuppopup(false)
        setchoosemodepopup(false);
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if (Logindetail) {
            dispatch(OrderDetailAPI())
            dispatch(OrderHistoryAPI())
        }
    }, [])
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    const componentRef = useRef(null);
    const CartDraweOpenSlice = useSelector(state => state?.CartDraweOpenSlice?.value)

    useEffect(() => {
        if (componentRef.current) {
            const height = componentRef.current.clientHeight;
            dispatch(Header_Height_success(componentRef.current.clientHeight))
        }
    })
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };


    }, []);
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 12,
        borderRadius: 5,
        display: 'flex',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            height: 6,
            margin: 3,
            backgroundColor: theme.palette.mode === 'light' ? ColorTheme.black : '#308fe8',
        },
    }));
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === "/") {
        }
        else {
        }
    }, [location]);

    const MenuProps = {
        disableScrollLock: true
    };
    const handleClear = () => {

        dispatch(Logout_Drawer_success(true))
    }

    // translater
    const { t } = useTranslation();
    const handleClick = (e) => {
        i18next.changeLanguage(e.target.value);
    }
    const list = (anchor) => (
        <Box
            className={classes.sidebarBox}
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 375 }}
            role="presentation">
            <div>
                <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween} ${CommonStyle.mb2rem}`}>
                    <div className={classes.logo}>
                        <NavLink to="/"><img src={companydata?.footer_logo} alt="Not Found" /></NavLink>
                    </div>
                    <NavLink onClick={toggleDrawer(anchor, false)} id="closeDrawer">
                        <img src={closeIcon} alt="Not Found" />
                    </NavLink>
                </div>
                <div className={classes.review}>
                    <div  
                    // onClick={toggleDrawer(anchor, false)}
                    >
                        <div className={`${CommonStyle.flexCenter} ${CommonStyle.mb20}`}>
                            <div className={classes.userImgContent}>
                                <img src={user} alt="Not Found" />
                            </div>
                            <div className={CommonStyle.userName}>
                                <h6>{t("Hello")}</h6>
                                <h3>{RewardsPoints?.value?.data?.name}</h3>
                            </div>
                        </div>
                        <div className={classes.reviewContent}>
                            <h3>{t("Reward Points")}</h3>
                            <div className={classes.rating}><h3>{RewardsPoints?.value?.data?.rewards_point==undefined?0:RewardsPoints?.value?.data?.rewards_point} </h3>
                            </div>
                        </div>
                        {/* <BorderLinearProgress variant="determinate" value={RewardsPoints?.value?.data?.rewards_point==undefined?0:(RewardsPoints?.value?.data?.rewards_point * 100) / 125 > 100 ? 100 : (RewardsPoints?.value?.data?.rewards_point * 100) / 125} className={CommonStyle.progressBar} /> */}
                    </div>
                </div>
                <Navbar cancelHandler={toggleDrawer(anchor, false)} />
            </div>
            {Logindetail && <div className={classes.signout}
                onClick={() => { handleClear() }}
            >
                <NavLink onClick={() => {
                    toggleDrawer(anchor, false)
                }}>{t("Sign out")}</NavLink>
            </div>}
        </Box>
    );
    useEffect(() => {
        if (Logindetail) {
            dispatch(RewardsPonitsAPI())
        }
    }, [])

    const handleCartclick = () => {
        dispatch(Cart_Drawer_Open_success(true))
    }

    useEffect(() => {
        if (cartitem?.length == 0) {
            document.getElementById("root").classList.add(`${CommonStyle.cardAdd}`);
        }
        else {
            document.getElementById("root").classList.remove(`${CommonStyle.cardAdd}`);
        }
    })
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{companydata?.title}</title>
            <link rel="icon" href={companydata?.favicon} />
            <link
                href={companydata?.google_font_link}
                rel="stylesheet" />
        </Helmet>
        <div className={`${classes.header} ${location.pathname === "/" ? classes.headerTransparent : ''} ${scrollPosition > 0 && `${CommonStyle.fixed} ${classes.fixed}`} `} ref={componentRef}>
            <Container maxWidth="xl" style={{display:urlresetpasswordcheck ?"flex":"",justifyContent:urlresetpasswordcheck ?"center":""}}>
                <div className={classes.headerInner} >
                    <div className={`${CommonStyle.flexCenter} ${classes.logoContainer}`}>
                        {/* <div className={`${classes.logoBox} ${!(outletname || outlet_mode) ? '' : classes.logo}`}> */}
                        <div className={`${classes.logoBox} ${!urlresetpasswordcheck ? classes.logoMobHide:""}`}>
                          {!urlresetpasswordcheck &&  <NavLink to="/">
                                <img src={companydata?.logo} alt="Not Found" />
                            </NavLink>}
                          {urlresetpasswordcheck &&  <>

                                <img src={companydata?.logo} alt="Not Found" />
                          </>
                            }
                        </div>
                       {!urlresetpasswordcheck && homeurl!="/" &&!urlsuccesscheck && <div className={classes.customSelect}>
                            { urlnewmatch ? "" :
                                <>
                                    {(outletname || outlet_mode) &&
                                        <Select
                                        className="selecticon"
                                            value={age}
                                            onChange={handleChange}
                                            MenuProps={
                                                {
                                                    sx: {
                                                        "&& .Mui-selected": {
                                                            backgroundColor: `${ColorTheme.yellow} !important`,
                                                            color: ColorTheme.secondaryColor,
                                                        },
                             
                                                    },
                                                    disableScrollLock: true
                                                }
                                            }
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }} 
                                            
                                            >
                                            <MenuItem value="" style={{ display: "block" }}>
                                                <p style={{ color: `${ColorTheme.secondaryColor}` }}>{outlet_mode}</p>
                                                {outletname &&
                                                    <span>{outletname}</span>}
                                            </MenuItem>
                                            {outletname && (
                                                <MenuItem value={10}>{t("Change Outlet")}</MenuItem>
                                            )
                                            }
                                            <MenuItem value={20}>{t("Change order mode")}</MenuItem>
                                        </Select>
                                    }
                                </>
                            }
                        </div>}
                        {/* <CardItem /> */}
                    </div>
                   {!urlresetpasswordcheck && <div className={classes.toggleBtnContent}>
                        {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <div className={`${classes.logoBox} ${classes.logoMobShow}`}>
                                    <NavLink to="/">
                                        <img src={companydata?.logo} alt="Not Found" />
                                    </NavLink>
                                </div>
                                <div className={CommonStyle.flex}>
                                    {
                                        isappwebviewcheck != "acecard" && (

                                            <MultipleSelectLanguage onChange={(e) => handleClick(e)} />
                                        )
                                    }
                                    <CardItem />
                                    {/* <CardModal /> */}
                                    <div className={classes.iconContainer}>
                                        {!urlsucesspage &&
                                            <>
                                                {cartitem?.length == 0 ?
                                                    <IconButton className={`${classes.toggleBtn} ${CommonStyle.ripple}`} onClick={() => handleCartclick()}>
                                                    
                                                        <img src={shopping} alt="Not Found" height="35" />
                                                        <sup class={`${classes.itemNumber}`}>{cartitem?.length}</sup>
                                                    </IconButton>
                                                    :
                                                    <IconButton className={`${classes.toggleBtn} ${CommonStyle.ripple}`} onClick={() => handleCartclick()}>
                                                        <img src={shopping} alt="Not Found" height="35" />

                                                        <sup class={`${classes.itemNumber} ${CommonStyle.hithere}`}>{cartitem?.length != undefined ? cartitem?.length : 0}</sup>
                                                    </IconButton>
                                                }
                                            </>
                                        }
                                    
                                        {isappwebviewcheck != "acecard" && <>
                                            {Logindetails ?
                                                <React.Fragment>
                                                    {/* <NavLink><IconButton className={`${classes.toggleBtn}`}><img src={user} alt="Not Found" /></IconButton></NavLink> */}
                                                    <NavLink onClick={toggleDrawer(anchor, true)}>
                                                        <IconButton className={`${classes.toggleBtn}`}><img src={toggleWhite} alt="Not Found" /></IconButton>
                                                    </NavLink>
                                                </React.Fragment>
                                                : <NavLink onClick={toggleDrawer(anchor, true)}>
                                                    <IconButton className={`${classes.toggleBtn}`}><img src={toggleWhite} alt="Not Found" /></IconButton>
                                                </NavLink>}
                                        </>
                                        }
                                    </div>
                                </div>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    transitionDuration={800}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}>
                                    {list(anchor)}
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </div>}
                </div>
            </Container>
            {
                device == "sm" ? <>
                    <LogoutRoundedDrawer>
                        <h3 className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>Are you sure  you want to logout.</h3>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            <BorderButton label={"Cancel"} clickHandler={() => dispatch(Logout_Drawer_success(false))} />
                            <CustomButton label={"Confirm"} clickHandler={() => {
                                localStorage.clear()
                                navigate("/")
                                window.location.reload()
                            }} />
                        </div>
                    </LogoutRoundedDrawer>
                </> : <>
                    {DrawerSlice == true && <CustomModal
                        open={true}
                        // title={StrConstent.cart.cardClear.heading}
                        handleClose={() => dispatch(Drawer_success(false))}
                        description={""}>
                        <h3 className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>Are you sure  you want to logout.</h3>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            <BorderButton label={"Cancel"} clickHandler={() => dispatch(Logout_Drawer_success(false))} />
                            <CustomButton label={"Confirm"} clickHandler={() => {
                                localStorage.clear()
                                navigate("/")
                                window.location.reload()
                            }} />
                        </div>
                    </CustomModal>}
                </>
            }
        </div>
        {device == "sm" ? <>
            {
                choosemodepopup == true && (
                    <RoundedDrawer
                        truekey={choosemodepopup}
                        setruekey={setchoosemodepopup}
                    >
                        <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            <BorderButton label={"Cancel"} clickHandler={() => setchoosemodepopup(false)} />
                            <CustomButton label={"Confirm"} clickHandler={handleCartClear} />
                        </div>
                    </RoundedDrawer>
                )
            }
        </> : <>
            <CustomModal
                open={choosemodepopup}
                title={""}
                handleClose={() => handleClose()}
                description={""}>
                <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                    <BorderButton label={"Cancel"} clickHandler={() => setchoosemodepopup(false)} />
                    <CustomButton label={"Confirm"} clickHandler={() => handleCartClear()} />
                </div>
            </CustomModal>
        </>}
        {
            device == "sm" ? <>
                {
                    pickuppopup == true && (
                        <RoundedDrawer
                            truekey={pickuppopup}
                            setruekey={setpickuppopup}
                        >
                            <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                            <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                                <BorderButton label={"Cancel"} clickHandler={() => setpickuppopup(false)} />
                                <CustomButton label={"Confirm"} clickHandler={handlepickupCartClear} />
                            </div>
                        </RoundedDrawer>
                    )
                }
            </> : <>
                <CustomModal
                    open={pickuppopup}
                    title={""}
                    handleClose={() => handleClose()}
                    description={""}>
                    <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                    <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                        <BorderButton label={"Cancel"} clickHandler={() => setpickuppopup(false)} />
                        <CustomButton label={"Confirm"} clickHandler={() => handlepickupCartClear()} />
                    </div>
                </CustomModal>
            </>
        }

        <DrawerRightCardItem />
    </>
    );
};
export default withStyles(Styles)(Header);
