import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
 
}

export const LogoutDrawerSlice = createSlice({
  name: 'LogoutDrawer',
  initialState,
  reducers: {
    Logout_Drawer_request: (state, action) => {
      state.value=false;

    },
    Logout_Drawer_success: (state, action) => {

      state.value = action.payload;

    }
  }
})

// Action creators are generated for each case reducer function
export const {Logout_Drawer_request, Logout_Drawer_success } = LogoutDrawerSlice.actions

export default LogoutDrawerSlice.reducer