import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    listing: {
        padding: '20px 0 0 40px',
        "& p": {
            paddingBottom: '10px',
        }
    },
    boxWrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: '15px 0',
    },
    boxIcon: {
        minHeight: '50px',
        minWidth: '50px',
        background: ColorTheme.yellow,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        marginRight: '15px',
        "@media(max-width:640px)": {
            minHeight: '40px',
            minWidth: '40px',
        },
        "& svg": {
            fontSize: '30px',
            "@media(max-width:640px)": {
                fontSize: '25px',
            },
        }
    }
})