import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error:{}
}

export const UpdateAddressSlice = createSlice({
  name: 'UpdateAddress',
  initialState,
  reducers: {
    Update_Address_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Update_Address_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};
    },
    Update_Address_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.value = {};
    },
    Clear_Update_Address_Address: (state, action) => {
        state.error = {};
        state.loading = false;
        state.value = {};
    },
  }
})

// Action creators are generated for each case reducer function
export const {Update_Address_request, Update_Address_success,Update_Address_failure ,Clear_Update_Address_Address} = UpdateAddressSlice.actions

export default UpdateAddressSlice.reducer