import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Payment_OrderId_request, Payment_OrderId_success } from "../../reducers/Stripepayment/PaymentOrderIdSlice"
import { Headers } from "../../../APIHandler/HeaderAPI"


export const PaymentOrderIdAPI = (payload) => async (dispatch, getState) => {

    dispatch(Payment_OrderId_request())
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    // console.log("payload", payload);
    const cart_identifier = localStorage.getItem("cart_identifier")
    const paymentorderID=localStorage.getItem("paymentorderID")
    const payloads = payload?payload:paymentorderID
    try {

        const { data } = await axios.get(`${APIURL.paymentorderid}/${payloads}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        
        // console.log("paymentorderID", data)
      dispatch(Payment_OrderId_success(data))

    } catch (error) {
        dispatch(Payment_OrderId_success(error?.response?.data))
        // console.log("checkouterror", error)
    }
}
