import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
const names = [
    'Change Outlet',
    'Change order mode',
    "Others"
];

const MenuProps = {
    disableScrollLock: true
    // "&":{
    //     .Mui-selected{

    //     }
    // }
};

function CustomSelect(props) {
    const { classes, handleChange, personName ,typebox} = props;
    const theme = useTheme();

    const genderobj=[
        'Male',
        'Female',
        "Others"
    ];
    const Slectboxcheck=typebox=="gender"?genderobj:names;
    // const [personName, setPersonName] = React.useState([]);

    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setPersonName(
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    // console.log("selectgender",personName)
    return (
        <div className='aceCart'>
            <div className={classes.selectBox}>
                <Select
                    value={personName}
                    onChange={handleChange}
                    // MenuProps={MenuProps}
                    MenuProps={
                        {
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: `${ColorTheme.yellow} !important`,
                                    color: ColorTheme.secondaryColor,
                                }
                            },
                            disableScrollLock: true
                        }
                    }
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <p>Gender</p>;
                        }
                        return selected.join(', ');
                    }}>
                    {Slectboxcheck.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </div>
    );
}

export default withStyles(Styles)(CustomSelect);
