import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    orderSummary: {
        "& > div": {
            "@media(max-width:640px)": {
                padding: '0 !important',
            }
        }
    },
    successMark: {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        objectFit: 'cover',
        backgroundColor: ColorTheme.green,
        margin: '10px auto 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media(max-width:640px)": {
            width: '65px',
            height: '65px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
            maxWidth: '55px',
            "@media(max-width:640px)": {
                maxWidth: '40px',
            },
        }
    },
    orderSummaryInner: {
        textAlign: 'center',
    },
    address: {
        color: ColorTheme.fontColor,
        margin: '10px 0 0',
    },
    getDirBtn: {
        margin: '15px 0 2rem',
        "& button": {
            padding: '8px 15px !important',
            minWidth: '150px'
        }
    },
    listing: {
        paddingBottom:'20px',
        "& li": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px 0',
            borderBottom: '1.09px solid #D5D4DC',
            "&:last-child": {
                borderBottom: 'none',
                paddingBottom: '0',
            }
        },
    },
    leftContent: {
        width: '70%',
    },
    title: {
        color: ColorTheme.fontColor,
    },
    rightContent: {
        width: '30%',
        textAlign: 'right',
        marginLeft: '10px !important',
        justifyContent: 'flex-end',
        "& p": {
            textAlign: 'right',
            color: ColorTheme.fontColor,
        },
        "& button": {
            float: 'right',
            marginLeft: '5px',
            marginTop: '8px',
        }
    },
    imgContent: {
        height: 'auto',
        width: '65px',
        borderRadius: '10px',
        "& img": {
            height: 'auto',
            maxWidth: '65px',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    txtContent: {
        paddingLeft: '10px',
        textAlign: 'left',
    },
    downloadReceipt: {
        marginLeft: 'auto',
        "@media(max-width:640px)": {
            width: '100%',
            "& div": {
                width: '100%',
            },
            "& button": {
                width: '100%',
                backgroundColor: ColorTheme.white,
            }
        }
    },
    strapper: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        alignItems: 'baseline',
        position: 'relative',
        "& > li": {
            width: '100%',
            "&:first-child": {
                "& > div ": {
                    margin: '0',
                }
            },
            "&:last-child": {
                "& > div ": {
                    margin: '0 0 0 auto',
                }
            }
        },

    },
    strapperItem: {
        maxWidth: '200px',
        margin: '0 auto',
        "@media(max-width:768px)": {
            maxWidth: '150px',
        },
        "@media(max-width:575px)": {
            maxWidth: '130px',
        },
        "@media(max-width:475px)": {
            maxWidth: '100px',
        },
        "&::after": {
            content: "''",
            left: '0',
            top: 'calc(100% - 50px)',
            width: '98%',
            borderTop: `1px solid ${ColorTheme.yellow}`,
            position: 'absolute',
            zIndex: '1',
            "@media(max-width:475px)": {
                top: 'calc(100% - 70px)',
            },
        },
    },
    stap: {
        padding: '5px 10px',
        border: '1.5px solid',
        borderRadius: '20px',
        borderColor: ColorTheme.yellow,
        textAlign: 'center',
        minWidth: '150px',
        margin: '12px 0',
        zIndex: '2',
        position: 'relative',
        background: ColorTheme.lightBrown,
        "@media(max-width:768px)": {
            minWidth: 'auto',
        },
    },
    strapperImg: {
        width: '30px',
        height: 'auto',
        margin: '0 auto',
        "@media(max-width:640px)": {
            width: '25px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
        }
    },
    accordionContainer: {
        "& > div": {
            background: ColorTheme.lightBrown,
        },
    },
})