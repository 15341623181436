import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const GroupToggleBtn = (props) => {
  const { classes, children } = props;
  const [alignment, setAlignment] = React.useState('web');
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <ToggleButtonGroup exclusive className={classes.toogleBtnBox}
      onChange={handleChange}>
      {children}
    </ToggleButtonGroup>
  );
};

export default withStyles(Styles)(GroupToggleBtn);
