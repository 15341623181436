import React, { useEffect, useState } from "react";
import Navigation from "./Navigation/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrlAPI } from "./redux/Action/BaseUrlAction";
import { LatLongLocationAPI } from "./redux/Action/LatLongLocation";
import { Categorieswithout } from "./redux/Action/Withoutlogin/Categorywithoutlogin";
import { AddLoginCartAPI } from "./redux/Action/LoginCart/LoginCartAction";
import { GetLoginCartAPI } from "./redux/Action/LoginCart/GetLoginCartAction";
import ColorTheme from "./Common/CommonCss/ColorTheme";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import '../src/Common/CommonCss/slick.min.css';
import '../src/Common/CommonCss/slick-theme.min.css';
import { FaqAPI } from "./redux/Action/Faq/Faq";

const App = () => {
  const dispatch = useDispatch();
  const baseUrlselector = useSelector(state => state?.Baseurlapi);
  const Logindetails = localStorage.getItem("Logindetails")
  const addtocart = localStorage.getItem("addtocart")
  const url = window.location.href
  const kiosk = url?.includes("kiosk") ? "1" : url?.includes("qr") ? "2" : "3"
  const BaseUrlSlice = useSelector(state => state?.BaseUrlSlice)
  const companydata = JSON.parse(localStorage.getItem("companydata"));
  const [apiData, setApiData] = useState(null);
  const isTabScreen = useMediaQuery({ query: '(max-width: 1366px)' });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const isSmallMobileScreen = useMediaQuery({ query: '(max-width: 475px)' });

 useEffect(() => {
    const logincheck = (Logindetails && Logindetails != undefined) ? true : false;
    if (logincheck) {
      dispatch(GetLoginCartAPI())
    }
  }, [])

  useEffect(() => {
    if (baseUrlselector?.value?.data?.company_setting?.primary_color != undefined && ColorTheme?.yellow == undefined) {

      window.location.reload()

    }
  })

  useEffect(() => {
    // Set font family dynamically on mount
    document.documentElement.style.setProperty('--main-heading-family', companydata?.primary_font);
    document.documentElement.style.setProperty('--sub-heading-family', companydata?.secondary_font);

    // Clean up on unmount
    return () => {
      document.documentElement.style.removeProperty('--main-heading-family');
      document.documentElement.style.removeProperty('--sub-heading-family');
    };
  });

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("companydata"));
    if (data?.primary_font === "\"Bebas Neue\", serif") {
      document.documentElement.style.setProperty("--bs-h1FontSize", "82px")
      document.documentElement.style.setProperty("--bs-h2FontSize", "30px")
      document.documentElement.style.setProperty("--bs-h3FontSize", "28px")
      document.documentElement.style.setProperty("--bs-h4FontSize", "24px")
      document.documentElement.style.setProperty("--bs-h5FontSize", "22px")
      document.documentElement.style.setProperty("--bs-h6FontSize", "16px")
      document.documentElement.style.setProperty("--bs-subtitle1", "16px")
      document.documentElement.style.setProperty("--bs-subtitle2", "14px")
      document.documentElement.style.setProperty("--bs-buttonFont", "20px")
      document.documentElement.style.setProperty("--bs-fontSizetMenu", "24px")
      document.documentElement.style.setProperty("--bs-fontWeightMenu", "500")
    }
    else if (isMobileScreen && data?.primary_font === "'\"Bebas Neue\", serif") {
      document.documentElement.style.setProperty("--bs-h2FontSize", "28px")
      document.documentElement.style.setProperty("--bs-h3FontSize", "24px")
      document.documentElement.style.setProperty("--bs-h4FontSize", "22px")
      document.documentElement.style.setProperty("--bs-h5FontSize", "18px")
    } else if (data?.primary_font === "'Inter', sans-serif") {
      document.documentElement.style.setProperty("--bs-h1FontSize", "82px")
      document.documentElement.style.setProperty("--bs-h2FontSize", "28px")
      document.documentElement.style.setProperty("--bs-h3FontSize", "25px")
      document.documentElement.style.setProperty("--bs-h4FontSize", "22px")
      document.documentElement.style.setProperty("--bs-h5FontSize", "20px")
      document.documentElement.style.setProperty("--bs-h6FontSize", "18px")
      document.documentElement.style.setProperty("--bs-subtitle1", "16px")
      document.documentElement.style.setProperty("--bs-subtitle2", "14px")
      document.documentElement.style.setProperty("--bs-buttonFont", "18px")
      document.documentElement.style.setProperty("--bs-fontSizetMenu", "20px")
      document.documentElement.style.setProperty("--bs-fontWeightMenu", "500")
      if (isTabScreen && data?.primary_font === "'Inter', sans-serif") {
        document.documentElement.style.setProperty("--bs-h2FontSize", "25px")
        document.documentElement.style.setProperty("--bs-h3FontSize", "18px")
        document.documentElement.style.setProperty("--bs-h4FontSize", "18px")
        document.documentElement.style.setProperty("--bs-h5FontSize", "18px")
        document.documentElement.style.setProperty("--bs-h6FontSize", "16px")
        document.documentElement.style.setProperty("--bs-subtitle1", "14px")
        document.documentElement.style.setProperty("--bs-buttonFont", "16px")
      }
      if (isMobileScreen && data?.primary_font === "'Inter', sans-serif") {
        document.documentElement.style.setProperty("--bs-h1FontSize", "45px")
        document.documentElement.style.setProperty("--bs-h2FontSize", "24px")
        document.documentElement.style.setProperty("--bs-h3FontSize", "22px")
        document.documentElement.style.setProperty("--bs-h4FontSize", "20px")
        document.documentElement.style.setProperty("--bs-h5FontSize", "18px")
        document.documentElement.style.setProperty("--bs-h6FontSize", "16px")
        document.documentElement.style.setProperty("--bs-subtitle1", "16px")
        document.documentElement.style.setProperty("--bs-subtitle2", "14px")
        document.documentElement.style.setProperty("--bs-buttonFont", "16px")
        if (isSmallMobileScreen && data?.primary_font === "'Inter', sans-serif") {
          document.documentElement.style.setProperty("--bs-h2FontSize", "22px")
          document.documentElement.style.setProperty("--bs-h3FontSize", "20px")
          document.documentElement.style.setProperty("--bs-h4FontSize", "18px")
        }
      }
    } else {
      document.documentElement.style.setProperty("--bs-h1FontSize", "82px")
      document.documentElement.style.setProperty("--bs-h2FontSize", "32px")
      document.documentElement.style.setProperty("--bs-h3FontSize", "30px")
      document.documentElement.style.setProperty("--bs-h4FontSize", "26px")
      document.documentElement.style.setProperty("--bs-h5FontSize", "22px")
      document.documentElement.style.setProperty("--bs-h6FontSize", "16px");
      document.documentElement.style.setProperty("--bs-pFontSize", "16px");

    }
  })


  useEffect(() => {
    dispatch(BaseUrlAPI())
    dispatch(Categorieswithout())
  }, [])
  return <Navigation />;
};

export default App;
