import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useState } from 'react';
const BottomDrawer = (props) => {
    const { classes, children, cancelHandler } = props
    const drawerslice = useSelector(state => state?.DrawerSlice?.value)
    const Logindrawer = useSelector(state => state?.LoginDrawerSlice?.value)
    const dispatch = useDispatch();
    const [draweropen, setdraweropen] = useState(false)
    const [state, setState] = React.useState({
        bottom: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        setdraweropen(false)
    };
    useEffect(() => {
        setdraweropen(drawerslice)
    }, [])
    // console.log(draweropen, "drawerslice")
    return (
        <div>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer anchor={anchor} open={draweropen}
                        cancelHandler={toggleDrawer(anchor, false)}
                        onClose={toggleDrawer(anchor, false)} className={classes.drawerOuter}>
                        <div style={{ padding: '1rem' }}>{children}</div>
                    </Drawer>
                </React.Fragment>
            ))
            }
        </div >
    );
}
export default withStyles(Styles)(BottomDrawer);