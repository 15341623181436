import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import checkWhite from "../../images/checkWhite.svg";
import card from "../../images/card.png";
import { Container } from "@mui/material";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import TimerCount from "../../Timer/TimerCount";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CheckoutstatusAPI } from "../../redux/Action/Stripepayment/CheckoutStatus";
import { useEffect } from "react";
import TimeCounter from "../../Components/TimeCounter/TimeCounter";
const KioOrderReady = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const PaymentOrderId = useSelector(state => state?.PaymentOrderIdSlice?.value)
    const CheckoutStatus=useSelector(state=>state?.CheckoutStatusSlice)
    const paymentordernumber=localStorage.getItem("paymentordernumber")
    const paymentorderoutletname=localStorage.getItem("paymentorderoutletname")
    
    useEffect(() => {
        const data = {
            payment_session_id: sessionId,
            status_key: "success"
        }
        dispatch(CheckoutstatusAPI(data))
    }, [sessionId])

    return (
        <>
            <Container maxWidth="xl+" className={classes.orderOuter}>
            <TimerCount/>
                <div className={classes.orderInner}>
                    <div className={classes.checkBox}>
                        <img src={checkWhite} alt="Not Found" />
                    </div>
                    <h3 className={CommonStyle.mb20}>{StrConstent.kisok.orderReady.heading}</h3>
                    <p>Your order will be ready around <span className={`${classes.time} ${CommonStyle.fw600}`}> <TimeCounter /></span>, your Order no.</p>
                    <h3 className={CommonStyle.my20}>{paymentordernumber}</h3>
                    <h3 className={CommonStyle.my20}>Congratulations ! you got one loyalty card </h3>
                    <p className={CommonStyle.my20}>Your loyalty card will be added in your account automatically </p>
                    <div className={classes.imgContent}><img src={card} alt="Not Found" /></div>
                    <p className={CommonStyle.my20}>Once order is confirmed we will send you order detail on you mobile no. You are not logged out.</p>
                </div>
            </Container>
        </>
    )
}
export default withStyles(Styles)(KioOrderReady);