// const url = "https://qr_hong-bao-the-rest-area-prachachuen_ace-card.customizeyourfood.com"
// const url = "https://thespread.customizeyourfood.com"
const url = window.location.href;
const urlheader = () => {
    // console.log("headere",urlaa0)
    const kisok = url.includes("kiosk") ? url?.split("_")[2]?.split("/")[0] : url.includes("qr") ? url?.split("_")[2]?.split("/")[0] : url.includes("http://localhost:") ? "ace-card.customizeyourfood.com" : url?.split("//")[1]?.split("/")[0]
    return kisok
}
// console.log("header",urlheader())
const urltype = () => {
    // const url="https://qr_shashank-outlets_hazelnutapp.customizeyourfood.com/"
    // const url=window.location.href;
    // console.log("headere",urlaa0)
    const kisok = url.includes("kiosk") ? url?.split("_")[0]?.split("//")[1] : url.includes("qr") ? url?.split("_")[0]?.split("//")[1] : url.includes("http://localhost:") ? null : null
    return kisok
}

const urloutletid = () => {
    // const url="https://qr_shashank-outlets_hazelnutapp.customizeyourfood.com/"   
    // const url=window.location.href;
    const kisok = url.includes("kiosk") ? url?.split("_")[1] : url.includes("qr") ? url?.split("_")[1] : url.includes("http://localhost:") ? null : null
    return kisok
}
// console.log("header",urloutletid(),urltype(),urlheader())
// export const Headers={
//     "url": "hazelnutapp.customizeyourfood.com",
//     "mode":urltype(),
//     "outlet":urloutletid()
// }
const i18nextLng=localStorage.getItem("languagemulti")
export const Headers = {
    "url": urlheader(),
    "owner_key":'api.ace-card',
    "mode": urltype(),
    "outlet": urloutletid(),
    "language":i18nextLng=="en"?"en":i18nextLng=="thai"?"th":i18nextLng=="chi"?"zh":"en"
}

export const Hipster_Base_URL = process.env.REACT_APP_BASE_URL + "/api/v1";
export const GoogleReacapt = process.env.REACT_APP_GOOGLE_RECAPTCHA ;
