import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: true,

}

export const FooterHideSlice = createSlice({
    name: 'FooterHide',
    initialState,
    reducers: {

        Footer_Hide_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Footer_Hide_success } = FooterHideSlice.actions

export default FooterHideSlice.reducer