import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const CouponlistCSlice = createSlice({
    name: 'Couponlist',
    initialState,
    reducers: {
       Coupon_list_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Coupon_list_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Coupon_list_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Coupon_list_request, Coupon_list_success, Coupon_list_failure } = CouponlistCSlice.actions

export default CouponlistCSlice.reducer