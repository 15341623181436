import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { NavLink } from "react-router-dom";
import blackLogo from "../../images/blackLogo.svg";
import home from "../../images/home.svg";
import { Container } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BorderButton from "../../KioskComponents/BorderButton/BorderButton";
import ColorTheme from "../../Common/CommonCss/ColorTheme";
import { useState } from "react";
import CustomModal from "../../KioskComponents/CustomModal/CustomModal";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../KioskComponents/CustomButton/CustomButton";
const KioHeader = (props) => {
    const { classes, backButton } = props;
    let navigate = useNavigate();
    const url = window.location.href;
    const headerurl = url?.includes("/kio-menu") || url?.includes("/kio-phone-number") || url?.includes("/kio-otp") || url?.includes("/success") ? false : true
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const addtocart = localStorage.getItem("addtocart");
    const addtocarts = addtocart ? JSON.parse(addtocart) : []
    const [clearcartpopup, setclearcartpopup] = useState(false);
    const dispatch = useDispatch()
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const handlenavigate = () => {
        if (url?.includes("/kio-vouchers") || url?.includes("/success") || url?.includes("/cancel") || url?.includes("/kio-proceed-to-pay")) {
            navigate("/kio-cart")
        } else if(url?.includes("/kio-cart")) {
            navigate("/kio-menu")
        }
        else {
            navigate(-1)
        }
    }
    const handleHome = () => {
        if (url?.includes("/success") || url?.includes("/cancel")) {
            localStorage.removeItem("addtocart")
            navigate("/")
        } else {


            if (addtocarts?.length > 0) {

                setclearcartpopup(true)
            } else {

                navigate("/")

                setclearcartpopup(false)
            }
        }
    }

    const handleCancle = () => {
        setclearcartpopup(false)
    }
    const handleCartClear = () => {
        localStorage.removeItem("addtocart");
        navigate("/")
        // window.location.reload();
    }
    return (
        <>
            <Container maxWidth="xl+">
                <div className={classes.header}>
                    <div className={classes.headerInner}>
                        <div className={CommonStyle.flex}>
                            <span className={classes.homeIcon} onClick={() => handleHome()}>
                                {/* <img src={home} alt="Not Found" /> */}
                                <svg width="39" height="32" viewBox="0 0 39 32" fill='white' xmlns="http://www.w3.org/2000/svg">
                                    <path d="M38.5915 15.4023C38.7905 15.6133 38.9895 15.9648 38.9895 16.2461C38.9895 16.457 38.8568 16.8086 38.7242 16.9492L38.0609 17.8633C37.8619 18.0742 37.4639 18.2852 37.1986 18.2852C36.9996 18.2852 36.668 18.1445 36.5353 18.0039L34.7445 16.3867V29.5352C34.6781 30.7305 33.7495 31.7148 32.6219 31.7148H24.1319C22.938 31.7148 22.0094 30.7305 22.0094 29.4648V22.1523H17.7643V29.4648C17.698 30.7305 16.7694 31.7148 15.6418 31.7148H7.15173C5.95782 31.7148 5.02922 30.7305 5.02922 29.4648V16.3867L3.17201 18.0039C3.03936 18.1445 2.70771 18.2148 2.50873 18.2148C2.24341 18.2148 1.84544 18.0742 1.71278 17.8633L0.983166 16.9492C0.850508 16.8086 0.78418 16.457 0.78418 16.2461C0.78418 15.9648 0.916837 15.6133 1.11582 15.4023L17.499 1.12891C18.0296 0.636719 19.0909 0.285156 19.8868 0.285156C20.6165 0.285156 21.6777 0.636719 22.2083 1.12891L31.5607 9.28516V5.27734C31.5607 4.99609 31.7597 4.78516 32.0913 4.71484H34.2138C34.4792 4.71484 34.7445 4.99609 34.7445 5.27734V12.0273L38.5915 15.4023ZM31.4944 28.3398L31.5607 13.6445L19.8868 3.44922L8.21299 13.6445V28.3398H14.5805V21.0273C14.5805 19.832 15.5091 18.8477 16.7031 18.7773H23.0706C24.1982 18.8477 25.1268 19.832 25.1931 21.0273V28.3398H31.4944Z" fill={ColorTheme?.secondaryColor} />
                                </svg>

                            </span>
                            {/* {
                                backButton && */}
                            {headerurl && <div className={`${CommonStyle.px20} ${classes.backBtn}`}>
                                <BorderButton label="Back" clickHandler={() => handlenavigate()} />
                            </div>}
                            {/* } */}

                        </div>
                        <span className={classes.logo} onClick={()=>handleHome()}>
                            <img src={companydata?.kiosk_logo} alt="Not Found" />
                        </span>
                    </div>
                </div>
            </Container>
            {
                clearcartpopup == true && (
                    <>
                        <CustomModal
                            open={clearcartpopup}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => setclearcartpopup(false)}
                            description={""}
                        >
                            <div>
                                <h3 className={CommonStyle.mb20}>
                                    Are you sure you want to Clear your cart.
                                </h3>
                                <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                                    <BorderButton label={"Cancel"} clickHandler={handleCancle} />
                                    <CustomButton label={"Confirm"} clickHandler={handleCartClear} />
                                </div>

                            </div>
                        </CustomModal>

                    </>
                )
            }
        </>
    )
}
export default withStyles(Styles)(KioHeader);