import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const UpdateItemQuantitySlice = createSlice({
  name: 'UpdateItemQuantity',
  initialState,
  reducers: {
 
    Update_Item_quantity_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Update_Item_quantity_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
    Update_Item_quantity_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const {     Update_Item_quantity_failure ,    Update_Item_quantity_success,    Update_Item_quantity_request} = UpdateItemQuantitySlice.actions

export default UpdateItemQuantitySlice.reducer