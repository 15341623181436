import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    cartItem: {
        marginRight:'15px',
        "& > a": {
        background: ColorTheme.lightYellow,
        padding: '25px',
        borderRadius: '35px',
        display: 'block',
        }
    },
    imgContent: {
        height: '60px',
        width: '60px',
        overflow: 'hidden',
        borderRadius: '10px',
        margin: '0',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        }
    },
    containerBox: {
        "& button": {
            minWidth: '100px',
            "&:hover": {
                background: ColorTheme.transparent,
            }
        }
    },
    recommendCart:{
        "& h5":{
            fontFamily:'var(--sub-heading-family)',
        }
    }
})

