import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Styles } from "./Style";
import excalamationMark from '../../images/excalamationMark.svg';
const CustomPropover = (props) => {
    const { classes, children } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div aria-describedby={id} variant="contained" onClick={handleClick} className={classes.customTooltip}>
                <img src={excalamationMark} alt="Not Found" />
            </div>
            <Popover
                id={id}
                open={open}
                disableScrollLock={true}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <p className={classes.tooltiptext}>
                    {children}
                </p>
            </Popover>
        </>
    )
}

export default withStyles(Styles)(CustomPropover);