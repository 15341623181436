import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Outlet_failure, Outlet_request, Outlet_success } from "../../reducers/Outlet/OutletSlice"
import { Headers } from "../../../APIHandler/HeaderAPI"
export const OutletfetchAPI = (payload,search) => async (dispatch, getState) => {
    dispatch(Outlet_request())
    const itemId = localStorage.getItem("itemId")
    // console.log("payload", payload);
    const outlet_mode = localStorage.getItem("outlet_mode");
    const Pick = outlet_mode == "Pick-Up Today" ? "pickup" :outlet_mode == "Schedule Pick-Up"?  "schdule-pickup":"delivery";
    // console.log("payload",payload)
    const modecheck=outlet_mode=="Schedule Delivery"?true:false;
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");
    const address_id = localStorage.getItem("address_id");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
     const latlongapi= `${APIURL.outletmode}?mode=${Pick}&search_text=${search!=undefined?search:""}&item_id=${itemId}&address_id=${modecheck?address_id:""}&timezone=${timeZone}`
    //  ?mode=${Pick}&item_id=${itemId}&longitude=${longitude}&latitude=${latitude}&search_text=${search}`
     const withoulatlongapi= `${APIURL.outletmode}?mode=${Pick}&item_id=${itemId}&search_text=${search}`
    //  const Locationapi = (latitude && longitude) ? latlongapi : withoulatlongapi
     const Locationapi = latlongapi
        const { data } = await axios.get(Locationapi, {
            headers: Headers
        })

        // console.log("dataoutlet", data);
        dispatch(Outlet_success(data))

    } catch (error) {
        dispatch(Outlet_failure(error?.response?.data))
    }
}