import React, { useEffect, useState } from 'react'
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import KioCounter from '../KioCounter/KioCounter';
import { useDispatch } from 'react-redux';
import { Modifier_success } from '../../redux/reducers/Modifier/ModifierSlice';
import { NavLink } from 'react-router-dom';
import plusIcon from '../../images/plusIcon.svg';
import minimizeIcon from '../../images/minimizeIcon.svg';
const KioViewCart = (props) => {
    const { classes, data, id } = props;
    const [counter, setCounter] = useState(0);
    const dispatch = useDispatch()
    const [modifieritem, setmodifieritem] = useState([])
    const companydata = JSON.parse(localStorage.getItem("companydata"))
   
    useEffect(() => {
        setmodifieritem(data)
        localStorage.setItem("modifierItem", JSON.stringify(data))
        dispatch(Modifier_success(data))

    }, [data])

    const increase = (modifier_id) => {
        const itemss = modifieritem?.map((item) => {
            return modifier_id == item?.modifier_id ? { ...item, modifier_quantity: item?.modifier_quantity + 1 } : item
        })
        setmodifieritem(itemss)
        localStorage.setItem("modifierItem", JSON.stringify(itemss))
        dispatch(Modifier_success(itemss))
        setCounter(counter + 1);
        // localStorage.setItem(itemName, counter + 1)
    };

    const decrease = (modifier_id) => {
        // if (counter === 0) {
        //     return;
        // }
        const itemss = modifieritem?.map((item) => {
            return modifier_id == item?.modifier_id ? { ...item, modifier_quantity: (item?.modifier_quantity > 0 ? item?.modifier_quantity - 1 : item?.modifier_quantity) } : item
        })
        setmodifieritem(itemss)
        localStorage.setItem("modifierItem", JSON.stringify(itemss))
        dispatch(Modifier_success(itemss))
        setCounter(counter - 1);
        // localStorage.setItem(itemName, counter - 1)
    };
    return (
        <>

            {
                modifieritem?.map((index) => {
                    return <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween} ${CommonStyle.mb20}`} key={index?.modifier_id}>
                        <div className={CommonStyle.flexCenterStart} >
                            <div className={classes.item}>
                                <img src={index?.modifier_image} alt="Not Found" />
                            </div>
                            <div className={classes.productInfo}>
                                <h4>{index?.modifier_name}</h4>
                                <h5>{companydata?.currency_symbol}{index?.modifier_price}</h5>
                            </div>
                        </div>
                        <div className={classes.rightContainer}>
                            <div className={classes.counter}>
                                <NavLink onClick={() => decrease(index?.modifier_id)} className={classes.btn}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                                <span className={classes.counterNumber}>{index?.modifier_quantity}</span>
                                <NavLink onClick={() => increase(index?.modifier_id)} className={classes.btn}><img src={plusIcon} alt="Not Found" /></NavLink>
                            </div>
                        </div>
                    </div>
                })
            }

        </>

    )
}
export default withStyles(Styles)(KioViewCart);