import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import dropDownIconCircle from '../../images/dropDownIconCircle.svg';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import CustomButton from "../../Components/CustomButton/CustomButton";
import BorderButton from '../../Components/BorderButton/BorderButton';
import { useNavigate } from "react-router";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrderdetailPdfReceiptAPI } from "../../redux/Action/OrderPdfReceipt/OrderPdfReceipt";
import Loader from "../Loader/Loader";
const OrderCardBox = (props) => {
  const { classes, data, isRecurring = false } = props;
  let navigate = useNavigate();
  const dispatch=useDispatch();
  const [selectedId, setSelectedId] = useState(null);
  const OrderPdfReceiptSlice=useSelector(state=>state?.OrderPdfReceiptSlice)
  const selectionHandler = (orderId, outletId, totalOrders, typeDisplay) => {
    setSelectedId(orderId);
    localStorage.setItem("orderId", orderId);
    localStorage.setItem("outletId", outletId);
    localStorage.setItem("totalOrders", totalOrders);
    localStorage.setItem("typeDisplay", typeDisplay);
  };
  const renderTimeOptions = (data) => {
   
    if (data?.order_type === 0) {
      return <></>;
    } else {
      return (
        <>
          <p>
            {data?.order_type !== 0 && data?.order_type !== 2
              ? data?.selected_days === "daily"
                ? `${"Every Day " + data?.order_time_slot}`
                : `${"Every " + data?.selected_days.replaceAll(",",", ")} ${data?.order_time_slot}`
              : null}{" "}
            {data?.order_type === 2
              ? ` ${data?.display_date} ${data?.order_time_slot}`
              : null}
          </p>
          {data?.order_type === 0 && data?.end_date !== "-" ? (
            <p>{`From ${data?.start_date} -${data?.end_date}`}</p>
          ) : null}
        </>
      );
    }
  };


  const handledownload=(id)=>{
    // console.log("handledownload",id)
    dispatch(OrderdetailPdfReceiptAPI(id))
  }
  return (
    <>
    {
      OrderPdfReceiptSlice?.loading && (
        <Loader/>
      )
    }
      <Accordion className={classes.accordionBox}>
        <AccordionSummary
          expandIcon={<img src={dropDownIconCircle} alt="Not Found" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.top}>
            <div className={CommonStyle.flexBaseline}>
              <h4>Order</h4> 
              <p className={CommonStyle.pl5}>{data?.order_type_display}</p>
              </div>
            <p>Order No. {data?.order_number}</p>
            <div className={classes.btBox}>
            {/* {renderTimeOptions(data)} */}
              <p>{data?.address_display}</p>
              {/* <p>Pick-up time : 11:15 PM</p> */}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
        {data?.cart_items?.map((data, index) => (
            <Fragment key={index}>
              <h6>{`${data?.item_quantity} ${data?.item_name}`}</h6>
            </Fragment>
          ))}
          {/* <h5>2 Green Tea Rock Salt M.</h5>
          <h5>2 Yakult Green Tea</h5> */}
          <div className={`${CommonStyle.flex} ${CommonStyle.JustifyEnd} ${classes.bottomBtn}`}>
            <CustomButton label="Download Receipt" clickHandler={() => { handledownload(data?.order_id) }} />
            <div className={CommonStyle.pl10}><BorderButton label="Order details" clickHandler={() => { navigate(`/orderdetail/${data?.order_id}`) }} /></div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default withStyles(Styles)(OrderCardBox);
