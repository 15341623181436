import * as React from 'react';
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import { useNavigate } from 'react-router';
import { useEffect, useState } from "react";
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BackArrow from '../../Components/BackArrow/BackArrow';
import { FormLabel } from '@mui/material';
import minimizeIcon from './../../images/minimizeIcon.svg';
import plusIcon from './../../images/plusIcon.svg';
import times from '../../images/times.svg';
import { NavLink } from 'react-router-dom';
import CustomButton from '../../Components/CustomButton/CustomButton';
import CommonDatePicker from '../../Components/CommonDatePicker/CommonDatePicker';
import CommonTimePicker from '../../Components/CommonTimePicker/CommonTimePicker';
import OutletSelect from '../../Components/OutletSelect/OutletSelect';
import { supplyDateFormat } from '../../Common/utlis/utlis';
import { useDispatch, useSelector } from 'react-redux';
import { ReservationoutletAPI } from '../../redux/Action/reservationoutlets/ReservationOutlets';
import { ReservationAPI } from '../../redux/Action/Reservation/Reservation';
import { validateEmail, validatePhone } from '../../Common/Validations';
import Loader from '../../Components/Loader/Loader';
import AlertMessage from '../../Components/Alertmsg/AlertMessage';
import { Clear_Reservation_Cart } from '../../redux/reducers/Reservation/ReservationSlice';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReacapt } from '../../APIHandler/HeaderAPI';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import CheckBox from '../../Components/CheckBox/CheckBox';
import OccupationSelect from '../../Components/Occupation/OccupationSelect';
import { BaseUrlAPI } from '../../redux/Action/BaseUrlAction';

const fields = {
    dob: "",
    inValid: false,
};

const WebReservation = (props) => {
    const { classes } = props;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    let navigate = useNavigate();
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const [dob, setdob] = useState(fields);
    const [counter, setCounter] = useState(1);
    const maxDate = new Date()
    const images = ['../images/set1.png', '../images/set2.png', '../images/set3.png', '../images/set4.png', '../images/set5.png', '../images/set6.png', '../images/set7.png', '../images/set8.png'];
    const [time, settime] = useState("")
    const [email, setemail] = useState("")
    const [contactnumber, setcontactnumber] = useState("")
    const [remark, setremark] = useState("")
    const [Selectvalue, setSelectvalue] = useState("")
    const [Selectoccupation, setSelectoccupation] = useState("")
    const dispatch = useDispatch()
    const [outleterror, setouleterror] = useState(false)
    const [occupationerror, setoccupationerror] = useState(false)
    const [doberror, setdoberor] = useState(false)
    const [timeerror, settimeeror] = useState(false)
    const [emailerror, setemaileror] = useState(false)
    const [phoneerror, setphoneeror] = useState(false)
    const ReservationSlice = useSelector(state => state?.ReservationSlice)
    const ReservationOutletSlice = useSelector(state => state?.ReservationOutletSlice)
    const [isOpen, setIsOpen] = useState(false);
    const [captcha, setcaptcha] = useState(false)
    const [captchaerror, setcaptchaerror] = useState(false)
    const Continueasguest = useSelector(state => state?.ContinueasguestSlice?.value)
    const noofseats = localStorage.getItem("noofseats")
    const [Birthday,setbirthday]=useState(false)
    const [Aninversary,setAniversary]=useState(false)
    const bindingHandler = (date) => {
        // setvalidationdob(true)
        // console.log("dateofbirth", date[""], supplyDateFormat(date[""]))
        setdob((pre) => ({
            ...pre,
            dob: supplyDateFormat(date[""]),
        }));
        setdoberor(false)
    };
   
    const bindingTimeHandler = (date) => {
        // setvalidationdob(true)
        const newdate = new Date()

        const timeformate = date[""].toLocaleString('en-IN', {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            // hourCycle: 'h23'
        });
        console.log("date", timeformate)
        settime(timeformate)
        settimeeror(false)
        console.log("timeset", timeformate)
       
    };
    useEffect(() => {
        if (dob?.dob != "" && time != "") {

            settime(null)
            setTimeout(() => {
                settime("")
            }, 100)
        }
    }, [dob])
    const decrease = () => {
        if (counter == 1) {
            return
        }
        setCounter(counter - 1);
        setCurrentImageIndex((prevIndex) => prevIndex === 0 ? images.length - 1 : prevIndex - 1);
    }
    const increase = () => {
        if (counter == noofseats) {
            return
        }
        setCounter(counter + 1);
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
    useEffect(() => {
        if (Selectvalue != "") {
            setCounter(1);
            setCurrentImageIndex(0);

        }
    }, [Selectvalue])
    const reserveHandle = () => {
        if (captcha == false) {
            setcaptchaerror(true)
        }
        if (Selectvalue == "" && dob?.dob == "" && time == "" && (email == "" || validateEmail(email)) && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(true)
            setdoberor(true)
            settimeeror(true)
            setemaileror(true)
            setphoneeror(true)
        } else if (Selectvalue == "" && dob?.dob == "" && time == "" && (email == "" || validateEmail(email))) {
            setouleterror(true)
            setdoberor(true)
            settimeeror(true)
            setemaileror(true)
            setphoneeror(false)
        } else if (Selectvalue == "" && dob?.dob == "" && time == "" && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(true)
            setdoberor(true)
            settimeeror(true)
            setemaileror(false)
            setphoneeror(true)
        } else if (Selectvalue == "" && dob?.dob == "" && (email == "" || validateEmail(email)) && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(true)
            setdoberor(true)
            settimeeror(false)
            setemaileror(true)
            setphoneeror(true)
        } else if (Selectvalue == "" && time == "" && (email == "" || validateEmail(email)) && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(true)
            setdoberor(false)
            settimeeror(true)
            setemaileror(true)
            setphoneeror(true)
        } else if (dob?.dob == "" && time == "" && (email == "" || validateEmail(email)) && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(false)
            setdoberor(true)
            settimeeror(true)
            setemaileror(true)
            setphoneeror(true)
        } else if (Selectvalue == "" && dob?.dob == "" && time == "") {
            setouleterror(true)
            setdoberor(true)
            settimeeror(true)
            setemaileror(false)
            setphoneeror(false)
        } else if (Selectvalue == "" && dob?.dob == "" && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(true)
            setdoberor(true)
            settimeeror(false)
            setemaileror(false)
            setphoneeror(true)
        } else if (Selectvalue == "" && (email == "" || validateEmail(email)) && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(true)
            setdoberor(false)
            settimeeror(false)
            setemaileror(true)
            setphoneeror(true)
        } else if (time == "" && (email == "" || validateEmail(email)) && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(false)
            setdoberor(false)
            settimeeror(true)
            setemaileror(true)
            setphoneeror(true)
        } else if (dob?.dob == "" && time == "" && (email == "" || validateEmail(email))) {
            setouleterror(false)
            setdoberor(true)
            settimeeror(true)
            setemaileror(true)
            setphoneeror(false)
        } else if (Selectvalue == "" && dob?.dob == "") {
            setouleterror(true)
            setdoberor(true)
            settimeeror(false)
            setemaileror(false)
            setphoneeror(false)
        } else if (Selectvalue == "" && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(true)
            setdoberor(false)
            settimeeror(false)
            setemaileror(false)
            setphoneeror(true)
        } else if ((email == "" || validateEmail(email)) && (contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(false)
            setdoberor(false)
            settimeeror(false)
            setemaileror(true)
            setphoneeror(true)
        } else if (time == "" && (email == "" || validateEmail(email))) {
            setouleterror(false)
            setdoberor(false)
            settimeeror(true)
            setemaileror(true)
            setphoneeror(false)
        } else if (dob?.dob == "" && time == "") {
            setouleterror(false)
            setdoberor(true)
            settimeeror(true)
            setemaileror(false)
            setphoneeror(false)
        } else if (Selectvalue == "") {
            setouleterror(true)
            setdoberor(false)
            settimeeror(false)
            setemaileror(false)
            setphoneeror(false)
        } else if (dob?.dob == "") {
            setouleterror(false)
            setdoberor(true)
            settimeeror(false)
            setemaileror(false)
            setphoneeror(false)

        } else if (time == "") {
            setouleterror(false)
            setdoberor(false)
            settimeeror(true)
            setemaileror(false)
            setphoneeror(false)
        } else if ((email == "" || validateEmail(email))) {
            setouleterror(false)
            setdoberor(false)
            settimeeror(false)
            setemaileror(true)
            setphoneeror(false)
        } else if ((contactnumber == "" || validatePhone(contactnumber))) {
            setouleterror(false)
            setdoberor(false)
            settimeeror(false)
            setemaileror(false)
            setphoneeror(true)
        } else if (Selectvalue != "" && dob?.dob != "" && time != "" && (email != "" && validateEmail(email) == false) && (contactnumber != "" && validatePhone(contactnumber) == false) && captcha == true) {
            const timeformate=time?.split(":")[0]+":"+time?.split(":")[1]+" "
            const ampm=time?.split(":")[2]?.split(" ")[1]=="am"?"AM":"PM"

            const obj = {
                outlet_id: Selectvalue,
                booking_date: dob?.dob,
                booking_time: timeformate+ampm,
                pax: counter,
                email: email,
                phone: contactnumber,
                remark: remark,
                anniversary:Aninversary==false?0:1,
                birthday:Birthday==false?0:1,
                occasion:Selectoccupation


            }
            dispatch(ReservationAPI(obj))
            setouleterror(false)
            setdoberor(false)
            settimeeror(false)
            setemaileror(false)
            setphoneeror(false)
            //   settime("")
            setemail("")
            setcontactnumber("")
            setremark("")
            //   setSelectvalue("")
            //   setdob("")

        }

    }
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    useEffect(() => {
        dispatch(BaseUrlAPI())
        localStorage.removeItem("loginredirectstatus")
        localStorage.removeItem("checknavigationroute")
        
        dispatch(ReservationoutletAPI())
    }, [])
    useEffect(() => {
        if (ReservationSlice?.value?.message) {
            navigate("/")
            setIsOpen(true)
            setTimeout(() => {
                dispatch(Clear_Reservation_Cart())
                dispatch(Clear_Continue_as_guest())

            }, 3000)
        }
    }, [ReservationSlice])

    function onChange(value) {
        setcaptcha(true)
        setcaptchaerror(false)
    }

  
    return (
        <>
            {
                (ReservationOutletSlice?.loading || ReservationSlice?.loading) && (
                    <Loader />
                )
            }
            {
                ReservationSlice?.value?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={ReservationSlice?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={isOpen}
                    />
                )
            }
            <ContainerBox>
                <div className={classes.reservationOuter}>
                    <BackArrow clickHandler={() => navigate("/")} />
                    <h2 className={CommonStyle.mb20}>Reservation</h2>
                    <div className={classes.reservationForm}>
                        <div className={classes.flex}>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${classes.width50} `}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Select date *</FormLabel>
                                </div>
                                <div className={`${CommonStyle.fromControl}`}>
                                    <CommonDatePicker
                                        onBinding={bindingHandler}
                                        allowedDays={null}
                                        isFutureDisable={true}
                                        dateValue={dob}
                                        maxDate={maxDate}
                                        isBlank={dob != "" ? false : true}
                                    />
                                    {
                                        doberror && (
                                            <p className={`${CommonStyle.errorMessage}`}>Please select Date.</p>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${classes.width50}`}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Select time *</FormLabel>
                                </div>
                                <div className={`${CommonStyle.fromControl}`}>
                                   <CommonTimePicker
                                        onBinding={bindingTimeHandler}
                                        allowedDays={null}
                                        isFutureDisable={true}
                                        dateValue={time}
                                        datepickerValue={dob}
                                        maxDate={maxDate}
                                        isBlank={dob != "" ? false : true}

                                    />
                                    {
                                        timeerror && (
                                            <p className={`${CommonStyle.errorMessage}`}>Time is required.</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.flex}>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${classes.width50} `}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Email address *</FormLabel>
                                </div>
                                <div className={`${CommonStyle.fromControl}`}>
                                    <input type="email" placeholder="Enter the email" value={email} onChange={(e) => {
                                        setemail(e.target.value)
                                        setemaileror(false)
                                    }} />
                                </div>
                                {
                                    emailerror && (
                                        <p className={`${CommonStyle.errorMessage}`}>Please enter a valid email address.</p>
                                    )
                                }
                            </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${classes.width50}`}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Contact number *</FormLabel>
                                </div>
                                <div className={`${CommonStyle.fromControl}`}>
                                    <input type="number" placeholder="Enter the contact number" value={contactnumber} onChange={(e) => {
                                        setcontactnumber(e.target.value)
                                        setphoneeror(false)
                                    }} />
                                </div>
                                {
                                    phoneerror && (
                                        <p className={`${CommonStyle.errorMessage}`}>Phone number length must be in between 8 to 10 digits.</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={classes.flex}>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${classes.width50}`}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Remark </FormLabel>
                                </div>
                                <div className={`${CommonStyle.fromControl}`}>
                                    <textarea placeholder="Enter the remark" value={remark} onChange={(e) => setremark(e.target.value)} ></textarea>
                                </div>
                            </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${classes.width50}`}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Outlets *</FormLabel>
                                </div>
                                <div className={`${CommonStyle.fromControl}`}>
                                    <OutletSelect
                                        setSelectvalue={setSelectvalue}
                                        setouleterror={setouleterror}
                                    />
                                </div>
                                {
                                    outleterror && (
                                        <p className={`${CommonStyle.errorMessage}`}>Please select outlet.</p>
                                    )
                                }
                            </div>
                        </div>

                        <div className={classes.flex}>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${classes.width50}`}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Occupation *</FormLabel>
                                </div>
                                <div className={`${CommonStyle.fromControl}`}>
                                    <OccupationSelect setSelectvalue={setSelectoccupation} setouleterror={setoccupationerror} />
                                </div>
                                    {
                                    outleterror && (
                                        <p className={`${CommonStyle.errorMessage}`}>Please select occupation.</p>
                                    )
                                }
                            </div>
                          
                        </div>
                        
                        <div className={classes.flex}>
                            <div className={`${CommonStyle.mb20} ${classes.width50}`}>
                                <div className={CommonStyle.flexSpaceBetween}>
                                    <FormLabel>Number of people</FormLabel>
                                </div>
                                <div className={classes.tableBook}>
                                    <div className={classes.countBox}>
                                        <NavLink onClick={() => decrease()}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                                        <span className={classes.countNumber}>{counter}</span>
                                        <NavLink onClick={() => increase()}><img src={plusIcon} alt="Not Found" /></NavLink>
                                    </div>
                                    {counter < 9 ? <div className={classes.tableImg}>
                                        <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} />
                                    </div> :
                                        <div className={classes.tableImg}>
                                            <div className={classes.reserveBtnsecond}>
                                                {counter}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.flex}>
                            <div>

                                <ReCAPTCHA
                                    sitekey={GoogleReacapt}
                                    onChange={onChange}
                                />
                                {
                                    captchaerror && (
                                        <p className={`${CommonStyle.errorMessage}`}>Please select captcha.</p>
                                    )
                                }
                            </div>
                            <div className={classes.reserveBtn}><CustomButton label="Reserve" clickHandler={reserveHandle} /></div>
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(WebReservation);