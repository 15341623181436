import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  error: {},
  loading: false,
}

export const IsDefaultaddressSlice = createSlice({
  name: 'IsDefaultaddress',
  initialState,
  reducers: {
    Is_Default_address_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Is_Default_address_success: (state, action) => {

      state.value = action.payload;
      state.error = {};

      state.loading = false;
    },
    Is_Default_address_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Is_Default_address_Address: (state, action) => {
      state.error = {};

      state.value = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Clear_Is_Default_address_Address, Is_Default_address_failure, Is_Default_address_success, Is_Default_address_request } = IsDefaultaddressSlice.actions

export default IsDefaultaddressSlice.reducer