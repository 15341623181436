import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,

}

export const HeaderHeightSlice = createSlice({
    name: 'HeaderHeight',
    initialState,
    reducers: {

        Header_Height_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Header_Height_success } = HeaderHeightSlice.actions

export default HeaderHeightSlice.reducer