import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const DeleteLoginCartSlice = createSlice({
  name: 'DeleteLoginCart',
  initialState,
  reducers: {
 
    Delete_Login_Cart_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Delete_Login_Cart_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
    Delete_Login_Cart_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const { Delete_Login_Cart_failure ,Delete_Login_Cart_success,Delete_Login_Cart_request} = DeleteLoginCartSlice.actions

export default DeleteLoginCartSlice.reducer