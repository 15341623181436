import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,

}

export const StateChangeSlice = createSlice({
    name: 'StateChange',
    initialState,
    reducers: {

        State_Change_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { State_Change_success } = StateChangeSlice.actions

export default StateChangeSlice.reducer