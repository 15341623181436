import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
const LargeBtn = (props) => {
  const { classes, label, clickHandler, number, amount } = props;
  // useEffect(() => {
  //   document.body.classList.add('bottom-padding')
  // }, [])
  return (
    <div className={classes.customBtn}>
      <Button onClick={clickHandler}>
        {number && (<span className={classes.circle}>{number}</span>)}
        {label}
        <span className={classes.amount}>{amount}</span>
      </Button>
    </div>
  );
};
export default withStyles(Styles)(LargeBtn);
