import React from "react";
import { Styles } from "./Styles";
import Alert from "@mui/material/Alert";
import { withStyles } from "@mui/styles";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
const AlterMessage = (props) => {
  const { classes, url, title, description, type, open, closeHandler } = props;
  const [openAlert, setOpenAlert] = React.useState(true);
  const isCloseHandler = (event, reason) => {
    if (reason === "clickaway") return;
    else closeHandler();
    setOpenAlert(false);
  };
  // console.log("Stripepayment", description)
  return (
    <div className={classes.alterMessage}>
      <div
        className={
          type === "Success"
            ? `${classes.alertSuccess}`
            : type === "Info"
              ? `${classes.alertInfo}`
              : type === "Error"
                ? `${classes.alertUnSuccess}`
                : null
        }>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={closeHandler} className={classes.alertInner}>
          <Alert
            onClose={closeHandler}
            className={`${open ? `${classes.alertInner1}` : null}`}
            style={{ backgroundColor: `white` }}>
            <div className={classes.alertBox} >
              {/* <div className={classes.imgContent}>
              <img src={url} alt="Not Found" />
            </div> */}
              <div className={classes.txtContent}>
                <p>{description}</p>
              </div>
              <IconButton
                aria-label="close"
                onClick={isCloseHandler}>
                <CloseIcon />
              </IconButton>
            </div>
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};
export default withStyles(Styles)(AlterMessage);

