import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import OutlinedInput from '@mui/material/OutlinedInput';
import CustomModal from '../../KioskComponents/CustomModal/CustomModal';
import RoundedDrawer from '../BottomDrawer/RoundedDrawer';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import StrConstent from '../../Common/StrConstent';
import CommonStyle from '../../Common/CommonCss/Common.module.css'
import { useState } from 'react';
import BorderButton from '../BorderButton/BorderButton';
import CustomButton from '../CustomButton/CustomButton';
import GrayButton from '../GrayButton/GrayButton';
import { CartClearAPI } from '../../redux/Action/CartClear/CartClear';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    // 'Change Outlet',
    'Change order mode',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function CommonSelectBoxAceCart(props) {
    const { classes } = props;
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const outlet_mode = localStorage.getItem("outlet_mode")
    const [choosemodepopup, setchoosemodepopup] = useState(false)
    const device = useBreakpoint();
    const navigate =useNavigate()
    const dispatch=useDispatch()
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
       
       if(event.target.value=="Change order mode"){
        setchoosemodepopup(true)
       }
        // setPersonName(
        //     // On autofill we get a stringified value.
        //     typeof value === 'string' ? value.split(',') : value,
        // );
    };
    const handleCartClear = () => {

        localStorage.removeItem("addtocart")
        localStorage.removeItem("outlet_mode")
        localStorage.removeItem("outletname")
        dispatch(CartClearAPI())
        setchoosemodepopup(false);
        navigate("/");
        // let result = url.slice(url?.length - 1);


    }
    const handleClose = () => {
     
        setchoosemodepopup(false);
    }
    return (
        <>
        
        <div className={classes.customSelect}>
            <Select
                displayEmpty
                value={personName}
                onChange={handleChange}
                MenuProps={
                    {
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: `${ColorTheme.yellow} !important`,
                                color: ColorTheme.secondaryColor,
                            }
                        },
                        disableScrollLock: true
                    }
                }
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return (
                            <>
                                <p>{outlet_mode}</p>
                                {/* <span>Toa Payoh Central</span> */}
                            </>
                        );
                    }

                    return selected.join(', ');
                }}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {names.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </div>
        {device == "sm" ? <>
            {
                choosemodepopup == true && (
                    <RoundedDrawer
                        truekey={choosemodepopup}
                        setruekey={setchoosemodepopup}
                    >
                        <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            <BorderButton label={"Cancel"} clickHandler={() => setchoosemodepopup(false)} />
                            <CustomButton label={"Confirm"} clickHandler={handleCartClear} />
                        </div>
                    </RoundedDrawer>
                )
            }
        </> : <>
            <CustomModal
                open={choosemodepopup}
                title={""}
                handleClose={() => handleClose()}
                description={""}>
                <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                    <BorderButton label={"Cancel"} clickHandler={() => setchoosemodepopup(false)} />
                    <CustomButton label={"Confirm"} clickHandler={() => handleCartClear()} />
                </div>
            </CustomModal>
        </>}
    </>
    );
}

export default withStyles(Styles)(CommonSelectBoxAceCart);
