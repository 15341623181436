import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ProfilenumberchangeSlice = createSlice({
    name: 'Profilenumberchange',
    initialState,
    reducers: {

        Profile_number_change_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Profile_number_change_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Profile_number_change_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Profile_number_change: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  Profile_number_change_request, Profile_number_change_success,Profile_number_change_failure, Clear_Profile_number_change } = ProfilenumberchangeSlice.actions

export default ProfilenumberchangeSlice.reducer