import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { NavLink } from "react-router-dom";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ChooseModeApi } from "../../redux/Action/choosemode/ChoosemodeAction";
import Loader from "../../Components/Loader/Loader";
import { mode_success } from "../../redux/reducers/OutletFetch";
import { OutletfetchAPI } from "../../redux/Action/Outlet/OutletAction";
import { LatLongLocationAPI } from "../../redux/Action/LatLongLocation";
import BlockedLocationDrawer from "../../Components/BottomDrawer/BlockedLocationDrawer";
import longArrowRight from '../../images/longArrowRight.svg';
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer'
import { CartClearAPI } from "../../redux/Action/CartClear/CartClear";
import CustomButton from "../../Components/CustomButton/CustomButton";
import BorderButton from "../../Components/BorderButton/BorderButton";
import GrayButton from "../../Components/GrayButton/GrayButton";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import { GetLoginCartAPI } from "../../redux/Action/LoginCart/GetLoginCartAction";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import { Container } from "@mui/material";
import { useTranslation } from 'react-i18next';
const KioskChooseMode = (props) => {
    const { classes, clickHandler } = props;
    let navigate = useNavigate();
    const choosemode = useSelector(state => state?.Choosemode)
    const [blocked, setblocked] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const device = useBreakpoint();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [permissions, setpermission] = useState("")
    const outlet_mode = localStorage.getItem("outlet_mode")
    const [locationallow, setallocation] = useState(false)
    const [cartclearpopup, setcartclearpopup] = useState(false)
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")

    // const [allowlocation,setallocation]=useState(false)
    const [lat, setlat] = useState("")
    const [long, setlong] = useState("")

    const [isOpen, setIsOpen] = useState(false);
    const addtocart = localStorage.getItem("addtocart")
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    const Logindetails = localStorage.getItem("Logindetails");
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const companydata = JSON.parse(localStorage.getItem("companydata"))
   
    useEffect(() => {
        setallocation(false)
        dispatch(Drawer_success(false))
        dispatch(ChooseModeApi())
        if (Logindetail) {
            dispatch(GetLoginCartAPI())
        }
    }, [])



    const handlemodechange = (e) => {
        // console.log("!outlet_mode", !outlet_mode)
        if (e?.title == "Schedule Delivery") {
            if (Logindetail) {

                localStorage.setItem("outlet_mode", e?.title)
                return navigate("/delivery-address")
            } else {

                localStorage.setItem("addressmodenavigate", true)
                return navigate("/login")
            }
        } else {
            if (outlet_mode == e?.title) {

                // console.log("handlemodechange", e?.title);
                localStorage.setItem("outlet_mode", e?.title)
                dispatch(mode_success(e?.title))
                dispatch(OutletfetchAPI(e?.title))
                // if ((permissions == "granted") || ((lat != "" || lat != null) && (long != "" || long != null))) {

                //     // window.location.reload()
                navigate("/select-outlet")
                // } else {
                //     // navigate("/pickup-outlet")
                //     setcartclearpopup(false);
                //     setallocation(true)
                //     dispatch(Drawer_success(true));
                // }
            } else {

                // if ((lat != "" && lat != null) && (long != "" && long != null)) {
                //     localStorage.setItem("outlet_mode", e?.title)
                //     return navigate("/pickup-outlet")
                // }
                // if ((permissions != "granted") && (lat == "") || (long == "")) {
                //     console.log("permissionspermissions", permissions)

                //     return setallocation(true)

                // } else
                if (!outlet_mode) {
                    localStorage.setItem("outlet_mode", e?.title)
                    return navigate("/select-outlet")
                }
                else if (addtocartlogin?.value?.data?.cart_items?.length > 0 || addtocartwithoutlogin?.length > 0) {

                    setShowModel(true)
                    setcartclearpopup(true);
                    dispatch(Drawer_success(true));
                }
                else {
                    localStorage.setItem("outlet_mode", e?.title)
                    navigate("/select-outlet")
                }


            }
        }
    }

    // console.log("blocked", blocked);
    const handleCartClear = () => {
        if (outlet_mode == "Pick-Up Today") {
            localStorage.removeItem("addtocart")
            localStorage.setItem("outlet_mode", "Schedule Pick-Up")
            dispatch(CartClearAPI())
            setShowModel(false);
            navigate("/select-outlet");
        } else if (outlet_mode == "Schedule Pick-Up") {
            localStorage.removeItem("addtocart")
            localStorage.setItem("outlet_mode", "Pick-Up Today")
            dispatch(CartClearAPI())
            setShowModel(false);
            navigate("/select-outlet")
        }
    }
    const handleCancle = () => {
        setcartclearpopup(false);
        setallocation(false)
        dispatch(Drawer_success(false));
    }
    const handleClose = () => {
        setShowModel(false);
    }
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    // console.log("choosemode", choosemode)
    useEffect(() => {
        if (choosemode?.error?.message) {
            setIsOpen(true)
        }
    }, [choosemode])
    return (
        <>
            {
                choosemode?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={choosemode?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }

            {/* <BlockedLocationDrawer /> */}
            {
                choosemode?.loading == true && (
                    <Loader />
                )
            }

            <Container maxWidth="xl" className={classes.sectionPadding}>
                <div className={`${CommonStyle.outer} ${CommonStyle.scrollBox}`}>
                    <div className={CommonStyle.leftContainer}>
                     {
                         isappwebviewcheck!="acecard" && (

                             <BackArrow clickHandler={() => navigate(-1)} />
                         )
                     }
                     
                        <h2>{t("Choose Mode")}</h2>
                    </div>
                    <div className={`${CommonStyle.rightContainer} ${classes.chooseMode}`}>
                        {choosemode?.value?.data?.map((data) => (
                            // <div className={classes.chooseModeInner}>
                            <div key={data?.id} className={`${classes.chooseModeBox} ${CommonStyle.flexSpaceBetween}`} onClick={() => handlemodechange(data)}>
                                <div className={classes.textContent}>
                                    <h3 className={CommonStyle.mb20}>{data.title}</h3>
                                    <p
                                    dangerouslySetInnerHTML={{
                                            __html: data.description
                                        }}
                                    ></p>
                                </div>
                                <div className={classes.arrowContent}>
                                    <NavLink to="" onClick={clickHandler}><img src={longArrowRight} alt="Not Found" /></NavLink>
                                </div>
                            </div>
                            // </div>
                        ))}
                        {/* <NavLink to='' className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.links}`}>I’m just browsing</NavLink> */}
                    </div>
                </div>
                {/* <CustomButton label="click" clickHandler={() => setShowModel(true)} /> */}
            </Container>

            {/* {
                device == "sm" ? <>
                    {locationallow == true && (

                        <RoundedDrawer>
                            <h3 className={CommonStyle.textCenter}>Please allow location first then proceed.</h3>
                        </RoundedDrawer>
                    )}
                </> : <>
                    {locationallow == true && <CustomModal
                        open={true}

                        handleClose={() => false}
                        description={""}>
                        <h3 className={CommonStyle.textCenter}>Please allow location first then proceed.</h3>
                    </CustomModal>}
                </>
            } */}


            {device == "sm" ? <>
                {
                    cartclearpopup == true && (
                        <RoundedDrawer>
                            <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                            <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                                <BorderButton label={"Cancel"} clickHandler={handleCancle} />
                                <CustomButton label={"Confirm"} clickHandler={handleCartClear} />
                            </div>
                        </RoundedDrawer>
                    )
                }
            </> : <>
                <CustomModal
                    open={showModel}
                    title={""}
                    handleClose={() => handleClose()}
                    description={""}>
                    <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                    <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                        <BorderButton label={"Cancel"} clickHandler={() => setShowModel(false)} />
                        <CustomButton label={"Confirm"} clickHandler={() => handleCartClear()} />
                    </div>
                </CustomModal>
            </>}
            {/* {device !== "sm" ? <>
                {
                    blocked && (
                        <CustomModal
                            open={true}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => true}
                            description={""}>
                            <h3 className={CommonStyle.mb10}> You have blocked location, First you have to allow location.</h3>
                            <ul className={CommonStyle.listDots}>
                                <li>
                                    <p>Select Chrome's Main Menu button, marked by three vertically aligned dots. ...</p>
                                </li>
                                <li>
                                    <p> Select Settings.</p>
                                </li>
                                <li>
                                    <p> Select location.</p>
                                </li>
                                <li>
                                    <p>  Under Permissions, select Location.</p>
                                </li>
                                <li>
                                    <p> Allow Location.</p>
                                </li>
                            </ul>
                        </CustomModal>
                    )
                }
            </>
                : <>
                    {
                        blocked && (
                            <BlockedLocationDrawer />
                        )
                    }
                </>
            } */}
        </>
    )
}

export default withStyles(Styles)(KioskChooseMode);