import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    tabContainer: {
        "& div": {
            "& > span": {
                backgroundColor: `${ColorTheme.yellow} !important`,
            }
        },
        '& .Mui-selected': {
            color: `${ColorTheme.yellow} !important`,
        },
    },
    varchersBox: {
        "& > a": {
            padding: '20px',
            backgroundColor: ColorTheme.white,
            borderRadius: '10px',
            marginBottom: '15px',
            width: '100%',
            boxSizing: 'border-box',
        },
        "& h4": {
            marginBottom: '5px',
        },
        "& p": {
            marginBottom: '5px',
            "&:last-child": {
                marginBottom: '0',
            }
        },

    },
    voucherDetail: {
        padding: '10px 0',
        "& h5": {
            fontFamily: 'var(--sub-heading-family)',
            color: `${ColorTheme.fontColor}`,
            marginBottom: '10px',
        }
    },
    activateBox: {
        padding: '20px 0',
        display: 'block',
        textAlign: 'center',
        "& > div": {
            justifyContent: 'center',
        }
    },
    textContent: {
        "@media(max-width:640px)": {
            textAlign: 'center',
        }
    }
})