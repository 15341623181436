export const Styles = Theme => ({
    timerContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    timer: {
        paddingLeft: '3px',
    },
    pl3: {
        paddingLeft: '3px',
    }
})