export const Styles = theme => ({
    flex: {
        display: 'flex',
        marginBottom: '2rem',
        "@media(max-width:768px)": {
            display: 'block',
        }
    },
    flexDirectionReverse: {
        flexDirection: 'row-reverse',
    },
    leftColumn: {
        width: '50%',
        paddingRight: '40px',
        "@media(max-width:768px)": {
            width: '100%',
            paddingRight: '0',
        }
    },
    rightColumn: {
        width: '50%',
        "@media(max-width:768px)": {
            width: '100%',
        },
        "& img": {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            display: 'block',
        }
    },
    paddingLeft:{
        paddingLeft:'40px',
        "@media(max-width:768px)": {
            paddingLeft: '0',
        }
    },
    imgContent: {
        width: '100%',
        "& img": {
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            display: 'block',
        }
    }
})