import React, { useEffect, useState } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstent from '../../Common/StrConstent';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import BorderButton from '../../Components/BorderButton/BorderButton';
import CustomButton from '../../KioskComponents/CustomButton/CustomButton';
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { ContinuewithphoneAPI, ContinuewithphoneOTPverifiedAPI } from '../../redux/Action/Conntinuewithphone/ContinuewithphoneSlice';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import { validatePhone } from '../../Common/Validations';
const KioOtp = (props) => {
    const { classes } = props;
    const [otp, setOtp] = useState('')
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const OtpverifiedSlice = useSelector(state => state?.OtpverifiedSlice?.value)
    const Otpverified = useSelector(state => state?.OtpverifiedSlice)
    const [isOpen, setIsOpen] = useState(false);
    const loginphonenumber = localStorage.getItem("loginphonenumber")
    const handleotpsubmit = () => {

        dispatch(ContinuewithphoneOTPverifiedAPI(otp))

        // navigate("/proceed-to-pay")
    }
    useEffect(() => {
        dispatch(Clear_Continue_as_guest())
    }, [])
    useEffect(() => {
        if (OtpverifiedSlice?.success == true) {
            navigate("/kio-vouchers")
        } else if (Otpverified?.error?.message) {
            setIsOpen(true)
        }
    }, [OtpverifiedSlice, Otpverified])
    function handleNavigate() {

    }
    const handlesubmitlogin = () => {
        if (validatePhone(loginphonenumber)) {
            return
        } else {


            const payload = {
                phone_number: loginphonenumber,
                country_code: "91"
            }
            dispatch(ContinuewithphoneAPI(payload))
        }
    }
    return (
        <>
            <ContainerBox>
                <h2 className={CommonStyle.mb10}>{StrConstent?.kisok?.otp?.heading}</h2>
                <p>{StrConstent?.kisok?.otp?.description} {loginphonenumber}</p>
                <p className={CommonStyle.fw600}></p>
                {/* <div className={`${CommonStyle.fromControl} ${classes.mt1rem}`}>
                    <input type="number" placeholder='Please enter the no' />
                </div> */}
                <div className={classes.otpContainer}>
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} type='number' />}
                    />
                    <p className={`${CommonStyle.my1rem} ${CommonStyle.textCenter}`}>you've entered the wrong otp. please <NavLink className={CommonStyle.links} onClick={()=>handlesubmitlogin()}>request a new OTP </NavLink> and try again</p>
                </div>
                <div className={`${CommonStyle.flexCenter} ${CommonStyle.JustifyCenter} ${CommonStyle.kioBottomBtnContainer} ${classes.nextBtn}`}>
                    <CustomButton label="Next" clickHandler={() => handleotpsubmit()} />
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioOtp);