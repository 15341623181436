import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    scrollable: {
        backgroundColor: `${ColorTheme.yellow} !important`,
        height: '100%',
        maxHeight: '100%',
        position: 'fixed',
        right: '0',
        top: '0',
        bottom: '0',
        maxWidth: '275px',
        minWidth: '275px',
        padding: '20px',
        zIndex: '99',
        backgroundColor: `${ColorTheme.yellow}`,
        boxSizing: 'border-box',
        "@media(max-width:1024px)": {
            maxWidth: '260px',
            minWidth: '260px',
        },
        "@media(max-width:640px)": {
            maxWidth: '200px',
            minWidth: '200px',
        },
    },
    logo: {
        textAlign: 'center',
    },
    scrollInner: {
        height: 'calc(100% - 200px)',
        "& > div": {
            "&:last-child": {
                border: 'none'
            }
        }
    },
    menuLeft: {
        width: 'calc(100% - 275px)',
        paddingRight: '30px',
        "@media(max-width:1024px)": {
            width: 'calc(100% - 260px)',
        },
        "@media(max-width:640px)": {
            width: 'calc(100% - 200px)',
        },
    },
    cartSectionRemove: {
        width: '100%',
        paddingRight: '0',
    },
    checkOutBtn: {
        padding: '1.5rem 0',
        position: 'absolute',
        bottom: '0',
        right: '0',
        left: '0',
        boxSizing: 'border-box',
        width: '100%',
        boxShadow: '0px -1px 15px 0px #00000026',
        backgroundColor: ColorTheme.yellow,
        height: '120px',
        "& button": {
            width: 'auto',
            backgroundColor: ColorTheme.white,
            padding: '24px 15px !important',
            borderRadius: '50px !important',
            margin: '0 auto',
            display: 'block',
            fontSize: '1.5rem !important',
        }
    },

    tabContainer: {
        border: 'none',
        marginBottom: '10px',
        "& div": {
            "&:nth-child(2)": {
                "& > span": {
                    backgroundColor: `${ColorTheme.yellow} !important`,
                }
            },
            '& .Mui-selected': {
                color: `${ColorTheme.yellow} !important`,
            },

        }
    },
    containerInner: {
        display: 'inline-block',
        width: '100%',
        marginBottom: '40px',
    },
    itemContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '25px',
        "@media(max-width:1366px)": {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        "@media(max-width:1024px)": {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        "@media(max-width:640px)": {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    chipsContent: {
        margin: '1.5rem 0',
        "& div": {
            padding: '6px 8px !important',
            minWidth: "80px",
            marginRight: "15px",
            "@media(max-width:640px)": {
                marginRight: "5px",
            },
        },
        "& span": {
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'var(--main-heading-family)',
        },
    },
    active: {
        backgroundColor: `${ColorTheme.yellow} !important`,
        color: `${ColorTheme.secondaryColor} !important`,
    },
    myCartHedaing: {
        color: `${ColorTheme.secondaryColor} !important`,
    }
})
