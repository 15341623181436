import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Slider from "react-slick";
import TabPanel from '@mui/lab/TabPanel';
import { withStyles } from "@mui/styles";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Styles } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import HeroSlider from "../../KioskComponents/HeroSlider/HeroSlider";
import ProductItem from '../../KioskComponents/ProductItem/ProductItem';
import RewardPoint from '../../KioskComponents/RewardPoint/RewardPoint';
import OrderBox from '../../KioskComponents/OrderBox/OrderBox';
import Loader from '../../KioskComponents/Loader/Loader';
import { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { useNavigate } from "react-router";
import { LatLongLocationAPI } from '../../redux/Action/LatLongLocation';
import { ChooseModeApi } from '../../redux/Action/choosemode/ChoosemodeAction';
import BottomDrawer from '../../KioskComponents/BottomDrawer/BottomDrawer';
import LargeBtn from '../../KioskComponents/LargeBtn/LargeBtn';
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import AlertMessage from '../../KioskComponents/Alertmsg/AlertMessage';
import { Clear_Login_Cart } from '../../redux/reducers/LoginCart/GetLoginCartSlice';
import { GetLoginCartAPI } from '../../redux/Action/LoginCart/GetLoginCartAction';
import { Clear_Modifier } from '../../redux/reducers/Modifier/ModifierSlice';
import RoundedDrawer from '../../KioskComponents/BottomDrawer/RoundedDrawer';
// import Counter from '../../KioskComponents/Counter/Counter';
import { OrderAgainAPI } from '../../redux/Action/OrderAgain/OrderAgain';
import { OutletScheduleAPI } from '../../redux/Action/OutletSchedule/OutletSchedule';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import CustomModal from '../../KioskComponents/CustomModal/CustomModal';
import { ProfilegetAPI } from '../../redux/Action/ProfileUpdate/Profileget';
import { Profile_Update_Cart } from '../../redux/reducers/ProfileUpdate/ProfileUpdateSlice';
import { PhoneverifymethodAPI } from '../../redux/Action/Phoneverifymethod/Phoneverifymethod';
import { Clear_Otp_verified } from '../../redux/reducers/Continuewithphone/Otpverified';
import { Container } from '@mui/material';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import { useTranslation } from 'react-i18next';
import Counter from '../../Components/Counter/Counter';
import { Footer_Hide_success } from '../../redux/reducers/Footerhideshow';
import { Sub_categories_success } from '../../redux/reducers/SubcategoriesSlice';
import { Clear_location_latlong } from '../../redux/reducers/Location/LocationSlice';
import { Clear_Refresh_count_success } from '../../redux/reducers/AddrefreshCountSlice';
import { Cart_Clear_count_success } from '../../redux/reducers/CartClearslicecount';
import FilterSearch from '../../Components/FilterSearch/FilterSearch';
const KioskHome = (props) => {
    const { classes } = props;
    const [desktop, setDesktop] = useState(window.innerWidth > 991);
    let navigate = useNavigate();
    const device = useBreakpoint();
    const dispatch = useDispatch()
    const [value, setValue] = useState('');
    const Logindetails = localStorage.getItem("Logindetails");
    const Filteridcartmatch = useSelector(state => state?.FilteridcartmatchSlice?.value)
    const disptach = useDispatch()
    const Locationlatlong = useSelector(state => state?.Locationlatlong);
    const [subcategories, setsubcategories] = useState("");
    const [subcategoriesclass, setsubcategoriesclass] = useState(false);
    const Categorieswithoutlogin = useSelector(state => state?.Categorieswithoutlogin)
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const [isOpen, setIsOpen] = useState(false);
    const ItemQuantitySlice = useSelector(state => state?.ItemQuantitySlice?.value)
    const OrderAgain = useSelector(state => state?.OrderAgainSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const Phoneverifymethod = useSelector(state => state?.Phoneverifymethod)
    const [showModel, setShowModel] = useState(false)
    const outletId = localStorage.getItem("outletID")
    const [page, setpage] = useState(1);
    const Continueasguest = useSelector(state => state?.ContinueasguestSlice?.value)
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const FooterHeightSlice = useSelector(state => state?.FooterHeightSlice?.value)
    const [notiData, setNotiData] = useState([])
    const AddtocartRefresh = useSelector(state => state?.AddtocartRefreshSlice?.value)
    const AddRefreshcount = useSelector(state => state?.AddRefreshcountSlice?.value)
    const CartClearcountSlice = useSelector(state => state?.CartClearcountSlice?.value)
    const [searchBoxtext, setsearchboxtext] = useState(false)
    var settings = {
        dots: false,
        arrows: false,
        speed: 500,
        infinite: OrderAgain?.value?.data?.orders?.length > 3 ? true : false,
        slidesToShow: OrderAgain?.value?.data?.orders?.length > 3 ? 4 : 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '40px 0 0 0',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                    centerPadding: '20px 0 0 0',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '15px 0 0 0',
                    slidesToShow: 1,
                    infinite: true,
                }
            }
        ]
    };

    useEffect(() => {
        if (CartClearcountSlice > 0) {
            dispatch(LatLongLocationAPI(value, 1, ""))
            dispatch(Cart_Clear_count_success(0))
        }
    }, [CartClearcountSlice])

    useEffect(() => {
        setNotiData([])
        dispatch(Clear_Otp_verified())
        dispatch(PhoneverifymethodAPI())
        dispatch(Drawer_success(false))

        dispatch(Clear_Modifier())
        dispatch(Clear_Login_Cart())
        localStorage.removeItem("modifierItem")
        localStorage.removeItem("couponname")
        disptach(ChooseModeApi())
        if (Logindetail) {

            dispatch(GetLoginCartAPI())
        }
    }, [])
    console.log("Locationlatlong", Locationlatlong?.value?.data?.items)
    useEffect(() => {
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined) {
            console.log("vvvvvvvvvvv", Locationlatlong, subcategories, "hh", notiData?.length)

            if (searchBoxtext == true) {

                setNotiData(Locationlatlong?.value?.data?.items);

            }
            else {
                if (subcategories != "" && notiData?.length > 0) {

                    if (notiData?.length > 0) {
                        if (page > 1) {
                            console.log("vvvv454")
                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                            dispatch(Clear_location_latlong())
                        } else {
                            console.log("vvvv458")
                            setNotiData(Locationlatlong?.value?.data?.items);
                            console.log("vvvvvvvvvvv151", Locationlatlong, subcategories, "hh", notiData?.length)
                        }
                    } else {
                        console.log("vvvv461")
                        setNotiData(Locationlatlong?.value?.data?.items);
                    }
                } else {

                    if (notiData?.length > 0) {
                        if (page > 1) {
                            console.log("vvvv467")
                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                            dispatch(Clear_location_latlong())
                        } else {
                            console.log("vvvvvvvvvvv165", Locationlatlong, subcategories, "hh", notiData?.length)
                            console.log("vvvv471")
                            setNotiData(Locationlatlong?.value?.data?.items);
                        }
                    } else {
                        console.log("vvvv475")
                        setNotiData(Locationlatlong?.value?.data?.items);
                    }
                }
            }
        } else {
            if (page > 1) {

            } else {

                setNotiData([])
            }
        }
    }, [Locationlatlong, AddtocartRefresh]);
    useEffect(() => {
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined) {
            if (searchBoxtext == true) {

                setNotiData(Locationlatlong?.value?.data?.items);

            }
            else {
                if (subcategories != "" && notiData?.length > 0) {
                    if (notiData?.length > 0) {
                        if (page > 1) {

                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                            dispatch(Clear_location_latlong())
                        } else {
                            setNotiData(Locationlatlong?.value?.data?.items);
                        }
                    } else {
                        setNotiData(Locationlatlong?.value?.data?.items);
                    }
                } else {

                    if (notiData?.length > 0) {
                        if (page > 1) {

                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                            dispatch(Clear_location_latlong())
                        }
                    } else {
                        setNotiData(Locationlatlong?.value?.data?.items);
                    }
                }
            }
        }
    }, []);
    const fetchMoreData = () => {
        // Simulate fetching more data from an API

        // console.log("vikalploader")
        setLoading(true);
        if (+Locationlatlong?.value?.data?.pagination?.lastPage > page) {
            dispatch(Footer_Hide_success(false))
            setpage(page + 1)
        } else {
            dispatch(Footer_Hide_success(true))
        }
        setTimeout(() => {
            // For demonstration purposes, let's just add some dummy data

            setLoading(false);
        }, 1000); // Simulating a delay as if fetching from an API
    };

    const increasequantity = () => {
        // dispatch(OutletfetchAPI(modename,pickupsearch))
        // disptach(LatLongLocationAPI(value,page,""))

    }
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            increasequantity();
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [ItemQuantitySlice]);

    useEffect(() => {
        if (outletId) {

            dispatch(OutletScheduleAPI())
        }
        dispatch(Sub_categories_success(value))
        disptach(LatLongLocationAPI(value, page, ""))
    }, [value])
    const ItemData = () => {
        console.log("datavikalps")
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined && AddRefreshcount && AddRefreshcount != 0) {
            console.log("datavikalps")
            setNotiData(Locationlatlong?.value?.data?.items);
            dispatch(Clear_Refresh_count_success(0))
            setpage(1)
        }
    }

    useEffect(() => {
        ItemData()

    }, [AddRefreshcount, Locationlatlong])
    const handleChange = (event, newValue) => {
        setValue(newValue);
        disptach(LatLongLocationAPI(newValue, page))
        setNotiData([])
        setpage(1)
        setsubcategories("")
        // setNotiData([])
        // setsubcategories([])
    };
    const handleChangesubcategories = (data) => {
        // console.log("datadata", data)
        setsubcategories(data)

    }
    const handleClose = () => {
        setShowModel(false)
    }
    useEffect(() => {
        dispatch(Sub_categories_success(subcategories))
        disptach(LatLongLocationAPI(subcategories, page, ""))
    }, [subcategories])
    // console.log("addtocartlogin", addtocartlogin)

    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };

    const handleNavigate = () => {
        navigate("/cart")
        dispatch(Drawer_success(true))
        setShowModel(true)
    }
    useEffect(() => {
        if (Locationlatlong?.error?.message) {
            setIsOpen(true)
        }
    }, [Locationlatlong])
    // console.log("LoginDrawerSlice", DrawerSlice)
    const mobileWidth = () => {
        if (window.innerWidth <= 991) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
    const [scrollHeights, setscrollHeight] = useState()
    const handleScroll = () => {
        const container = document.getElementById('viewmores');
        const scrollTopheight = container?.scrollTop;
        const { clientHeight, scrollHeight } = document.documentElement;
        const scrollTop = window.scrollY;
        // console.log("vikalp", scrollTop, clientHeight, scrollHeight)
        // Check if the user has scrolled to the bottom
        //   console.log('vikalp', scrollTop);
        setscrollHeight(scrollHeight)
        if (scrollTop + clientHeight >= scrollHeight - 380 - FooterHeightSlice && !loading) {
            fetchMoreData();
        }
    };
    const handleScrollheight = () => {
        const container = document.getElementById('viewmores');
        const scrollTopheight = container?.scrollTop;

    }
    useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Detach the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });
    useEffect(() => {
        const container = document.getElementById('viewmores');
        container.addEventListener('scroll', handleScrollheight);

        return () => {
            container.removeEventListener('scroll', handleScrollheight);
        };
    });
    useEffect(() => {
        window.addEventListener("scroll", mobileWidth);
        return () => window.removeEventListener("scroll", mobileWidth);
    })

    // bottom buttom poistion get of div
    const lastDivRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [lastDivBottom, setLastDivBottom] = useState(0);
    const [totalHeight, setTotalHeight] = useState(0);
    const [filterproductsearch, setfilterproductsearch] = useState("")

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
            if (lastDivRef.current) {
                const lastDivRect = lastDivRef.current.getBoundingClientRect();
                const lastDivBottomPos = lastDivRect.bottom + window.scrollY;
                setLastDivBottom(lastDivBottomPos);
                setTotalHeight(lastDivBottomPos - position);
            }
            // console.log(totalHeight, 'totalHeight');
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        dispatch(Sub_categories_success(value))
        disptach(LatLongLocationAPI(value, page, ""))
        // setfilterproductsearch("")
    }, [page])

    //top bar sticky
    const componentRef = useRef(null);
    const [topbarsticky, settopbarsticky] = useState("")
    useEffect(() => {
        // console.log('Component height:');
        if (componentRef.current) {
            const height = componentRef.current.clientHeight;
            // console.log('Component height:', height);
            // dispatch(Header_Height_success(componentRef.current.clientHeight))
            settopbarsticky(componentRef.current.clientHeight)
        }
    })

    const boxRef = useRef(null);
    const [scrolltopdata, setscrolltopdata] = useState('');
    useLayoutEffect(() => {
        const divAnimate = boxRef.current.getBoundingClientRect().top;
        // console.log(divAnimate, 'divAnimate');
        const onScroll = () => {
            if (divAnimate < window.scrollY) {
                setscrolltopdata(`${CommonStyle.fixedTab}`);
            } else {
                setscrolltopdata('');
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const samedatapopup = () => {
        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.costomizeContent}>
                    {Filteridcartmatch?.map((data) => {
                        return <div className={classes.costomizeItem} key={data?.cart_item_id}>
                            <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                                <div className={CommonStyle.flexCenterStart}>
                                    <div>
                                        <h4>{data?.name}</h4>
                                        <p dangerouslySetInnerHTML={{
                                            __html: (data?.description),
                                        }}></p>
                                        <p>
                                            {data?.item_size} / {data?.modifier?.map((data) => {
                                                return " " + data?.modifier_quantity > 0 ? data?.modifier_name + " " + data?.modifier_quantity + " / " : ""
                                            })} {data?.sugarlevel ? "sugarlevel " + data?.sugarlevel : ""}
                                        </p>
                                    </div>
                                </div>
                                <div className={CommonStyle.ml10}>
                                    <Counter itemQuantity={data?.itemQuantity} item_price={data?.item_price} cart_item_id={Logindetail ? data?.cart_item_id : data?.unique_id} />
                                    {/* <h5 className={`${CommonStyle.mt10} ${CommonStyle.textCenter}`}>$ {data?.totalamount}</h5> */}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </React.Fragment>
    }
    // console.log("LoginCartSlice", LoginCartSlice)

    useEffect(() => {
        if (LoginCartSlice?.value?.message) {
            setIsOpen(true)
        }
    }, [LoginCartSlice])
    useEffect(() => {
        // console.log("Continueasguest", Continueasguest)
        if (Continueasguest?.success == true) {
            dispatch(Clear_Continue_as_guest())
        }
    }, [Continueasguest])
    return (
        <>
            {
                Categorieswithoutlogin?.loading == true && (
                    <Loader />
                )
            }
            {
                Locationlatlong?.loading == true && (
                    <Loader />
                )
            }
            <HeroSlider />
            <Container maxWidth="lg" className={`${classes.sectionSpacing} ${classes.bottomSpacing}`} id='viewmores'>
                <TabContext value={value}>
                    {/* <div className={`${classes.fixedHeader} ${scrollPosition > 0 && `${CommonStyle.fixedTab}`}`} ref={componentRef}> */}
                    <div className={`${classes.fixedHeader} ${scrolltopdata}`} ref={boxRef}>
                        <div>
                            <div className={classes.flex}>
                                <h2 className={CommonStyle.mb20}>{t("menu")}</h2>
                                <div className={classes.filterBox}>
                                    <FilterSearch filterproductsearch={filterproductsearch} setfilterproductsearch={setfilterproductsearch} setsearchboxtext={setsearchboxtext} />
                                </div>
                            </div>
                            <TabList onChange={handleChange} variant="scrollable" scrollButtons={false} className={`${CommonStyle.tabsContent} ${classes.tabContainer}`}>
                                <Tab label={t("ALL")} value={""} />
                                {
                                    Categorieswithoutlogin?.value?.data?.map((data) => {
                                        return <Tab label={data?.category_name} value={data?.category_id} key={data?.category_id} />
                                    })
                                }
                            </TabList>
                        </div>
                    </div>
                    {
                        Categorieswithoutlogin?.value?.data?.map((data) => {
                            return <>
                                <div className={classes.chipsContent} key={data?.category_id}>
                                    {
                                        data?.category_id == value && (data?.child_categories?.map((childdata) => {
                                            return <Chip label={childdata?.category_name} className={
                                                subcategories == childdata?.category_id
                                                    ? classes.active
                                                    : null
                                            } onClick={() => { handleChangesubcategories(childdata?.category_id) }} key={childdata?.category_id} />
                                        }))
                                    }
                                </div>
                            </>
                        })
                    }
                    <TabPanel value={value} className={CommonStyle.p0}>
                        <div className={classes.productContainer}>
                            {
                                notiData?.map((data) => {
                                    return <ProductItem key={data?.item_id} url={data?.image_url} title={data?.item_name} offer={data?.item_price} price={data?.item_price} discount={data?.discount_off} item_id={data?.item_id} show_detail_page={data?.show_detail_page} itemdetails={data} />
                                })
                            }
                        </div>
                    </TabPanel>
                </TabContext>
                <div ref={lastDivRef}></div>
                {/* <div className={`${CommonStyle.bottomBtn} ${CommonStyle.JustifyCenter}`}>
                    <LargeBtn label={StrConstent.buttonTxt.viewCart.heading}  clickHandler={() => { handleNavigate() }} Logindetail={Logindetail} />
                </div> */}
                {
                    device == "sm" ? <>
                        <RoundedDrawer>
                            <h3 className={CommonStyle.mb20}>{t("Repeat last used customizations?")}</h3>
                            {samedatapopup()}
                            <NavLink to="/select-outlet" className={`${CommonStyle.links} ${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20}`}>Add New customizations</NavLink>
                        </RoundedDrawer>
                    </> : <>
                        {DrawerSlice == true && <CustomModal
                            open={true}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => dispatch(Drawer_success(false))}
                            description={""}>
                            <h3 className={CommonStyle.mb20}>{t("Repeat last used customizations?")}</h3>
                            {samedatapopup()}
                            <NavLink to="/select-outlet" className={`${CommonStyle.links} ${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20}`}>Add New customizations</NavLink>
                        </CustomModal>}
                    </>
                }
            </Container>
        </>
    )
}
export default withStyles(Styles)(KioskHome);
