import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../KioskComponents/ContainerBox/ContainerBox";
import CustomCheckbox from "../../Components/CustomCheckbox/CustomCheckbox";
import CustomButton from '../../Components/CustomButton/CustomButton';
import { FormLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContinuewithphoneAPI } from "../../redux/Action/Conntinuewithphone/ContinuewithphoneSlice";
import { useNavigate } from "react-router-dom";
import { validatePhone } from "../../Common/Validations";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
const KioskLogin = (props) => {
    const { classes } = props;
    const [loginnumber, setloginnumber] = useState("")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Continuewithphone = useSelector(state => state?.ContinuewithphoneSlice?.value)
    const Continuewithphoneslice = useSelector(state => state?.ContinuewithphoneSlice)
    const [isOpen, setIsOpen] = useState(false);
    const handlesubmitlogin = () => {
        if (validatePhone(loginnumber)) {
            return
        } else {


            const payload = {
                phone_number: loginnumber,
                country_code: "91"
            }
            dispatch(ContinuewithphoneAPI(payload))
        }
    }
    // console.log("Continuewithphone", Continuewithphoneslice)
    useEffect(() => {
        if (Continuewithphone?.success == true) {
            navigate("/verify-otp")
        }else if(Continuewithphoneslice?.error?.message){
            setIsOpen(true)
        }


    }, [Continuewithphone,Continuewithphoneslice])
    const handleKeyDown = (event) => {
        if (event.keyCode === 69) {
            event.preventDefault();
        }
    };
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };

    return (
        <>
  {
                Continuewithphoneslice?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={Continuewithphoneslice?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }

            <ContainerBox>
                <div className={classes.loginWrapper}>
                    <div className={classes.accOuter}>
                        <div className={classes.accInner}>
                            {/* <h2 className={CommonStyle.mb20}>{StrConstent.login.heading}</h2> */}
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem}`}>
                                <FormLabel>Phone Number*</FormLabel>
                                <input type="number" name="number" placeholder="Enter your mobile no." value={loginnumber} onChange={(e) => setloginnumber(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    onInput={(e) => {
                                        if (e?.target?.value != "") {
                                            e.target.value = Math.max(0, parseInt(e.target.value))
                                                .toString()
                                                .slice(0, 10);
                                        }
                                    }}
                                />
                            </div>
                            {/* <label className={CommonStyle.customCheckContainer}>
                                <CustomCheckbox /> <span>Keep me logged in</span>
                            </label> */}
                        </div>
                        <div className={`${CommonStyle.mt1rem} ${CommonStyle.fullBtn}`}>
                            <CustomButton label={"Confirm"} clickHandler={() => handlesubmitlogin()} />
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioskLogin);