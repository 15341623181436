import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useLocation } from "react-router-dom";
import ContainerBox from "../../KioskComponents/ContainerBox/ContainerBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useNavigate } from 'react-router';
import CustomButton from "../../Components/CustomButton/CustomButton";
import BorderButton from "../../Components/BorderButton/BorderButton";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import TimeCounter from "../../Components/TimeCounter/TimeCounter";
import checkWhite from '../../images/checkWhite.svg';
import dropDownIconCircle from '../../images/dropDownIconCircle.svg';
import locationImg from '../../images/locationImg.svg';
import qr from '../../images/qr.svg';
import collectOrder from '../../images/collectOrder.svg';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StripeCheckoutAPI } from "../../redux/Action/Stripepayment/StripeCheckout";
import { CheckoutstatusAPI } from "../../redux/Action/Stripepayment/CheckoutStatus";
import { PaymentOrderIdAPI } from "../../redux/Action/Stripepayment/PaymentOrderId";
import { OrderPdfReceiptAPI } from "../../redux/Action/OrderPdfReceipt/OrderPdfReceipt";
import Loader from "../../Components/Loader/Loader";
import TimerCount from "../../Timer/TimerCount";
import { useTranslation } from 'react-i18next';
import { validateEmail } from "../../Common/Validations";
import { ProfileUpdateAPI } from "../../redux/Action/ProfileUpdate/ProfileUpdate";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import CustomModal from "../../KioskComponents/CustomModal/CustomModal";
import RoundedDrawer from "../../Components/BottomDrawer/RoundedDrawer";
import AlertMessage from "../../KioskComponents/Alertmsg/AlertMessage";


const KioskOrderSummary = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const device = useBreakpoint();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const PaymentOrderId = useSelector(state => state?.PaymentOrderIdSlice?.value)
    const CheckoutStatus = useSelector(state => state?.CheckoutStatusSlice)
    const paymentordernumber = localStorage.getItem("paymentordernumber")
    const paymentorderoutletname = localStorage.getItem("paymentorderoutletname")
    // console.log("session_id", sessionId)
    const OrderDetailsIdSlice = useSelector(state => state?.OrderDetailsIdSlice)
    const OrderPdfReceiptSlice = useSelector(state => state?.OrderPdfReceiptSlice)
    const [order, setorder] = useState([])
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const outlet_mode = localStorage.getItem("outlet_mode")
    const { t } = useTranslation();
    const Logindetails = localStorage.getItem("Logindetails");
    const logincheck = (Logindetails && Logindetails != undefined) ? JSON.parse(Logindetails) : [];
    const [isOpen, setisOpen] = useState(false)
    const [choosemodepopup, setchoosemodepopup] = useState(false)
    const [email, setemail] = useState("")
    const [pickuppopup, setpickuppopup] = useState(false);
    const url = window.location.href;
    const urlcheck = url?.includes("/orderdetail")
    useEffect(() => {
        const data = {
            payment_session_id: sessionId,
            status_key: "success"
        }
        dispatch(CheckoutstatusAPI(data))
    }, [sessionId])

    useEffect(() => {
        localStorage.removeItem("addtocart")
        dispatch(PaymentOrderIdAPI())
    }, [])

    const downloadReceiptHandle = () => {
        if (logincheck?.data?.user_data?.email == "") {

            setchoosemodepopup(true)
        } else {
            // setchoosemodepopup(true)
            dispatch(OrderPdfReceiptAPI(logincheck?.data?.user_data?.email))
        }

    }
    const handledownloadreceipt = () => {
        const formData = new FormData();
        if (validateEmail(email) == false && email != "") {
            setchoosemodepopup(false);
            dispatch(OrderPdfReceiptAPI(email))
            formData.append('email', email);
            dispatch(ProfileUpdateAPI(formData))
        }
    }

    const snackHandler = () => {
        setisOpen(false)
    }
    const handleClose = () => {
        setpickuppopup(false)
        setchoosemodepopup(false);
    }

    useEffect(() => {
        if (OrderPdfReceiptSlice?.value?.message) {
            
            setisOpen(true)
        }else{

            setisOpen(false)
        }
    }, [OrderPdfReceiptSlice])

    return (
        <>
         {
                OrderPdfReceiptSlice?.value?.message && (
                    <>

                        <AlertMessage
                            closeHandler={snackHandler}
                            url={"./images/error.svg"}
                            description={(OrderPdfReceiptSlice?.value?.message)}
                            title={"Success"}
                            type={"Success"}
                            open={isOpen}
                        />
                    </>
                )
            }
            {
                OrderPdfReceiptSlice?.loading == true && (
                    <Loader />
                )
            }
            {
                PaymentOrderId?.loading == true && (

                    <Loader />

                )
            }
            {CheckoutStatus?.loading == true && (

                <Loader />

            )

            }
            {/* <TimerCount/> */}
            <div className={`${CommonStyle.spacingX0} ${CommonStyle.wrapper}`}>
                <ContainerBox>
                    <div className={classes.successMark}>
                        <img src={checkWhite} alt="Not Found" />
                    </div>
                    <div className={`${classes.orderSummaryInner} ${CommonStyle.spacingX16}`}>
                    <h2 className={CommonStyle.my1rem}>{t("We got your Order")}</h2>
                        <p className={CommonStyle.my1rem}>{t("The")} {paymentorderoutletname} {t("is preparing your order. It will be")}</p>
                        <div className={CommonStyle.my20}>
                            <TimeCounter />
                        </div>

                        <p>{t("Your order no.")} {paymentordernumber}, {t("in the meantime, check your email for receipt and order details.")}</p>
                        <div className={classes.getDirBtn}>
                            {/* <BorderButton label={'Get Directions'} /> */}
                        </div>
                    </div>
                    {/* <Accordion className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<img src={dropDownIconCircle} alt="Not Found" />}>
                            <h3>Order Pickup instructions</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className={classes.strapper}>
                                <li>
                                    <div className={classes.strapperItem}>
                                        <div className={classes.strapperImg}>
                                            <img src={locationImg} alt="Not Found" />
                                        </div>
                                        <div className={classes.stap}><h6>Step 1</h6></div>
                                        <p>Visit Store</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.strapperItem}>
                                        <div className={classes.strapperImg}>
                                            <img src={qr} alt="Not Found" />
                                        </div>
                                        <div className={classes.stap}><h6>Step 2</h6></div>
                                        <p>Show your QR code</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.strapperItem}>
                                        <div className={classes.strapperImg}>
                                            <img src={collectOrder} alt="Not Found" />
                                        </div>
                                        <div className={classes.stap}><h6>Step 3</h6></div>
                                        <p>Collect your order</p>
                                    </div>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion> */}
                    <Accordion className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<img src={dropDownIconCircle} alt="Not Found" />}>
                            <h3>{t("Order Details")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className={classes.listing}>
                                {PaymentOrderId?.data?.items?.map((data) => {
                                    return <li>
                                        <div className={`${classes.leftContent} ${CommonStyle.flexCenterStart}`}>
                                            <div className={classes.imgContent}>
                                                <img src={data?.image_url} alt="Not Found" />
                                            </div>
                                            <div className={classes.txtContent}>
                                                <h4>{data?.item_name}</h4>
                                                <p>{data?.item_size}</p>
                                            </div>
                                        </div>
                                        <div className={classes.rightContent}>
                                            <h4>{data?.item_quantity}</h4>
                                        </div>
                                    </li>
                                })
                                }
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>{t("Grand Total")}</p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>{companydata?.currency_symbol}{PaymentOrderId?.data?.grand_total}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>{t("Promo Code")}</p>
                                        <p>{t("This promotion is used only one time and on first order")} </p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>-{companydata?.currency_symbol}{PaymentOrderId?.data?.discount_availed}</p>
                                    </div>
                                </li>
                                {outlet_mode == "Schedule Delivery" && <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>{t("Delivery Fee")}</p>

                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>{companydata?.currency_symbol}
                                            {urlcheck ? OrderDetailsIdSlice?.value?.data?.delivery_fee : PaymentOrderId?.data?.delivery_fee}
                                        </p>

                                    </div>
                                </li>}
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>{t("Total Payable")}</p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>{companydata?.currency_symbol}{urlcheck ? parseInt((PaymentOrderId?.data?.grand_total) - (PaymentOrderId?.data?.discount_availed)) + +PaymentOrderId?.data?.delivery_fee : PaymentOrderId?.data?.grand_total - PaymentOrderId?.data?.discount_availed + +PaymentOrderId?.data?.delivery_fee}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.downloadReceipt}>
                                        <BorderButton label={t("Email Receipt")} clickHandler={downloadReceiptHandle} />
                                    </div>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                </ContainerBox>
                {device == "sm" ? <>
                    {
                        choosemodepopup == true && (
                            <RoundedDrawer
                                truekey={choosemodepopup}
                                setruekey={setchoosemodepopup}
                            >
                                <h3 className={`${CommonStyle.textCenter} ${CommonStyle.mb20}`}>{t("Please add email to download Receipt.")}</h3>
                                <div className={`${CommonStyle.my25}`}>
                                    <div className={`${CommonStyle.fromControl}`}>
                                        <input type="text" placeholder="Your Email" onChange={(e) => setemail(e.target.value)} />
                                        {/* <span to="" className={classes.close}><img src={locationIcon} alt="Not Found" /></span> */}
                                    </div>
                                </div>
                                <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                                    <BorderButton label={"Cancel"} clickHandler={() => setchoosemodepopup(false)} />
                                    <CustomButton label={"Confirm"} clickHandler={() => handledownloadreceipt()} />
                                </div>
                            </RoundedDrawer>
                        )
                    }
                </> : <>
                    <CustomModal
                        open={choosemodepopup}
                        title={""}
                        handleClose={() => handleClose()}
                        description={""}>
                        <h3 className={`${CommonStyle.textCenter} ${CommonStyle.mb20}`}>{t("Please add email to download Receipt.")}</h3>
                        <div className={`${CommonStyle.my25}`}>
                            <div className={`${CommonStyle.fromControl}`}>
                                <input type="text" placeholder="Your Email" onChange={(e) => setemail(e.target.value)} />
                                {/* <span to="" className={classes.close}><img src={locationIcon} alt="Not Found" /></span> */}
                            </div>
                        </div>
                        <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            <BorderButton label={"Cancel"} clickHandler={() => setchoosemodepopup(false)} />
                            <CustomButton label={"Confirm"} clickHandler={() => handledownloadreceipt()} />
                        </div>
                    </CustomModal>
                </>}
            </div>
        </>
    )
}
export default withStyles(Styles)(KioskOrderSummary);