import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { NavLink, useLocation } from "react-router-dom";
import dropDownIconCircle from '../../images/dropDownIconCircle.svg';
import closeWhite from '../../images/closeWhite.svg';
import { CheckoutstatusAPI } from "../../redux/Action/Stripepayment/CheckoutStatus";
import BorderButton from "../../Components/BorderButton/BorderButton";
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StripeCheckoutAPI } from "../../redux/Action/Stripepayment/StripeCheckout";
import { useTranslation } from 'react-i18next';
const PaymentFailed = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    // console.log("session_id", sessionId)
    const { t } = useTranslation();
    useEffect(() => {
        const data = {
            payment_session_id: sessionId,
            status_key: "failure"
        }
        dispatch(CheckoutstatusAPI(data))
    }, [sessionId])

    const handletryagainpayment = () => {
        dispatch(StripeCheckoutAPI())
    }

    const downloadReceiptHandle = () => {

    }
    const tryHandle = () => {

    }

    return (
        <>
            {/* <div className={CommonStyle.spacingX0}> */}
            <ContainerBox>
                <div className={classes.checkBox}>
                    <img src={closeWhite} alt="Not Found" />
                </div>
                <div className={`${classes.paymentOuter} ${CommonStyle.spacingX16}`}>
                    <h2 className={CommonStyle.mb10}>{t("Payment Failed")}</h2>
                    <p className={CommonStyle.mb20}>{t("Unfortunately your payment is failed, and we are unable to take your order.")}</p>
                </div>
                {/* <Accordion  className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<img src={dropDownIconCircle} alt="Not Found" />}>
                            <h3>Payment details</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className={classes.listing}>
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>Order no</p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>#112</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>Payment Method </p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>Visa (xxxx xxxx xxxx 2361)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>Total Payable</p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>S$23.3</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.tryBtn}>
                                        <CustomButton label={"Try Again"} clickHandler={()=>{handletryagainpayment()}}/>
                                        <NavLink to="" className={CommonStyle.links}>Need Help?</NavLink>
                                    </div>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion> */}

                {/* <Accordion  className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<img src={dropDownIconCircle} alt="Not Found" />}>
                            <h3>Order Details</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className={classes.listing}>
                                <li>
                                    <div className={`${classes.leftContent} ${CommonStyle.flexCenterStart}`}>
                                        <div className={classes.imgContent}>
                                            <img src="./images/product-1.png" alt="Not Found" />
                                        </div>
                                        <div className={classes.txtContent}>
                                            <h4>Green Tea Rock Salt M.</h4>
                                            <p>Large / Honey Pearls / Sugar Level (50%)</p>
                                        </div>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <h4>x 1</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className={`${classes.leftContent} ${CommonStyle.flexCenterStart}`}>
                                        <div className={classes.imgContent}>
                                            <img src="./images/product-2.png" alt="Not Found" />
                                        </div>
                                        <div className={classes.txtContent}>
                                            <h4>Yakult Green Tea</h4>
                                            <p>Large / Honey Pearls / Sugar Level (50%)</p>
                                        </div>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <h4>x 1</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>Grand Total</p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>S$23.3</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>Promo Code</p>
                                        <p>This promotion is used only one time and on first order </p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>-S$5.00</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.leftContent}>
                                        <p className={classes.title}>Total Payable</p>
                                    </div>
                                    <div className={classes.rightContent}>
                                        <p>S$18.3</p>
                                    </div>
                                </li>
                                <li>
                                    <div className={classes.downloadReceipt}>
                                        <BorderButton label={"Download Receipt"} clickHandler={downloadReceiptHandle} />
                                    </div>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion> */}
                <p className={CommonStyle.spacingX16}>{t("In case of any payment is deducted from your account will revert within 72 hrs.")}</p>
                <div className={`${CommonStyle.bottomBtn} ${CommonStyle.JustifyCenter}`}> <LargeBtn label={t("try again")} clickHandler={handletryagainpayment} /></div>
            </ContainerBox>
            {/* </div> */}
        </>
    )
}
export default withStyles(Styles)(PaymentFailed);