import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    productItem: {
        padding: '20px 45px 20px 20px !important',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'flex-start',
        // justifyContent: 'space-between',
        backgroundColor: ColorTheme.lightBrown,
        position: 'relative',
        overflow: 'hidden',
        cursor: "pointer",
        marginBottom: '25px',
    },
    imgContent: {
        width: "100px",
        height: '100px',
        borderRadius: '10px',
        overflow: 'hidden',
        marginRight: '15px',
        "@media(max-width:640px)": {
            width: "82px",
            height: '82px',
        },
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
        }
    },
    counterBox: {
        "& div": {
            maxWidth: '100px',
            margin: '20px auto 0',
            backgroundColor: ColorTheme.transparent,
        },
        "& a": {
            borderColor: ColorTheme.yellow,
        }
    },
    myCardDes: {
        "& h4": {
            marginBottom: '10px',
        }
    },
    deleteIcon: {
        position: 'absolute',
        top: '8px',
        right: '15px',
        "& svg": {
            height: '20px',
            "@media(max-width:640px)": {
                height: '18px',
            },
        }
    },
    countBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: ColorTheme.transparent,
        borderRadius: '10px',
        maxWidth: '120px',
        "& a": {
            minWidth: '20px',
            textAlign: 'center',
            padding: '10px',
            minHeight: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '5px',
            border: `1px solid ${ColorTheme.yellow}`,
        }
    },
    countNumber: {
        margin: "0 5px",
        fontSize: "18px",
        lineHeight: "18px",
        color: ColorTheme.fontColor,
        fontWeight: "400",
        minWidth: '20px',
        textAlign: 'center',
        padding: '10px',
    }
})