import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    active: {
        background: `${ColorTheme.yellow} !important`,
        "& h5": {
            color: `${ColorTheme.secondaryColor} !important`,
        },
        "& p": {
            color: `${ColorTheme.secondaryColor} !important`,
        }
    },
    leftBox: {
        width: '70%'
    },
    rightBox: {
        width: '30%',
        paddingLeft: '10px',
        "& h5": {
            fontFamily: 'var( --sub-heading-family)',
        }
    },
    selectItems: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '25px',
        "@media(max-width:991px)": {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        "@media(max-width:768px)": {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        "@media(max-width:640px)": {
            display: 'inline',
        }
    },
    itemImgContent: {
        width: '80px',
        height: '80px',
        borderRadius: '10px',
        overflow: 'hidden',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            maxHeight: '80px',

        }
    },
    smallImgContent: {
        maxHeight: '65px',
        width: '65px',
        borderRadius: '10px',
        overflow: 'hidden',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            maxHeight: '65px',
        }
    },
    price: {
        fontFamily: 'var(--sub-heading-family)'
    },
    outer: {
        // height: '100%',
        "@media(max-width:640px)": {
            height: 'auto',
        },
        "& + div": {
            "@media(max-width:640px)": {
                paddingBottom: '8rem',
            }
        }
    }
})