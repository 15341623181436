import * as React from 'react';
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import { useNavigate } from 'react-router';
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BackArrow from '../../Components/BackArrow/BackArrow';
import user from '../../images/user.svg';
import { NavLink } from "react-router-dom";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { FormLabel } from "@mui/material";
import success from '../../images/success.svg';
import checkIcon from '../../images/checkIcon.svg';
import times from '../../images/times.svg';
import CommonSelectBox from '../../Components/CommonSelectBox/CommonSelectBox';
import CustomDatePicker from '../../Components/CustomDatePicker/CustomDatePicker';
import { useState } from 'react';
import { supplyDateFormat } from '../../Common/utlis/utlis';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileUpdateAPI } from '../../redux/Action/ProfileUpdate/ProfileUpdate';
import { ProfilegetAPI } from '../../redux/Action/ProfileUpdate/Profileget';
import Loader from '../../Components/Loader/Loader';
import AlertMessage from '../../Components/Alertmsg/AlertMessage';
import CustomPropover from '../../Components/CustomPropover/CustomPropover';
import { validateEmail } from '../../Common/Validations';
import { ProfilenumberchangeAPI } from '../../redux/Action/ProfileUpdate/ProfilenumberChange';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer';
import CustomButton from '../../Components/CustomButton/CustomButton';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import CustomModal from '../../Components/CustomModal/CustomModal';
import BorderButton from '../../Components/BorderButton/BorderButton';
const fields = {
    dob: "",
    inValid: false,
};
const KioskProfile = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModel, setShowModel] = useState(false);
    const [firstname, setfirstName] = useState("")
    const [lastname, setlastName] = useState("")
    const [email, setemail] = useState("")
    const [Gender, setgender] = useState([])
    const [dob, setdob] = useState(fields)
    const [number, setnumber] = useState("")
    const profile = localStorage.getItem("profile")
    const [images, setimages] = useState("")
    const ProfileUpdate = useSelector(state => state?.ProfileUpdateSlice)
    const maxDate = new Date()
    const [isOpen, setIsOpen] = useState(false);
    const [validation, setvalidation] = useState(false)
    const [validationlastname, setvalidationlastname] = useState(false)
    const [validationemail, setvalidationemail] = useState(false)
    const [validationgender, setvalidationgender] = useState(false)
    const [validationdob, setvalidationdob] = useState(false)
    const [validationimages, setvalidationimages] = useState(false)
    const profileparse = profile != undefined ? JSON.parse(profile) : ""
    const ProfileGet = useSelector(state => state?.ProfileGetSlice)
    const [mobileverifiedday,setmobileverifiedday]=useState(0)
    const [profilecheckshow, setprofilecheckshow] = useState(false)
    const [popup, setpopup] = useState(false);
    const device = useBreakpoint();
    const Profilenumberchange = useSelector(state => state?.ProfilenumberchangeSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    useEffect(() => {
        setpopup(false);
        dispatch(ProfilegetAPI())
        if (profile != undefined) {
            // console.log("profile", profileparse?.data?.user_data?.date_of_birth)
            setfirstName(profileparse?.data?.user_data?.first_name)
            setlastName(profileparse?.data?.user_data?.last_name)
            setemail(profileparse?.data?.user_data?.email)
            setdob((pre) => ({
                ...pre,
                dob: profileparse?.data?.user_data?.date_of_birth,
            }));
            setimages(profileparse?.data?.user_data?.avatar)
            setnumber(profileparse?.data?.user_data?.contact_number)
            setgender([profileparse?.data?.user_data?.gender])
            setmobileverifiedday(profileparse?.data?.user_data?.mobile_verified_day)

        }
    }, [])

    const handlenumberupdatepopup = () => {
        dispatch(Drawer_success(true));
        setpopup(true);
    }

    const handlenumberupdate = () => {

        const obj = {
            "phone_number": number,
            "country_code": "91"
        }
        dispatch(ProfilenumberchangeAPI(obj))
        setpopup(true);
    }

    const bindingHandler = (date) => {
        setvalidationdob(true)
        // console.log("dateofbirth", date[""], supplyDateFormat(date[""]))
        setdob((pre) => ({
            ...pre,
            dob: supplyDateFormat(date[""]),
        }));
    };
    const handlefileimage = (e) => {
        setvalidationimages(true)
        const file = e.target.files[0];
        if (file) {
            setimages(file)
        }

    }

    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };

    const [debouncedValue, setDebouncedValue] = useState('');

    // This useEffect handles the debouncing logic
    useEffect(() => {
        const delay = 600; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            profileupdatechange()
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [firstname, Gender, lastname, email, dob, images]);


    const profileupdatechange = () => {
        const formData = new FormData();


        // const obj = {
        //     "first_name": firstname,
        //     "last_name": lastname,
        //     "email": email,
        //     "gender": Gender[0],
        //     "date_of_birth": dob?.dob,
        //     formData
        // }

        // if ((firstname != "" && lastname != "" && validateEmail(email) == false && Gender != "" && dob != "" && images && validation)
        // ) {
        //     // console.log("obj", obj)
        //     formData.append('avatar', images);
        //     formData.append('first_name', firstname);
        //     formData.append('last_name', lastname);
        //     formData.append('email', email);
        //     formData.append('gender', Gender[0]);
        //     formData.append('date_of_birth', dob?.dob);
        //     dispatch(ProfileUpdateAPI(formData))
        // } else 
        if ((firstname != "" && validation)) {
            // console.log("emailemail", validateEmail(email))
            // formData.append('avatar', images);
            formData.append('first_name', firstname);
            dispatch(ProfileUpdateAPI(formData))
        } else if ((lastname != "" && validationlastname)) {
            formData.append('last_name', lastname);
            dispatch(ProfileUpdateAPI(formData))
        } else if ((validateEmail(email) == false && validationemail)) {
            formData.append('email', email);
            dispatch(ProfileUpdateAPI(formData))
        } else if ((Gender != "" && validationgender)) {
            formData.append('gender', Gender[0]);
            dispatch(ProfileUpdateAPI(formData))
        } else if ((dob != "" && validationdob)) {
            formData.append('date_of_birth', dob?.dob);
            dispatch(ProfileUpdateAPI(formData))
        }
        else if ((images && validationimages)) {
            formData.append('avatar', images);
            dispatch(ProfileUpdateAPI(formData))
        }
    }


    useEffect(() => {
        if (Profilenumberchange?.error?.message) {
            setIsOpen(true)
            dispatch(Drawer_success(false))
        }
       else if (Profilenumberchange?.value?.success) {
            navigate("/verify-otp-number")
        }
    }, [Profilenumberchange])

    useEffect(() => {
        if (ProfileUpdate?.value?.message ||ProfileUpdate?.error?.message) {
            dispatch(ProfilegetAPI())
            setIsOpen(true)
        }
    }, [ProfileUpdate])
    // console.log("ProfileUpdate", ProfileUpdate)

    const modalData = () => {
        return (
            <React.Fragment>
                <div className={classes.changeNumberModal}>
                    <h3>{StrConstent.profile.changeNumber.heading}</h3>
                    <p className={CommonStyle.my10}>Phone number can only be changed once every 180 days. Your current number is {number} </p>
                    <BorderButton label="cancel" clickHandler={() => cancleHandle()} />
                    <CustomButton label="Change number" clickHandler={() => handlenumberupdate()} />
                </div>
            </React.Fragment>
        )
    }

    const cancleHandle = () => {
        dispatch(Drawer_success(false))
        setShowModel(false)
    }
    return (
        <>

            {
                ProfileUpdate?.loading == true && (
                    <Loader />
                )
            }


            {
                ProfileUpdate?.value?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}

                        description={ProfileUpdate?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={isOpen}
                    />
                )
            }
            {
                ProfileUpdate?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}

                        description={ProfileUpdate?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                Profilenumberchange?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}

                        description={Profilenumberchange?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            <ContainerBox>
                <BackArrow clickHandler={() => navigate("/")} />
                <h2>{StrConstent.profile.heading}</h2>
                <div className={classes.userProfile}>
                    <span to="" className={`${classes.chips} ${classes.editProfile}`}><ModeEditIcon /> <input type="file" accept="image/*" className={classes.uploadFile} onChange={handlefileimage} /></span>
                    <div className={classes.userImg}>
                        {ProfileGet?.value?.data?.user_data?.avatar ? <>
                            <img src={ProfileGet?.value?.data?.user_data?.avatar} alt="Not Found" />
                        </> : <>
                            <img src={user} alt="Not Found" />
                        </>}
                        {/* <input type="file" accept="image/*"  onChange={handlefileimage}/> */}
                    </div>
                </div>
                <div className={CommonStyle.flexSpaceBetween}>
                    <FormLabel>Name</FormLabel>
                    {/* <div className={`${classes.verfiyContent} ${CommonStyle.mb0}`}>
                        <NavLink to="" className={classes.chips}><img src={checkIcon} alt="Not Found" /></NavLink>
                        <NavLink to="" className={`${classes.chips} ${classes.chipsBorder}`}><img src={times} alt="Not Found" /></NavLink>
                    </div> */}
                </div>
                <div className={`${CommonStyle.flexSpaceBetween} ${classes.firstName} ${CommonStyle.itemStart}`}>
                    <div className={CommonStyle.fromControl}>
                        <input type="text" name="" value={firstname} onChange={(e) => {
                            setfirstName(e.target.value)
                            setvalidation(true)
                        }} placeholder="First name" />
                    </div>
                    <div className={CommonStyle.fromControl}>
                        <input type="text" name="" value={lastname} placeholder="Last name" onChange={(e) => {
                            setlastName(e.target.value)
                            setvalidationlastname(true)
                        }} />
                    </div>
                </div>
                <div className={`${CommonStyle.flexSpaceBetween} ${classes.fieldContainer} ${CommonStyle.mt20} ${CommonStyle.itemStart}`}>
                    <div className={CommonStyle.fromControl}>
                        <div className={CommonStyle.flexSpaceBetween}>
                            <FormLabel>Gender</FormLabel>
                            {/* <div className={classes.verfiyContent}>
                                <NavLink to="" className={classes.chips}><img src={checkIcon} alt="Not Found" /></NavLink>
                                <NavLink to="" className={`${classes.chips} ${classes.chipsBorder}`}><img src={times} alt="Not Found" /></NavLink>
                            </div> */}
                        </div>
                        <CommonSelectBox personName={Gender} handleChange={(e) => {
                            setvalidationgender(true)
                            const {
                                target: { value },
                            } = e;
                            setgender(
                                typeof value === 'string' ? value.split(',') : value,
                            );

                        }} />
                    </div>
                    <div className={CommonStyle.fromControl}>
                        <div className={CommonStyle.flexSpaceBetween}>
                            <FormLabel>Mobile Number*</FormLabel>
                            {profilecheckshow && <div className={classes.verfiyContent}>
                                <NavLink to="" className={classes.chips} onClick={() => handlenumberupdatepopup()}>
                                    <img src={checkIcon} alt="Not Found" />
                                </NavLink>
                                <NavLink to="" className={`${classes.chips} ${classes.chipsBorder}`}><img src={times} alt="Not Found" /></NavLink>
                            </div>}
                        </div>
                        <div className={classes.mobileField}>
                            <input type="number" name="" disabled={mobileverifiedday<180?true:false} value={number} onChange={(e) => {

                                if (e.target.value.length < 11) {
                                    setnumber(e.target.value)
                                    if (e.target.value.length == 10) {
                                        setprofilecheckshow(true)
                                    } else (
                                        setprofilecheckshow(false)

                                    )
                                }
                            }} placeholder="Mobile Number" />
                            {number?.length < 10 ? "" : <img src={success} alt="Not Found" />}
                        </div>
                    </div>
                </div>
                <div className={`${CommonStyle.flexSpaceBetween} ${classes.fieldContainer} ${CommonStyle.mt20} ${CommonStyle.itemStart}`}>
                    <div className={CommonStyle.fromControl}>
                        <div className={CommonStyle.flexSpaceBetween}>
                            <FormLabel>Email</FormLabel>
                            {/* <div className={classes.verfiyContent}>
                                <NavLink to="" className={classes.chips}><img src={checkIcon} alt="Not Found" /></NavLink>
                                <NavLink to="" className={`${classes.chips} ${classes.chipsBorder}`}><img src={times} alt="Not Found" /></NavLink>
                            </div> */}
                        </div>
                        <input type="email" name="" value={email} onChange={(e) => {
                            setvalidationemail(true)
                            setemail(e.target.value)
                        }} placeholder="Email" />
                    </div>
                    <div className={CommonStyle.fromControl}>
                        <div className={CommonStyle.flexSpaceBetween}>
                            <FormLabel className={CommonStyle.flex}>Birthday <CustomPropover><small>we use this info to send you delightful birthday surprises.</small></CustomPropover></FormLabel>
                            {/* <div className={classes.verfiyContent}>
                                <NavLink to="" className={classes.chips}><img src={checkIcon} alt="Not Found" /></NavLink>
                                <NavLink to="" className={`${classes.chips} ${classes.chipsBorder}`}><img src={times} alt="Not Found" /></NavLink>
                            </div> */}
                        </div>
                        <CustomDatePicker
                            onBinding={bindingHandler}
                            allowedDays={null}
                            isFutureDisable={true}
                            dateValue={dob}
                            maxDate={maxDate}
                            isBlank={dob != "" ? false : true}
                        />
                    </div>
                </div>
            </ContainerBox>

            {
                device == "sm" ? <>
                    <RoundedDrawer>
                        {modalData()}
                    </RoundedDrawer>
                </> : <>
                    {DrawerSlice && <CustomModal
                        open={true}
                        // title={StrConstent.cart.cardClear.heading}
                        handleClose={() => dispatch(Drawer_success(false))}
                        description={""}>
                        {modalData()}
                    </CustomModal>}
                </>
            }
        </>
    )
}

export default withStyles(Styles)(KioskProfile);