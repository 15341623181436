import { Container } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import CommonSelectBoxAceCart from "../../Components/CommonSelectBoxAceCart/CommonSelectBoxAceCart";
import { useEffect } from "react";
const ContainerBox = (props) => {
    const { children, classes } = props;
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Container maxWidth="lg" className={`${classes.spacingTop} ${(isappwebviewcheck != "acecard") ? classes.qrModeSpacing : classes.aceAppSpacing}`}>
                {children}
            </Container>
        </>
    )
}
export default withStyles(Styles)(ContainerBox);

