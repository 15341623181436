import success from '../images/success.svg';
import info from '../images/info.svg';
import error from '../images/error.svg';

const StrConstent = {
  login: {
    heading: 'Login',
    errorMsg: {
      phoneNumberValid: 'Please Enter valid mobile number.',
      emailNumberValid: 'Please Enter valid email address.',

    },
  },
  cardItem: {
    heading: 'Cart Item',
  },
  menu: {
    heading: "Menu",
    costomizations: {
      heading: 'Repeat last used customizations?',
    },
    outlets: {
      heading: 'Outlets'
    }
  },
  orderAgain: {
    heading: 'Order Again'
  },
  chooseMode: {
    heading: 'Choose Mode',
  },
  orderPickUp: {
    heading: 'Pick-up Now',
  },
  selectOutlets: {
    heading: 'Select Outlets',
  },
  cart: {
    heading: 'Cart',
    cardClear: {
      heading: 'To Proceed , Your Cart will be Cleared.',
    },
    outlercarClear: {
      heading: 'You Switch to a different outlet, Your Cart will be Cleared.',
    }
  },
  flashdeal: {
    headingfirst: 'Are you sure you want to use',
    headingdsecond: 'point to get the coupon?'
  },
  flashdealcoupon: {
    heading: 'Your Coupon is saved',
  },
  recommended: {
    heading: 'Recommended',
  },
  verifyOtp: {
    heading: 'Enter the code',
  },
  proceedToPay: {
    heading: 'Proceed To Pay',
    noCoupons: {
      heading: 'No coupons Exist',
    }
  },
  orderSummary: {
    heading: 'Order Summary'
  },
  paymentFailed: {
    heading: 'Payment Failed',
    description: 'Unfortunately your payment is failed, and we are unable to take your order. ',
  },
  scanQrCode: {
    heading: 'Scan QR',
    description: 'Show this QR to order collection window and then you can collect your order.',
    set: {
      heading: 'SEt birthday',
    },
    confirmModal: {
      heading: 'Are you sure you want to exit?',
      description: "If you exit now, this voucher will be considered as used, and you'll miss out on the rewards."
    }
  },
  reward: {
    heading: 'reward',
    rewardCongratulations: {
      heading: 'Congratulations!',
      description: 'To use, simply tap on ‘promotions’ and apply the voucher on your next order.',
    },
    rewardUnsuccessful: {
      heading: 'redemption unsuccessful',
      description: 'Uh oh, something went wrong! Please try again later or contact support.',
    },
  },
  frequentlyQuestions: {
    heading: 'Frequently asked questions',
  },
  vouchers: {
    heading: 'Vouchers',
    voucherActivate: {
      heading: 'Are you sure you want to activate the voucher now?'
    }
  },
  reservation: "Reservation",
  profile: {
    heading: 'profile',
    changeNumber: {
      heading: 'Change phone number',
    }
  },
  addAddress: {
    heading: 'Add Address',
    checkBoxTxt: {
      heading: 'Set as default address',
    }
  },
  deliveryAddress: {
    heading: 'Manage Addresses',
    addNewAddress: {
      heading: 'Add new address'
    },
    noAddressTo: {
      heading: 'No address to display'
    }
  },
  notifications: {
    heading: 'Notifications',
  },
  notFound: {
    heading: "404 Opps",
    discription: "This page doesn’t exist.",
  },
  promotionalOrder: {
    heading: 'Promotional Offer -',
  },
  orderDetails: {
    heading: 'My Orders',
  },
  orderHistory: {
    heading: 'Order History',
  },
  mycart: {
    heading: "MY CART"
  },
  faqs: {
    heading: 'Frequently asked questions',
    description: 'Choose the number of credits you want to receive every month for greater savings or purchase them one-off.',
  },
  getInTouch: {
    heading: 'WE’D LOVE TO HEAR FROM YOU',
    description: 'Check if your questions have already been answered at our FAQ page.',
    address: 'Address',
    addressTxt: '9 Harrison Road, #04-01 Harrison Industrial Building, Singapore 369651',
    call: 'CALL US',
    callTxt: 'Mondays to Fridays:10am to 6pm (Excluding Public Holidays)',
    tel: 'Tel',
    emailUs: 'Email Us',
    emailTxt: 'contact@hipster-inc.com',
    formLabel: {
      name: "Name",
      email: 'Email',
      phoneNumber: 'Phone Number',
      typeOfEnquiry: 'Type of Enquiry',
    },
    placeholderTextarea: {
      heading: 'Enter your email address',
    },
  },
  ourStory: {
    heading: "COFFEE, OUR VEHICLE FOR SOCIAL CHANGE",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.."
  },
  kioSelectItem: {
    choice: {
      heading: 'Choice of pearls/ toppings',
    },
    sugarLevel: {
      heading: 'Sugar level',
    },
    quantity: {
      heading: 'Quantity',
    }
  },
  filterSearchBox: {
    placeholder: 'Find a food items',
  },
  all: {
    heading: 'All'
  },
  nocardItme: {
    heading: 'No Cart Item'
  },
  productModifier: {
    size: {
      heading: 'size'
    },
    customizations: {
      heading: 'Customizations'
    },
    sugarLevel: {
      heading: 'Sugar Level',
    },
    quantity: {
      heading: 'Quantity'
    }
  },
  buttonTxt: {
    viewCart: {
      heading: 'View Cart'
    },
    addMoreItems: {
      heading: 'Add More Items'
    },
    confirm: {
      heading: 'Confirm'
    }
  },
  kisok: {
    menu: {
      heading: 'Menu'
    },
    cart: {
      heading: 'Cart'
    },
    recommended: {
      heading: 'recommended',
    },
    phoneNumber: {
      heading: 'Phone Number',
      description: `To collect rewards on your order if an account exists. (optional)`
    },
    otp: {
      heading: 'Enter OTP',
      description: `Please enter the OTP send on `
    },
    mycart: {
      heading: 'My cart',
    },
    vouchers: {
      heading: 'Vouchers',
    },
    proceedToPay: {
      heading: 'Proceed to Pay',
    },
    orderDetails: {
      heading: 'Order Details',
    },
    selectPaymentMethod: {
      heading: 'Select Payment Method '
    },
    orderReady: {
      heading: 'We got your Order',
    },
    kioSelectItem: {
      choice: {
        heading: 'Choice of pearls/ toppings',
      },
      sugarLevel: {
        heading: 'Sugar level',
      },
      quantity: {
        heading: 'Quantity',
      },
    },
  },
  termsConditions: {
    heading: 'Terms of Use',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
  },
  privacyPolicy: {
    heading: 'Privacy Policy',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
  },
  howItWorks: {
    heading: 'REVITALISE YOUR TEAM',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    employersWorks: {
      heading: 'HOW IT WORKS FOR EMPLOYERS'
    }
  },
  Message: {
    Success: {
      type: "Success",
      url: { success },
      title: "Success",
      description:
        "Your request has been submitted, an email will be sent to you shortly.",
    },
    Info: {
      type: "Info",
      url: { info },
      title: "Info",
      description:
        "Your request has been submitted, an email will be sent to you shortly.",
    },
    Error: {
      type: "Error",
      url: { error },
      title: "unsuccessful",
      description: "Your request has not been submitted due to... ",
    },
  },
  footerTxt: {
    support: 'Support',
    faqs: 'FAQs',
    contactUs: 'Contact Us',
    about: 'About',
    ourStory: 'Our Story',
    termsUse: 'Terms Of Use',
    privacyPolicy: 'Privacy Policy'
  }
};
export default StrConstent;
