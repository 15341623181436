import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Get_Login_Cart_failure, Get_Login_Cart_request, Get_Login_Cart_success } from "../../reducers/LoginCart/GetLoginCartSlice"
import { Delete_Login_Cart_failure, Delete_Login_Cart_request, Delete_Login_Cart_success } from "../../reducers/LoginCart/DeleteLoginCartSlice"
import { GetLoginCartAPI } from "./GetLoginCartAction"
import { Headers } from "../../../APIHandler/HeaderAPI"


export const DeleteLoginCartAPI = (payload) => async (dispatch, getState) => {
    dispatch(Delete_Login_Cart_request())
    const payloads = payload

    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    // console.log("token", Logindetails?.data?.user_data?.auth_token);
    const cart_identifier = localStorage.getItem("cart_identifier")
    try {

        const { data } = await axios.delete(`${APIURL.deletelogincart}/${cart_identifier}/${payloads}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })

        dispatch(Delete_Login_Cart_success(data))
        dispatch(GetLoginCartAPI())

    } catch (error) {
        dispatch(Delete_Login_Cart_failure(error?.response?.data))

    }
}
