import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    productItem: {
        // "& a": {
        padding: '20px !important',
        borderRadius: '15px',
        display: 'flex',
        // alignItems: 'flex-start',
        justifyContent: 'space-between',
        backgroundColor: ColorTheme.lightBrown,
        position: 'relative',
        overflow: 'hidden',
        cursor: "pointer"
        // },
    },
    offerPrice: {
        borderRadius: '0px 32px 32px 0',
        padding: '10px 20px',
        backgroundColor: ColorTheme.yellow,
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '8',
        "& h6": {
            color: `${ColorTheme.secondaryColor} !important`,
        }
    },
    imgContent: {
        width: "100px",
        height: '100px',
        borderRadius: '10px',
        overflow: 'hidden',
        "@media(max-width:640px)": {
            width: "82px",
            height: '82px',
        },
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
        }
    },
    textContent: {
        paddingLeft: "15px",
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: '100px',
        width: 'calc(100% - 100px)',
    },
    flex: {
        display: 'flex',
        alignItems: "flex-end",
        // justifyContent: 'space-between',
        "& h3": {
            // marginRight: '5px',
            lineHeight: '25px'
        },
        "@media(max-width:991px)": {
            paddingBottom: '8px',
        }
    },
    flexBottom: {
        display: 'flex',
        alignItems: "flex-end",
        justifyContent: 'space-between',
        "@media(max-width:991px)": {
            paddingTop: '10px',
            alignItems: "flex-start",
            flexDirection: 'column'
        }
    },
    bottomContent: {
        paddingTop: '0',
        alignItems: "center",
        flexDirection: 'row'
    },
    quickAddButton: {
        padding: '6px',
        backgroundColor: ColorTheme.yellow,
        borderRadius: '100%',
        color: ColorTheme.white,
        position: 'relative',
        // zIndex: '9',
        width: '25px',
        height: '25px',
        float: 'right',
        marginTop: '5px',
    },
    countBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: ColorTheme.transparent,
        borderRadius: '10px',
        minWidth: '100px',
        maxWidth: '100px',
        marginTop: '20px',
        "& > a": {
            minWidth: '10px',
            textAlign: 'center',
            padding: '10px !important',
            minHeight: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '5px',
            border: `1px solid ${ColorTheme.yellow}`,
        }
    },
    countNumber: {
        margin: "0 5px",
        fontSize: "18px",
        lineHeight: "18px",
        color: ColorTheme.fontColor,
        fontWeight: "400",
        minWidth: '20px',
        textAlign: 'center',
        padding: '10px',
    }
})