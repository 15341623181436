import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { GetinTouch_failure, GetinTouch_request, GetinTouch_success } from "../../reducers/getinTouch/GetinTouch";

export const GetintouchAPI = (payload) => async (dispatch, getState) => {
  dispatch(GetinTouch_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    
    try {

        const { data } = await axios.post(`${APIURL.getintouch}`,payload, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },
            
        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(GetinTouch_success(data))
       
    } catch (error) {

        dispatch(GetinTouch_failure(error?.response?.data))
    }
}
