import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    navbar: {
        "&  a": {
            padding: "0 20px 15px 0",
            fontSize: 'var(--bs-fontSizetMenu)',
            lineHeight: "24px",
            fontWeight: 'var(--bs-fontWeightMenu)',
            color: ColorTheme.fontColor,
            "@media(max-width:640px)": {
                fontSize: "20px",
                lineHeight: "24px",
            }
        }
    },
    subMenu: {
        paddingTop: '2rem',
        "&  a": {
            padding: "0 20px 15px 0",
            fontSize: 'var(--bs-subtitle1)',
            lineHeight: "24px",
            fontWeight: '400',
            color: ColorTheme.fontColor,
        }
    },
    active: {
        "& a": {
            color: ColorTheme.yellow,
            fontWeight: '700',
        }
    }
})