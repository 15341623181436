import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    itemAdd: {
        background: ColorTheme.lightBrown,
        padding: '20px',
        borderRadius: '10px',
        "@media(max-width:640px)": {
            background: ColorTheme.transparent,
            padding: '0',
            borderRadius: '0',
        }
    },
    leftBox: {
        width: '70%'
    },
    rightBox: {
        width: '30%',
        paddingLeft: '10px',
    },
    smallImgContent: {
        maxHeight: '65px',
        width: '65px',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            maxHeight: '65px',
            borderRadius:'10px',
        }
    },
    countBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: ColorTheme.white,
        borderRadius: '10px',
        minWidth: '120px',
        "& a": {
            minWidth: '35px',
            textAlign: 'center',
            padding: '15px',
            minHeight: '20px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    },
    countNumber: {
        margin: "0 10px",
        fontSize: "18px",
        lineHeight: "18px",
        color: ColorTheme.fontColor,
        fontWeight: "400",
    }
})