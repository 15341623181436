import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const GetinTouchSlice = createSlice({
    name: 'GetinTouch',
    initialState,
    reducers: {

        GetinTouch_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        GetinTouch_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        GetinTouch_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_GetinTouch_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  GetinTouch_request, GetinTouch_success,GetinTouch_failure, Clear_GetinTouch_Cart } = GetinTouchSlice.actions

export default GetinTouchSlice.reducer