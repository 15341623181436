import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import BackArrow from '../../Components/BackArrow/BackArrow';
import { NavLink } from "react-router-dom";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import StrConstent from "../../Common/StrConstent";
import OrderCardBox from "../../Components/OrderCardBox/OrderCardBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useSelector } from "react-redux";
import Loader from "../../KioskComponents/Loader/Loader";
import { useTranslation } from "react-i18next";
const OrderHistory = (props) => {
    const { classes } = props;
    const OrderHistorySlice=useSelector(state=>state?.OrderHistorySlice)
    let navigate = useNavigate();
    const { t } = useTranslation();
    // console.log("OrderHistorySlice",OrderHistorySlice)
    return (
        <>
        {
            OrderHistorySlice?.loading==true && (
                <Loader/>
            )
        }
            <ContainerBox>
                <BackArrow
                    clickHandler={() => {
                        navigate(-1)
                    }} />
                <div className={CommonStyle.flexSpaceBetween}>
                    <h3 className={CommonStyle.mb20}>{StrConstent.orderHistory.heading}</h3>
                    {/* <NavLink to="" className={CommonStyle.links}>View order history</NavLink> */}
                </div>
                {/* <div className={classes.labelBox}>
                    <h4>April</h4>
                </div> */}
                    <div className={`${classes.cardOuter} ${classes.orderHistory}`}>
                    <ul>
                       { OrderHistorySlice?.value?.data?.orders?.length==0?<div className={CommonStyle.nodata}>
                            <h4>{t("No order found")}</h4>
                        </div>:<> {OrderHistorySlice?.value?.data?.orders?.map((data)=>{
                            return   <li>
                            <OrderCardBox data={data}/>
                        </li>
                        })}
                        </>
                        }
                    
                     
                        {/* <li>
                            <div className={classes.dateBox}>
                                <h3>21</h3>
                                <h6>Fri</h6>
                            </div>
                            <p className={classes.ltLine}>
                                <span></span>
                            </p>
                            <OrderCardBox />
                        </li>
                        <li>
                            <div className={classes.dateBox}>
                                <h3>22</h3>
                                <h6>SAt</h6>
                            </div>
                            <p className={classes.ltLine}>
                                <span></span>
                            </p>
                            <OrderCardBox />
                        </li> */}
                    </ul>
                </div>
            </ContainerBox>
        </>
    );
};
export default withStyles(Styles)(OrderHistory);
