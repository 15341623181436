import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "@mui/styles";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import BackArrow from '../../Components/BackArrow/BackArrow';
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import CustomButton from "../../Components/CustomButton/CustomButton";
import BorderButton from "../../Components/BorderButton/BorderButton";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import CustomModal from "../../Components/CustomModal/CustomModal";
const VoucherDetail = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const device = useBreakpoint();
    const [showModel, setShowModel] = useState(false);
    const dispatch = useDispatch();
    const voucherActivateHandler = () => {
        dispatch(Drawer_success(true));
        setShowModel(true)
    }

    const modalData = () => {
        return (
            <React.Fragment>
                <div className={classes.activateBox}>
                    <div className={classes.textContent}>
                        <h3 className={CommonStyle.mb10}>{StrConstent.vouchers.voucherActivate.heading}</h3>
                        <p className={CommonStyle.mb20}>Do not activate this voucher until you are in front of the cashier.</p>
                        <p>Once the voucher is activated, you will have 10 minutes to have it scanned.</p>
                    </div>
                    <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.mt20} ${CommonStyle.mb25} ${CommonStyle.footerModal}`}>
                        <BorderButton label="back" clickHandler={() => navigate(-1)} />
                        <CustomButton label="activate" clickHandler={() => navigate('/qr-code')} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
    return (
        <>
            <ContainerBox>
                <BackArrow clickHandler={() => navigate(-1)} />
                <h2>$$5{StrConstent.vouchers.heading}</h2>
                <div className={classes.voucherDetail}>
                    <h5 className={CommonStyle.fw700}>Description</h5>
                    <p className={CommonStyle.mb20}>Get ready to savor the flavors of our delightful beverages with our exclusive $5 voucher reward.
                        At Hollins, we are passionate about delivering the finest refreshments, and we want to share the joy with you.</p>
                    <h5 className={CommonStyle.fw700}>How to redeem</h5>
                    <p>Option A: In-app order</p>
                    <ul className={`${CommonStyle.listDots} ${CommonStyle.mb20}`}>
                        <li><p>During payment, select "Promotion" and apply the voucher.</p></li>
                    </ul>
                    <p>Option B: Order via cashier</p>
                    <ul className={`${CommonStyle.listDots} ${CommonStyle.mb20}`}>
                        <li><p>At the cashier, press "Redeem" and show the QR code.</p></li>
                    </ul>
                    <h5 className={CommonStyle.fw700}>Terms and conditions</h5>
                    <ul className={`${CommonStyle.listDots} ${CommonStyle.mb20}`}>
                        <li><p>At the cashier, press "Redeem" and show the QR code.</p></li>
                        <li><p>At the cashier, press "Redeem" and show the QR code.</p></li>
                    </ul>
                </div>
                <div className={`${CommonStyle.bottomBtn} ${CommonStyle.JustifyCenter}`}>
                    <LargeBtn label={"Activate (order via cashier)"} clickHandler={() => { voucherActivateHandler() }} />
                </div>
                {
                    device == "sm" ?
                        <RoundedDrawer>
                            {modalData()}
                        </RoundedDrawer>
                        :
                        <CustomModal
                            open={showModel}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => dispatch(Drawer_success(false))}
                            description={""}>
                            {modalData()}
                        </CustomModal>

                }
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(VoucherDetail);