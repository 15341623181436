import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    chooseMode: {
        "& > div": {
            "&:last-child": {
                marginBottom: '0',
                "@media(max-width:768px)": {
                    marginBottom: '20px',
                },
            }
        }
    },
    chooseModeBox: {
        padding: '20px',
        borderRadius: '10px',
        background: ColorTheme.lightBrown,
        marginBottom: '20px',
        cursor: "pointer",
        "@media(max-width:640px)": {
            background: ColorTheme.white,
        }
    },
    textContent: {
        width: '100%',
        marginRight: '10px',
    },
    arrowContent: {
        width: '10%',
        "& a": {
            height: '35px',
            width: '35px',
            background: ColorTheme.lightBrown,
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            "@media(max-width:768px)": {
                background: ColorTheme.lightBrown,
            },
            "& img": {
                height: '18px',
                width: '18px',
            }
        }
    },
    sectionPadding: {
        margin: '2rem 0',
},
    // qrMode: {
    //     display: 'flex !important',
    //     alignItems: 'center',
    //     "@media(max-width:768px)": {
    //         display: 'block !important',
    //         paddingTop: '2rem',
    //     },
    //     "@media(max-width:640px)": {
    //         paddingTop: '7rem',
    //     },
    // }
})