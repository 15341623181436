import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import BackArrow from "../../Components/BackArrow/BackArrow";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import RewardPoint from "../../Components/RewardPoint/RewardPoint";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import rewardStare from './../../images/rewardStare.png'
import longArrowRight from '../../images/longArrowRight.svg';
import { NavLink } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import checkWhite from '../../images/checkWhite.svg';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box } from "@mui/material";
import RoundedDrawer from "../../Components/BottomDrawer/RoundedDrawer";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import closeWhite from '../../images/closeWhite.svg';
import CustomModal from "../../Components/CustomModal/CustomModal";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import BorderButton from "../../Components/BorderButton/BorderButton";
const Reward = (props) => {
    const { classes } = props;
    const [showModel, setShowModel] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const device = useBreakpoint();
    const frequentlyQuestion = [
        {
            question: 'How do I use the redeemed vouchers?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
        {
            question: 'What is the expiry date of the points?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
        {
            question: 'Where can I use my points?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
        {
            question: 'Are there any restrictions to the use of points?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
    ]
    // use for accordion icon
    const CustomExpandIcon = () => {
        return (
            <Box
                sx={{
                    ".Mui-expanded & > .collapsIconWrapper": {
                        display: "none",
                    },
                    ".expandIconWrapper": {
                        display: "none",
                    },
                    ".Mui-expanded & > .expandIconWrapper": {
                        display: "block",
                    }
                }}>
                <div className="expandIconWrapper"><RemoveIcon /></div>
                <div className="collapsIconWrapper"><AddIcon /></div>
            </Box>
        );
    };
    const handleDrawer = () => {
        dispatch(Drawer_success(true))
        setShowModel(true)
    }

    const cancleHandle = () => {
        dispatch(Drawer_success(false))
        setShowModel(false)
    }

    const tryAgainHandle = () => {

    }

    const modalData = () => {
        return (
            <React.Fragment>
                <div className={classes.congratBox}>
                    <div className={classes.successMark}>
                        <img src={checkWhite} alt="Not Found" />
                    </div>
                    <h4>{StrConstent.reward.rewardCongratulations.heading}</h4>
                    <p className={CommonStyle.my10}>{StrConstent.reward.rewardCongratulations.description}</p>
                    <div className={CommonStyle.my1rem}> <CustomButton label="Close" clickHandler={cancleHandle} /></div>
                    <BorderButton clickHandler={() => navigate("/vochers")} label="view vouchers" />
                </div>
                {/* <div className={classes.congratBox}>
            <div className={classes.checkBox}>
                <img src={closeWhite} alt="Not Found" />
            </div>
            <h4>{StrConstent.reward.rewardUnsuccessful.heading}</h4>
            <p className={CommonStyle.my10}>{StrConstent.reward.rewardUnsuccessful.description}</p>
            <div className={CommonStyle.my1rem}> <CustomButton label="try again" clickHandler={tryAgainHandle} /></div>
            <NavLink to=""  className={CommonStyle.links}>Need Help?</NavLink>
            <BorderButton clickHandler={() => navigate("")} label="view vouchers" />
        </div> */}
            </React.Fragment>
        )
    }
    return (
        <>
            <ContainerBox>
                <BackArrow clickHandler={() => navigate(-1)} />
                <h2 className={CommonStyle.mb20}>{StrConstent.reward.heading}</h2>
                <RewardPoint button="button" link="link" linksTxt="View Vouchers" path="/vochers" label="insufficient points for redemption" givenPoint="84" totalPoint="125" clickHandler={handleDrawer} />
                <div className={classes.rewardBox}>
                    <NavLink to="" className={CommonStyle.flexSpaceBetween}>
                        <div className={CommonStyle.flexCenterStart}>
                            <div className={`${classes.stareImg}`}>
                                <img src={rewardStare} alt="Not Found" />
                            </div>
                            <div>
                                <h5>How points work</h5>
                                <p>Get 1 point/ $1 spent. Redeem $5 voucher for 125 points. </p>
                            </div>
                        </div>
                        <div className={`${CommonStyle.ml10} ${classes.arrowContent}`}>
                            <NavLink to=""><img src={longArrowRight} alt="Not Found" /></NavLink>
                        </div>
                    </NavLink>
                </div>
                <h2 className={CommonStyle.my1rem}>{StrConstent.frequentlyQuestions.heading}</h2>
                <div className={classes.frequentlyQues}>
                    {frequentlyQuestion.map((data) => (
                        <Accordion className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`}>
                            <AccordionSummary expandIcon={<CustomExpandIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <h6 className={CommonStyle.fw700}>{data.question}</h6>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>{data.answer}</p>
                            </AccordionDetails>
                        </Accordion>
                    ))
                    }
                </div>

                {
                    device == "sm" ? <>
                        <RoundedDrawer>
                            {modalData()}
                        </RoundedDrawer>
                    </> : <>
                        <CustomModal
                            open={showModel}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => dispatch(Drawer_success(false))}
                            description={""}>
                            {modalData()}
                        </CustomModal>
                    </>
                }
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(Reward);