import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Order_Again_failure, Order_Again_request, Order_Again_success } from "../../reducers/OrderAgain/OrderAgainSlice";
import { Headers } from "../../../APIHandler/HeaderAPI";
export const OrderAgainAPI = (payload) => async (dispatch, getState) => {
    dispatch(Order_Again_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const outlet_id=localStorage.getItem("outletID")

    try {

        const { data } = await axios.get(`${APIURL.orderAgain}?outlet_id=${outlet_id}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
   
        dispatch(Order_Again_success(data))
   
    } catch (error) {
        dispatch(Order_Again_failure(error?.response?.data))
    }
}
