import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Profile_Get_failure, Profile_Get_request, Profile_Get_success } from "../../reducers/ProfileUpdate/ProfilegetSlice";
import { Faq_failure, Faq_request, Faq_success } from "../../reducers/Faq/FaqSlice";
import { CategoryFaq_failure, CategoryFaq_request, CategoryFaq_success } from "../../reducers/Faq/CategoryFaqSlice";

export const CategoryFaqAPI = (payload) => async (dispatch, getState) => {
  dispatch(CategoryFaq_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    
    try {

        const { data } = await axios.get(`${APIURL.categoryfaq}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },
            
        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(CategoryFaq_success(data))
        localStorage.setItem("profile",JSON.stringify(data))
       
    } catch (error) {

        dispatch(CategoryFaq_failure(error?.response?.data))
    }
}
