import ColorTheme from "../../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    searchBox: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
        marginRight: "25px",
        position: 'relative',
        background: `${ColorTheme.white} !important`,
        borderRadius: "32px",
        "@media(max-width:991px)": {
            width: "100%",
        },
        "& > img": {
            height: "20px",
            position: "absolute",
            left: "25px",
        },
        "& input": {
            border: `1px solid ${ColorTheme.white}`,
            padding: "20px 60px",
            outline: "none",
            borderRadius: "32px",
            width: "100%",
            fontSize: "16px",
            lineHeight: "20px",
            color: 'var(--font-color)',
            fontWeight: "400",
            fontFamily: 'var(--sub-heading-family)',
            transition: ".5s ease",
            minHeight: "50px",
            "@media(max-width:320px)": {
                padding: "20px 50px",
            },
            "&::placeholder": {
                fontSize: "16px",
                lineHeight: "21px",
                color: `${ColorTheme.darkGray}`,
                fontWeight: "400",
                "@media(max-width:648px)": {
                    fontSize: "14px",
                    lineHeight: "21px",
                },
                "@media(max-width:320px)": {
                    fontSize: "13px",
                    lineHeight: "21px",
                },
            },
            "&:hover": {
                transition: ".5s ease",
                borderColor: "rgba(63, 68, 68, 0.12)",
            },
            "&:active": {
                transition: ".5s ease",
                borderColor: "rgba(63, 68, 68, 0.12)",
            },
        },
        "& span": {
            position: "absolute",
            right: "25px",
            "& img": {
                height: '20px',

            }
        },
    },

})