export const Styles = Theme => ({
    notFountOuter: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    notFountInner: {
        maxWidth: "400px",
        textAlign: "center",
    },
    imgContent: {
        maxWidth: "300px",
        height: "auto",
        margin: "0 auto 40px",
        "@media(max-width:640px)": {
            maxWidth: "200px",
            margin: "0 auto 20px",
        },
        "& img": {
            height: "auto",
            width: "100%",
        }
    },
})