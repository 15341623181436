import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const AddStripeCardSlice = createSlice({
    name: 'AddStripeCard',
    initialState,
    reducers: {
        Add_Stripe_Card_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Add_Stripe_Card_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Add_Stripe_Card_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        Add_Stripe_Card_guest:(state,action)=>{
            state.value = {};
            state.loading = true;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Add_Stripe_Card_request, Add_Stripe_Card_success, Add_Stripe_Card_failure ,Add_Stripe_Card_guest} = AddStripeCardSlice.actions

export default AddStripeCardSlice.reducer