import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const RewardsPointsSlice = createSlice({
  name: 'RewardsPoints',
  initialState,
  reducers: {
    Rewards_Points_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Rewards_Points_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    },
    Rewards_Points_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    },
    Clear_Rewards_Points_failure: (state, action) => {
        state.error = {};
        state.value={};
        state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Rewards_Points_request,  Rewards_Points_success , Rewards_Points_failure} = RewardsPointsSlice.actions

export default RewardsPointsSlice.reducer