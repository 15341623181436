import React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
const GrayButton = (props) => {
  const { classes, label, clickHandler } = props;
  return (
    <div className={classes.grayBtn}>
      <Button onClick={clickHandler}>{label}</Button>
    </div>
  );
};
export default withStyles(Styles)(GrayButton);
