import axios from "axios"
import { base_url_request, base_url_success } from "../reducers/BaseUrlSlice"
import { Headers, Hipster_Base_URL } from "../../APIHandler/HeaderAPI"
import { reloadHandler } from "../../Common/utlis/utlis"


export const BaseUrlAPI = (payload) => async (dispatch, getState) => {
    dispatch(base_url_request())
    try {
        const { data } = await axios.get(`${Hipster_Base_URL}`, {
            headers: Headers
            // {
            //     "url": "hazelnutapp.customizeyourfood.com"
            // }
        })
        // console.log("data", data)
        if(data?.data?.company_data?.outlet){

            localStorage.setItem("outletID",data?.data?.company_data?.outlet)
        }
        localStorage.setItem("companydata", JSON.stringify(data?.data?.company_setting))
        localStorage.setItem("companyoccupation", JSON.stringify(data?.data?.ocassions))
        localStorage.setItem("companyoutletcount", JSON.stringify(data?.data?.company_data?.outlet_count))
        localStorage.setItem("companyoutletname", JSON.stringify(data?.data?.company_data?.outlet_name))
        if(data?.data?.company_data?.outlet_count==1){

            localStorage.setItem("outletname", (data?.data?.company_data?.outlet_name))
           
            localStorage.setItem("outletID", (data?.data?.company_data?.outlet_id))
        }
        dispatch(base_url_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
    }


}