import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI"

import { Login_Cart_failure, Login_Cart_request, Login_Cart_success } from "../../reducers/LoginCart/LoginCartSlice"
import { Clear_Login_Cart } from "../../reducers/LoginCart/GetLoginCartSlice"
import { GetLoginCartAPI } from "./GetLoginCartAction"


export const AddLoginCartAPI = (payload) => async (dispatch, getState) => {
    dispatch(Login_Cart_request())
    const payloads = payload
   const kioskmode=localStorage.getItem("kioskmode")
    // console.log("Continueasguest", payloads)
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    // console.log("token",Logindetails?.data?.user_data?.auth_token);
    
    try {

        const { data } = await axios.post(`${APIURL.addlogincart}`, {
            items:payloads}, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })

        dispatch(Login_Cart_success(data))
        dispatch(GetLoginCartAPI())
        {
            kioskmode==3 &&(

                localStorage.removeItem("addtocart")
            )
        }
        // window.location.reload()
       dispatch(Clear_Login_Cart())
    } catch (error) {
        dispatch(Login_Cart_failure(error?.response?.data))
    
    }
}
