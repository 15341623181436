import axios from "axios"
import { Headers, Hipster_Base_URL } from "../../APIHandler/HeaderAPI"
import { reloadHandler } from "../../Common/utlis/utlis";
import { location_latlong_failure, location_latlong_request, location_latlong_success } from "../reducers/Location/LocationSlice";
import { APIURL } from "../../APIHandler/APIURL";



export const LatLongLocationAPI = (payload,page,filterproductsearch) => async (dispatch, getState) => {
    // console.log("payload", payload)
    console.log("vvvvvvvalue",payload)
    dispatch(location_latlong_request())
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");
    const outletID = localStorage.getItem("outletID");
    console.log("outletID",outletID)
    try {
        const payloadapi = payload == undefined ? "" : payload
        const latlongapi = `${APIURL.latlongfetchapi}?latitude=${latitude}&longitude=${longitude}&category_id=${payloadapi}&selected_outlet_id=${outletID && outletID!="undefined"?outletID:""}&page=${page}&search_by=${filterproductsearch!=undefined && filterproductsearch!="undefined"?filterproductsearch:""}`
        const withoulatlongapi = `${APIURL.latlongfetchapi}?category_id=${payloadapi}&selected_outlet_id=${outletID && outletID!="undefined"?outletID:""}&page=${page}&search_by=${filterproductsearch!=undefined && filterproductsearch!="undefined"?filterproductsearch:""}`
        const Locationapi = (latitude && longitude) ? latlongapi : withoulatlongapi
        const { data } = await axios.get(Locationapi, {
            headers: Headers
        })
        dispatch(location_latlong_success(data))
        // console.log("datalatlong", data)
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(location_latlong_failure(error?.response?.data))

    }
}