import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: "",

}

export const modeSlice = createSlice({
    name: 'mode',
    initialState,
    reducers: {

        mode_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { mode_success } = modeSlice.actions

export default modeSlice.reducer