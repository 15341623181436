import React, { useEffect } from 'react'
import { Styles } from './Style'
import { withStyles } from "@mui/styles";
import SearchBox from '../../../Components/FlashDeal/Search/SearchBox'
import CommonStyle from "../../../Common/CommonCss/Common.module.css";
import { Container } from "@mui/material";
// import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useTranslation } from 'react-i18next';
import FdIcon from '../../../images/fdIcon.png'
import Product from '../../../images/productImg.png'
import ListSlider from '../../../Components/FlashDeal/Slider/ListSlider';
import { useDispatch, useSelector } from 'react-redux';
import { FlashDealAPI, FlashDealLimitAPI } from '../../../redux/Action/FlashDeal/FlashDeal';
import Loader from '../../../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';

const FlashDealList = (props) => {
    const { t } = useTranslation();
    const { classes } = props;
    const [value, setValue] = React.useState('1');
    const dispatch = useDispatch();
    const FlashDealSlice = useSelector(state => state?.FlashDealSlice)
    const FlashDealLimitSlice = useSelector(state => state?.FlashDealLimitSlice)
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(FlashDealAPI())
        dispatch(FlashDealLimitAPI())
    }, [])
    const handleClick = (id) => {
        navigate(`/fd-detail/${id}`)
    }
    return (
        <>
            {
                (FlashDealSlice?.loading == true || FlashDealLimitSlice?.loading) && (
                    <Loader />
                )
            }
            <Container maxWidth="xl">
                {/* <div className={classes.searchBox}>
                    <SearchBox />
                </div> */}
                <TabContext value={value}>
                    <TabList variant="scrollable" className={`${CommonStyle.tabsContent} ${classes.tabContainer}`}>
                        <Tab label={t("FLASHDEALS")} className={classes.borderBottomSolid} value={""} />
                        <img src={FdIcon} className={classes.fdIcon} alt="Icon" />
                    </TabList>
                </TabContext>

                {(FlashDealSlice?.value?.data?.length > 0 || FlashDealLimitSlice?.value?.data?.length > 0) ? <>

                    {
                        FlashDealLimitSlice?.value?.data?.length > 0 &&
                        <>
                            <div>
                                <h2 className={`${CommonStyle.mb20} ${classes.upperCase} ${CommonStyle.mt30}`}>{t("limited time deals")}</h2>
                            </div>
                            <ListSlider FlashDealLimitSlice={FlashDealLimitSlice?.value?.data} />
                        </>

                    }
                    <p className={classes.borderBottomSolidLight}></p>
                    {FlashDealSlice?.value?.data?.length > 0 &&
                        <div className={CommonStyle.mb40}>
                            <div>
                                <h2 className={`${CommonStyle.mb20} ${classes.upperCase} ${CommonStyle.mt30}`}>{t("all flash deals")}</h2>
                            </div>
                            <div className={`${classes.gridDimension} ${classes.gap20}`}>
                                {FlashDealSlice?.value?.data?.map((data) => {
                                    return <div className={`${classes.itemContainer} ${classes.allFDContainer}`} onClick={(e) => handleClick(data?.id)}>
                                        <div className={classes.itemSlider}>
                                            <div className={`${classes.allItemTop}`}>
                                                <div className={`${CommonStyle.relative}`}>
                                                    <img src={data?.original} alt='Product Icon' className={`${classes.allProdImg} ${classes.rounded20}`} />
                                                    <div className={`${classes.digits} ${classes.flexDimension} ${CommonStyle.absolute}`}>
                                                        <p className={classes.flexDimension}>{data?.remaining_quantity}</p>
                                                    </div>
                                                </div>
                                                <div className={CommonStyle.ml10}>
                                                    <img src={FdIcon} className={classes.fdProdIcon} alt="Icon" />
                                                    <h3 className={classes.sliderHeading}> {data?.name} </h3>
                                                    <p className={classes.sliderContent}>{data?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                }
                            </div>
                        </div>
                    }
                </> : <div className={CommonStyle.nodata}>
                    <h4>{t("No Flash Deal Available")}</h4>
                </div>
                }
            </Container>
        </>
    )
}

export default withStyles(Styles)(FlashDealList);

