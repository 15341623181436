import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    sidebarBox: {
        width: "375px !important",
        padding: '3rem 2rem 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'auto',
        // height: '100%',
        "@media(max-width:640px)": {
            width: "300px !important",
            maxHeight: '100%',
            overflow: 'auto',
        },
        "@media(max-width:375px)": {
            width: "275px !important",
            padding: '3rem 1.5rem 1rem',
        }
    },
    header: {
        width: '100%',
        top: '0',
        padding: '15px 0',
        zIndex: '99',
        transition: 'all .5s',
        backgroundColor: `${ColorTheme.black}`,
        transition: '0.2s all',
        // "@media(max-width:640px)": {
        position: 'fixed !important',
        // animation: 'inherit !important',
        // display:'none',
        // },
    },
    headerInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        "@media(max-width:575px)": {
            display: 'block',
        },
    },
    logo: {
        "@media(max-width:640px)": {
            display: 'none',
        },
    },
    logoBox: {
        maxWidth: '200px',
        height: 'auto',
        "@media(max-width:640px)": {
            maxWidth: '80px',
        },
        "@media(max-width:475px)": {
            maxWidth: '65px',
        },
        "& img": {
            width: 'auto',
            height: "50px",
            "@media(max-width:768px)": {
                height: "40px",
            },
            "@media(max-width:375px)": {
                height: "38px",
            },
            "@media(max-width:340px)": {
                height: "35px",
            },
        }
    },
    logoContainer: {
        width: '100%',
        "@media(max-width:440px)": {
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: 'auto',
        }
    },
    review: {
        padding: '20px',
        borderRadius: '10px',
        background: ColorTheme.black,
        marginBottom: "2rem",
        "& h3": {
            color: ColorTheme.white,
        },
        "& h6": {
            color: ColorTheme.white,
            fontFamily: 'var( --sub-heading-family)',
            marginBottom: '3px',
        },
        "& small": {
            color: ColorTheme.white,
        },
        "& svg": {
            paddingLeft: '2px',
            fontSize: '14px',
            color: ColorTheme.white,
        },
        "& a": {
            display: 'inline-block',
            width: '100%',
        }
    },
    reviewContent: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px'

    },
    rating: {
        display: 'flex',
        alignItems: 'flex-end',
        "& h3": {
            lineHeight: '22px',
        }
    },
    userImgContent: {
        width: '65px',
        height: '65px',
        padding: '10px',
        background: ColorTheme.steel,
        marginRight: '12px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& img": {
            width: '100%',
            maxWidth: '35px'
        }
    },
    signout: {
        "& a": {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: '700',
        }
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        "& a": {
            marginLeft: '15px',
            "@media(max-width:360px)": {
                marginLeft: '8px',
            },
        },
    },
    leftContent: {
        display: 'flex',
    },
    customSelect: {
        width: '100%',
        "& > div": {
            "@media(max-width:575px)": {
                minHeight: '40px',
            },
        },
        "& div": {
            padding: '0 20px 0 10px !important',
            minWidth: '120px',
            color: `${ColorTheme.white} !important`,
            maxWidth: '300px',
            "@media(max-width:640px)": {
                // padding: '0 20px 0 15px !important',
                maxWidth: '250px',
            },
            "@media(max-width:575px)": {
                minWidth: 'auto',
                maxWidth: '100%',
                width: '100%',
                paddingLeft: '0 !important',
            },
            "@media(max-width:375px)": {
                // maxWidth: '180px',
            },
            "@media(max-width:360px)": {
                // maxWidth: '150px',
            },
        },
        "& p": {
            color: `${ColorTheme.white} !important`,
            marginBottom: '2px',
            "@media(max-width:360px)": {
                fontSize: '14px',
            },
        },
        "& span": {
            color: `${ColorTheme.white} !important`,
            fontStyle: 'inherit',
            fontSize: '14px',
            "@media(max-width:360px)": {
                fontSize: '12px',
            },
        },
        "& svg": {
            fontSize: '40px',
            color: ColorTheme.white,
            position: 'absolute',
            top: '-10px',
            right: '0',
        },
    },
    headerTransparent: {
        position: 'absolute',
        background: `${ColorTheme.transparent}`,
    },
    toggleBtn: {
        height: '45px',
        width: '45px',
        position: 'relative !important',
        borderRadius: '8px !important',
        background: 'rgb(255 255 255 / 40%) !important',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        cursor: 'pointer !important',
        borderRadius: '5px !important',
        "& img": {
            maxWidth: '45px',
            "@media(max-width:375px)": {
                maxWidth: '30px',
            },
        },
        "@media(max-width:375px)": {
            height: '40px',
            width: '40px',
        },
    },
    popupContent: {
        minWidth: '300px',
        maxWidth: '350px',
        width: 'auto',
        top: '62px !important',
        zIndex: '999',
        "@media(max-width:1600px)": {
            minWidth: '240px',
            width: 'auto',
        },
        "@media(max-width:640px)": {
            maxWidth: 'none',
        },
        "@media(max-width:575px)": {
            minWidth: '220px',
        },
        "@media(max-width:475px)": {
            minWidth: '200px',
        },
        "@media(max-width:375px)": {
            top: '56px !important',
        }
    },
    cardView: {
        padding: '1rem 1rem !important',
        display: 'block !important',
        border: 'none !important',
        background: `${ColorTheme.silkGray}`,
    },
    cardViewInner: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #E6E7E7',
    },
    itemNumber: {
        height: '25px',
        width: '25px',
        borderRadius: '100%',
        position: 'absolute',
        top: '-5px',
        right: '0',
        background: ColorTheme.yellow,
        color: ColorTheme.secondaryColor,
        textAlign: 'center',
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '700',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'var( --sub-heading-family)',
    },
    mobileHide: {
        "@media(max-width:640px)": {
            display: 'none',
        }
    },
    addCard: {
        marginTop: '20px',
        "& button": {
            width: '100%',
        },
    },
    imgContent: {
        width: "50px",
        height: '50px',
        borderRadius: '10px',
        overflow: 'hidden',
        marginRight: '10px',
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
        }
    },
    proImgContent: {
        width: "80px",
        height: '80px',
        borderRadius: '10px',
        overflow: 'hidden',
        marginRight: '10px',
        "@media(max-width:991px)": {
            width: "50px",
            height: '50px',
        },
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
        }
    },
    toggleBtnContent: {
        "@media(max-width:575px)": {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '12px',
        }
    },
    logoMobShow: {
        display: 'none',
        "@media(max-width:575px)": {
            display: 'block',
        }
    },
    logoMobHide: {
        "@media(max-width:575px)": {
            display: 'none',
        }
    },
    cardItem: {
        width: '300px',
        padding: '20px',
        background: `#fafafa`,
        boxSizing: 'border-box',
        "@media(max-width:375px)": {
            width: '250px',
        }
    },
    fixed:{
        
        backgroundColor:`${ColorTheme.yellow} !important`,
    }
})