import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    countBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: ColorTheme.white,
        padding: '18px',
        borderRadius: '10px',
        minWidth: '120px',
        "& a": {
            minWidth: '35px',
            textAlign: 'center',
        }
    },
    countNumber: {
        margin: "0 10px",
        fontSize: "18px",
        lineHeight: "18px",
        color: ColorTheme.darkGray,
        fontWeight: "400",
    }
})