import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    selectBox: {
        border: '1px solid #f1eedb',
        background: 'var(--bs-white) !important',
        borderRadius: '30px',
        "& div": {
            width: '100%',
            fontFamily:'var(--sub-heading-family)',
            color: `${ColorTheme.fontColor} !important`,
        },
        marginBottom: '15px',
    },
    "& .MuiMenuItem-root": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
    "& .Mui-selected": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
})
