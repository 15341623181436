import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    fields: {
        "& div": {
            width: '100%',
        }
    },
    textFields: {
        "& div": {
            width: '100%',
            padding: '0',
            border: 'none',
            background: 'transparent',
            boxShadow: 'none',
        }
    },
    selectBox: {
        border: '1px solid #f1eedb',
        background: 'var(--bs-white) !important',
        borderRadius: '30px',
        "& div": {
            width: '100%',
            fontFamily: 'var(--sub-heading-family) !important',
            // color: `${ColorTheme.secondaryColor} !important`,
            margin: '0 !important',
        },
        marginBottom: '15px',
    },
    "& .MuiMenuItem-root": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
    "& .Mui-selected": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
    getInTouchContainer:{
        "@media(max-width:991px)": {
           display:'block !important',
        }
    },
    leftSection: {
        "& .rw": {
            alignItems: "start"
        },
        width: '70%',
        "@media(max-width:991px)": {
            width: '100%',
         }
    },
    rightSection: {
        width: '30%',
        padding:'35px 0 0 40px',
        "@media(max-width:991px)": {
            width: '100%',
            padding:'25px 0 0 0',
         },
        "& li":{
            marginBottom:'20px',
        }
    },
})