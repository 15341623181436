import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Verify_number_failure, Verify_number_request, Verify_number_success } from "../../reducers/ProfileUpdate/verifynumberSlice";

export const VerifynumberAPI = (payload) => async (dispatch, getState) => {
    dispatch(Verify_number_request())
    const numberchange=localStorage.getItem("numberchange")
    const payloads = {
        "otp":payload,
        "phone_number":numberchange
    }
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    
    try {

        const { data } = await axios.post(`${APIURL.profilenumberverify}`,payloads, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },
            
        })
        localStorage.setItem("newnumber",numberchange)
     dispatch(Verify_number_success(data))
   } catch (error) {
        dispatch(Verify_number_failure(error?.response?.data))
    }
}
