import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Headers } from "../../../APIHandler/HeaderAPI"
import { ChangePassword_failure, ChangePassword_request, ChangePassword_success } from "../../reducers/ChangePassword/ChangePasswordSlice"


export const ChangePasswordAPI = (payload) => async (dispatch, getState) => {
    dispatch(ChangePassword_request())

   const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));


    try {
        const addtocart= localStorage.getItem("addtocart")

        const { data } = await axios.post(`${APIURL.changePassword}`,payload, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "owner-key":'api.ace-card',
                "Content-type": "application/json",

                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(ChangePassword_success(data))
     

    } catch (error) {
        dispatch(ChangePassword_failure(error?.response?.data))
        // console.log("otpverified",error?.response?.data)
    }
}