import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    cartOuter:{
    //   display:'table',
      width:'100%',
    //   height:'calc(100% - 200px)'
    },
    cartInner: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '25px',
        "@media(max-width:1200px)": {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        "@media(max-width:991px)": {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        "@media(max-width:640px)": {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    },
    leftBox: {
        width: '70%'
    },
    rightBox: {
        width: '30%',
        paddingLeft: '10px',
        "& h5": {
            fontFamily: 'var( --sub-heading-family)',
        }
    },
    drawerOuter: {
        // padding: '15px',
    },
    productImg: {
        maxWidth: '80px',
        height: '80px',
        margin: 'auto',
        borderRadius: '10px',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    topBox: {
        padding: '2.5rem 0 1.5rem',
        textAlign: 'center',
    },
    bottomBox: {
        padding: '1.5rem 0 ',
        borderTop: '1px solid #ddd',
        textAlign: 'center',
    },
    spacingTop: {
        margin: '1.5rem',
    },
    inputField: {
        "& input": {
            background: ColorTheme.white,
            border: 'none !important',
        }
    },
    orderSlider: {
        "& > div > div > div> div > div": {
            paddingRight: '15px',
        },
    }
})