import React, { useEffect } from 'react'
import { Styles } from './Style';
import { withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from '../../Common/StrConstent';
import CustomButton from '../../KioskComponents/CustomButton/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { VoucherkioskAPI } from '../../redux/Action/Voucherkiosk/VoucherKiosk';
import { ApplyCouponListAPI } from '../../redux/Action/Coupon/applyCouponAction';
import CustomModal from '../../KioskComponents/CustomModal/CustomModal';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import { Clear_Apply_Coupon } from '../../redux/reducers/Coupon/ApplyCouponSlice';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import Loader from '../../KioskComponents/Loader/Loader';
const KioVouchers = (props) => {
    const { classes } = props;
    const dispatch = useDispatch()
    const VoucherKioskSlice = useSelector(state => state?.VoucherKioskSlice)
    const ApplyCoupon = useSelector(state => state?.ApplyCouponSlice)
    const navigate = useNavigate()
    const Drawerslice = useSelector(state => state?.DrawerSlice?.value)
    const grandTotal = localStorage.getItem("grandTotal");
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    
    useEffect(() => {
        dispatch(Clear_Continue_as_guest())
        dispatch(VoucherkioskAPI())
    }, [])

    // const vouchersData = [
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    //     {
    //         offer: 'Welcome offer',
    //         forOffer: 'For new customer only'
    //     },
    // ]

    const applycoupon = (id) => {
        dispatch(ApplyCouponListAPI(id))
    }

    useEffect(() => {
        if (ApplyCoupon?.error?.message) {
            dispatch(Drawer_success(true))
        } else if (ApplyCoupon?.value?.success == true) {
            navigate("/kio-proceed-to-pay")
        }
    }, [ApplyCoupon])
    // console.log("VoucherKioskSlice", VoucherKioskSlice,grandTotal)
    return (
        <>
            {VoucherKioskSlice?.loading == true && (
                <Loader />
            )}
            {
                ApplyCoupon?.loading == true && (
                    <Loader />
                )
            }
            <ContainerBox>
                <div className={CommonStyle.kioOuter}>
                    <h2 className={CommonStyle.mb20}>{StrConstent.kisok.vouchers.heading}</h2>
                    {
                        VoucherKioskSlice?.value?.data?.length > 0 && VoucherKioskSlice?.value?.data?.map((data) => (
                            <>
                                <div className={`${CommonStyle.flexSpaceBetween} ${classes.vouchersCart}`}>
                                    <div className={CommonStyle.pr10}>
                                        <h4>{data?.name}</h4>
                                        <p>{data?.descriptions}</p>
                                        <p className={classes.errorMessage}>{data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used ? (+grandTotal < data?.minimum_amount ? `Add items worth ${companydata?.currency_symbol}${data?.minimum_amount - +grandTotal} more to unlock.` : "") : +grandTotal < data?.minimum_amount ? `Add items worth ${companydata?.currency_symbol}${data?.minimum_amount - +grandTotal} more to unlock.` : "This coupon already used."}</p>
                                    </div>
                                    {data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used && (
                                        +grandTotal >= data?.minimum_amount ? <CustomButton label="Apply" clickHandler={() => applycoupon(data?.code)} /> : <CustomButton label="Add Items" clickHandler={() => navigate("/kio-menu")} />
                                    )}
                                    {/* <CustomButton label="Apply" clickHandler={() => applycoupon(data?.code)} /> */}
                                </div>
                            </>
                        ))
                    }

                    {
                        VoucherKioskSlice?.value?.data?.length == 0 && (
                            <div className={CommonStyle.nodata}>
                                <h2>No Vouchers available.</h2>
                            </div>
                        )
                    }
                </div>
                <div className={`${CommonStyle.flexCenter} ${CommonStyle.JustifyCenter} ${CommonStyle.kioBottomBtnContainer} ${classes.kioBottomBtn}`}>
                    <CustomButton label="Next" clickHandler={() => navigate("/kio-proceed-to-pay")} />
                </div>
            </ContainerBox>

            {Drawerslice == true && <CustomModal
                open={true}
                // title={StrConstent.cart.cardClear.heading}
                handleClose={() => {
                    dispatch(Drawer_success(false))
                    dispatch(Clear_Apply_Coupon())
                }}
                description={""}
            >
                <h3>
                    {ApplyCoupon?.error?.message}
                </h3>

            </CustomModal>}
        </>

    )
}
export default withStyles(Styles)(KioVouchers);
