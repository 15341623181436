import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ProfileGetSlice = createSlice({
    name: 'ProfileGet',
    initialState,
    reducers: {

        Profile_Get_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Profile_Get_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Profile_Get_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Profile_Get_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  Profile_Get_request, Profile_Get_success,Profile_Get_failure, Clear_Profile_Get_Cart } = ProfileGetSlice.actions

export default ProfileGetSlice.reducer