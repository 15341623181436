import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    cartContainer: {
        background: ColorTheme.yellow,
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        margin: '0 auto',
        paddingBottom: '60px',
        borderBottom: `1px solid #00000026`,
        "& h4": {
            color: ColorTheme.secondaryColor,
        },
        "& h3": {
            color: ColorTheme.secondaryColor,
            margin: '15px 0',
            fontFamily: 'var(--sub-heading-family)',
            color: ColorTheme.secondaryColor,
        },
        "& p": {
            color: ColorTheme.secondaryColor,
        },
        "& div": {
            marginTop: '15px',
            justifyContent: 'center',
            "& a": {
                background: ColorTheme.white,
                borderColor: ColorTheme.white,
            }
        }
    },
    imgContent: {
        height: '100px',
        width: '100px',
        overflow: 'hidden',
        borderRadius: '20px',
        margin: '0 auto 20px',
        "@media(max-width:640px)": {
            height: '80px',
            width: '80px',
        },
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        }
    },
    order: {
        width: 'auto',
    },
    topBox: {
        padding: '15px',
        borderRadius: '10px',
        backgroundColor: ColorTheme.white,
        marginBottom: '3px',
        boxSizing: 'border-box',
    },
    bottonBox: {
        padding: '15px',
        borderRadius: '10px',
        alignItems: 'baseline',
        backgroundColor: ColorTheme.white,
        boxSizing: 'border-box',
    },
    imgContent: {
        width: "100px",
        height: '100px',
        borderRadius: '10px',
        margin: '0 auto 20px',
        position: 'relative',
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    edit: {
        bottom: '-4px',
        right: '-6px',
        padding: '10px',
        position: 'absolute',
        borderRadius: '15px 0 0 10px',
        backgroundColor: ColorTheme.yellow,
        color: ColorTheme.secondaryColor,
        "& svg":{
          height:'20px',
        }
    },
    textContent: {
        paddingLeft: "15px",
        width: 'calc(100% - 60px)',
    },
    counter: {
        display: 'flex',
        alignItems: 'center',
    },
    btn: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        height: ' 30px',
        width: ' 30px',
        border: '1px solid !important',
        borderColor: ColorTheme.yellow,
        color: ColorTheme.yellow,
        borderRadius: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    counterNumber: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        minWidth: ' 30px',
        textAlign: 'center',
        margin: '0 5px',
    },
    mt10: {
        margin: '8px 0 10px',
    },
    productName: {
        width: '90%',
        paddingRight: '10px',
    },
    deleteIcon: {
        width: '10%',
        "& svg": {
            width: '30px',
            color: ColorTheme.fontColor,
        }
    },
})

