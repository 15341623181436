import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    review: {
        padding: '20px',
        borderRadius: '10px',
        background: ColorTheme.black,
        marginBottom: "2.5rem",
        "& h3": {
            color: ColorTheme.white,
        },
        "& small": {
            color: ColorTheme.white,
            lineHeight: 'normal',
        },
        "& svg": {
            paddingLeft: '2px',
            fontSize: '14px',
            color: ColorTheme.white,
        },
        "& a": {
            display: 'inline-block',
            width: '100%',
            color: ColorTheme.white,
            textAlign: 'right',
        },
    },
    reviewContent: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    flexEnd: {
        display: 'flex',
        alignItems: 'flex-end',
        "& h3": {
            lineHeight: '22px'
        }
    },
    customBtn: {
        "& button": {
            padding: "15px 24px",
            background: ColorTheme.yellow,
            letterSpacing: "0",
            width: "auto",
            minWidth: '200px',
            borderRadius: '30px',
            fontSize: '1.25rem !important',
            lineHeight: '1.75rem !important',
            fontWeight: '400 !important',
            fontFamily: 'var(--main-heading-family) !important',
            "@media(max-width:640px)": {
                width: '100%',
            },
            "&:hover": {
                background: ColorTheme.yellow,
            },
        },
    },
    disable: {
        "& button": {
            background: ColorTheme.paleGrey,
        }
    }
})