import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { NavLink } from "react-router-dom";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ChooseModeApi } from "../../redux/Action/choosemode/ChoosemodeAction";
import Loader from "../../Components/Loader/Loader";
import longArrowRight from '../../images/longArrowRight.svg';
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer'
import { CartClearAPI } from "../../redux/Action/CartClear/CartClear";
import CustomButton from "../../Components/CustomButton/CustomButton";
import BorderButton from "../../Components/BorderButton/BorderButton";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import { GetLoginCartAPI } from "../../redux/Action/LoginCart/GetLoginCartAction";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import { useGeolocated } from "react-geolocated";
import { Clear_Login_Cart_failure } from "../../redux/reducers/LoginCart/LoginCartSlice";
import { OutletIdfetchAPI } from "../../redux/Action/Outlet/OutletIdAction";
import { useTranslation } from 'react-i18next';
const ChooseMode = (props) => {
    const { classes, clickHandler } = props;
    let navigate = useNavigate();
    const choosemode = useSelector(state => state?.Choosemode)
    const [showModel, setShowModel] = useState(false);
    const device = useBreakpoint();
    const dispatch = useDispatch();
    const outlet_mode = localStorage.getItem("outlet_mode")
    const [locationallow, setallocation] = useState(false)
    const [cartclearpopup, setcartclearpopup] = useState(false)
    const OutletIDSlice = useSelector(state => state?.OutletIDSlice)
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")

    const [lat, setlat] = useState("")
    const [long, setlong] = useState("")
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    const [isOpen, setIsOpen] = useState(false);
    const Logindetails = localStorage.getItem("Logindetails");
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const companyoutletcount = localStorage.getItem("companyoutletcount")
    const outletID = localStorage.getItem("outletID");
    const { t } = useTranslation();
    const urlnew = window.location.href;
    const urlnewmatch = urlnew?.includes("baby-on-board")
    useEffect(() => {
        setallocation(false)
        dispatch(Clear_Login_Cart_failure())
        dispatch(Drawer_success(false))
        dispatch(ChooseModeApi())
        dispatch(OutletIdfetchAPI(outletID))
        localStorage.removeItem("addressmodenavigate")
        if (Logindetail) {
            dispatch(GetLoginCartAPI())
        }
    }, [])
    const [todaysDay, setTodaysDay] = useState([]);

    useEffect(() => {
        const getCurrentDay = () => {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const currentDate = new Date();
            const dayIndex = currentDate.getDay();
            const today = daysOfWeek[dayIndex];

            const filterday = OutletIDSlice?.value?.data?.schedule?.filter((data) => {
                return data?.day == today
            })
            setTodaysDay(filterday);
        };

        getCurrentDay();
    }, [OutletIDSlice]);

    console.log("todaysDay", todaysDay)
    const handlemodechange = (e) => {
        // console.log("!outlet_mode", !outlet_mode)

        if (e?.title == "Schedule Delivery") {
            if (Logindetail) {

                localStorage.setItem("outlet_mode", e?.title)
                return navigate("/delivery-address")
            } else {

                localStorage.setItem("addressmodenavigate", true)
                return navigate("/login")
            }
        } else {
            localStorage.setItem("outlet_mode", e?.title)
            return navigate("/pickup-outlet")
        
        }
    }
    useEffect(() => {
        if (!isGeolocationAvailable) {
            // dispatch(Drawer_success(true));
        } else if (!isGeolocationEnabled) {
            // setblocked(true)
        } else if (coords) {
            setlong(coords.longitude)
            setlat(coords.latitude)
            localStorage.setItem("latitude", (coords.latitude))
            localStorage.setItem("longitude", (coords.longitude))
        }
     
    })
    const handleCartClear = () => {
        if (outlet_mode == "Pick-Up Today") {
            localStorage.removeItem("addtocart")
            localStorage.setItem("outlet_mode", "Schedule Pick-Up")
            dispatch(CartClearAPI())
            setShowModel(false);
            if (companyoutletcount == "1" || companyoutletcount == 1) {
                navigate("/select-outlet")
            } else {

                navigate("/pickup-outlet")
            }
        } else if (outlet_mode == "Schedule Pick-Up") {
            localStorage.removeItem("addtocart")
            localStorage.setItem("outlet_mode", "Pick-Up Today")
            dispatch(CartClearAPI())
            setShowModel(false);
            if (companyoutletcount == "1" || companyoutletcount == 1) {
                navigate("/select-outlet")
            } else {

                navigate("/pickup-outlet")
            }
            // navigate("/pickup-outlet")
        }
    }
    const handleCancle = () => {
        setcartclearpopup(false);
        setallocation(false)
        dispatch(Drawer_success(false));
    }
    const handleClose = () => {
        setShowModel(false);
    }
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    useEffect(() => {
        if (choosemode?.error?.message) {
            setIsOpen(true)
        }
    }, [choosemode])
    return (
        <>
            {
                OutletIDSlice?.loading == true && (
                    <Loader />
                )
            }
            {
                choosemode?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={choosemode?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }

            {/* <BlockedLocationDrawer /> */}
            {
                choosemode?.loading == true && (
                    <Loader />
                )
            }
            <ContainerBox>
                <div className={`${CommonStyle.outer}`}>
                    <div className={`${CommonStyle.leftContainer} ${CommonStyle.pb0} ${classes.leftBox}`}>
                        {
                            isappwebviewcheck != "acecard" && (
                                <div className={CommonStyle.mb20}> <BackArrow clickHandler={() => navigate(-1)} /></div>
                            )
                        }
                      
                        <h2 className={CommonStyle.mb10}>{t("Choose Mode")}</h2>
                    </div>
                    <div className={`${CommonStyle.rightContainer} ${classes.chooseMode} ${CommonStyle.scrollBox} ${CommonStyle.pl20}`}>
                        {/* <h2 className={CommonStyle.mb10}>{StrConstent.chooseMode.heading}</h2> */}
                        {
                            urlnewmatch && choosemode?.value?.data?.filter((data) => data?.title == "Schedule Delivery")?.map((data) => (
                                <div key={data?.id} className={`${classes.chooseModeBox} ${CommonStyle.flexSpaceBetween}`} onClick={() => handlemodechange(data)}>
                                    <div className={classes.textContent}>
                                        <h3>{data.title}</h3>
                                        <p>{data.description}</p>
                                    </div>
                                    <div className={classes.arrowContent}>
                                        <NavLink to=""><img src={longArrowRight} alt="Not Found" /></NavLink>
                                    </div>
                                </div>
                            ))
                        }
                        {!urlnewmatch && choosemode?.value?.data?.map((data) => (
                            // <div className={classes.chooseModeInner}>
                            <div key={data?.id} className={`${classes.chooseModeBox} ${CommonStyle.flexSpaceBetween}`} onClick={() => handlemodechange(data)}>
                                <div className={classes.textContent}>
                                    <h3 className={CommonStyle.mb10}>{data.title}</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: data.description
                                        }}
                                    >

                                    </p>
                                </div>
                                <div className={classes.arrowContent}>
                                    <NavLink to=""><img src={longArrowRight} alt="Not Found" /></NavLink>
                                </div>
                            </div>
                            // </div>
                        ))}
                        {/* <NavLink to='' className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.links}`}>I’m just browsing</NavLink> */}
                    </div>
                </div>
                {/* <CustomButton label="click" clickHandler={() => setShowModel(true)} /> */}
            </ContainerBox>

            {
                device == "sm" ? <>
                    {locationallow == true && (

                        <RoundedDrawer>
                            <h3 className={CommonStyle.textCenter}>Please allow location first then proceed.</h3>
                        </RoundedDrawer>
                    )}
                </> : <>
                    {locationallow == true && <CustomModal
                        open={true}
                        handleClose={() => false}
                        description={""}>
                        <h3 className={CommonStyle.textCenter}>Please allow location first then proceed.</h3>
                    </CustomModal>}
                </>
            }


            {device == "sm" ? <>
                {
                    cartclearpopup == true && (
                        <RoundedDrawer>
                            <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                            <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                                <BorderButton label={"Cancel"} clickHandler={handleCancle} />
                                <CustomButton label={"Confirm"} clickHandler={handleCartClear} />
                            </div>
                        </RoundedDrawer>
                    )
                }
            </> : <>
                <CustomModal
                    open={showModel}
                    title={""}
                    handleClose={() => handleClose()}
                    description={""}>
                    <h3 className={CommonStyle.textCenter}>{StrConstent.cart.cardClear.heading}</h3>
                    <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                        <BorderButton label={"Cancel"} clickHandler={() => setShowModel(false)} />
                        <CustomButton label={"Confirm"} clickHandler={() => handleCartClear()} />
                    </div>
                </CustomModal>
            </>}
         
        </>
    )
}

export default withStyles(Styles)(ChooseMode);