import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { DialogActions } from '@mui/material';
import { Button } from 'react-scroll';
import { useState } from 'react';
import { common } from '@mui/material/colors';
import dayjs from 'dayjs';
import { useEffect } from 'react';
const CommonTimePicker = (props) => {
  const { classes,
    dateValue = "",
    maxFutureDate = "",
    allowedDays = null,
    onBinding,

    name = "",
    isPastDisable = false,
    isWeekDayDisable = false,
    allowAfter = 0,
    isFutureDisable = false,
    isBlank = false,
    editdata = null,
    datepickerValue = "",
    selectmode = "" } = props;
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState("");
  const [mintime, setmintime] = useState(false)
  useEffect(() => {
    if (value != "") {

      onBinding({ [name]: value?.$d });
    }
  }, [value]);
  const theme = createTheme({
    palette: {
      primary: {
        main: ColorTheme && ColorTheme.yellow ? ColorTheme.yellow :  common.black
      },
    },
  });


  const currentTime = dayjs();
  const currentTimePlus30Minutes = currentTime.add(60, 'minute');
  const dateString = datepickerValue?.dob;
  const dateToMatch = dayjs(dateString);

  useEffect(() => {
    if (currentTime.isSame(dateToMatch, 'day')) {
      setmintime(true)
    } else {
      setmintime(false)
    }
  }, [dateString])


  return (
    <div className={classes.timePicker}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            {mintime ? <TimePicker
              label=""
              defaultValue=""
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              // disablePast={true}
              minTime={currentTimePlus30Minutes}
              value={(dateValue)}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              disabled={datepickerValue?.dob?false:true} 
              // ampm={false}
            /> :
              <TimePicker
                label=""
                defaultValue=""
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                // disablePast={true}
                // minTime={currentTimePlus30Minutes}
                value={(dateValue)}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                disabled={datepickerValue?.dob?false:true} 
                // ampm={false}
              />
            }
            <span className={classes.timePickerIcon}><QueryBuilderIcon /></span>
          </DemoContainer>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default withStyles(Styles)(CommonTimePicker);