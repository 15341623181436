import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import StrConstent from '../../Common/StrConstent';
import NoData from '../../images/404.svg';
import { useTranslation } from 'react-i18next';
const NotFound = (props) => {
    const { classes } = props
    const { t } = useTranslation();
    const url=window.location.href;
    const urlourstory=url?.includes("our-story")?true:false
    const urltermsofuse=url?.includes("terms-of-use")?true:false
    const urlprivacypolicy=url?.includes("privacy-policy")?true:false
    const urlhowitworks=url?.includes("how-it-works")?true:false
    return (
        <>
            <div className={classes.notFountOuter}>
                <div className={classes.notFountInner}>
                    <div className={classes.imgContent}>
                        <img src={NoData} alt="Not Found" />
                    </div>
                    {(urlhowitworks || urlprivacypolicy || urltermsofuse ||urlourstory) ? <>
                    <h3>{t("Coming Soon...")}</h3>

                    </>: <>

                    <h3>{t("Opps")} 404</h3>
                    <p>{t("This page doesn’t exist")}</p>
                    </>}
                </div>
            </div>
        </>
    )
}
export default withStyles(Styles)(NotFound);