import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    customBtn: {
        "& button": {
            padding: '8px 15px !important',
            background: `${ColorTheme.yellow} !important`,
            letterSpacing: "0",
            width: "auto",
            minWidth: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            position: 'relative',
            fontSize: '14px !important',
            lineHeight: 'normal !important',
            fontWeight: '400 !important',
            borderRadius: '32px !important',
            whiteSpace: 'nowrap !important',
            color: `${ColorTheme.secondaryColor} !important`,
            fontFamily: 'var(--main-heading-family) !important',
            "& span": {
                marginRight: '16px',
                marginLeft: '0',
            },
            "& svg": {
                maxWidth: '20px',
                position: 'absolute',
                top: '50%',
                left: '8px',
                transform: 'translateY(-55%)',
                color: `${ColorTheme.secondaryColor} !important`,
            },
            "&:hover": {
                background: ColorTheme.yellow,
            },
        },
    }
})