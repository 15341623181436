import * as React from 'react';
import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
const CustomModal = (props) => {
    const { classes, open, children, title, description, modalClose, handleClose ,tabcheck} = props
    const url=window.location.href;
    const routeurl=url?.includes("/kio-vouchers")
    // console.log("tabcheck",tabcheck)
    return (
        <>
            <Modal
                style={{ opacity:routeurl?"1":tabcheck==""? "0.5" :"1"}}
                open={open}
                onClose={() => handleClose()}
                onClick={modalClose}>
                <Fade in={open} onClick={modalClose}>
                    <div className={classes.modalContent}>
                        <div className={classes.modalInner}>
                            {/* <Button onClick={modalClose} className={classes.closeIcon}><img src={times} alt="Not Found" /></Button> */}
                            <div className={classes.title}><h3>{title}</h3> <p>{description}</p></div>
                            {children}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}
export default withStyles(Styles)(CustomModal);