import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CustomCheckbox from "../../Components/CustomCheckbox/CustomCheckbox";
import CustomButton from '../../Components/CustomButton/CustomButton';
import { FormLabel } from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useDispatch, useSelector } from "react-redux";
import { ContinuewithphoneAPI } from "../../redux/Action/Conntinuewithphone/ContinuewithphoneSlice";
import { useNavigate, useParams } from "react-router-dom";
import { validatePhone } from "../../Common/Validations";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import Loader from "../../Components/Loader/Loader";
import { useTranslation } from 'react-i18next';
import { ChangePasswordAPI } from "../../redux/Action/ChangePassword/ChangePassword";
import { Clear_ChangePassword_Cart } from "../../redux/reducers/ChangePassword/ChangePasswordSlice";
import { ForgotPasswordAPI } from "../../redux/Action/ForgotPassword/ForgotPassword";
import { Clear_ForgotPassword_Cart } from "../../redux/reducers/ForgotPassword/ForgotPasswordSlice";
const ForgotPassword = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [password, setpassword] = useState("")
    const [emailerror, setemailerror] = useState(false)
    const [confirmpassword, setconfirmpassword] = useState("")
    const [confirmpassworderror, setconfirmpassworderror] = useState(false)
    const ChangePasswordSlice = useSelector(state => state?.ChangePasswordSlice)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const { id } = useParams();
    const ForgotPasswordSlice=useSelector(state=>state?.ForgotPasswordSlice)

    const handlesubmitlogin = () => {
        if (email == "") {
           
                setemailerror(true)
      
         
        }
        else if (email != "" ) {


            const payload = {

                email: email,
                type: "",
            }
            dispatch(ForgotPasswordAPI(payload))

        }

    }
    // console.log("Continuewithphone", Continuewithphoneslice)

    useEffect(() => {
        if (ForgotPasswordSlice?.error?.message) {
            setIsOpen(true)
            setTimeout(() => {
                dispatch(Clear_ForgotPassword_Cart())
            }, 3000)
        } else if (ForgotPasswordSlice?.value?.message) {
            setIsOpen(true)

            setTimeout(() => {

                dispatch(Clear_ForgotPassword_Cart())
                navigate("/")
            }, 1000)
        }
    }, [ForgotPasswordSlice])
    const snackHandler = () => {
        setIsOpen(false)
    }
    const handleLogin=()=>{
        navigate("/login")
    }
    // console.log("ForgotPasswordSlice", ForgotPasswordSlice)
    return (
        <>
            {
                ForgotPasswordSlice?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={ForgotPasswordSlice?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                ForgotPasswordSlice?.value?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={ForgotPasswordSlice?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={isOpen}
                    />
                )
            }
            {
                ForgotPasswordSlice?.loading && (
                    <Loader />
                )
            }
            <ContainerBox>
                <div className={classes.loginWrapper}>
                    <div className={classes.accOuter}>
                        <div className={classes.accInner}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        

                            <h2 className={CommonStyle.mb20}>{t("Forget Password")}</h2>
                            <h5 className={CommonStyle.mb20} style={{ cursor: "pointer" }} onClick={() => handleLogin()}>{t("Back to Login")}</h5>
                        </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem} ${classes.inputContainer}`}>
                                <FormLabel>{t("Email*")}</FormLabel>
                                <input type="text" name="email" placeholder={t("Email")} value={email} onChange={(e) => {
                                    setemailerror(false)
                                    setEmail(e.target.value)
                                }}
                                    className={`${classes.Input} form-input`}
                                />
                               

                            </div>
                            {emailerror && (
                                <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                    This field is required.
                                </span>
                            )}
                          
                           
                        </div>
                        <div className={`${CommonStyle.mt1rem} ${CommonStyle.fullBtn}`}>
                            <CustomButton label={t("Change Password")} clickHandler={() => handlesubmitlogin()} />
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(ForgotPassword);