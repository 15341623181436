import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const CartClearSlice = createSlice({
  name: 'CartClear',
  initialState,
  reducers: {
    Cart_Clear_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Cart_Clear_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    },
    Cart_Clear_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const {Cart_Clear_request, Cart_Clear_success,Cart_Clear_failure } = CartClearSlice.actions

export default CartClearSlice.reducer