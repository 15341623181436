import { Styles } from "./Style.js";
import React from "react";
import { withStyles } from "@mui/styles";
import searchIcon from '../../../images/searchIcon.svg';
import { useTranslation } from 'react-i18next';

const SearchBox = (props) => {
    const { classes, setpickupsearchcheck, setpickupsearch } = props;
    const { t } = useTranslation();
    return (
        <>
            <div className={classes.searchBox} >
                <img src={searchIcon} alt="Not Found" />
                <input type="text" placeholder={t("Search")} onChange={(e) => {
                    setpickupsearchcheck(true)
                    setpickupsearch(e.target.value)}} />
                {/* <span to="" className={classes.close}><img src={locationIcon} alt="Not Found" /></span> */}
            </div>
        </>
    );
};
export default withStyles(Styles)(SearchBox);
