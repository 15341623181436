import React from 'react'
import CommonStyle from "../../../Common/CommonCss/Common.module.css";
import ProdSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Product from '../../../images/prodLarge.png'
import { Styles } from './Style'
import { withStyles } from "@mui/styles";
import location from '../../../images/location.png'
import locationFill from '../../../images/locationFill.png'
import Arrow from './Arrow';

const ProSlider = (props) => {
    const { classes, data } = props;
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        centerPadding: 20,
        arrows: true,
        prevArrow: <Arrow direction="left" />,
        nextArrow: <Arrow direction="right" />,
        responsive: [
            {
                breakpoint: 920, // breakpoint at which these settings will apply
                settings: {
                    slidesToShow: 1,
                    centerMode: 'true'
                },
            },
            {
                breakpoint: 767, // breakpoint at which these settings will apply
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575, // breakpoint at which these settings will apply
                settings: {
                    slidesToShow: 1,
                    centerMode: 'false'
                },
            }
        ],
    };

    return (
        <div>
            <ProdSlider {...settings}>
                {
                    data?.map((outeletdata) => {
                        return <div className={`${classes.itemContainer} ${classes.rounded20} ${CommonStyle.cursorPointer}`}>
                            <div className={classes.itemSlider}>
                                <div className={classes.sliderItemTop}>
                                    <div className={`${CommonStyle.relative} ${classes.sliderImg}`}>
                                        <img src={outeletdata?.outlets_banner} alt='Product Icon' className={`${classes.allProdImg} ${classes.rounded20}`} />
                                        {/* <div className={`${classes.distance} ${classes.flexDimension} ${CommonStyle.absolute}`}>
                                            <img src={location} alt="Location" /><p className={classes.flexDimension}>500 m</p>
                                        </div> */}
                                    </div>
                                    <div className={`${CommonStyle.ml10} ${classes.bottomContent}`}>
                                        <h3 className={classes.sliderHeading}>{outeletdata?.name}</h3>
                                        <p className={`${classes.sliderContent} ${CommonStyle.mt10}`}

                                            dangerouslySetInnerHTML={{
                                                __html: outeletdata?.description
                                            }}
                                        ></p>
                                        <span className={`${classes.flexDimension} ${CommonStyle.JustifyStart} ${CommonStyle.mt40}`}><img src={locationFill} alt="Location" /> <span>{outeletdata?.address}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }


            </ProdSlider>
        </div>
    )
}

export default withStyles(Styles)(ProSlider)
