import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    customTooltip: {
        position: "relative",
        display: "flex",
        fontWeight: "700 !important",
        "& img": {
            marginLeft: "5px",
            width: "15px",
        },
    },
    tooltiptext: {
        background: ColorTheme.white,
        position: "relative",
        maxWidth: "250px",
        padding:'12px',
        "&:after": {
            content: "''",
            position: "absolute",
            top: "100%",
            left: "10%",
            marginLeft: "-5px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "#fff transparent transparent transparent",
        },
    },

})