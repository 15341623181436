import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Slider from "react-slick";
import TabPanel from '@mui/lab/TabPanel';
import { withStyles } from "@mui/styles";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Styles } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import ProductItem from '../../Components/ProductItem/ProductItem';
import RewardPoint from '../../Components/RewardPoint/RewardPoint';
import OrderBox from '../../Components/OrderBox/OrderBox';
import Loader from '../../Components/Loader/Loader';
import { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { useNavigate } from "react-router";
import { LatLongLocationAPI } from '../../redux/Action/LatLongLocation';
import { ChooseModeApi } from '../../redux/Action/choosemode/ChoosemodeAction';
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import AlertMessage from '../../Components/Alertmsg/AlertMessage';
import { Clear_Login_Cart } from '../../redux/reducers/LoginCart/GetLoginCartSlice';
import { GetLoginCartAPI } from '../../redux/Action/LoginCart/GetLoginCartAction';
import { Clear_Modifier } from '../../redux/reducers/Modifier/ModifierSlice';
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer';
import Counter from '../../Components/Counter/Counter';
import { OrderAgainAPI } from '../../redux/Action/OrderAgain/OrderAgain';
import { OutletScheduleAPI } from '../../redux/Action/OutletSchedule/OutletSchedule';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import CustomModal from '../../Components/CustomModal/CustomModal';
import { ProfilegetAPI } from '../../redux/Action/ProfileUpdate/Profileget';
import { Profile_Update_Cart } from '../../redux/reducers/ProfileUpdate/ProfileUpdateSlice';
import { RewardsPonitsAPI } from '../../redux/Action/RewardPoints/RewardPonits';
import { Container } from "@mui/material";
import { OrderHistoryAPI } from '../../redux/Action/Orderhistory/OrderHistory';
import { OrderDetailAPI } from '../../redux/Action/OrderDetails/OrderDetails';
import { NotificationAPI } from '../../redux/Action/Notification/Notification';
import { Clear_Login_Cart_failure } from '../../redux/reducers/LoginCart/LoginCartSlice';
import CustomButton from '../../Components/CustomButton/CustomButton';
import { OutletfetchAPI } from '../../redux/Action/Outlet/OutletAction';
import ProductOutlet from '../../Components/ProductOutlet/ProductOutlet';
import { useTranslation } from 'react-i18next';
import { Footer_Hide_success } from '../../redux/reducers/Footerhideshow';
import FilterSearch from '../../Components/FilterSearch/FilterSearch';
import { Clear_Reservation_Cart } from '../../redux/reducers/Reservation/ReservationSlice';
import { Sub_categories_success } from '../../redux/reducers/SubcategoriesSlice';
const Home = (props) => {
    const { classes } = props;
    const [desktop, setDesktop] = useState(window.innerWidth > 991);
    let navigate = useNavigate();
    const device = useBreakpoint();
    const dispatch = useDispatch()
    const [value, setValue] = useState('');
    const Logindetails = localStorage.getItem("Logindetails");
    const Filteridcartmatch = useSelector(state => state?.FilteridcartmatchSlice?.value)
    const disptach = useDispatch()
    const Locationlatlong = useSelector(state => state?.Locationlatlong);
    const [subcategories, setsubcategories] = useState("");
    const Categorieswithoutlogin = useSelector(state => state?.Categorieswithoutlogin)
    const [isOpen, setIsOpen] = useState(false);
    const OrderAgain = useSelector(state => state?.OrderAgainSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const [showModel, setShowModel] = useState(false)
    const outletId = localStorage.getItem("outletID")
    const outlet_mode = localStorage.getItem("outlet_mode")
    const outletname = localStorage.getItem("outletname")
    const [page, setpage] = useState(1);
    const [notiData, setNotiData] = useState([])
    const companyoutletcount = localStorage.getItem("companyoutletcount")
    const [loading, setLoading] = useState(false);
    const FooterHeightSlice = useSelector(state => state?.FooterHeightSlice?.value)
    const address_id = localStorage.getItem("address_id")
    const ReservationSlice = useSelector(state => state?.ReservationSlice)
    const [searchBoxtext, setsearchboxtext] = useState(false)
  
    //loadmore
    const fetchMoreData = () => {
        setLoading(true);
        if (+Locationlatlong?.value?.data?.pagination?.lastPage > page) {
            dispatch(Footer_Hide_success(false))
            setpage(page + 1)
        } else {
            dispatch(Footer_Hide_success(true))
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };
    const [scrollHeights, setscrollHeight] = useState()
    const handleScroll = () => {
        const container = document.getElementById('viewmores');
        const scrollTopheight = container?.scrollTop;
        const { clientHeight, scrollHeight } = document.documentElement;
        const scrollTop = window.scrollY;
        setscrollHeight(scrollHeight)
        if (scrollTop + clientHeight >= scrollHeight - 380 - FooterHeightSlice && !loading) {
            fetchMoreData();
        }
    };
    const handleScrollheight = () => {
        const container = document.getElementById('viewmores');
        const scrollTopheight = container?.scrollTop;
        console.log(scrollTopheight, 'scrollTopheight kavya');
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });
    useEffect(() => {
        const container = document.getElementById('viewmores');
        container.addEventListener('scroll', handleScrollheight);
        return () => {
            container.removeEventListener('scroll', handleScrollheight);
        };
    });

    useEffect(() => {
        dispatch(OutletfetchAPI())
    }, [])
    var settings = {
        dots: false,
        arrows: false,
        speed: 500,
        infinite: OrderAgain?.value?.data?.orders?.length > 3 ? true : false,
        slidesToShow: OrderAgain?.value?.data?.orders?.length > 3 ? 4 : 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '40px 0 0 0',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: OrderAgain?.value?.data?.orders?.length >= 2 ? 2 : 1,
                    infinite: true,
                    centerPadding: '20px 0 0 0',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '15px 0 0 0',
                    slidesToShow: 1,
                    infinite: true,
                }
            }
        ]
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
        disptach(LatLongLocationAPI(newValue, page))
        setNotiData([])
        setpage(1)
        setsubcategories("")
    };


   
    useEffect(() => {
        dispatch(Clear_Login_Cart_failure())
        if (Logindetail) {

            dispatch(NotificationAPI())
            dispatch(OrderDetailAPI())
            dispatch(OrderHistoryAPI())
            dispatch(ProfilegetAPI())
            dispatch(OrderAgainAPI())
        }
        dispatch(Drawer_success(false))
        dispatch(Profile_Update_Cart())
        dispatch(Clear_Modifier())
        dispatch(Clear_Login_Cart())
        localStorage.removeItem("modifierItem")
        localStorage.removeItem("addressmodenavigate")
        localStorage.removeItem("couponname")
        disptach(ChooseModeApi())
        if (Logindetail) {

            dispatch(GetLoginCartAPI())
            dispatch(RewardsPonitsAPI())
        }
    }, [])
    const handlecustomizenavigate = () => {
        if (outletname && outlet_mode) {

            navigate("/select-outlet")
        } else if (outlet_mode && outlet_mode != "Schedule Delivery") {
            if (companyoutletcount == "1" || companyoutletcount == 1) {
                navigate("/select-outlet")
            } else {

                navigate("/pickup-outlet")
            }
        }
        else if (outlet_mode && outlet_mode == "Schedule Delivery") {

            navigate("/delivery-address")
        }
        else {

            navigate("/choose-mode")
        }

    }
    useEffect(() => {
        if (outletId) {
            dispatch(OutletScheduleAPI())
        }
        disptach(LatLongLocationAPI(value, page))
    }, [value])

    const handleChangesubcategories = (data) => {
        setsubcategories(data)

    }
    const handleClose = () => {
        setShowModel(false)
    }
    useEffect(() => {
        if (subcategories != "") {

            disptach(LatLongLocationAPI(subcategories, page))
        }
    }, [subcategories])

    const snackHandler = (event, reason) => {
        setIsOpen(false);
    };

    const handleNavigate = () => {
        navigate("/cart")
        dispatch(Drawer_success(true))
        setShowModel(true)
    }
    useEffect(() => {
        if (Locationlatlong?.error?.message) {
            setIsOpen(true)
        }
    }, [Locationlatlong])
    const mobileWidth = () => {
        if (window.innerWidth <= 991) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
    useEffect(() => {
        if (outletname && outlet_mode!="Schedule Delivery" && outlet_mode) {
            navigate("/product-items")
        }
        else if (outletname && outlet_mode=="Schedule Delivery" && address_id && outlet_mode) {
            navigate("/product-items")
        }
    })
    useEffect(() => {
        window.addEventListener("scroll", mobileWidth);
        return () => window.removeEventListener("scroll", mobileWidth);
    })
    const { t } = useTranslation();
    const lastDivRef = useRef(null);
    const [totalHeight, setTotalHeight] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [lastDivBottom, setLastDivBottom] = useState(0);
    const [filterproductsearch, setfilterproductsearch] = useState("")
    const boxRef = useRef(null);
    const [scrolltopdata, setscrolltopdata] = useState('');
    useLayoutEffect(() => {
        const divAnimate = boxRef.current.getBoundingClientRect().top;
        console.log(divAnimate, 'divAnimate');
        const onScroll = () => {
            if (divAnimate < window.scrollY) {
                setscrolltopdata(`${CommonStyle.fixedTab}`);
            } else {
                setscrolltopdata('');
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const samedatapopup = () => {
        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.costomizeContent}>
                    {Filteridcartmatch?.map((data) => {
                        return <div className={classes.costomizeItem} key={data?.cart_item_id}>
                            <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                                <div className={CommonStyle.flexCenterStart}>
                                    <div>
                                        <h4>{data?.name}</h4>
                                        <p dangerouslySetInnerHTML={{
                                            __html: (data?.description),
                                        }}></p>
                                        <p>
                                            {data?.item_size} / {data?.modifier?.map((data) => {
                                                return " " + data?.modifier_name + " " + data?.modifier_quantity + " / "
                                            })} {data?.sugarlevel ? "sugarlevel " + data?.sugarlevel : ""}
                                        </p>
                                    </div>
                                </div>
                                <div className={CommonStyle.ml10}>
                                    <Counter itemQuantity={data?.itemQuantity} item_price={data?.item_price} cart_item_id={Logindetail ? data?.cart_item_id : data?.unique_id} />
                                    {/* <h5 className={`${CommonStyle.mt10} ${CommonStyle.textCenter}`}>$ {data?.totalamount}</h5> */}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </React.Fragment>
    }

    useEffect(() => {
        if (LoginCartSlice?.value?.message) {
            setIsOpen(true)
        }
    }, [LoginCartSlice])

    useEffect(() => {
        disptach(LatLongLocationAPI(value, page))
    }, [page])

    //top bar sticky
    const componentRef = useRef(null);
    const [topbarsticky, settopbarsticky] = useState("");
    useEffect(() => {
        if (componentRef.current) {
            const height = componentRef.current.clientHeight;
            settopbarsticky(componentRef.current.clientHeight)
        }
    })

    useEffect(() => {
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined) {
            if (notiData?.length > 0) {
                if (page > 1) {
                    setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                } else {
                    setNotiData(Locationlatlong?.value?.data?.items);
                }
            } else {
                setNotiData(Locationlatlong?.value?.data?.items);
            }
        }
    }, [Locationlatlong]);
    useEffect(() => {
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined) {
            if (notiData?.length > 0) {
                if (page > 1) {
                    setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                } else {
                    setNotiData(Locationlatlong?.value?.data?.items);
                }
            } else {
                setNotiData(Locationlatlong?.value?.data?.items);
            }
        }
    }, []);

    useEffect(() => {
        if (ReservationSlice?.value?.message) {
            setIsOpen(true)
            setTimeout(() => {
                dispatch(Clear_Reservation_Cart())
            }, 3000)
        }
    }, [ReservationSlice])




    const searchtextbox = () => {
        dispatch(Sub_categories_success(value))
        disptach(LatLongLocationAPI(value, page, filterproductsearch))
    }
    useEffect(() => {
        const delay = 500; 
        const timerId = setTimeout(() => {
            searchtextbox();
        }, delay);

        return () => clearTimeout(timerId);
    }, [filterproductsearch]);


    return (
        <>
            {
                Locationlatlong?.loading == true && (
                    <Loader />
                )
            }
            {
                Categorieswithoutlogin?.loading == true && (
                    <Loader />
                )
            }
            {
                ReservationSlice?.value?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={ReservationSlice?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={isOpen}
                    />
                )
            }
            {
                Locationlatlong?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={Locationlatlong?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                (LoginCartSlice?.value?.message || LoginCartSlice?.error?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={(LoginCartSlice?.value?.message || LoginCartSlice?.error?.message)}
                        title={LoginCartSlice?.value?.message ? "Success" : "Error"}
                        type={LoginCartSlice?.value?.message ? "Success" : "Error"}
                        open={isOpen}
                    />
                )
            }
            <HeroSlider />
         
            <Container maxWidth="xl" className={`${classes.sectionSpacing} ${classes.bottomSpacing} ${CommonStyle.relative}`} id='viewmores'>
                <TabContext value={value}>
                    <div className={`${classes.fixedHeader} ${scrolltopdata}`} ref={boxRef}>
                        <div>
                            <div className={classes.flex}>
                                <h2 className={CommonStyle.mb20}>{t("menu")}</h2>
                                <div className={classes.filterBox}><FilterSearch filterproductsearch={filterproductsearch} setfilterproductsearch={setfilterproductsearch} setsearchboxtext={setsearchboxtext} /></div>
                            </div>
                            <TabList onChange={handleChange} variant="scrollable" scrollButtons={false} className={`${CommonStyle.tabsContent} ${classes.tabContainer}`}>
                                <Tab label={t("ALL")} value={""} />
                                {
                                    Categorieswithoutlogin?.value?.data?.map((data) => {
                                        return <Tab label={data?.category_name} value={data?.category_id} key={data?.category_id} />
                                    })
                                }
                            </TabList>
                        </div>
                    </div>
                    {
                        Categorieswithoutlogin?.value?.data?.map((data) => {
                            return <>
                                <div className={classes.chipsContent} key={data?.category_id}>
                                    {
                                        data?.category_id == value && (data?.child_categories?.map((childdata) => {
                                            return <Chip label={childdata?.category_name} className={
                                                subcategories == childdata?.category_id
                                                    ? classes.active
                                                    : null
                                            } onClick={() => { handleChangesubcategories(childdata?.category_id) }} key={childdata?.category_id} />
                                        }))
                                    }
                                </div>
                            </>
                        })
                    }
                    <TabPanel value={value} className={CommonStyle.p0}>
                        <div className={classes.productContainer}>
                            {notiData?.map((data) => {
                                return <ProductItem key={data?.item_id} url={data?.image_url} title={data?.item_name} offer={data?.item_price} price={data?.item_price} discount={data?.discount_off} item_id={data?.item_id} show_detail_page={data?.show_detail_page} itemdetails={data} />
                            })
                            }
                        </div>
                    </TabPanel>
                  
                </TabContext>
               
                {
                    device == "sm" ? <>
                        {DrawerSlice == true && <RoundedDrawer>
                            <h3 className={CommonStyle.mb20}>{StrConstent.menu.costomizations.heading}</h3>
                            {samedatapopup()}
                            <span onClick={() => handlecustomizenavigate()} className={`${CommonStyle.links} ${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20} ${CommonStyle.cursorPointer}`}>Add New costomizations</span>
                        </RoundedDrawer>}
                    </> : <>
                        {DrawerSlice == true && <CustomModal
                            open={true}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => dispatch(Drawer_success(false))}
                            description={""}>
                            <h3 className={CommonStyle.mb20}>{StrConstent.menu.costomizations.heading}</h3>
                            {samedatapopup()}
                            <span onClick={() => handlecustomizenavigate()} className={`${CommonStyle.links} ${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20} ${CommonStyle.cursorPointer}`}>Add New costomizations</span>
                        </CustomModal>}
                    </>
                }
            </Container>
        </>
    )
}
export default withStyles(Styles)(Home);
