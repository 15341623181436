import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  loading: false,

}

export const ModifierSlice = createSlice({
  name: 'ModifierSlice',
  initialState,
  reducers: {
    Modifier_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Modifier_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    },
    Clear_Modifier: (state, action) => {
      state.value = [];
      state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const { Modifier_request, Modifier_success ,Clear_Modifier} = ModifierSlice.actions

export default ModifierSlice.reducer