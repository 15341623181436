import React, { useEffect, useState } from 'react';
import { Styles } from './Style';
import Slider from "react-slick";
import { withStyles } from '@mui/styles';
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import StrConstent from '../../Common/StrConstent';
import KioCartItem from '../../KioComponents/KioCartItem/KioCartItem';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BorderButton from '../../Components/BorderButton/BorderButton';
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import { useNavigate } from 'react-router-dom';
import KioRecommendedCart from '../../KioComponents/KioRecommendedCart/KioRecommendedCart';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import { OutletScheduleAPI } from '../../redux/Action/OutletSchedule/OutletSchedule';
import { PhoneverifymethodAPI } from '../../redux/Action/Phoneverifymethod/Phoneverifymethod';
import { Clear_Login_Cart_failure } from '../../redux/reducers/LoginCart/LoginCartSlice';
import { Clear_Modifier } from '../../redux/reducers/Modifier/ModifierSlice';
import { GetLoginCartAPI } from '../../redux/Action/LoginCart/GetLoginCartAction';
import { FormLabel } from '@mui/material';
import Loader from '../../KioskComponents/Loader/Loader';
import { Clear_Otp_verified } from '../../redux/reducers/Continuewithphone/Otpverified';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import { Login_Drawer_success } from '../../redux/reducers/LoginDrawer';
import { ContinueasguestAPI } from '../../redux/Action/Continueasguest/Continueasguest';
import { Clear_Apply_Coupon } from '../../redux/reducers/Coupon/ApplyCouponSlice';
import { Clear_Continue_with_phone } from '../../redux/reducers/Continuewithphone/ContinuewithphoneSlice';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import CustomButton from '../../KioskComponents/CustomButton/CustomButton';
const KioCart = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const Logindrawer = useSelector(state => state?.LoginDrawerSlice?.value)
    const [signdrawer, setsigndrawer] = useState(false)
    // const [guestModal, setGuestModal] = useState(false);
    const [paymaentModal, setPaymaentModal] = useState(false);
    const addtocart = localStorage.getItem("addtocart")
    const [cartitem, setcartitem] = useState([]);
    const [guestnumber, setguestnumber] = useState("")
    const Continueasguest = useSelector(state => state?.ContinueasguestSlice?.value)
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const Logindetails = localStorage.getItem("Logindetails");
    const UpdateLoginCart = useSelector(state => state?.UpdateLoginCartSlice?.value)
    const [nodata, setnodata] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [withoutlogintotalamount, setwithoutlogintotalamount] = useState(0)
    const StateChangeSlice = useSelector(state => state?.StateChangeSlice?.value)
    const Totalamountwithoutlogin = useSelector(state => state?.TotalamountwithoutloginSlice?.value)
    const OutletSchdeule = useSelector(state => state?.OutletSchdeuleSlice)
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    const outletID = localStorage.getItem("outletID")
    const RecomendedOrder = useSelector(state => state?.RecomendedOrderSlice)
    const TimeSlotCheck = useSelector(state => state?.TimeSlotCheckSlice)
    const outlet_mode = localStorage.getItem("outlet_mode")
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const [error, sererror] = useState("")
    const device = useBreakpoint();
    const Phoneverifymethod = useSelector(state => state?.PhoneverifymethodSlice)
    const kioskmode = localStorage.getItem("kioskmode")
    const grandTotal = localStorage.getItem("grandTotal")
    const [initalloder, setinitialloader] = useState(false)
    useEffect(() => {

        setinitialloader(true)
        setTimeout(() => {
            setinitialloader(false)
        }, 1000)

        if (outletID) {
            dispatch(OutletScheduleAPI())
        }
        dispatch(PhoneverifymethodAPI())
        dispatch(Clear_Login_Cart_failure())
        dispatch(Clear_Modifier())
        if (Logindetail) {

            dispatch(GetLoginCartAPI())
        }
        setcartitem((addtocart ? JSON.parse(addtocart) : []))
        setnodata((addtocart ? JSON.parse(addtocart) : []))
    }, [])
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '40px 0 0 0',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '20px 0 0 0',
                }
            },
        ]
    };
    useEffect(() => {
        // dispatch(ContinueasguestAPI())
        setcartitem((addtocart ? JSON.parse(addtocart) : []))
        setnodata((addtocart ? JSON.parse(addtocart) : []))
    }, [addtocartlogin, UpdateLoginCart])
    const handleNavigate = () => {
        const logincheck = (Logindetails && Logindetails != undefined) ? true : false
        // setGuestModal(true)
        if (nodata?.data?.order_mode == "Schedule Pick-Up") {
            // if (logincheck) {
            //     if (TimeSlotCheck?.value?.success == true) {
            //         sererror(false)
            //         navigate("/proceed-to-pay")
            //     } else {
            //         console.log("vikalp")
            //         sererror(true)
            //     }
            // } else {
            setPaymaentModal(false);
            dispatch(Drawer_success(true))
            dispatch(Login_Drawer_success(true))
            setsigndrawer(true)

            // }
        }
        else {


            // if (logincheck) {
            //     sererror(false)
            //     navigate("/proceed-to-pay")
            // } else {

            setPaymaentModal(false);
            dispatch(Drawer_success(true))
            dispatch(Login_Drawer_success(true))
            setsigndrawer(true)
            // }
        }
    }

    useEffect(() => {
        if (Totalamountwithoutlogin?.length > 0) {


            const arr = Totalamountwithoutlogin?.map((item, i) => {
                return (item?.item_price * item?.itemQuantity)
            }).reduce((data, i) => data + i)
            // console.log("arr", arr)
            setwithoutlogintotalamount(arr)
        } else {
            setwithoutlogintotalamount(0)
        }
    }, [Totalamountwithoutlogin])
    const addtocarts = addtocart ? JSON.parse(addtocart) : []
    useEffect(() => {
        dispatch(Clear_Modifier())
        dispatch(Clear_Continue_as_guest())
        dispatch(Clear_Continue_with_phone())
        dispatch(Clear_Apply_Coupon())
        dispatch(Clear_Otp_verified())
        dispatch(Drawer_success(false))
        if (addtocarts?.length > 0) {


            const arr = addtocarts?.map((item, i) => {
                return (item?.item_price * item?.itemQuantity)
            })?.reduce((data, i) => data + i)
            // console.log("arr", arr)
            setwithoutlogintotalamount(arr)
        } else {
            setwithoutlogintotalamount(0)
        }
    }, [])

    useEffect(() => {
        if (addtocartlogin?.error?.message) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [addtocartlogin])

    const handleClickguest = () => {
        // setGuestModal(false);
        setsigndrawer(false);
        setPaymaentModal(true);
    }

    const handlecontinueasguestsubmit = () => {
        dispatch(ContinueasguestAPI(guestnumber))
        // console.log("guestnumber", guestnumber)
        // navigate("/proceed-to-pay")
    }
    // useEffect(() => {
    //     // console.log("Continueasguest", Continueasguest)
    //     if (Continueasguest?.success == true) {
    //         localStorage.setItem("Continueasguest", JSON.stringify(Continueasguest))
    //         navigate("/proceed-to-pay")
    //     }
    // }, [Continueasguest])

    // useEffect(() => {
    //     if(addtocartlogin?.value?.data?.grand_total!=undefined && addtocartlogin?.value?.data?.grand_total!=null){

    //         localStorage.setItem("grandTotal", addtocartlogin?.value?.data?.grand_total)
    //     }
    // }, [addtocartlogin])
    // console.log("UpdateLoginCart", nodata)
    useEffect(() => {
        if (LoginCartSlice?.value?.success) {
            dispatch(Clear_Login_Cart_failure())
        }
    }, [LoginCartSlice])


    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    // console.log("RecomendedOrder", RecomendedOrder)
    // useEffect(() => {
    //     const arr = []
    //     const cartid = cartitem != undefined && cartitem?.map((itemid) => {
    //         arr.push(itemid?.id)
    //     })
    //     const obj = {
    //         itemId: arr,
    //         outletID: addtocartlogin?.value?.data?.outlet?.outlet_id
    //     }
    //     if (cartid?.length > 0 && cartitem != undefined) {

    //         // dispatch(RecomendedOrderAPI(obj))
    //     }
    //     // console.log("arrrr", addtocartlogin?.value?.data)
    // }, [cartitem,addtocartlogin])

    // console.log("Phoneverifymethod", Phoneverifymethod)
    const handleKeyDown = (event) => {
        if (event.keyCode === 69 || event.keyCode == 45 || event.keyCode == 95 || event.key == "-") {
            event.preventDefault();
        }
    };
    const phonemethod = () => {
        return <>
            {
                (Phoneverifymethod?.value?.data?.kiosk_otp_verifications == true && (kioskmode == 1)) && navigate("/login")

                // && <>
                //     <div className={classes.topBox}>
                //         <div className={classes.productImg}>
                //             <img src="./images/product-1.png" alt="Not Found" />
                //         </div>
                //         <div className={classes.spacingTop}>
                //             <CustomButton variant="text" label={"Sign in"} clickHandler={() => navigate("/login")} />
                //         </div>
                //         {/* <p classes={CommonStyle.flexJustifyCenter}>New here? <NavLink to="/login" className={CommonStyle.links}>Register</NavLink> to get $5 off your first order</p> */}
                //     </div>
                // </>
            }
            {
                (Phoneverifymethod?.value?.data?.qr_otp_verifications == true && (kioskmode == 2)) && navigate("/login")
                // <>
                //     <div className={classes.topBox}>
                //         <div className={classes.productImg}>
                //             <img src="./images/product-1.png" alt="Not Found" />
                //         </div>
                //         <div className={classes.spacingTop}>
                //             <CustomButton variant="text" label={"Sign in"} clickHandler={() => navigate("/login")} />
                //         </div>
                //         {/* <p classes={CommonStyle.flexJustifyCenter}>New here? <NavLink to="/login" className={CommonStyle.links}>Register</NavLink> to get $5 off your first order</p> */}
                //     </div>
                // </>
            }

            {
                (Phoneverifymethod?.value?.data?.kiosk_otp_verifications == false && (kioskmode == 1)) && <>
                    <div className={classes.drawerOuter}>
                        <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                            <FormLabel>Phone Number*</FormLabel>
                            <p className={CommonStyle.mb20}>This is for us to notify you when your order is ready for collection </p>
                            <input type="number" name="number" placeholder="Enter your mobile no." maxlength="10" minLength="10" value={guestnumber} onChange={(e) => {

                                setguestnumber(e.target.value)

                            }}
                                onInput={(e) => {
                                    if (e?.target?.value != "") {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                            <LargeBtn number={"2"} label={"Continue to Payment"} amount={`$${withoutlogintotalamount}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                        </div>
                    </div>
                </>
            }
            {
                (Phoneverifymethod?.value?.data?.qr_otp_verifications == false && (kioskmode == 2)) && <>
                    <div className={classes.drawerOuter}>
                        <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                            <FormLabel>Phone Number*</FormLabel>
                            <p className={CommonStyle.mb20}>This is for us to notify you when your order is ready for collection </p>
                            <input type="number" name="number" maxlength="10" minLength="10" placeholder="Enter your mobile no." value={guestnumber} onChange={(e) => {

                                // console.log("vikalp", e.target.value.length, "vv", e.target.value)
                                setguestnumber(e.target.value)

                            }}
                                onInput={(e) => {
                                    if (e?.target?.value != "") {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                            <LargeBtn number={"2"} label={"Continue to Payment"} amount={`$${withoutlogintotalamount}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                        </div>
                    </div>
                </>
            }


        </>
    }
    const recommendedData = [
        {
            id: 1,
            url: './images/product-2.png',
            productName: 'muffin',
            price: 'S$4.50'
        },
        {
            id: 2,
            url: './images/product-4.png',
            productName: 'muffin',
            price: 'S$4.50'
        },
        {
            id: 3,
            url: './images/product-5.png',
            productName: 'Yakult Green Tea',
            price: 'S$4.50'
        },
        {
            id: 4,
            url: './images/product-4.png',
            productName: 'muffin',
            price: 'S$4.50'
        },

    ]
    return (<>
        {
            RecomendedOrder?.loading == true && (
                <Loader />
            )
        }
        {
            initalloder == true && (
                <Loader />
            )
        }
        <ContainerBox>
            <div className={CommonStyle.kioOuter}>
                <h2 className={CommonStyle.mb20}>{StrConstent.kisok.cart.heading}</h2>
                {cartitem?.map((data) => (
                    <KioCartItem key={data?.cart_item_id} url={data?.url} heading={data?.name} description={data} price={data?.item_price} quantity={data?.itemQuantity} />
                ))}
                {addtocarts?.length == 0 &&
                    <div className={CommonStyle.nodata}>
                        <h4>{StrConstent.nocardItme.heading}</h4>
                    </div>}
                {/* <KioCartItem /> */}
                {/* <h2 className={`${CommonStyle.mt6rem} ${CommonStyle.mb20}`}>{StrConstent.kisok.recommended.heading}</h2> */}
                {/* <Slider {...settings}>
                    {
                        recommendedData.map((data) => (
                            <KioRecommendedCart url={data.url} productName={data.productName} price={data.price} />
                        ))
                    }
                </Slider> */}
                <div className={`${CommonStyle.flexCenter} ${CommonStyle.flexSpaceBetween} ${CommonStyle.kioBottomBtnContainer}`}>
                    {/* <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.my2rem} ${CommonStyle.JustifyEnd}`}> */}
                    <CustomButton label={StrConstent.buttonTxt.addMoreItems.heading} clickHandler={() => navigate("/kio-menu")} />
                    {/* </div> */}
                    {
                        addtocartwithoutlogin?.length > 0 && (
                            <LargeBtn number={`${addtocartwithoutlogin?.length}`} label="proceed to pay" amount={`S$${withoutlogintotalamount}`} clickHandler={() => {
                                localStorage.setItem("grandTotal", withoutlogintotalamount)
                                navigate('/kio-phone-number')
                            }} />
                        )
                    }
                </div>
            </div>
        </ContainerBox>
    </>)
}
export default withStyles(Styles)(KioCart);

