import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    grayBtn: {
        "& button": {
            letterSpacing: "0",
            width: "auto",
            transition: '.5s ease',
            minWidth: '200px',
            // fontSize: '20px !important',
            // lineHeight: '22px !important',
            fontWeight: '400 !important',
            borderRadius: '32px !important',
            padding: '12px 24px !important',
            whiteSpace: 'nowrap !important',
            color: 'var(--bs-fontColor) !important',
            fontFamily: 'var(--main-heading-family) !important',
            background: ColorTheme.battleshipGray,
            letterSpacing: "0",
            "@media(max-width:640px)": {
                minWidth: '180px',
                fontSize: '16px !important',
                lineHeight: '18px !important',
            },
            "@media(max-width:475px)": {
                minWidth: '150px',
                fontSize: '14px !important',
                lineHeight: '16px !important',
            },
            "&:hover": {
                background: ColorTheme.battleshipGray,
            },
        },
    }
})

