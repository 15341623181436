import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const StripepaymentSlice = createSlice({
  name: 'Stripepayment',
  initialState,
  reducers: {
    Stripe_payment_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Stripe_payment_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error={}
    },
    Stripe_payment_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.value={};
    }
  }
})

// Action creators are generated for each case reducer function
export const {Stripe_payment_success, Stripe_payment_failure ,Stripe_payment_request} = StripepaymentSlice.actions

export default StripepaymentSlice.reducer