import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const OrderHistorySlice = createSlice({
    name: 'OrderHistory',
    initialState,
    reducers: {

        Order_History_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Order_History_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Order_History_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        CLear_Order_History_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { Order_History_request,Order_History_success,Order_History_failure, CLear_Order_History_Cart } = OrderHistorySlice.actions

export default OrderHistorySlice.reducer