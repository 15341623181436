import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    wrapper: {
        height: 'auto !important',
    },
    cartItem: {
        background: ColorTheme.lightBrown,
        padding: '20px',
        margin: '20px 0',
        borderRadius: '10px',
        // position: 'relative',
        // zIndex: '9',
    },

    cartNodata: {
        "@media(max-width:620px)": {
            background: ColorTheme.white,
            padding: '20px',
            margin: '20px 0',
            borderRadius: '10px',
        }

    },
    addressBox: {
        width: '90%',
        paddingRight: '10px',
        "@media(max-width:620px)": {
            width: '80%',
        },
    },
    icons: {
        width: '10%',
        display: 'flex',
        justifyContent: 'end',
        // position: 'absolute',
        // right:'10px',
        // zIndex: '999',
        "@media(max-width:620px)": {
            width: '20%',
        },
        "& span": {
            height: '25px',
            width: '25px',
            display: 'inline-block',
            marginLeft: '5px',
            cursor: 'pointer',
            "& svg": {
                height: '20px',
                width: '20px',
            }
        }
    },
    addressHeading: {
        "& span": {
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'var(--sub-heading-family)',
        }
    },
    primary: {
        background: ColorTheme.lightYellow,
    }
})