import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const CheckoutStatusSlice = createSlice({
  name: 'CheckoutStatus',
  initialState,
  reducers: {
    Checkout_status_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Checkout_status_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    },
    Checkout_status_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const {Checkout_status_request, Checkout_status_success ,Checkout_status_failure} = CheckoutStatusSlice.actions

export default CheckoutStatusSlice.reducer