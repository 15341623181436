import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Headers } from "../../../APIHandler/HeaderAPI"
import { Add_Address_failure, Add_Address_request, Add_Address_success } from "../../reducers/Addaddress/AddaddressSlice"
import { Get_Address_failure, Get_Address_request, Get_Address_success } from "../../reducers/Addaddress/GetAddressSlice"
import { Get_ID_Address_failure, Get_ID_Address_request, Get_ID_Address_success } from "../../reducers/Addaddress/GetIdaddressSlice"


export const GetIDAddressAPI = (addressid) => async (dispatch, getState) => {
    dispatch(Get_ID_Address_request())

   const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));


    try {
        const addtocart= localStorage.getItem("addtocart")

        const { data } = await axios.get(`${APIURL.getidaddress}/${addressid}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Get_ID_Address_success(data))
     

    } catch (error) {
        dispatch(Get_ID_Address_failure(error?.response?.data))
        // console.log("otpverified",error?.response?.data)
    }
}