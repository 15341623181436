import React, { useEffect, useState } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import moment from 'moment';
const TimeCounter = (props) => {
    const { classes } = props;
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    useEffect(() => {
        setInterval(() => {
            const now = moment();
            const then = moment('2020-10-15 12:12:12', 'YYYY-MM-DD hh:mm:ss');
            const countdown = moment(now);
            setHours(countdown.format('HH'));
            setMinutes(countdown.format('mm'));
        }, 1000);
    }, []);
    return (
        <>
            <h3 className={classes.timerContainer}>
                <div className={classes.timer}>
                    {hours}
                </div>
                <span className={classes.pl3}>:</span>
                <div className={classes.timer}>
                    {minutes}
                </div>
                <div className={classes.pl3}>PM</div>
            </h3>
        </>
    )
}

export default withStyles(Styles)(TimeCounter);