import React, { useEffect, useState } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CustomCheckbox from '../../Components/CustomCheckbox/CustomCheckbox';
import BackArrow from "../../Components/BackArrow/BackArrow";
import GrayButton from "../../Components/GrayButton/GrayButton";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { Clear_Otp_verified } from '../../redux/reducers/Continuewithphone/Otpverified';
import { ContinuewithphoneAPI, ContinuewithphoneOTPverifiedAPI } from '../../redux/Action/Conntinuewithphone/ContinuewithphoneSlice';
import { Clear_Continue_with_phone } from '../../redux/reducers/Continuewithphone/ContinuewithphoneSlice';
import { VerifynumberAPI } from '../../redux/Action/ProfileUpdate/Verifynumber';
import { ProfilenumberchangeAPI } from '../../redux/Action/ProfileUpdate/ProfilenumberChange';
import AlertMessage from '../../Components/Alertmsg/AlertMessage';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import { Clear_Profile_number_change } from '../../redux/reducers/ProfileUpdate/ProfilenumberchangeSlice';
import { Clear_Verify_number_change } from '../../redux/reducers/ProfileUpdate/verifynumberSlice';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../Components/CustomButton/CustomButton';
const VerifyOtp = (props) => {
    const { classes } = props;
    const [otp, setOtp] = useState('');
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const url = window.location.href;
    const params = url.includes("/verify-otp-number")
    const OtpverifiedSlice = useSelector(state => state?.OtpverifiedSlice?.value)
    const Otpverified = useSelector(state => state?.OtpverifiedSlice)
    const loginphonenumber = localStorage.getItem("loginphonenumber")
    const numberchange = localStorage.getItem("numberchange")
    const Verifynumber = useSelector(state => state?.VerifynumberSlice)
    const Continuewithphoneslice = useSelector(state => state?.ContinuewithphoneSlice)
    const [isOpen, setIsOpen] = useState(false);
    const loginredirectstatus = localStorage.getItem("loginredirectstatus")
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const addressmodenavigate = localStorage.getItem("addressmodenavigate")
    const { t } = useTranslation();
    const handleotpsubmit = () => {
        if (params) {
            dispatch(VerifynumberAPI(otp))
        } else {

            dispatch(ContinuewithphoneOTPverifiedAPI(otp))
        }
        // navigate("/proceed-to-pay")
    }

    useEffect(() => {
        dispatch(Clear_Profile_number_change())
        dispatch(Clear_Continue_with_phone())
        dispatch(Drawer_success(false))
    }, [])
    useEffect(() => {
        if (OtpverifiedSlice?.success == true) {
            if (addressmodenavigate == "true" || addressmodenavigate == true) {
                localStorage.setItem("outlet_mode", "Schedule Delivery")
                navigate("/delivery-address")
            } else {
                if (loginredirectstatus == 1) {

                    navigate("/")
                } else if (loginredirectstatus == 3) {
                    navigate("/reservation")
                } else {

                    navigate("/proceed-to-pay")
                }
            }
        } else if (Otpverified?.error?.message) {
            setIsOpen(true)
        }
    }, [OtpverifiedSlice, Otpverified])

    const handleresendcode = () => {
        const payload = {
            phone_number: loginphonenumber,
            country_code: "91"
        }
        if (params) {
            const payloadchange = {
                phone_number: numberchange,
                country_code: "91"
            }
            dispatch(Clear_Otp_verified())
            dispatch(Clear_Verify_number_change())
            dispatch(ProfilenumberchangeAPI(payloadchange))
        } else {
            dispatch(Clear_Otp_verified())
            dispatch(Clear_Verify_number_change())
            dispatch(ContinuewithphoneAPI(payload))
        }
    }
    useEffect(() => {
        if (Verifynumber?.value?.success) {
            navigate("/")
        } else if (Verifynumber?.error?.message) {
            setIsOpen(true)

        }
        // console.log("Verifynumber", Verifynumber)
    }, [Verifynumber])
    useEffect(() => {
        if (Continuewithphoneslice?.value?.message) {
            setIsOpen(true)
        }


    }, [Continuewithphoneslice])
    // console.log("Continuewithphoneslice",Otpverified)

    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);

    };
    useEffect(() => {
        if (Otpverified?.error?.message) {
            setTimeout(() => {
                dispatch(Clear_Verify_number_change())
                dispatch(Clear_Otp_verified())
            }, 1000)
        }
    }, [Otpverified])
    console.log("Verifynumber", Verifynumber, Otpverified)
    return (
        <>
            {
                Continuewithphoneslice?.value?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={Continuewithphoneslice?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={isOpen}
                    />
                )
            }
            {
                Verifynumber?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={Verifynumber?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                Otpverified?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={Otpverified?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            <ContainerBox>
                <div className={`${CommonStyle.outer} ${CommonStyle.scrollBox}`}>
                    <div className={CommonStyle.leftContainer}>
                        {isappwebviewcheck != "acecard" && (

                            <BackArrow clickHandler={() => navigate(-1)} />
                        )
                        }
                        <h2>{StrConstent.verifyOtp.heading}</h2>
                    </div>
                    <div className={`${CommonStyle.rightContainer} ${classes.otpOuter}`}>
                        <p>{t("Sent to")} {params ? numberchange : loginphonenumber}.</p>
                        <div className={classes.textContent}><p>{t("Entered the wrong number?")} </p><NavLink to="/login" className={CommonStyle.links}>{t("Edit your number")}</NavLink></div>
                        <div className={classes.otpContainer}> <OtpInput value={otp} onChange={setOtp} numInputs={4} placeholder={'-'} renderInput={(props, index) => <input {...props} type='number' key={index} />} /></div>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.my1rem}  ${classes.btnContent} `}>
                            <CustomButton label={t("Submit code")} clickHandler={() => { handleotpsubmit() }} />
                        </div>
                        <div className={classes.otpOuter}>
                            <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.flexBaseline}`}>
                                <label className={`${CommonStyle.customCheckContainer} ${classes.checkbox}`}>
                                    {/* <CustomCheckbox /> */}
                                    <span>{t("Didn’t receive code?")}</span> </label>
                                <span className={`${CommonStyle.links} ${CommonStyle.block}}`} onClick={() => handleresendcode()}>{t("Request again")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(VerifyOtp);