import React, { useEffect, useState } from 'react'
import Product from '../../../images/prodLarge.png'
import { Container } from "@mui/material";
import CommonStyle from "../../../Common/CommonCss/Common.module.css";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style.js";
import FdIcon from '../../../images/fdIconWithText.png'
import { useTranslation } from 'react-i18next';
import CountdownTimer from '../../../Components/FlashDeal/Counter/CountdownTimer.js';
import BlackButton from '../../../Components/FlashDeal/Button/BlackButton.js';
import ProductCarousel from '../../../Components/FlashDeal/ProductSlider/ProSlider.js'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FlashDealAddCouponAPI, FlashDealDetailsAPI } from '../../../redux/Action/FlashDeal/FlashDeal.js';
import Loader from '../../../Components/Loader/Loader.js';
import GrayButton from '../../../Components/GrayButton/GrayButton.js';
import LargeBtn from '../../../Components/LargeBtn/LargeBtn.js';
import CustomFlashButton from '../../../Components/CustomButton/CustomFlashDeal.js';
import RoundedDrawer from '../../../Components/BottomDrawer/RoundedDrawer.js';
import CustomModal from '../../../Components/CustomModal/CustomModal.js';
import BorderButton from '../../../Components/BorderButton/BorderButton.js';
import StrConstent from '../../../Common/StrConstent.js';
import { useBreakpoint } from '../../../CustomHooks/useBreakpoint.js';
import CustomButton from '../../../Components/CustomButton/CustomButton.js';
import { Clear_FlashDealAddCoupon_Cart } from '../../../redux/reducers/FlashDeal/FlashDealAddCoupon.js';
import AlertMessage from '../../../Components/Alertmsg/AlertMessage.js';

const FlashDealDetail = (props) => {
    const { classes } = props;
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch()
    const device = useBreakpoint();
    const FlashDealDetailsSlice = useSelector(state => state?.FlashDealDetailsSlice)
    const [data, setdata] = useState([])
    const [totaltimeinsecond, settotaltimeinsecond] = useState(0)
    const [open, setopen] = useState(false);
    const [opencoupon, setopencoupon] = useState(false);
    const FlashDealAddCouponSlice = useSelector(state => state?.FlashDealAddCouponSlice)
    const navigate = useNavigate()
    const [flasherror, setflasherror] = useState(false);
    const [flasherrormsg, setflasherrormsg] = useState("");
    const [flashdealopen,setflashdealopen]=useState(false)
    useEffect(() => {
        if (FlashDealDetailsSlice?.value?.data != undefined && FlashDealDetailsSlice?.value?.data != null) {
            setdata(FlashDealDetailsSlice?.value?.data)
        }
    }, [FlashDealDetailsSlice])
    useEffect(() => {
        dispatch(FlashDealDetailsAPI(id))
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };

    console.log("end_dateformate", formatDate(data?.end_date));
    const handleClick = () => {
        setopen(true)
    }


    const handleClose = () => {
        setopen(false)
        // setpickuppopup(false)
        // setchoosemodepopup(false);
    }

    const handleSubmit = () => {
        const obj = {
            promotion_id: data?.id,
            code: data?.unique_code
        }
        dispatch(FlashDealAddCouponAPI(obj))
    }
    // /coupon-list

    useEffect(() => {
        if (FlashDealAddCouponSlice?.value?.message) {
            dispatch(Clear_FlashDealAddCoupon_Cart())
            // navigate("/coupon-list")
            setopen(false)
            setopencoupon(true)
        }
    }, [FlashDealAddCouponSlice])
    const handleSubmitCoupon = () => {
        navigate("/coupon-list")
    }
    const handleClosecoupon = () => {

    }
    useEffect(() => {
        if (FlashDealAddCouponSlice?.error?.message) {
            setflasherrormsg(FlashDealAddCouponSlice?.error?.message)
            setflasherror(true)
            dispatch(Clear_FlashDealAddCoupon_Cart())
        }
    }, [FlashDealAddCouponSlice])
    console.log("FlashDealDetailsSlice", FlashDealAddCouponSlice)
    
    const snackHandler = (event, reason) => {
        setflasherror(false);
    };

    useEffect(()=>{
        if( FlashDealDetailsSlice?.loading == true){

            setflashdealopen(true)
        }else if(FlashDealDetailsSlice?.loading == false){
           setTimeout(()=>{

               setflashdealopen(false)
           },2000)

        }
    },[FlashDealDetailsSlice])
    return (
        <>
            {
                flashdealopen==true && (
                    <Loader />
                )
            }

            {
                flasherror && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={flasherrormsg}
                        title={"Error"}
                        type={"Error"}
                        open={flasherror}
                    />
                ) 
            }
            <Container maxWidth='xl'>
                <div className={`${CommonStyle.mt50} ${CommonStyle.mb50} ${classes.fdetail}`}>
                    <div className={classes.proDimension}>
                        <img src={FlashDealDetailsSlice?.value?.data?.original} alt='Product img' className={`${CommonStyle.wFull} ${CommonStyle.hFull}`} />
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>


                            <CustomFlashButton label={t("Get Coupon")} clickHandler={() => handleClick()} />
                        </div>
                    </div>
                    <div className={`${CommonStyle.ml40} ${classes.rightSection}`}>
                        <div className={`${CommonStyle.flex} ${CommonStyle.wFull} ${CommonStyle.JustifySpaceBetween} ${CommonStyle.alignItemCenter}`}>
                            <img src={FdIcon} className={classes.fdIcon} />
                            <CountdownTimer initialTime={FlashDealDetailsSlice?.value?.data?.end_date != null && FlashDealDetailsSlice?.value?.data?.end_date != undefined ? FlashDealDetailsSlice?.value?.data?.end_date : new Date()} />
                        </div>
                        <h2 className={`${CommonStyle.mb20} ${classes.upperCase}`}>{data?.name}</h2>
                        <div className={`${CommonStyle.flex} ${CommonStyle.gap30}`}>
                            <div>{t("Valid Till")} : <span>{formatDate(data?.end_date)}</span></div> <div>|</div><div>{FlashDealDetailsSlice?.value?.data?.remaining_quantity} {t("items remains")}</div>
                        </div>
                        <div className={classes.borderBottomSolidLight}></div>
                        <h2 className={`${CommonStyle.mb20} ${classes.upperCase}`}>{t("Description")}</h2>
                        <p className={classes.desc}>{data?.description}</p>

                        <div className={classes.borderBottomSolidLight}></div>

                        <h2 className={`${CommonStyle.mb20} ${classes.upperCase}`}>{t("Available")}</h2>
                        <div className={`${CommonStyle.flex} ${classes.productSlider}`}>
                            <ProductCarousel data={data?.outlets} />
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {device == "sm" ? <>

                    <RoundedDrawer
                        truekey={open}
                        setruekey={setopen}
                    >
                        <h3 className={CommonStyle.textCenter}>{t("Are you sure you want to use")} {FlashDealDetailsSlice?.value?.data?.points} {t("point to get the coupon?")}</h3>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            <BorderButton label={t("Cancel")} clickHandler={() => { handleClose() }} />
                            <CustomButton label={t("Confirm")} clickHandler={() => { handleSubmit() }} />
                        </div>
                    </RoundedDrawer>

                </> : <>
                    <CustomModal
                        open={open}
                        title={""}
                        handleClose={() => handleClose()}
                        description={""}>
                        <h3 className={CommonStyle.textCenter}>{t("Are you sure you want to use")} {FlashDealDetailsSlice?.value?.data?.points} {t("point to get the coupon?")}</h3>

                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            <BorderButton label={t("No")} clickHandler={() => { handleClose() }} />
                            <CustomButton label={t("YES")} clickHandler={() => { handleSubmit() }} />
                        </div>
                    </CustomModal>
                </>}
                {device == "sm" ? <>

                    <RoundedDrawer
                        truekey={opencoupon}
                    // setruekey={setopen}
                    >
                        <h3 className={CommonStyle.textCenter}>{t("Your Coupon is saved")}</h3>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            {/* <BorderButton label={"Cancel"} clickHandler={() => { }} /> */}
                            <CustomButton label={t("ok")} clickHandler={() => { handleSubmitCoupon() }} />
                        </div>
                    </RoundedDrawer>

                </> : <>
                    <CustomModal
                        open={opencoupon}
                        title={""}
                        handleClose={() => handleClosecoupon()}
                        description={""}>
                        <h3 className={CommonStyle.textCenter}>{t("Your Coupon is saved")}</h3>

                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                            {/* <BorderButton label={"No"} clickHandler={() => { handleClose() }} /> */}
                            <CustomButton label={t("ok")} clickHandler={() => { handleSubmitCoupon() }} />
                        </div>
                    </CustomModal>
                </>}
            </Container>
        </>
    )
}

export default withStyles(Styles)(FlashDealDetail);
