import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    width50: {
        width: '50%',
        "@media(max-width:640px)": {
            width: '100%',
        },
    },
    flex: {
        display: 'flex',
        "@media(max-width:640px)": {
            display: 'block',
        },
        "& > div": {
            "&:nth-child(2)": {
                paddingLeft: '15px',
                "@media(max-width:640px)": {
                    paddingLeft: '0',
                },
            }
        }
    },
    countBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: ColorTheme.white,
        padding: '0',
        borderRadius: '10px',
        minWidth: '220px',
        maxWidth: '220px',
        "@media(max-width:475px)": {
            minWidth: '150px',
        },
        "& a": {
            textAlign: 'center',
            minWidth: '55px',
            textAlign: 'center',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    countNumber: {
        margin: "0 10px",
        fontSize: "18px",
        lineHeight: "18px",
        color: ColorTheme.darkGray,
        fontWeight: "400",
    },
    tableBook: {
        display: "flex",
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: '5px',
    },
    tableImg: {
        maxWidth: '80px',
        height: '80px',
        "& img": {
            maxWidth: '80px',
            maxHeight: '80px',
        }
    },
    chips: {
        width: '45px',
        padding: '6px 8px !important',
        background: ColorTheme.yellow,
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& svg": {
            height: '20px',
            color: ColorTheme.white,
        }
    },
    checkIcon: {
        "& svg": {
            color: ColorTheme.yellow,
        }
    },
    chipsBorder: {
        marginLeft: '10px',
        background: ColorTheme.transparent,
        border: `1px solid ${ColorTheme.yellow}`,
        "& svg": {
            color: ColorTheme.yellow,
        }
    },
    verfiyContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '5px',
    },
    reserveBtn: {
        marginTop: '45px',
        marginLeft: 'auto',
        "& > div": {
            // "@media(max-width:768px)": {
            //     float: 'right',
            // }
        }
    },
    reserveBtnsecond: {
        width: "70px",
        height: "70px",
        borderRadius: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: ColorTheme.yellow,
        color: ColorTheme.white,

    }
})
