import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ChoosemodeSlice = createSlice({
    name: 'Choosemode',
    initialState,
    reducers: {
        Choose_mode_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Choose_mode_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Choose_mode_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Choose_mode_request, Choose_mode_success, Choose_mode_failure } = ChoosemodeSlice.actions

export default ChoosemodeSlice.reducer