import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    textCheckbox: {
        display: "flex",
        alignItems: "center",
        margin: "0px 0px 10px !important",
        maxWidth: "250px",
        "& p": {
            padding: "0",
            margin: "0",
            color: ColorTheme.fontColor,
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            "& a": {
                fontWeight: "700",
                padding: "0 2px",
                fontSize: "14px",
                lineHeight: "14px",
            }
        },
        "& span": {
            padding: "0",
            margin: "0 5px 0 0",
            color: ColorTheme.fontColor,
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
        },
    },
})