export const Styles = Theme => ({ 
    tagLine : {
        fontSize: '16px',
        color: '#3F4444BF'
    },
    itemContainer : {
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        position : 'relative',
        backgroundColor : '#fff',
        margin: '10px 0',
        '&::before, &::after' : {
            content: '""',
            top: '58%',
            transform: 'rotate(90deg)',
            height: '30px',
            width: '33px',
            backgroundColor: '#f7f7f7',
            position: 'absolute',
            zIndex: '9',
        },
        '&::before' : {
            left: '-7px',
            borderRadius: '150px 150px 0 0',
            boxShadow :'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.1)'
        },
        '&::after' : {
            right: '-4px',
            borderRadius: '0 0 150px 150px',
            boxShadow : 'inset 0px -4px 4px 0px rgba(0, 0, 0, 0.1)'
        },
    },
    allFDContainer : {  
        '&::before, &::after' : {
            top: '45%',
            height : '20px',
            width : '31px'
        },
        '&::before' : {
            left : '-9px'
        },
        '&::after' : {
            right : '-9px'
        }
    },
    gridDimension : {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)', /* Each row contains four items */
        gap: '20px',
        '@media(max-width: 1368px)' : {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        '@media(max-width: 1024px)' : {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },

        '@media(max-width: 640px)' : {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }, 
    },
    allItemTop : {
        padding : '20px',
        display : 'flex !important',
        '& > div' : {
            height : '105px'
        }
    },
    allProdImg : {
        width : '107px',
        height : '105px'
    },
    rounded20 : {
        borderRadius : '20px',
    },
    sliderHeading : {
        fontSize : '22px',
        WebkitLineClamp: 1, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical'  
    },
    sliderContent : {
        fontSize : '12px',
        margin : '10px 0',
        WebkitLineClamp: 2, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical'  
    },
})