import React, { useState } from 'react'
import moment from "moment";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CommonTimeSlot from '../../Components/CommonTimeSlot/CommonTimeSlot';
function compareTimes(time1, time2) {
    const [hours1, minutes1, ampm1] = time1.split(/:| /); // Split the first time string
    const [hours2, minutes2, ampm2] = time2.split(/:| /); // Split the second time string

    // Convert hours to 24-hour format
    const hours124 = ampm1 === 'PM' ? parseInt(hours1, 10) + 12 : parseInt(hours1, 10);
    const hours224 = ampm2 === 'PM' ? parseInt(hours2, 10) + 12 : parseInt(hours2, 10);

    // Create Date objects for both times
    const date1 = new Date(0, 0, 0, hours124, parseInt(minutes1, 10), 0);
    const date2 = new Date(0, 0, 0, hours224, parseInt(minutes2, 10), 0);

    // Compare the Date objects
    if (date1 < date2) {
        return `${time2}`;
    } else if (date1 > date2) {
        return `${time1}`;
    } else {
        return `${time1}`;
    }
}

const TimeFormate = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currenttime, setCurrentTime] = useState("")
    const [weekday, setweekday] = useState("")
    const OutletSchdeule = useSelector(state => state?.OutletSchdeuleSlice)
    useEffect(() => {
        setCurrentDate(new Date());
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 300000); // Update the current date every second

        return () => clearInterval(interval);
    }, []);// Replace this with your actual date string

    function weekdayfunction(date){
        const options = {
            // for AM/PM
            weekday: 'long', // for the day of the week
        };

        setweekday(date.toLocaleString(undefined, options));
    }
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
      

        setCurrentTime(`${hour}:${minute} ${ampm}`)
        return `${year}-${month}-${day}`;
    }

    const [timeSlots, setTimeSlots] = useState([]);

    useEffect(() => {
        const startString = weekdayfunction(currentDate); // Replace with your start time
        const endString = '2023-09-15 11:33 PM';   // Replace with your end time

        // const result = intervalHandler(startString, endString);
        // //   console.log("addtocartlogin",result)
        // //   setTimeSlots(result);
    }, []);
    const intervalHandler = async (startString, endString) => {
        // debugger
        let result = []; // timeSlot;
        const end = moment(endString, "YYYY-MM-DD hh:mm a");
        const start = moment(startString, "YYYY-MM-DD hh:mm a").add(15, "minutes");
        end.subtract(15, "minutes");
        start.minutes(Math.ceil(start.minutes() / 15) * 15);
        const current = moment(start);
        while (current <= end) {
            let startTime = current.format("hh:mm A");
            current.add(15, "minutes");
            let endTime = current.format("hh:mm A");
            if (!result?.includes(startTime + "-" + endTime)) {
                // ✅ only runs if value not in array
                result.push(startTime + "-" + endTime);
            }
        }
        // console.log("addtocartlogin",result)
        setTimeSlots(result);
        return result;
        // setTimeSlot((v) => result);
    };
    useEffect(() => {
        const filtertime = OutletSchdeule?.value?.data?.schedule?.filter((data) => data?.day == weekday)

        const comparisonResult = filtertime?.length == 1 && compareTimes(currenttime, filtertime[0]?.open)
        // console.log("addtocartlogin",weekday, filtertime, comparisonResult)

        if (filtertime?.length == 1) {
            const startString = `${formatDate(currentDate)} ${comparisonResult}`;
            const endString = `${formatDate(currentDate)} ${filtertime[0]?.close}`;
            // console.log("addtocartlogin", startString, endString)
            const result = intervalHandler(startString, endString);
            // setTimeSlots(result);
        }
    }, [weekday, currenttime, OutletSchdeule])
        // console.log("addtocartlogin", OutletSchdeule)
  return (
    <>
      <CommonTimeSlot
      timeSlots={timeSlots}
      />
    </>
  )
}

export default TimeFormate
