import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    wrapper: {
        height: 'auto !important',
    },
    fields: {
        "& div": {
            width: '100%',
        }
    },
    selectBox: {
        border: '1px solid #f1eedb',
        background: 'var(--bs-white) !important',
        borderRadius: '30px',
        "& div": {
            width: '100%',
            fontFamily: 'var(--sub-heading-family) !important',
            // color: `${ColorTheme.secondaryColor} !important`,
            margin: '0 !important',
        },
        marginBottom: '15px',
    },
    "& .MuiMenuItem-root": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
    "& .Mui-selected": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
    // checkbox: {
    //     "& span": {
    //         "& span": {
    //             background: `${ColorTheme.lightBrown}`,
    //             "@media(max-width:768px)": {
    //                 background: `${ColorTheme.white}`,
    //             }
    //         }
    //     }
    // },
    bottomBtn: {
        "@media(max-width:768px)": {
            flexDirection: 'column',
        },
        "& div": {
            "@media(max-width:768px)": {
                marginTop: '10px',
                width: '100% !important',
                padding: '0',
            },
            "& button": {
                "@media(max-width:768px)": {
                    width: '100% !important',
                }
            }
        },
    }
})