import React, { useEffect, useState } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useNavigate } from 'react-router';
import BackArrow from '../../Components/BackArrow/BackArrow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

import SmallButton from '../../Components/SmallButton/SmallButton';
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer';
import dropDownIconCircle from '../../images/dropDownIconCircle.svg';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import { Clear_Otp_verified } from '../../redux/reducers/Continuewithphone/Otpverified';

import { CouponListAPI } from '../../redux/Action/Coupon/Couponlistaction';
import { ApplyCouponListAPI } from '../../redux/Action/Coupon/applyCouponAction';
import { RemoveCouponListAPI } from '../../redux/Action/Coupon/RemoveCouponAction';
import AlertMessage from '../../Components/Alertmsg/AlertMessage';
import { StripeCheckoutAPI } from '../../redux/Action/Stripepayment/StripeCheckout';
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import { GetLoginCartAPI } from '../../redux/Action/LoginCart/GetLoginCartAction';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import Loader from '../../Components/Loader/Loader';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import CustomModal from '../../Components/CustomModal/CustomModal';
import { FlashDealApplyCouponAPI, FlashDealCouponListAPI, FlashDealRemoveCouponAPI } from '../../redux/Action/FlashDeal/FlashDeal';
import { FlashDealDrawer_success } from '../../redux/reducers/FlashDealDrawerSlice';
import { Stripe_payment_failure } from '../../redux/reducers/Stripepayment/StripeCheckoutSlice';
import { Clear_FlashDealApply_Cart } from '../../redux/reducers/FlashDeal/FlashDealApplySlice';



const ProceedToPay = (props) => {
    let navigate = useNavigate();
    const { classes } = props;
    const [desktop, setDesktop] = useState(window.innerWidth > 640);
    const [paymentCart, setPaymentCart] = useState();
    const [promoCode, setPromoCode] = useState(false);
    const [FlashDeal, setFlashDeal] = useState(false);
    const dispatch = useDispatch();
    const grandTotal = localStorage.getItem("grandTotal");
    const Couponlist = useSelector(state => state?.CouponlistCSlice?.value)
    const ApplyCoupon = useSelector(state => state?.ApplyCouponSlice?.value)
    const ApplyCouponerror = useSelector(state => state?.ApplyCouponSlice)
    const couponname = localStorage.getItem("couponname")
    const device = useBreakpoint();
    const [isOpen, setIsOpen] = useState(false);
    const GetLoginCartSlice = useSelector(state => state?.GetLoginCartSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const Stripepayment = useSelector(state => state?.StripepaymentSlice)
    const [GetLoginCart, setGetLoginCart] = useState(false)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const outlet_mode = localStorage.getItem("outlet_mode")
    const { t } = useTranslation();
    const [cartitem, setcartitem] = useState([]);
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const addtocart = localStorage.getItem("addtocart")
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    // const companydata = JSON.parse(localStorage.getItem("companydata"))
    const FlashDealCouponListSlice = useSelector(state => state?.FlashDealCouponListSlice)
    const FlashDealApplySlice = useSelector(state => state?.FlashDealApplySlice)
    const FlashDealDrawerSlice = useSelector(state => state?.FlashDealDrawerSlice?.value)
    const outletID = localStorage.getItem("outletID")
    const cart_identifier = localStorage.getItem("cart_identifier")
    const coupon_codeflash = localStorage.getItem("coupon_codeflash")
    const promotion_idflash = localStorage.getItem("promotion_idflash")
    useEffect(() => {
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))

    }, [])
    useEffect(() => {
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))

    }, [addtocartlogin, addtocartwithoutlogin, addtocart])
    const handleNavigate = () => {
        setPaymentCart(true)
    }
    useEffect(() => {
        if (addtocartlogin?.value?.data?.cart_items?.length == 0) {
            navigate("/")
        }
    }, [addtocartlogin])

    console.log("addtocartlogin", addtocartlogin?.value?.data?.cart_items?.length)
    const handlePromoCodeApply = () => {
        setPromoCode(true);
        dispatch(Drawer_success(true))
    }
    const handleFlashDealApply = () => {
        setFlashDeal(true);
        dispatch(FlashDealDrawer_success(true))
    }
    useEffect(() => {
     
        dispatch(Clear_Otp_verified())
        dispatch(Clear_Continue_as_guest())
    }, [])

   
    const mobileWidth = () => {
        if (window.innerWidth <= 640) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
    useEffect(() => {

        if (couponname) {
            dispatch(ApplyCouponListAPI(couponname))
        }
        dispatch(Drawer_success(false))
        dispatch(GetLoginCartAPI())
        dispatch(CouponListAPI())
    }, [])
    useEffect(() => {
        window.addEventListener("resize", mobileWidth);
        return () => window.removeEventListener("resize", mobileWidth);
    })
    const handleApplyCoupon = (id) => {
        dispatch(ApplyCouponListAPI(id))
        setPromoCode(false)
        dispatch(Drawer_success(false))
    }
    const handleRemovecoupon = () => {
        dispatch(RemoveCouponListAPI())
    }
    const snackHandler = (event, reason) => {
        setIsOpen(false);

    };

    useEffect(() => {
        if (ApplyCouponerror?.error) {
            setIsOpen(true)
        }
    }, [ApplyCouponerror])

    const handlepayment = () => {
        const paymentamount = ApplyCoupon?.success == true ? outlet_mode == "Schedule Delivery" ? +ApplyCoupon?.data?.discounted_total + +GetLoginCartSlice?.value?.data?.delivery_fee - +GetLoginCartSlice?.value?.data?.flasdeal_discount_availed : Math.abs(+ApplyCoupon?.data?.discounted_total) - +GetLoginCartSlice?.value?.data?.flasdeal_discount_availed : +grandTotal + +GetLoginCartSlice?.value?.data?.delivery_fee - +GetLoginCartSlice?.value?.data?.flasdeal_discount_availed

        if (paymentamount == 0 || paymentamount<0) {
            navigate("/success")
        } else {

            dispatch(StripeCheckoutAPI())
        }
    }

    useEffect(() => {
        if (coupon_codeflash && promotion_idflash) {

            const obj = {
                coupon_code: coupon_codeflash,
                promotion_id: promotion_idflash,
                cart_id: cart_identifier
            }
            dispatch(FlashDealApplyCouponAPI(obj))
        }
    }, [])
  
    const handleApplyCouponcode = (data) => {
        const obj = {
            coupon_code: data?.promotion?.code,
            promotion_id: data?.promotion?.id,
            cart_id: cart_identifier
        }
        dispatch(FlashDealApplyCouponAPI(obj))
        dispatch(FlashDealDrawer_success(false))
        setFlashDeal(false)
    }
    const handleremoveflashDeal = (data) => {
        const obj = {
            coupon_code: coupon_codeflash,
            promotion_id: promotion_idflash,
            cart_id: cart_identifier
        }
        dispatch(FlashDealRemoveCouponAPI(obj))
    }

    const promocodehandler = () => {
        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.cart}>
                    {Couponlist?.data?.map((data) => {
                        return <div className={`${classes.cartBox} ${+grandTotal < data?.minimum_amount ? CommonStyle.disable : ""}`}>
                            <div className={CommonStyle.flexSpaceBetween}>
                                <div>
                                    <h4>{data?.code}  ({data?.name})</h4>
                                    <p>{data?.descriptions}</p>
                                    <p className={classes.errorMessage}>{data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used ? (+grandTotal < data?.minimum_amount ? `Add items worth ${companydata?.currency_symbol}${data?.minimum_amount - +grandTotal} more to unlock.` : "") : +grandTotal < data?.minimum_amount ? `Add items worth ${companydata?.currency_symbol}${data?.minimum_amount - +grandTotal} more to unlock.` : "This coupon already used."}</p>
                                </div>
                                <div>
                                    {data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used && (
                                        +grandTotal >= data?.minimum_amount ? <SmallButton label={"Apply"} clickHandler={() => handleApplyCoupon(data?.code)} /> : null
                                    )}
                                </div>
                            </div>
                        </div>
                    })}
                    {Couponlist?.data?.length == 0 && (
                        <h3 align="center">{StrConstent?.proceedToPay?.noCoupons?.heading}</h3>
                    )}
                </div>
            </div>
        </React.Fragment>
    }
    console.log("FlashDealDrawerSlice", FlashDealDrawerSlice, DrawerSlice)
    const handleFlashCodeApply = () => {

        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.cart}>
                    {FlashDealCouponListSlice?.value?.data?.map((data) => {
                        return <div className={`${classes.cartBox} `}>
                            <div className={CommonStyle.flexSpaceBetween}>
                                <div>
                                    <h4>{data?.promotion?.name}</h4>
                                    <p>{data?.promotion?.descriptions}</p>
                                </div>
                                <div>
                                 
                                    <SmallButton label={"Apply"} clickHandler={() => { handleApplyCouponcode(data) }} />
                                </div>
                            </div>
                        </div>
                    })}
                    {FlashDealCouponListSlice?.value?.data?.length == 0 && (
                        <h3 align="center">{StrConstent?.proceedToPay?.noCoupons?.heading}</h3>
                    )}
                </div>
            </div>
        </React.Fragment>
    }

    useEffect(() => {
        if (Stripepayment?.error?.message) {
            setIsOpen(true)
            setTimeout(() => {

                dispatch(Stripe_payment_failure())
            }, 3000)
        }
    }, [Stripepayment])
    useEffect(() => {
        if (GetLoginCartSlice?.loading == true) {
            setGetLoginCart(true)
        } else {
            setTimeout(() => {
                setGetLoginCart(false)
            }, 1500)
        }
    }, [GetLoginCartSlice])
    useEffect(() => {
        dispatch(FlashDealCouponListAPI(outletID))
    }, [])

    console.log("FlashDealCouponListSlice", FlashDealApplySlice?.error?.message, ApplyCouponerror?.error?.message)
    useEffect(() => {
        if (FlashDealApplySlice?.error?.message) {
            setIsOpen(true)
            setTimeout(() => {
                dispatch(Clear_FlashDealApply_Cart())
            }, 3000)
        }
    }, [FlashDealApplySlice])

    const totalamountnegativecheck = ApplyCoupon?.success == true ? outlet_mode == "Schedule Delivery" ? +ApplyCoupon?.data?.discounted_total + +GetLoginCartSlice?.value?.data?.delivery_fee - +GetLoginCartSlice?.value?.data?.flasdeal_discount_availed : Math.abs(+ApplyCoupon?.data?.discounted_total) - +GetLoginCartSlice?.value?.data?.flasdeal_discount_availed : +grandTotal + +GetLoginCartSlice?.value?.data?.delivery_fee - +GetLoginCartSlice?.value?.data?.flasdeal_discount_availed
    const totalamountcheck = totalamountnegativecheck > 0 ? totalamountnegativecheck : 0
    return (
        <>
            {
                Stripepayment?.loading == true && (
                    <Loader />
                )
            }
            {
                GetLoginCart == true || FlashDealApplySlice?.loading == true && (
                    <Loader />
                )
            }

            {
                (ApplyCouponerror?.error?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}

                        description={ApplyCouponerror?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                Stripepayment?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}

                        description={Stripepayment?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            <div className={`${CommonStyle.spacingX0} ${CommonStyle.wrapper} ${classes.processToPay}`}>
                <ContainerBox>
                    <div className={classes.p16}>
                        {isappwebviewcheck != "acecard" && (

                            <BackArrow clickHandler={() => navigate("/cart")} />
                        )}
                        <h2 className={CommonStyle.mb20}>{t("proceed to pay")}</h2>
                    </div>
                    <Accordion className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<img src={dropDownIconCircle} alt="Not Found" />}>
                            <h3>{t("Order Details")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.listing}>
                                <ul>
                                    {cartitem?.map((data) => {
                                        return <li>
                                            <div className={`${classes.leftContent} ${CommonStyle.flexCenter}`}>
                                                <div className={classes.imgContent}>
                                                    <img src={data?.url} alt="Not Found" />
                                                </div>
                                                <div className={classes.txtContent}>
                                                    <h5>{data?.name}</h5>
                                                    <p>{data?.selectedsize?.size}</p>
                                                </div>
                                            </div>
                                            <div className={classes.rightContent}>
                                                <h4>{companydata?.currency_symbol}{data?.totalamount}</h4>
                                            </div>
                                        </li>
                                    })}

                                    <li>
                                        <div className={classes.leftContent}>
                                            <p className={classes.title}>{t("Grand Total")}</p>
                                        </div>
                                        <div className={classes.rightContent}>
                                            <p>{companydata?.currency_symbol}{GetLoginCartSlice?.value?.data?.grand_total}</p>
                                        </div>
                                    </li>
                                    {companydata?.discount_type != "flashdeal" && <>
                                        {Couponlist?.data?.length > 0 && (<li>
                                            <div className={classes.leftContent}>
                                                <p className={classes.title}>{t("Promo Code")} <h6>{ApplyCoupon?.data?.coupon_used}</h6></p>
                                                <p>{t("This promotion is used only one time and on first order")} </p>
                                            </div>
                                            <div className={classes.rightContent}>
                                                <p>-{companydata?.currency_symbol}{ApplyCoupon?.success == true ? ApplyCoupon?.data?.discount_availed : 0}</p>
                                                {
                                                    ApplyCoupon?.success == true && (

                                                        <SmallButton label={t("Remove")} icon={<DeleteIcon />} clickHandler={() => handleRemovecoupon()} />
                                                    )
                                                }
                                                {ApplyCoupon?.success != true && (
                                                    <SmallButton label={t("Apply Promo Code")} defaultExpanded={desktop} clickHandler={() => handlePromoCodeApply()} />
                                                )
                                                }
                                            </div>
                                        </li>)}
                                    </>}
                                    {companydata?.discount_type == "flashdeal" && <>


                                        {FlashDealCouponListSlice?.value?.data?.length > 0 && (<li>
                                            <div className={classes.leftContent}>
                                                <p className={classes.title}>{t("Flash Deal")} <h6>
                                                    {/* {ApplyCoupon?.data?.coupon_used} */}
                                                </h6></p>
                                                {/* <p>{t("This promotion is used only one time and on first order")} </p> */}
                                            </div>
                                            <div className={classes.rightContent}>
                                                {/* <p>-{companydata?.currency_symbol}{ApplyCoupon?.success == true ? ApplyCoupon?.data?.discount_availed : 0}</p> */}
                                                {
                                                    FlashDealApplySlice?.value?.success == true && (

                                                        <SmallButton label={t("Remove")} icon={<DeleteIcon />} clickHandler={() => handleremoveflashDeal()} />
                                                    )
                                                }
                                                {FlashDealApplySlice?.value?.success != true && (
                                                    <SmallButton label={t("Apply Flash Deal")} defaultExpanded={desktop} clickHandler={() => handleFlashDealApply()} />
                                                )
                                                }
                                            </div>
                                        </li>)}
                                    </>}
                                    {outlet_mode == "Schedule Delivery" && <li>
                                        <div className={classes.leftContent}>
                                            <p className={classes.title}>{t("Delivery Fee")}</p>

                                        </div>
                                        <div className={classes.rightContent}>
                                            <p>{companydata?.currency_symbol}{+GetLoginCartSlice?.value?.data?.delivery_fee}</p>

                                        </div>
                                    </li>}
                                    <li>
                                        <div className={classes.leftContent}>
                                            <p className={classes.title}>{t("Total Payable")}</p>
                                        </div>
                                        <div className={classes.rightContent}>
                                            <p>{companydata?.currency_symbol}
                                          {totalamountcheck}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div className={`${CommonStyle.bottomBtn} ${CommonStyle.JustifyCenter}`}><LargeBtn label={t("proceed to pay")} clickHandler={() => { handlepayment() }} /></div>
                 
                </ContainerBox>
            </div>

            {device == "sm" ? <>
                {
                    promoCode == true &&
                    (
                        <RoundedDrawer>
                            {promocodehandler()}
                        </RoundedDrawer>
                    )
                }
            </> : <>

                {DrawerSlice == true && <CustomModal
                    open={true}
                    // title={StrConstent.cart.cardClear.heading}
                    handleClose={() => dispatch(Drawer_success(false))}
                    description={""}> {promocodehandler()}
                </CustomModal>}
            </>

            }
            {device == "sm" ? <>
                {
                    FlashDeal == true &&
                    (
                        <RoundedDrawer>
                            {handleFlashCodeApply()}
                        </RoundedDrawer>
                    )
                }
            </> : <>

                {FlashDealDrawerSlice == true && <CustomModal
                    open={true}
                    // title={StrConstent.cart.cardClear.heading}
                    handleClose={() => dispatch(FlashDealDrawer_success(false))}
                    description={""}> {handleFlashCodeApply()}
                </CustomModal>}
            </
            >

            }

        </>
    )
}
export default withStyles(Styles)(ProceedToPay);