import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import calender from '../../images/calender.png';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useState } from 'react';
import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect } from 'react';
const CommonDatePicker = (props) => {
  const { classes,
    dateValue = "",
    maxFutureDate = "",
    allowedDays = null,
    onBinding,

    name = "",
    isPastDisable = false,
    isWeekDayDisable = false,
    allowAfter = 0,
    isFutureDisable = false,
    isBlank = false,
    editdata = null,
    selectmode = "" } = props;
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (value != "") {

      onBinding({ [name]: value?.$d });
    }
  }, [value]);
  const theme = createTheme({

    components: {

      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: `${ColorTheme.secondaryColor} !important`,
              backgroundColor: `${ColorTheme.yellow} !important`
            },
            "&:hover": {
              color: `${ColorTheme.secondaryColor} !important`,
              backgroundColor: `${ColorTheme.yellow} !important`
            }
          }
        }
      },

    }
  })

  // console.log("valuevalue",value)
  return (
    <>
      <div className={classes.datapicker} onClick={() => setIsOpen(true)}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['MobileDatePicker']}>
              <MobileDatePicker
                disablePast={true}
                value={dayjs(dateValue?.dob)}
                onChange={(newValue) => {
                  setValue(newValue);
                }}

              />

              <img src={calender} alt="Not Found" />
            </DemoContainer>
          </LocalizationProvider>
        </ThemeProvider>
      </div>
    </>
  )
}
export default withStyles(Styles)(CommonDatePicker);
