import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import CustomButton from "../CustomButton/CustomButton";
import StrConstent from "../../Common/StrConstent";
import closeIcon from '../../images/closeIcon.svg';
import MyCardItme from "../MyCardItme/MyCardItme";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Cart_Drawer_Open_success } from "../../redux/reducers/CartdraweropenSlice";
import { useTranslation } from 'react-i18next';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
const DrawerRightCardItem = (props) => {
    const { classes, parentCallback, onOpen } = props;
    const [state, setState] = useState({
        right: false
    });
    const [pageType, setPageType] = useState("");
    const CartDraweOpenSlice = useSelector(state => state?.CartDraweOpenSlice?.value)
    const dispatch = useDispatch()
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const addtocart = localStorage.getItem("addtocart")
    const [cartitem, setcartitem] = useState([]);
    const navigate = useNavigate()
    const [withoutlogintotalamount, setwithoutlogintotalamount] = useState(0)
    const Totalamountwithoutlogin = useSelector(state => state?.TotalamountwithoutloginSlice?.value)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const AddtocartRefresh = useSelector(state => state?.AddtocartRefreshSlice?.value)
    const Logindetails = localStorage.getItem("Logindetails")
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const [totalamount, settotalamount] = useState(0)
    const { t } = useTranslation();
    useEffect(() => {

        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))
    }, [])
    useEffect(() => {
        if (addtocartlogin?.value?.data?.cart_items?.length > 0) {

            return setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))
        } else if (addtocartlogin?.value?.data?.cart_items?.length == 0) {
            return setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))

        }
        else if (!Logindetail) {
            setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))

        }
        else if (Logindetail && addtocartlogin?.loading == false && addtocartlogin?.value?.data == undefined) {
            setcartitem([])

        }
    }, [addtocartlogin, addtocart, AddtocartRefresh])
    //    console.log("AddtocartRefresh",AddtocartRefresh)
    useEffect(() => {


        if (Totalamountwithoutlogin?.length > 0 && Logindetail) {

            const arr = Totalamountwithoutlogin?.map((item, i) => {
                return (item?.item_price * item?.itemQuantity)
            }).reduce((data, i) => data + i)
            setwithoutlogintotalamount(arr)
        } else if (Logindetail && Totalamountwithoutlogin?.length == 0) {
            setwithoutlogintotalamount(0)
        }

    }, [Totalamountwithoutlogin, AddtocartRefresh])
    useEffect(() => {
        // dispatch(Drawer_success(false))
        if (!Logindetail) {
            const addtocarts = addtocart ? JSON.parse(addtocart) : []
            if (addtocarts?.length > 0) {


                const arr = addtocarts?.map((item, i) => {
                    return (item?.item_price * item?.itemQuantity)
                })?.reduce((data, i) => data + i)
                // console.log("arr", arr)
                setwithoutlogintotalamount(arr)
            } else {
                setwithoutlogintotalamount(0)
            }
        }
    }, [Totalamountwithoutlogin, AddtocartRefresh,addtocart])
    const toggleDrawer = (anchor, open, pageType) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        )
            return;
        setPageType(pageType);
        setState({ ...state, [anchor]: open });
    };

    const handleNavigate = () => {
        navigate("/cart")
        dispatch(Cart_Drawer_Open_success(false))
    }

    useEffect(() => {

        if (Logindetail) {
            if (addtocartlogin?.value?.data) {

                settotalamount(addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount)
            }
        } else if (!Logindetail) {
            settotalamount(addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount)

        }
    }, [addtocartlogin, withoutlogintotalamount, AddtocartRefresh, Logindetail])

    useEffect(() => {
        if (addtocartlogin?.value?.data?.grand_total && Logindetail) {

            settotalamount(addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount)
        } else if (!Logindetail) {
            settotalamount(addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount)

        }
    }, [])

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450 }}
            role="presentation"
            className={classes.boxOuter}>
            <div className={classes.cardHeader}>
                <div className={classes.cardHeaderInner}>
                    <h4>{t("Cart Item")}</h4>
                    {/* <NavLink to="" className={CommonStyle.links}>Clear Items</NavLink> */}
                    <NavLink id="closeDrawer" onClick={() => dispatch(Cart_Drawer_Open_success(false))}>
                        <img src={closeIcon} alt="Not Found" />
                    </NavLink>
                </div>
            </div>
            <div className={`${classes.scrollInner} ${CommonStyle.scroll}`}>
                {
                    cartitem.length == 0 ?
                        <div className={classes.nodata}>
                            <ProductionQuantityLimitsIcon />
                            <h5>{t("No Cart Item")}</h5>
                        </div>
                        :
                        cartitem?.map((data) => {
                            return <MyCardItme key={data?.cart_item_id} url={data?.url} heading={data?.name} description={data} price={data?.item_price} quantity={data?.itemQuantity} cartitemid={data?.cart_item_id ? data?.cart_item_id : data?.unique_id} />
                        })
                }
            </div>
            {cartitem.length > 0 && (

                <div className={classes.checkouBtn} onClick={() => handleNavigate()}>
                    <div className={`${classes.totalamount} ${CommonStyle.mb20}`}>
                        <h5>{t("Total")}</h5>
                        <h4>{companydata?.currency_symbol}{totalamount?.toFixed(2)}</h4>
                    </div>
                    <CustomButton clickHandler={checkHandle} label={t("check out")} className={classes.checkOutBtn} />
                </div>
            )

            }
        </Box >
    );

    const checkHandle = () => {

    }
    return (
        <>
            {/* <div className={classes.btnContent}> */}
            {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <div className={classes.dFlex}>
                            <CustomButton
                                label="Click"
                                clickHandler={toggleDrawer(anchor, true, "csv")}
                            />
                        </div> */}
                    <Drawer anchor={anchor} open={CartDraweOpenSlice} onClose={() => dispatch(Cart_Drawer_Open_success(false))}>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
            {/* </div> */}
        </>
    );
};
export default withStyles(Styles)(DrawerRightCardItem);
