import React, { useState } from 'react';
import { withStyles } from "@mui/styles";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstent from "../../Common/StrConstent";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import BackArrow from '../../Components/BackArrow/BackArrow';
import { NavLink } from 'react-router-dom';
const Vouchers = (props) => {
    const { classes } = props;
    const [showModel, setShowModel] = useState(false);
    let navigate = useNavigate();
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const varchers = [
        {
            id: '1',
            amount: '$5 voucher',
            useFor: 'For new customer only',
            expireDate: 'Expires on 10 Dec 2023'
        },
        {
            id: '2',
            amount: '$5 voucher',
            useFor: 'For new customer only',
            expireDate: 'Expires on 10 Dec 2023'
        }
    ]

    const expired = [
        {
            id: '1',
            amount: 'Complimentary drink',
            useFor: 'For new customer only',
            expireDate: 'Expired on 10 Aug 2022'
        },
        {
            id: '2',
            amount: 'Complimentary drink',
            useFor: 'For new customer only',
            expireDate: 'Expires on 10 Dec 2023'
        }
    ]
    return (
        <>
            <ContainerBox>
                <BackArrow clickHandler={() => navigate(-1)} />
                <h2>{StrConstent.vouchers.heading}</h2>
                <TabContext value={value}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" className={`${CommonStyle.tabsContent} ${classes.tabContainer}`}>
                        <Tab label="available" value="1" />
                        <Tab label="used" value="2" />
                        <Tab label="expired" value="3" />
                    </TabList>
                    <TabPanel value="1" className={CommonStyle.py20}>
                        <p className={CommonStyle.mb20}>To use your vouchers, simply find them under the 'Promotions' section when making a payment.</p>
                        {varchers.map((data) => (
                            <div className={classes.varchersBox} key={data.id}>
                                <NavLink to="/voucher-details">
                                    <h4>{data.amount}</h4>
                                    <p>{data.useFor}</p>
                                    <p>{data.expireDate}</p>
                                </NavLink>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel value="2" className={CommonStyle.py20}>
                        {varchers.map((data) => (
                            <div className={classes.varchersBox} key={data.id}>
                                <NavLink to="/voucher-details">
                                    <h4>{data.amount}</h4>
                                    <p>{data.useFor}</p>
                                    <p>{data.expireDate}</p>
                                </NavLink>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel value="3" className={CommonStyle.py20}>
                        {expired.map((data) => (
                            <div className={classes.varchersBox} key={data.id}>
                                <NavLink to="/voucher-details">
                                    <h4>{data.amount}</h4>
                                    <p>{data.useFor}</p>
                                    <p>{data.expireDate}</p>
                                </NavLink>
                            </div>
                        ))}
                    </TabPanel>
                </TabContext>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(Vouchers);