import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Headers } from "../../../APIHandler/HeaderAPI"
import { Continue_with_phone_failure, Continue_with_phone_request, Continue_with_phone_success } from "../../reducers/Continuewithphone/ContinuewithphoneSlice"
import { Otp_verified_failure, Otp_verified_request, Otp_verified_success } from "../../reducers/Continuewithphone/Otpverified"
import { AddLoginCartAPI } from "../LoginCart/LoginCartAction"
import { GetLoginCartAPI } from "../LoginCart/GetLoginCartAction"


export const ContinuewithphoneAPI = (payload) => async (dispatch, getState) => {
    dispatch(Continue_with_phone_request())
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const payloads = {
        phone_number: payload.phone_number,
        country_code: companydata?.company_code
      }
    // console.log("Continueasguest",payloads)
    
    try {
        // const addtocart= localStorage.getItem("addtocart")

        const { data } = await axios.post(`${APIURL.continuewithphone}`, payloads, {
            headers: Headers
        })
        localStorage.setItem("loginphonenumber", payload.phone_number)
        dispatch(Continue_with_phone_success(data))
        // console.log("continuouswithphone", data)
     
    } catch (error) {
        dispatch(Continue_with_phone_failure(error?.response?.data))
        // console.log("Continueasguest",error?.response?.data)
    }
}


export const ContinuewithphoneOTPverifiedAPI = (payload) => async (dispatch, getState) => {
    dispatch(Otp_verified_request())
   const loginphonenumber= localStorage.getItem("loginphonenumber")
    const payloads = {
        phone_number: loginphonenumber,
        otp: payload
      }
    // console.log("otpverified",payloads)
    try {
        const addtocart= localStorage.getItem("addtocart")

        const { data } = await axios.post(`${APIURL.otpverified}`, payloads, {
            headers: Headers
        })
        dispatch(Otp_verified_success(data))
        // console.log("otpverified", data)
        localStorage.setItem("Logindetails",JSON.stringify(data))
        dispatch(AddLoginCartAPI(JSON.parse(addtocart)))
        dispatch(GetLoginCartAPI())
    } catch (error) {
        dispatch(Otp_verified_failure(error?.response?.data))
        // console.log("otpverified",error?.response?.data)
    }
}