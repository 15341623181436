import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from "react-router-dom";
const Navbar = (props) => {
    const { classes, cancelHandler } = props;
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const Continueasguest = localStorage.getItem("Continueasguest")
    const Logindetails = localStorage.getItem("Logindetails")
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const { t } = useTranslation();
    const handlelogin = () => {
        localStorage.setItem("loginredirectstatus", 1)
    }
    return (
        <>
            <div className={classes.navbar}>
                <ul>
                    <li className={splitLocation[1] === "" ? `${classes.active}` : ""}>
                        <NavLink to="/" onClick={cancelHandler}>{t("Home")}</NavLink>
                    </li>
                    <li className={splitLocation[1] === "menu" ? `${classes.active}` : ""}>
                        <NavLink to="/" onClick={cancelHandler}>{t("Menu")}</NavLink>
                    </li>
                    {!Logindetails && <li className={splitLocation[1] === "login" ? `${classes.active}` : ""} onClick={() => { handlelogin() }}>
                        <NavLink to="/login" onClick={cancelHandler}>{t("Register / Sign in")}</NavLink>
                    </li>}
                    {Logindetails && <>
                        {!Continueasguest && <li className={splitLocation[1] === "profile" ? `${classes.active}` : ""}>
                            <NavLink to="/profile" onClick={cancelHandler}>{t("Profile")}</NavLink>
                        </li>}
                        <li className={splitLocation[1] === "reward" ? `${classes.active}` : ""}>
                            <NavLink to="/reward" onClick={cancelHandler}>{t("Rewards")}</NavLink>
                        </li>
                        <li className={splitLocation[1] === "order-history" ? `${classes.active}` : ""}>
                            <NavLink to="/order-details" onClick={cancelHandler}>{t("Order History")}</NavLink>
                        </li>
                        <li className={splitLocation[1] === "notifications" ? `${classes.active}` : ""}>
                            <NavLink to="/notifications" onClick={cancelHandler}>{t("Notification")}</NavLink>
                        </li>
                        <li className={splitLocation[1] === "reservation" ? `${classes.active}` : ""}>
                            <NavLink to="/reservation" onClick={cancelHandler}>{t("Reservation")}</NavLink>
                        </li>
                        {companydata?.discount_type == "flashdeal" && <> <li className={splitLocation[1] === "flashDeal" ? `${classes.active}` : ""}>
                            <NavLink to="/flashDeal" onClick={cancelHandler}>{t("Flash deals")}</NavLink>
                        </li>
                            <li className={splitLocation[1] === "coupon-list" ? `${classes.active}` : ""}>
                                <NavLink to="/coupon-list" onClick={cancelHandler}>{t("My coupons")}</NavLink>
                            </li>
                        </>
                        }
                    </>
                    }
                </ul>
                <ul className={classes.subMenu}>
                    <li className={splitLocation[1] === "how-it-works" ? `${classes.active}` : ""}>
                        <NavLink to="/how-it-works" onClick={cancelHandler}>{t("How it works")}</NavLink>
                    </li>
                    {/* <li className={splitLocation[1] === "reward" ? `${classes.active}` : ""}>
                        <NavLink to="/reward" onClick={cancelHandler}>Rewards</NavLink>
                    </li> */}
                    <li className={splitLocation[1] === "faqs" ? `${classes.active}` : ""}>
                        <NavLink to="/faqs" onClick={cancelHandler}>{t("FAQs")}</NavLink>
                    </li>
                    <li className={splitLocation[1] === "our-story" ? `${classes.active}` : ""}>
                        <NavLink to="/our-story" onClick={cancelHandler}>{t("Our Story")}</NavLink>
                    </li>
                    <li className={splitLocation[1] === "get-in-touch" ? `${classes.active}` : ""}>
                        <NavLink to="/get-in-touch" onClick={cancelHandler}>{t("Get in touch")}</NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default withStyles(Styles)(Navbar);
