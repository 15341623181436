import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    loginWrapper: {
        // height: 'calc(100% - 400px)',
        display: 'flex',
        alignItems: 'center',
        "@media(max-width:640px)": {
            height: 'auto',
            display: 'block',
        },
        "@media(max-height:768px)": {
            height: 'auto',
        }
    },
    accOuter: {
        width: '100%',
        // maxHeight: '400px',
        margin: '0 auto',
        padding: '2rem',
        boxSizing: 'border-box',
        borderRadius: '25px',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: ColorTheme.lightBrown,
        overflow: 'auto',
        '@media(max-width:640px)': {
            background: ColorTheme.transparent,
            padding: '0',
            height: 'auto',
            maxWidth: '100%',
            minHeight: '150px',
            // marginBottom: '4rem',
            overflow: 'inherit',
        },
        "&::-webkit-scrollbar": {
            width: '1px'
          }
    },
    signuoaccOuter: {
        width: '100%',
        // maxHeight: '400px',
        margin: '0 auto',
        padding: '2rem',
        boxSizing: 'border-box',
        borderRadius: '25px',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: ColorTheme.lightBrown,
        overflow: 'auto',
        '@media(max-width:640px)': {
            background: ColorTheme.transparent,
            padding: '0',
            height: 'auto',
            maxWidth: '100%',
            minHeight: '150px',
            // marginBottom: '4rem',
            overflow: 'inherit',
        }
    },
    Input:{
        borderRadius: "10px !important",
        backgroundColor: `${ColorTheme.orange5} !important`,
        borderColor: `${ColorTheme.orange5} !important`,
        "&.Mui-focused":{
            borderColor: `${ColorTheme.orange} !important`,
            backgroundColor: `${ColorTheme.white} !important`,
        }
    },
    inputContainer: {
        position: "relative"
      },
    eyeIcon:{
        position: "absolute",
        top: "65%",
        right: "10px",/* Adjust as needed */
        transform: "translateY(-50%)",
        cursor: "pointer"
    },
    haveTxt: {
        display: "flex",
        justifyContent: "center",
        padding: "40px 0",
        "& p": {
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            margin: "0",
            fontFamily: "'Noto Serif', serif",
            paddingRight: "5px",
        },
        "& a": {
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            textDecoration: "underline",
        },
    },
    firstName: {
        "@media(max-width:375px)": {
            display: 'block',
        },
        "& > div": {
            width: '100%',
            // marginBottom: '15px',
            "@media(max-width:375px)": {
                // marginBottom: '25px',
            },
            "&:first-child": {
                marginRight: '20px',
                "@media(max-width:375px)": {
                    marginRight: '0',
                },
            }
        },
    },
    bottommargin:{
        marginBottom:'10px'
    }
})