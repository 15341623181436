import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useState } from 'react';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import { Login_Drawer_success } from '../../redux/reducers/LoginDrawer';
const RoundedDrawer = (props) => {
    const { classes, children, cancelHandler,opendrawer,truekey ,setruekey} = props
    const [desktop, setDesktop] = useState(window.innerWidth > 640);
    const drawerslice = useSelector(state => state?.DrawerSlice?.value)
    const Logindrawer = useSelector(state => state?.LoginDrawerSlice?.value)
    const dispatch = useDispatch();
    const [draweropen, setdraweropen] = useState(false)
    const [state, setState] = React.useState({
        bottom: false,
    });

    // console.log("truekey",truekey)
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if(truekey!=undefined && truekey!=null && setruekey!=null && setruekey!=undefined){
            setdraweropen(false)

            setruekey(false)
        }
        dispatch(Drawer_success(false));
        dispatch(Login_Drawer_success(false))
    };
    React.useEffect(() => {
        setdraweropen(true)
    }, [])
    React.useEffect(() => {
        setdraweropen(drawerslice)
    }, [drawerslice])
    React.useEffect(() => {
        setdraweropen(Logindrawer)
    }, [Logindrawer])
 
    React.useEffect(()=>{
        if(truekey!=undefined && truekey!=null){

            setdraweropen(truekey)
        }

    },[truekey])
    // console.log(draweropen, drawerslice, "drawerslice")
    const mobileWidth = () => {
        if (window.innerWidth <= 640) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
    return (
        <div>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer anchor={anchor} open={draweropen}
                        cancelHandler={toggleDrawer(anchor, false)}
                        ModalProps={{ onBackdropClick: toggleDrawer(anchor, false) }}
                        onClose={toggleDrawer(anchor, false)} className={`${classes.drawerOuter} ${CommonStyle.drawerRounded} ${Logindrawer ? `${CommonStyle.drawerRounded}` : ""}`}>
                        <div className={`${CommonStyle.scrollBox} ${classes.modallInner}`}>
                            <span className={classes.border36}></span>
                            <span className={classes.border20}></span>
                            {children}
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
export default withStyles(Styles)(RoundedDrawer);
