import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    back: {
        "& button": {
            height: "auto",
            margin: "0 0 20px",
            padding: "0 !important",
            minWidth: "auto",
            display: "flex",
            alignItems: "center",
            "& span": {
                display: "inline-block",
                lineHeight: "normal",
                paddingLeft: "12px",
                color: ColorTheme.fontColor,
            },
            "& img": {
                height: "auto",
                width: "100%",
                maxWidth: "50px",
                objectFit: "cover",
            }
        }
    },
})