import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const CategoryFaqSlice = createSlice({
    name: 'CategoryFaq',
    initialState,
    reducers: {

        CategoryFaq_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        CategoryFaq_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        CategoryFaq_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_CategoryFaq_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  CategoryFaq_request, CategoryFaq_success,CategoryFaq_failure, Clear_CategoryFaq_Cart } = CategoryFaqSlice.actions

export default CategoryFaqSlice.reducer