import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColorTheme from "../../Common/CommonCss/ColorTheme";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { TimeSlotCheckAPI } from '../../redux/Action/TimeSlotCheck/TimeSlotCheck';
const names = [
    '10:00  -  10:15',
    '11:00  -  10:15',
];

// const MenuProps = {
//     disableScrollLock: true
// };

function CommonTimeSlot(props) {
    const { classes, timeSlots } = props;
    const theme = useTheme();
    const dispatch = useDispatch()
    const [personName, setPersonName] = React.useState([]);
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const outletID = localStorage.getItem("outletID")
    const timeslot=localStorage.getItem("timeslot")
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
        const obj = {
            timeslot: typeof value === 'string' ? value.split(',')[0] : value[0],
            outlet_id: addtocartlogin?.value?.data?.outlet?.outlet_id ? addtocartlogin?.value?.data?.outlet?.outlet_id : outletID

        }
        localStorage.setItem("timeslot", obj?.timeslot)
        dispatch(TimeSlotCheckAPI(obj))
    };
    useEffect(()=>{
        setPersonName(typeof timeslot === 'string' ? timeslot.split(',') : timeslot)
    },[])
    // useEffect(() => {

    // }, [personName, addtocartlogin])

    // console.log("addtocartlogin", personName, timeSlots, addtocartlogin?.value?.data?.outlet?.outlet_id, outletID)
    return (
        <>
            <h3 className={CommonStyle.mb20}> Time slot</h3>
            <div className={classes.timeSlotBox}>
                {timeSlots?.length > 0 ?
                    <Select
                        displayEmpty
                        value={personName}
                        onChange={handleChange}
                        MenuProps={
                            {
                                sx: {
                                    "&& .Mui-selected": {
                                        backgroundColor: `${ColorTheme.yellow} !important`,
                                        color: ColorTheme.secondaryColor,
                                    }
                                },
                                disableScrollLock: true
                            }
                        }
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                            if (selected?.length === 0) {
                                return <p>Please Select Time</p>;
                            }
                            return selected?.join(', ');
                        }}>
                        {(timeSlots != undefined) && timeSlots?.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}>
                                {name}
                            </MenuItem>
                        ))
                        }
                    </Select>
                    :

                    <MenuItem
                        key=""
                        value=""
                    >
                        No Available Timeslot.
                    </MenuItem>
                }
            </div>
        </>
    );
}

export default withStyles(Styles)(CommonTimeSlot);
