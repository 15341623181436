import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: true,

}

export const HeaderCheckSlice = createSlice({
    name: 'HeaderCheck',
    initialState,
    reducers: {

        Header_check_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Header_check_success } = HeaderCheckSlice.actions

export default HeaderCheckSlice.reducer