import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CustomCheckbox from "../../Components/CustomCheckbox/CustomCheckbox";
import CustomButton from '../../Components/CustomButton/CustomButton';
import { FormLabel, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContinuewithphoneAPI } from "../../redux/Action/Conntinuewithphone/ContinuewithphoneSlice";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail, validatePhone } from "../../Common/Validations";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import Loader from "../../Components/Loader/Loader";
import { useTranslation } from 'react-i18next';
import Box from '@mui/joy/Box';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';
import { EmailSigninAPI } from "../../redux/Action/Signin/Signin";
import { Clear_Signin_Cart } from "../../redux/reducers/Signin/SigninSlice";
import { Clear_Signup_Cart } from "../../redux/reducers/Signin/SignupSlice";
import ColorTheme from "../../Common/CommonCss/ColorTheme";
const Login = (props) => {
    const { classes } = props;
    const [loginnumber, setloginnumber] = useState("")
    const [emailaddress, setemailaddress] = useState("")
    const [password, setpassword] = useState("")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errormsg, seterrmsg] = useState(false)
    const [emailerrormsg, setemailerrmsg] = useState(false)
    const [passworderrormsg, setpassworderrmsg] = useState(false)
    const Continuewithphone = useSelector(state => state?.ContinuewithphoneSlice?.value)
    const Continuewithphoneslice = useSelector(state => state?.ContinuewithphoneSlice)
    const SigninSlice = useSelector(state => state?.SigninSlice)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const [isOpen, setIsOpen] = useState(false);
    const [isOpensignin, setIsOpensignin] = useState(false);
    const { t } = useTranslation();
    const [justify, setJustify] = useState('Phone Number');
    const SignupSlice = useSelector(state => state?.SignupSlice)
    const [signupmsg, setsignupmsg] = useState("")
    const [signupmsgopen, setsignupmsgopen] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handlesubmitlogin = () => {
        if (justify == "Phone Number") {

            if (validatePhone(loginnumber)) {
                return seterrmsg(true)
            } else {


                const payload = {
                    phone_number: loginnumber,
                    country_code: companydata?.company_code
                }
                dispatch(ContinuewithphoneAPI(payload))
            }
        } else if (justify == "Email Address") {
            if (validateEmail(emailaddress) || password == "") {
                if (validateEmail(emailaddress)) {

                    setemailerrmsg(true)
                }
                if (password == "") {

                    setpassworderrmsg(true)
                }
            } else {
                const payload = {
                    email: emailaddress,
                    password: password
                }
                dispatch(EmailSigninAPI(payload))
            }
        }
    }
    // console.log("Continuewithphone", Continuewithphoneslice)
    useEffect(() => {
        if (Continuewithphone?.success == true) {
            navigate("/verify-otp")
        } else if (Continuewithphoneslice?.error?.message) {
            setIsOpen(true)
        }


    }, [Continuewithphone, Continuewithphoneslice])
    const handleKeyDown = (event) => {
        if (event.keyCode === 69) {
            event.preventDefault();
        }
    };
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    useEffect(() => {
        if (SigninSlice?.error?.message) {
            setIsOpen(true)
            setTimeout(() => {
                dispatch(Clear_Signin_Cart())
            }, 3000)
        } else if (SigninSlice?.value?.message) {
            setIsOpen(true)
            console.log("SigninSliceSigninSlice")
            setTimeout(() => {
                dispatch(Clear_Signin_Cart())
                navigate("/")
            }, 1000)
        }
    }, [SigninSlice])
    useEffect(() => {
        if (SignupSlice?.value?.message) {
            setsignupmsgopen(true)
            setsignupmsg(SignupSlice?.value?.message)
            console.log("SigninSliceSigninSlice")
            dispatch(Clear_Signup_Cart())
            setTimeout(() => {
                setsignupmsg('')
                setsignupmsgopen(false)
            }, 3000)
        }
    }, [SignupSlice])
    console.log("SigninSlice", SigninSlice)
    const handleForgotPage = () => {
        navigate("/forget-password")
    }
    return (
        <>
            {
                (Continuewithphoneslice?.error?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={Continuewithphoneslice?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                (SigninSlice?.error?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={SigninSlice?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                (SigninSlice?.value?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={SigninSlice?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={isOpen}
                    />
                )
            }
            {
                (signupmsgopen) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={signupmsg}
                        title={"Success"}
                        type={"Success"}
                        open={signupmsgopen}
                    />
                )
            }

            {
                (Continuewithphoneslice?.loading || SigninSlice?.loading) && (
                    <Loader />
                )
            }
            <ContainerBox>
                <div className={classes.loginWrapper}>
                    <div className={classes.accOuter}>
                        <div className={classes.accInner}>

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                <h2 className={CommonStyle.mb20}>{t("Login")}</h2>
                                <h5 className={CommonStyle.mb20} style={{ cursor: "pointer" }} onClick={() => handleForgotPage()}>{t("Forgot Password")}</h5>
                            </div>
                            <FormLabel>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="segmented-controls-example"
                                        name="justification"
                                        value={justify}
                                        onChange={(event) => {
                                            setJustify(event.target.value);
                                            setemailerrmsg(false);
                                            seterrmsg(false);
                                            setpassworderrmsg(false);
                                        }}
                                        sx={{
                                            display: 'flex !important',
                                            flexDirection : 'row !important',
                                            height : '50px',
                                            width: '100%',
                                            bgcolor: 'neutral.softBg',
                                            '--RadioGroup-gap': '4px',
                                            '--Radio-actionRadius': '8px',
                                            borderRadius: '26px'
                                        }}
                                        >
                                        {['Phone Number', 'Email Address'].map((item) => (
                                            <FormControlLabel
                                            key={item}
                                            value={item}
                                            control={<Radio
                                                color="neutral"
                                                disableIcon
                                                variant="plain"
                                                sx={{
                                                display: 'none',
                                                }}
                                            />}
                                            label={item}
                                            sx={{
                                                flex : 1, 
                                                width : '100%',
                                                display: 'unset !important',
                                                margin: '0 auto',
                                            borderRadius: '26px',

                                                color: item === justify ? 'white' : '',
                                                backgroundColor: item === justify ? ColorTheme.yellow : "",
                                                '&:hover': {
                                                backgroundColor: item === justify ? ColorTheme.yellow: 'neutral.softBg',
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                    color: item === justify ? 'white' : 'inherit',
                                                    height : '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontWeight : 'bold'
                                                }
                                            }}
                                            />
                                        ))}
                                        </RadioGroup>
                                    </Box>
                                </FormLabel>
                           
                            {justify == "Phone Number" && <>
                                <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem} ${CommonStyle.mt20}`}>
                                    <div className={CommonStyle.flexSpaceBetween}>
                                        <FormLabel>Contact Number*</FormLabel>
                                    </div>
                                    <input type="number" name="number" placeholder={t("Enter your mobile no.")} value={loginnumber} onChange={(e) => {
                                        seterrmsg(false)
                                        setloginnumber(e.target.value)
                                    }}
                                        onKeyDown={handleKeyDown}
                                        onInput={(e) => {
                                            if (e?.target?.value != "") {
                                                e.target.value = Math.max(0, parseInt(e.target.value))
                                                    .toString()
                                                    .slice(0, 10);
                                            }
                                        }}
                                    />
                                </div>

                                {errormsg && (
                                    <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        {StrConstent.login.errorMsg.phoneNumberValid}
                                    </span>
                                )}

                                <label className={CommonStyle.customCheckContainer}>
                                    {/* <CustomCheckbox /> <span>Keep me logged in</span> */}
                                </label>
                            </>}
                            {justify == "Email Address" && <>
                                <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem}  ${CommonStyle.mt20}`}>
                                    <div className={CommonStyle.flexSpaceBetween}>
                                        <FormLabel>Email address*</FormLabel>
                                    </div>
                                    <input type="text" name="email" placeholder={t("Enter your Email Address.")} value={emailaddress} onChange={(e) => {
                                        setemailerrmsg(false)
                                        setemailaddress(e.target.value)
                                    }}

                                    />
                                </div>

                                {emailerrormsg && (
                                    <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        {StrConstent.login.errorMsg.emailNumberValid}
                                    </span>
                                )}
                               
                                <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem} ${classes.inputContainer}  ${CommonStyle.mt20}`}>
                                <FormLabel>{t("Password*")}</FormLabel>
                                <input type={showConfirmPassword ? "text" : "password"} name="password" placeholder={t("Password")} value={password} onChange={(e) => {
                                     setpassworderrmsg(false)
                                        setpassword(e.target.value)
                                }}
                                    // className={`${classes.Input} form-input`}
                                />
                                <span className={`${classes.eyeIcon} icon`} onClick={() => setShowConfirmPassword(!showConfirmPassword)} >
                                    {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </span>

                            </div>

                                {passworderrormsg && (
                                    <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        Please Enter valid Password.
                                    </span>
                                )}


                            </>}
                        </div>
                        <div className={`${CommonStyle.mt1rem} ${CommonStyle.fullBtn}`}>
                            <CustomButton label={t("Log in")} clickHandler={() => handlesubmitlogin()} />
                        </div>
                        {justify == "Email Address" && <div className={classes.haveTxt}>
                            <p>Don’t have an account?</p>
                            <Link to="/sign-up"> Sign up here </Link>
                        </div>}
                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(Login);