import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    header: {
        padding: '15px 0',
        position: 'fixed',
        width: '100%',
        top: '0',
        left: '0',
        right: '0',
        zIndex: '99',
        background: ColorTheme.white,
    },
    headerInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
    },
    logo: {
        maxWidth: '180px',
        cursor:"pointer"
    },
    homeIcon: {
        height: '50px',
        width: '50px',
        borderRadius: '100%',
        background: ColorTheme.yellow,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor:"pointer",
        "& img": {
            maxWidth: '35px',
        }
    },
    backBtn:{
        "& button":{
            minWidth:'100px',
            padding:'10px !important',
        }
    }
})