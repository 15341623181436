import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BackArrow from "../../Components/BackArrow/BackArrow";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import { useNavigate } from "react-router";
import LargeBtn from "../../Components/LargeBtn/LargeBtn";
import OrderBox from "../../Components/OrderBox/OrderBox";
import { NavLink } from "react-router-dom";
import BottomDrawer from "../../Components/BottomDrawer/BottomDrawer";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import { Login_Drawer_success } from "../../redux/reducers/LoginDrawer";
import { useEffect, useState } from "react";
import RoundedDrawer from "../../Components/BottomDrawer/RoundedDrawer";
import CustomButton from "../../Components/CustomButton/CustomButton";
import BorderButton from "../../Components/BorderButton/BorderButton";
import { FormLabel } from "@mui/material";
import { ContinueasguestAPI } from "../../redux/Action/Continueasguest/Continueasguest";
import { GetLoginCartAPI } from "../../redux/Action/LoginCart/GetLoginCartAction";
import Loader from "../../Components/Loader/Loader";
import { Clear_Modifier } from "../../redux/reducers/Modifier/ModifierSlice";
import RecommendedItem from "../../Components/RecommendedItem/RecommendedItem";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import { Clear_Login_Cart } from "../../redux/reducers/LoginCart/GetLoginCartSlice";
import moment from "moment";
import { OutletScheduleAPI } from "../../redux/Action/OutletSchedule/OutletSchedule";
import TimeFormate from "./TimeFormate";
import CommonTimeSlot from "../../Components/CommonTimeSlot/CommonTimeSlot";
import { RecomendedOrderAPI } from "../../redux/Action/RecomendedOrder/RecomendedOrder";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { Clear_Login_Cart_failure } from "../../redux/reducers/LoginCart/LoginCartSlice";
import { TimeSlot_Check_Cart } from "../../redux/reducers/TimeSlotCheck/TimeSlotCheck";
import { useTranslation } from 'react-i18next';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FlashDealCouponListAPI, FlashDealRemoveCouponAPI } from "../../redux/Action/FlashDeal/FlashDeal";
const Cart = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const Logindrawer = useSelector(state => state?.LoginDrawerSlice?.value)
    const [signdrawer, setsigndrawer] = useState(false);
    const [desktop, setDesktop] = useState();
    // const [guestModal, setGuestModal] = useState(false);
    const [paymaentModal, setPaymaentModal] = useState(false);
    const addtocart = localStorage.getItem("addtocart")
    const [cartitem, setcartitem] = useState([]);
    const [guestnumber, setguestnumber] = useState("")
    const Continueasguest = useSelector(state => state?.ContinueasguestSlice?.value)
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const Logindetails = localStorage.getItem("Logindetails");
    const UpdateLoginCart = useSelector(state => state?.UpdateLoginCartSlice?.value)
    const [nodata, setnodata] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [withoutlogintotalamount, setwithoutlogintotalamount] = useState(0)
    const StateChangeSlice = useSelector(state => state?.StateChangeSlice?.value)
    const Totalamountwithoutlogin = useSelector(state => state?.TotalamountwithoutloginSlice?.value)
    const OutletSchdeule = useSelector(state => state?.OutletSchdeuleSlice)
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    const outletID = localStorage.getItem("outletID")
    const RecomendedOrder = useSelector(state => state?.RecomendedOrderSlice)
    const TimeSlotCheck = useSelector(state => state?.TimeSlotCheckSlice)
    const outlet_mode = localStorage.getItem("outlet_mode")
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const [error, sererror] = useState("")
    const device = useBreakpoint();
    const addtocarts = addtocart ? JSON.parse(addtocart) : []
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const { t } = useTranslation();
    const timeslot = localStorage.getItem("timeslot")
    const [guestlengtherror, setguestlengtherror] = useState(false);
    const [guesterror, setguesterror] = useState(false);

     const cart_identifier = localStorage.getItem("cart_identifier")
     const coupon_codeflash = localStorage.getItem("coupon_codeflash")
     const promotion_idflash = localStorage.getItem("promotion_idflash")
     const [timesloterror,settimesloterror]=useState(false)
     useEffect(()=>{
        if(promotion_idflash !=null && promotion_idflash && coupon_codeflash!=null && coupon_codeflash){

            const obj = {
                coupon_code: coupon_codeflash,
                promotion_id: promotion_idflash,
                cart_id: cart_identifier
            }
            dispatch(FlashDealRemoveCouponAPI(obj))
        }
     },[])
    useEffect(() => {
        if (outletID) {
            dispatch(OutletScheduleAPI())
        }
        dispatch(Clear_Login_Cart_failure())
        dispatch(Clear_Modifier())
        if (Logindetail) {

            dispatch(GetLoginCartAPI())
        }
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))
        setnodata(addtocartlogin?.value ? addtocartlogin?.value : (addtocart ? JSON.parse(addtocart) : []))
    }, [])

    function SampleNextArrow(props) {
        console.log(props);
        const { className, style, onClick } = props;
        return (
            <div
                className={`${classes.arrow} ${classes.arrowLeft}`}
                onClick={onClick}><ArrowBackIosNewIcon />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${classes.arrow} ${classes.arrowRight}`}
                onClick={onClick}><ArrowForwardIosIcon />
            </div>
        );
    }

    var settings = {
        dots: false,
        arrows: true,
        infinite: RecomendedOrder?.value?.data?.length > 3 ? true : false,
        slidesToShow: RecomendedOrder?.value?.data?.length > 3 ? 4 : 3,
        speed: 500,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '40px 0 0 0',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: RecomendedOrder?.value?.data?.length >= 2 ? 2 : 1,
                    infinite: true,
                    centerPadding: '20px 0 0 0',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '15px 0 0 0',
                    slidesToShow: 1,
                    infinite: true,
                }
            }
        ]
    };
    const handleKeyDown = (event) => {
        if (event.keyCode === 69 || event.keyCode == 45 || event.keyCode == 95 || event.key == "-") {
            event.preventDefault();
        }
    };

    useEffect(() => {
        // dispatch(ContinueasguestAPI())
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))
        setnodata(addtocartlogin?.value ? addtocartlogin?.value : (addtocart ? JSON.parse(addtocart) : []))
    }, [addtocartlogin, UpdateLoginCart])
    console.log("timeslotcheck", TimeSlotCheck?.value?.success, timeslot)
    const handleNavigate = () => {
        const logincheck = (Logindetails && Logindetails != undefined) ? true : false
        // setGuestModal(true)
        // console.log("vikalp",nodata?.data?.order_mode)

        if (nodata?.data?.order_mode == "Schedule Pick-Up" || outlet_mode == "Schedule Pick-Up") {
            if (logincheck) {
                if ((TimeSlotCheck?.value?.success == true && TimeSlotCheck?.value?.success != undefined && TimeSlotCheck?.value?.success != null) && (timeslot && timeslot != null && timeslot != undefined)) {
                    sererror(false)
                    navigate("/proceed-to-pay")
                    settimesloterror(false)
                } else if(timeslot==""||timeslot==null){

                    sererror(true)
                    settimesloterror(false)
                }
                 else {
                    sererror(false)
                    settimesloterror(true)
                }
            } else {
                console.log("vikalp", TimeSlotCheck?.value?.success, nodata)
                if ((TimeSlotCheck?.value?.success == true && TimeSlotCheck?.value?.success != undefined && TimeSlotCheck?.value?.success != null) && (timeslot && timeslot != null & timeslot != undefined)) {
                    sererror(false)
                    setPaymaentModal(false);
                    dispatch(Drawer_success(true))
                    dispatch(Login_Drawer_success(true))
                    setsigndrawer(true)
                    settimesloterror(false)
                }
                else if (TimeSlotCheck?.value?.success == undefined && (timeslot=="" ||timeslot==null)) {
                    sererror(true)
                    settimesloterror(false)
                } else {
                    sererror(false)
                    settimesloterror(true)

                    // setPaymaentModal(false);
                    // dispatch(Drawer_success(true))
                    // dispatch(Login_Drawer_success(true))
                    // setsigndrawer(true)

                }
            }
        }
        else {


            if (logincheck) {
                sererror(false)
                settimesloterror(false)
                navigate("/proceed-to-pay")
            } else {

                setPaymaentModal(false);
                dispatch(Drawer_success(true))
                dispatch(Login_Drawer_success(true))
                setsigndrawer(true)
            }
        }
    }


    useEffect(() => {
        if (Totalamountwithoutlogin?.length > 0) {


            const arr = Totalamountwithoutlogin?.map((item, i) => {
                return (item?.item_price * item?.itemQuantity)
            }).reduce((data, i) => data + i)
            // console.log("arr", arr)
            setwithoutlogintotalamount(arr)
        } else {
            setwithoutlogintotalamount(0)
        }
    }, [Totalamountwithoutlogin])
    useEffect(() => {
        dispatch(Drawer_success(false))
        const addtocarts = addtocart ? JSON.parse(addtocart) : []
        if (addtocarts?.length > 0) {


            const arr = addtocarts?.map((item, i) => {
                return (item?.item_price * item?.itemQuantity)
            })?.reduce((data, i) => data + i)
            // console.log("arr", arr)
            setwithoutlogintotalamount(arr)
        } else {
            setwithoutlogintotalamount(0)
        }
    }, [])

    useEffect(() => {
        if (addtocartlogin?.error?.message) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [addtocartlogin])

    const handleClickguest = () => {
        // setGuestModal(false);
        setsigndrawer(false);
        setPaymaentModal(true);
    }

    const handlecontinueasguestsubmit = () => {
        if (guestnumber?.length >= 8 && guestnumber?.length <= 10) {

            dispatch(ContinueasguestAPI(guestnumber))
            setguesterror(false)
            setguestlengtherror(false)
        } else if (guestnumber?.length < 8 || guestnumber?.length > 10) {
            setguesterror(false)
            setguestlengtherror(true)

        } else if (guestnumber == "") {
            setguesterror(true)
            setguestlengtherror(false)

        }
        // console.log("guestnumber", guestnumber)
        // navigate("/proceed-to-pay")
    }
    useEffect(() => {
        // console.log("Continueasguest", Continueasguest)
        if (Continueasguest?.success == true) {
            localStorage.setItem("Continueasguest", JSON.stringify(Continueasguest))
            navigate("/proceed-to-pay")
        }
    }, [Continueasguest])

    useEffect(() => {
        localStorage.setItem("grandTotal", addtocartlogin?.value?.data?.grand_total)
    }, [addtocartlogin])
    // console.log("UpdateLoginCart", nodata)
    useEffect(() => {
        if (LoginCartSlice?.value?.success) {
            dispatch(Clear_Login_Cart_failure())
        }
    }, [LoginCartSlice])


    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        // console.log("vikalpvikalp")
        setIsOpen(false);
        dispatch(TimeSlot_Check_Cart())
    };
    // console.log("RecomendedOrder", RecomendedOrder)
    useEffect(() => {
        const arr = []
        const cartid = cartitem != undefined && cartitem?.map((itemid) => {
            arr.push(itemid?.id)
        })
        const obj = {
            itemId: arr,
            outletID: addtocartlogin?.value?.data?.outlet?.outlet_id
        }
        if (cartid?.length > 0 && cartitem != undefined && Logindetail) {

            dispatch(RecomendedOrderAPI(obj))
        }
        // console.log("arrrr", addtocartlogin?.value?.data)
    }, [cartitem])

    // console.log("TimeSlotCheck", outlet_mode)

    const mobileWidth = () => {
        if (window.innerWidth <= 991) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", mobileWidth);
        return () => window.removeEventListener("scroll", mobileWidth);
    })
    const phonemethod = () => {
        return <>
            <div className={classes.topBox}>
                <div className={classes.productImg}>
                    <img src="./images/product-1.png" alt="Not Found" />
                </div>
                <div className={classes.spacingTop}>
                    <CustomButton variant="text" label={t("Login")} clickHandler={() => {
                        localStorage.setItem("loginredirectstatus", 2)
                        navigate("/login")
                    }} />
                </div>
                {/* <p classes={CommonStyle.flexJustifyCenter} onClick={() => { localStorage.setItem("loginredirectstatus", 2) }}>{t("New here?")} <NavLink to="/login" className={CommonStyle.links}>{t("Register")}</NavLink> {t("to get $5 off your first order")}</p> */}
            </div>
            <div className={classes.bottomBox}>
                <div className={classes.productImg}>
                    <img src="./images/product-12.png" alt="Not Found" />
                </div>
                <div className={classes.spacingTop}>
                    <BorderButton label={t("Continue as guest")} clickHandler={() => handleClickguest()} />
                </div>
            </div>
        </>
    }
    useEffect(() => {
        if (TimeSlotCheck?.error?.message) {
            setIsOpen(true)

        } else {
            sererror(false)
            settimesloterror(false)
            setIsOpen(false)
        }
    }, [TimeSlotCheck])


    useEffect(() => {
        dispatch(FlashDealCouponListAPI(outletID))
    }, [])
    // console.log("TimeSlotCheck",TimeSlotCheck, nodata, addtocartlogin)
    return (
        <>
            {
                addtocartlogin?.loading == true && (
                    <Loader />
                )
            }
            {
                addtocartlogin?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}

                        description={addtocartlogin?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                (TimeSlotCheck?.error?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={TimeSlotCheck?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                RecomendedOrder?.loading == true && (
                    <Loader />
                )
            }

            <ContainerBox>
                <div className={classes.cartOuter}>
                    {
                        isappwebviewcheck != "acecard" && (
                            <BackArrow clickHandler={() => navigate("/")} />
                        )
                    }
                    {
                        (nodata?.data?.order_mode == "Schedule Pick-Up" || outlet_mode == "Schedule Pick-Up") && (
                            <>
                                <TimeFormate />
                            </>
                        )
                    }
                    {error == true && (
                        <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>Please Select TimeSlot.</p>
                    )}
                    {timesloterror == true && (
                        <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>Please Select Different TimeSlot.</p>
                    )}
                    <h2 className={`${CommonStyle.mb20} ${CommonStyle.mt20}`}>{t("Cart")}</h2>
                    {Logindetail && nodata?.data?.cart_items?.length == 0 ?
                        <div className={CommonStyle.nodata}>
                            <h4>{t("No Cart Item")}</h4>
                        </div>
                        :
                        !Logindetail && addtocarts?.length == 0 ?
                            <div className={CommonStyle.nodata}>
                                <h4>{t("No Cart Item")}</h4>
                            </div>
                            :
                            <div className={classes.cartInner}>
                                {cartitem?.map((data) => (
                                    <OrderBox key={data?.cart_item_id} url={data?.url} heading={data?.name} description={data} price={data?.item_price} quantity={data?.itemQuantity} />
                                ))}
                            </div>
                    }
                    {/* <NavLink
                        to="/" >label={StrConstent.buttonTxt.addMoreItems.heading}</NavLink> */}
                    <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.my3rem} ${CommonStyle.JustifyEnd}`}>
                        <CustomButton label={t("Add More Items")} clickHandler={() => navigate("/")} />
                    </div>
                    {RecomendedOrder?.value?.data?.length > 0 && (
                        <h2 className={`${CommonStyle.mt2rem} ${CommonStyle.mb20}`}>{t("Recommended")}</h2>
                    )
                    }
                    {desktop ? (<>
                        {RecomendedOrder?.value?.data?.length > 3 ?
                            <Slider {...settings}>
                                {RecomendedOrder?.value?.data?.map((data) => (
                                    <RecommendedItem key={data?.cart_item_id} url={data?.url} heading={data?.name} price={data?.item_price} />
                                ))}
                            </Slider>
                            :
                            <div className={`${classes.orderContainer} ${CommonStyle.flexCenterStart}`}>
                                {RecomendedOrder?.value?.data?.map((data) => (
                                    <RecommendedItem key={data?.cart_item_id} url={data?.url} heading={data?.name} price={data?.item_price} />
                                ))}
                            </div>
                        }
                    </>
                    ) : (
                        <>
                            <Slider {...settings}>
                                {RecomendedOrder?.value?.data?.map((data) => (
                                    <RecommendedItem key={data?.cart_item_id} url={data?.url} heading={data?.name} price={data?.item_price} />
                                ))}
                            </Slider>
                        </>
                    )}
                    {/* <div className={CommonStyle.my25}>
                        <Slider {...settings}>
                            {RecomendedOrder?.value?.data?.map((data) => (
                                <RecommendedItem key={data?.cart_item_id} url={data?.url} heading={data?.name} price={data?.item_price} />
                            ))}
                        </Slider>
                    </div> */}

                    {
                        (addtocartlogin?.value?.data?.cart_items?.length > 0 && Logindetail) ?
                            <div className={CommonStyle.bottomBtn}><LargeBtn number={`${Logindetail ? (addtocartlogin?.value?.data?.cart_items?.length !== undefined ? addtocartlogin?.value?.data?.cart_items?.length : 0) : addtocartwithoutlogin?.length}`} label={t("proceed to pay")} amount={`${companydata?.currency_symbol}${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total?.toFixed(2) : withoutlogintotalamount?.toFixed(2)}`} clickHandler={() => handleNavigate()} /></div>
                            : addtocartwithoutlogin?.length > 0 ?
                                <div className={CommonStyle.bottomBtn}><LargeBtn number={`${Logindetail ? (addtocartlogin?.value?.data?.cart_items?.length !== undefined ? addtocartlogin?.value?.data?.cart_items?.length : 0) : addtocartwithoutlogin?.length}`} label={t("proceed to pay")} amount={`${companydata?.currency_symbol}${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total?.toFixed(2) : withoutlogintotalamount?.toFixed(2)}`} clickHandler={() => handleNavigate()} /></div>
                                : null}
                    {/* <div className={CommonStyle.bottomBtn}><LargeBtn number={"2"} label={"proceed to pay"} amount={`$${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount}`} clickHandler={() => handleNavigate()} /></div>  */}
                    {/* <div className={`${CommonStyle.my2rem} ${CommonStyle.flexJustifyCenter}`}>
                        <NavLink  className={CommonStyle.links} onClick={() => { handleNavigate() }} >Sign in </NavLink> <p className={CommonStyle.pl5}> to earn 10 points for this order!</p>
                    </div> */}
                    {
                        device == "sm" ? <>
                            {signdrawer == true && (
                                <RoundedDrawer>
                                    {phonemethod()}
                                </RoundedDrawer>
                            )}
                        </> : <>
                            {signdrawer == true && (
                                <>
                                    {
                                        DrawerSlice == true && <CustomModal
                                            open={true}
                                            handleClose={() => dispatch(Drawer_success(false))}
                                            description={""}>
                                            <div className={CommonStyle.smallModal}>
                                                {phonemethod()}
                                            </div>
                                        </CustomModal>
                                    }
                                </>
                            )}
                        </>
                    }


                    {
                        device == "sm" ? <>
                            {paymaentModal == true && (
                                <RoundedDrawer>
                                    <div className={classes.drawerOuter}>
                                        <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                                            <FormLabel>{t("Phone Number*")}</FormLabel>
                                            <p className={CommonStyle.mb20}>{t("This is for us to notify you when your order is ready for collection")} </p>
                                            <input type="number" name="number" placeholder={t("Enter your mobile no.")} maxlength="10" minLength="10" value={guestnumber} onChange={(e) => {
                                                setguesterror(false)
                                                setguestlengtherror(false)
                                                setguestnumber(e.target.value)

                                            }}
                                                onInput={(e) => {
                                                    if (e?.target?.value != "") {
                                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                                            .toString()
                                                            .slice(0, 10);
                                                    }
                                                }}
                                            />
                                            {guesterror && (
                                                <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("This field is required.")} </p>
                                            )}
                                            {guestlengtherror && (

                                                <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("Phone number length must be in between 8 to 10 digits.")} </p>
                                            )}
                                        </div>
                                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                                            <LargeBtn number={`${Logindetail ? (addtocartlogin?.value?.data?.cart_items?.length !== undefined ? addtocartlogin?.value?.data?.cart_items?.length : 0) : addtocartwithoutlogin?.length}`} label={t("Continue as a guest")} amount={`${companydata?.currency_symbol}${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                                        </div>
                                    </div>
                                </RoundedDrawer>
                            )}
                        </> : <>
                            {paymaentModal == true && (
                                <>
                                    {DrawerSlice == true && <CustomModal
                                        open={true}
                                        // title={StrConstent.cart.cardClear.heading}
                                        handleClose={() => dispatch(Drawer_success(false))}
                                        description={""}>
                                        <div className={classes.drawerOuter}>
                                            <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                                                <FormLabel>{t("Phone Number*")}</FormLabel>
                                                <p className={CommonStyle.mb20}>{t("This is for us to notify you when your order is ready for collection")} </p>
                                                <input type="number" name="number" placeholder={t("Enter your mobile no.")} maxlength="10" minLength="10" value={guestnumber} onChange={(e) => {
                                                    setguesterror(false)
                                                    setguestlengtherror(false)
                                                    setguestnumber(e.target.value)
                                                }} onKeyDown={handleKeyDown} onInput={(e) => {
                                                    if (e?.target?.value != "") {
                                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                                            .toString()
                                                            .slice(0, 10);
                                                    }
                                                }} />
                                                {guesterror && (

                                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("This field is required.")} </p>
                                                )}
                                                {guestlengtherror && (

                                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("Phone number length must be in between 8 to 10 digits.")} </p>
                                                )}
                                            </div>
                                            <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                                                <LargeBtn number={`${Logindetail ? (addtocartlogin?.value?.data?.cart_items?.length !== undefined ? addtocartlogin?.value?.data?.cart_items?.length : 0) : addtocartwithoutlogin?.length}`} label={t("Continue as a guest")} amount={`${companydata?.currency_symbol}${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total?.toFixed(2) : withoutlogintotalamount?.toFixed(2)}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                                            </div>
                                        </div>
                                    </CustomModal>}
                                </>
                            )}
                        </>
                    }

                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(Cart);