import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    modalContent: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    modalInner: {
        width: "100%",
        background: ColorTheme.white,
        padding: "20px",
        minHeight: '120px',
        borderRadius: "10px",
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        "& h3": {
            textAlign: 'center',
        },
        "@media(max-width:991px)": {
            padding: "20px 30px",
        }
    },
    title: {
        width: "100%",
        boxSizing: 'border-box',
    },
    closeIcon: {
        height: '32px',
        width: '32px',
        borderRadius: '100% !important',
        minWidth: 'auto !important',
        boxSizing: 'border-box !important',
        padding: ' 0 !important',
        position: 'absolute !important',
        right: '10px',
        top: '10px',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: `${ColorTheme.lightBrown} !important`,
    },
})
