import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Headers } from "../../../APIHandler/HeaderAPI"
import { Continue_as_guest_failure, Continue_as_guest_request, Continue_as_guest_success } from "../../reducers/Continueasguest/ContinueasguestSlice"
import { AddLoginCartAPI } from "../LoginCart/LoginCartAction"

export const ContinueasguestAPI = (payload) => async (dispatch, getState) => {
  const addtocart=localStorage.getItem("addtocart")
  const email=localStorage.getItem("email")
  
    dispatch(Continue_as_guest_request())
    const payloadsemail = {
        phone_number: payload,
        email:email
    }
    const payloads = {
        phone_number: payload,
      
    }
    const payloadcheck= email && email!="" && email!=null  && email!= undefined ? payloadsemail:payloads
    // console.log("Continueasguest",payloads)
    try {
      
        const { data } = await axios.post(`${APIURL.continueasguest}`, payloadcheck, {
            headers: Headers
        })
        dispatch(Continue_as_guest_success(data))
        // console.log("continueasguest", data)
        localStorage.setItem("Logindetails",JSON.stringify(data))
        dispatch(AddLoginCartAPI(JSON.parse(addtocart)))
    } catch (error) {
        dispatch(Continue_as_guest_failure(error?.response?.data))
        // console.log("continueasguest",error?.response?.data)
    }
}