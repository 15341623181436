import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    labelBox: {
        background: ColorTheme.lightBrown,
        padding: "15px",
        "& h4": {
            lineHeight: 'normal',
        }
    },
    ltLine: {
        border: `1px solid ${ColorTheme.lightBrown}`,
        position: "absolute",
        left: "60px",
        height: "100%",
        top: "0",
        "& span": {
            height: "14px",
            width: "14px",
            border: `3px solid ${ColorTheme.yellow}`,
            borderRadius: "100%",
            position: "absolute",
            left: "-10px",
            top: "28px",
        },
    },
    cardOuter: {
        paddingBottom:'1rem',
        "& ul": {
            padding: "0",
            margin: "0"
        },
        "&  li": {
            position: "relative",
            padding: "20px 0 0 80px",
        },
    },
    orderHistory:{
        "&  li": {
            padding: "0",
        },
    },
    dateBox: {
        position: "absolute",
        left: "10px",
        width: "auto",
        "& h3": {
            borderBottom: `3px solid ${ColorTheme.fontColor}`,
            marginBottom: "5px",
        },
    },
})