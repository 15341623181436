import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ContinueasguestSlice = createSlice({
    name: 'Continueasguest',
    initialState,
    reducers: {
        Continue_as_guest_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Continue_as_guest_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error={};
        },
        Continue_as_guest_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.value={};
        },
        Clear_Continue_as_guest:(state,action)=>{
            state.value = {};
            state.loading = false;
            state.error={};
        }
    }
})

// Action creators are generated for each case reducer function
export const { Continue_as_guest_request, Continue_as_guest_success, Continue_as_guest_failure ,Clear_Continue_as_guest} = ContinueasguestSlice.actions

export default ContinueasguestSlice.reducer