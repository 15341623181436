import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import logoWhite from '../../images/logoWhite.svg';
import eatin from '../../images/eatin.svg';
import takeout from '../../images/takeout.svg';
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Header_check_success } from "../../redux/reducers/HeaderSlice";
import { Container } from "@mui/material";
const KioSelectionMode = (props) => {
    const { classes } = props;
    const dispatch = useDispatch()
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    useEffect(() => {
        dispatch(Header_check_success(false))
        localStorage.setItem("headercheck", false)
    }, [])
    return (
        <>
            <div className={classes.outer}>
                <div className={classes.curve}>
                    <NavLink to="/kio-home">
                        <img src={companydata?.logo} alt="Not Found" />
                    </NavLink>
                </div>
                <Container maxWidth="xl+">
                    <div className={`${classes.modes} ${CommonStyle.flex} ${CommonStyle.JustifySpaceEvenly}`}>
                        <div className={classes.content} onClick={() => {
                            localStorage.setItem("outlet_mode", "Pick-Up Today")
                            localStorage.setItem("kiosk_order_mode", "Eat In")
                        }}>
                            <NavLink to="/kio-menu">
                                <img src={eatin} alt="Not Found" />
                                <h2 className={`${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20}`}> Eat in</h2>
                            </NavLink>
                        </div>
                        <div className={`${classes.content} ${CommonStyle.pl20}`} onClick={() => {
                            localStorage.setItem("outlet_mode", "Pick-Up Today")
                            localStorage.setItem("kiosk_order_mode", "Take Away")
                        }}>
                            <NavLink to="/kio-menu">
                                <img src={takeout} alt="Not Found" />
                                <h2 className={`${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20}`}>Take Out</h2>
                            </NavLink>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default withStyles(Styles)(KioSelectionMode);