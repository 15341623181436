import React from 'react'
import { Styles } from './Style';
import { withStyles } from "@mui/styles";
import CommonStyle from "../../../Common/CommonCss/Common.module.css";
import Icon from '../../../images/couponIcon.png';
import couponIcon from '../../../images/couponArrow.png'

const BlackButton = (props) => {
const { classes } = props
  return (
    <div>
        <button className={`${classes.dimension} ${CommonStyle.wFull} ${CommonStyle.mt20} ${CommonStyle.flex} ${CommonStyle.JustifySpaceBetween} ${CommonStyle.alignItemCenter}`}>
            <img src={Icon} alt="Icon" className={classes.iconDimension} /><span>Get Coupon</span><img src={couponIcon} alt="Icon" className={classes.arrowDimension} />
            </button>
    </div>
  )
}

export default withStyles(Styles)(BlackButton)
