import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    loaderContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        // zIndex: '1000',
        zIndex: '9999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        left: '0',
        // background: 'rgba(0, 0, 0, 1) !important',
        background: 'rgb(247 242 242) !important',
        "& > div": {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    loaderText: {
        fontSize: '16px',
        fontWeight: '700',
        marginTop: '5px',
        color: `${ColorTheme.yellow} !important`,
    }
})
