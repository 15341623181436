import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ColorTheme from '../../Common/CommonCss/ColorTheme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    disableScrollLock: true,
    sx: {
        "&& .Mui-selected": {
            backgroundColor: `${ColorTheme.yellow} !important`,
            color: ColorTheme.secondaryColor,
        }
    },
};

const names = [
    'Corporate Enquiry',
    'About Credit Subscription Plans',
    'About My Account',
    'Delivery Matters',
    'Feedback',
    'Others'
];

export default function EnquirySelectBox(props) {
    const {Typeofenquiry,setTypeofenquiry,setTypeofenquiryerror}=props;
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setTypeofenquiry(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setTypeofenquiryerror(false)
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                <Select
                    displayEmpty
                    value={Typeofenquiry}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <p>Please Select</p>;
                        }
                        return selected.join(', ');
                    }}
                    MenuProps={
                        {
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: `${ColorTheme.yellow} !important`,
                                    color: ColorTheme.secondaryColor,
                                }
                            },
                            disableScrollLock: true
                        }
                    }
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}