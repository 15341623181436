import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
 
}

export const DrawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    Drawer_request: (state, action) => {
      state.value=false;

    },
    Drawer_success: (state, action) => {

      state.value = action.payload;

    }
  }
})

// Action creators are generated for each case reducer function
export const {Drawer_request, Drawer_success } = DrawerSlice.actions

export default DrawerSlice.reducer