import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Get_Login_Cart_failure, Get_Login_Cart_request, Get_Login_Cart_success } from "../../reducers/LoginCart/GetLoginCartSlice"
import { Headers } from "../../../APIHandler/HeaderAPI"


export const GetLoginCartAPI = (payload) => async (dispatch, getState) => {
    dispatch(Get_Login_Cart_request())
    const payloads = payload
    // console.log("Continueasguest", payloads)
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const kioskmode= localStorage.getItem("kioskmode")
    
    // console.log("token", Logindetails?.data?.user_data?.auth_token);

    try {

        const { data } = await axios.get(`${APIURL.getlogincart}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        localStorage.setItem("cart_identifier", (data?.data?.cart_identifier))
        localStorage.setItem("grandTotal",data?.data?.grand_total)
        dispatch(Get_Login_Cart_success(data))
        if(kioskmode==3){

            localStorage.removeItem("addtocart")
        }

    } catch (error) {
        dispatch(Get_Login_Cart_failure(error?.response?.data))

    }
}
