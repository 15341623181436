import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import QRCode from "react-qr-code";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstent from '../../Common/StrConstent';
import ContainerBox from '../../Components/ContainerBox/ContainerBox';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import CustomButton from '../../Components/CustomButton/CustomButton';
import BackArrow from '../../Components/BackArrow/BackArrow';
import BorderButton from '../../Components/BorderButton/BorderButton';
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import CustomModal from "../../Components/CustomModal/CustomModal";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
// import mark from '../../images/mark.svg';
const ScanQrCode = (props) => {
    const { classes } = props;
    const device = useBreakpoint();
    const [showModel, setShowModel] = useState(false);
    let navigate = useNavigate();
    const cloaseHandler = () => {

    }
    const dispatch = useDispatch();
    const voucherActivateHandler = () => {
        dispatch(Drawer_success(true));
        setShowModel(true)
    }
    const modalData = () => {
        return (
            <React.Fragment>
                <div className={classes.activateBox}>
                    <div className={CommonStyle.mb20}>
                        <svg width="71" height="71" viewBox="0 0 71 71" fill="" className={classes.mark} xmlns="http://www.w3.org/2000/svg">
                            <path d="M35.315 2.16043e-06C30.6769 -0.0016204 26.084 0.910721 21.7986 2.68489C17.5133 4.45906 13.6195 7.06028 10.3399 10.3399C7.06028 13.6195 4.45906 17.5133 2.68489 21.7986C0.910721 26.084 -0.0016204 30.6769 2.16043e-06 35.315C-0.0016204 39.9531 0.910721 44.546 2.68489 48.8314C4.45906 53.1167 7.06028 57.0105 10.3399 60.2901C13.6195 63.5697 17.5133 66.1709 21.7986 67.9451C26.084 69.7193 30.6769 70.6316 35.315 70.63C39.9531 70.6316 44.546 69.7193 48.8314 67.9451C53.1167 66.1709 57.0105 63.5697 60.2901 60.2901C63.5697 57.0105 66.1709 53.1167 67.9451 48.8314C69.7193 44.546 70.6316 39.9531 70.63 35.315C70.6316 30.6769 69.7193 26.084 67.9451 21.7986C66.1709 17.5133 63.5697 13.6195 60.2901 10.3399C57.0105 7.06028 53.1167 4.45906 48.8314 2.68489C44.546 0.910721 39.9531 -0.0016204 35.315 2.16043e-06ZM35.315 60.7517C34.2589 60.7541 33.2259 60.443 32.3468 59.8578C31.4677 59.2726 30.7821 58.4397 30.3768 57.4644C29.9715 56.4892 29.8648 55.4157 30.0703 54.3798C30.2757 53.3438 30.784 52.3922 31.5308 51.6455C32.2775 50.8987 33.2291 50.3904 34.265 50.185C35.3009 49.9796 36.3745 50.0862 37.3497 50.4915C38.3249 50.8968 39.1579 51.5824 39.7431 52.4615C40.3283 53.3406 40.6394 54.3737 40.6369 55.4297C40.6337 56.8402 40.0719 58.192 39.0746 59.1893C38.0772 60.1867 36.7255 60.7484 35.315 60.7517ZM41.7729 16.7931L39.7602 43.9585C39.7477 44.1463 39.6641 44.3223 39.5265 44.4508C39.3889 44.5792 39.2076 44.6504 39.0194 44.65H31.6106C31.4224 44.6504 31.2411 44.5792 31.1035 44.4508C30.9659 44.3223 30.8823 44.1463 30.8698 43.9585L28.857 16.7931C28.8025 15.9115 28.9291 15.028 29.2289 14.1971C29.5288 13.3662 29.9956 12.6055 30.6007 11.9619C31.2057 11.3183 31.9361 10.8054 32.7469 10.4548C33.5577 10.1042 34.4317 9.9233 35.315 9.9233C36.1983 9.9233 37.0723 10.1042 37.8831 10.4548C38.6939 10.8054 39.4243 11.3183 40.0293 11.9619C40.6344 12.6055 41.1012 13.3662 41.4011 14.1971C41.7009 15.028 41.8275 15.9115 41.7729 16.7931Z" fill="#FFC048" />
                        </svg>
                    </div>
                    <div className={classes.textContent}>
                        <h3 className={CommonStyle.mb10}>{StrConstent.scanQrCode.confirmModal.heading}</h3>
                        <p className={CommonStyle.mb20}>{StrConstent.scanQrCode.confirmModal.description}</p>
                    </div>
                    <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.mt20} ${CommonStyle.mb25} ${CommonStyle.footerModal} ${classes.btnContainer}`}>
                        <BorderButton label="Exit and forfeit voucher" clickHandler={() => navigate(-1)} />
                        <CustomButton label="Stay and redeem" clickHandler={() => navigate('/qr-code')} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
    return (
        <>
            <ContainerBox>
                <div className={classes.qrCodeOuter}>
                    <BackArrow clickHandler={() => navigate(-1)} />
                    <h2 className={CommonStyle.mb10}>{StrConstent.scanQrCode.heading}</h2>
                    <p>{StrConstent.scanQrCode.description}</p>
                    <div className={`${classes.qrScanCode} ${classes.qrCode}`}>
                        <div className={classes.qrScanCode}>
                            <QRCode size={256}
                                style={{ height: "auto", maxWidth: "400px", width: "100%" }}
                                value={"value"}
                                viewBox={`0 0 256 256`} />
                        </div>
                    </div>
                    <div className={`${classes.orderNumber} ${classes.qrCode}`}>
                        <h4>#FD00449</h4>
                        <p>Order Id</p>
                    </div>
                    <div className={`${classes.btnButton} ${CommonStyle.mt30}`}>
                        <CustomButton label={"Close"} clickHandler={cloaseHandler} clickHandler={() => { voucherActivateHandler() }} />
                    </div>
                </div>
                {
                    device == "sm" ?
                        <RoundedDrawer>
                            {modalData()}
                        </RoundedDrawer>
                        :
                        <CustomModal
                            open={showModel}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => dispatch(Drawer_success(false))}
                            description={""}>
                            {modalData()}
                        </CustomModal>

                }
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(ScanQrCode);