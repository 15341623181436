import React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { NavLink } from 'react-router-dom';
import BorderButton from '../../Components/BorderButton/BorderButton';
const KioRecommendedCart = (props) => {
    const { classes, url, productName, price } = props;
    const addCartHabdle = () => {

    }
    return (
        <>
            <div className={classes.cartItem}>
                <NavLink to="">
                <div className={`${classes.containerBox} ${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                    <div className={CommonStyle.flexCenterStart}>
                        <div className={classes.imgContent}>
                            <img src={url} alt="Not Found" />
                        </div>
                        <div className={`${CommonStyle.pl20} ${CommonStyle.pt5} ${CommonStyle.recommendCart}`}>
                            <h4 className={CommonStyle.mb5}>{productName}</h4>
                            <h5 className={CommonStyle.mb10}>{price}</h5>
                        </div>
                    </div>
                    <BorderButton label="Add" clickHandler={addCartHabdle} />
                </div>
                </NavLink>
            </div>
        </>
    )
}
export default withStyles(Styles)(KioRecommendedCart);