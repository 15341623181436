import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const BaseUrlSlice = createSlice({
  name: 'baseUrl',
  initialState,
  reducers: {
    base_url_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    base_url_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const {base_url_request, base_url_success } = BaseUrlSlice.actions

export default BaseUrlSlice.reducer