import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useEffect } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    disableScrollLock: true,
    sx: {
        "&& .Mui-selected": {
            backgroundColor: `${ColorTheme.yellow} !important`,
            color: ColorTheme.secondaryColor,
        }
    },
};

const names = [
    'Home',
    'office',
    'Custom'
];

function AddressTypeSelectBox(props) {
    const { classes,setaddressname ,errorstatus,addressname,editid,GetIdAddressSlice} = props;
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        errorstatus(false)
    };
    useEffect(()=>{
        
        if(personName[0]!=undefined){

            console.log("personName",personName[0])
            setaddressname(personName[0])
        }
    },[personName])
    useEffect(()=>{
        if(editid){
if(GetIdAddressSlice?.value?.data?.address_data?.custom_address_tag!=undefined){

    setPersonName(typeof GetIdAddressSlice?.value?.data?.address_data?.custom_address_tag === 'string' ? GetIdAddressSlice?.value?.data?.address_data?.custom_address_tag?.split(',') : GetIdAddressSlice?.value?.data?.address_data?.custom_address_tag,)
}
        }
    },[GetIdAddressSlice])
    console.log("GetIdAddressSlice",GetIdAddressSlice)
    return (
        <div className={classes.selectBox}>
            <Select
                displayEmpty
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <p>Enter address tag</p>;
                    }

                    return selected.join(', ');
                }}
                MenuProps={
                    {
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: `${ColorTheme.yellow} !important`,
                                color: ColorTheme.secondaryColor,
                            }
                        },
                        disableScrollLock: true
                    }
                }
                inputProps={{ 'aria-label': 'Without label' }}>
                {names.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
export default withStyles(Styles)(AddressTypeSelectBox);