import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    counter: {
        display: 'flex',
        alignItems: 'center',
    },
    btn: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        height: ' 35px',
        width: ' 35px',
        border: '2px solid !important',
        borderColor: ColorTheme.yellow,
        color: ColorTheme.yellow,
        borderRadius: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& img": {
            height: '15px',
            width: '15px',
        }
    },
    counterNumber: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
        minWidth: ' 30px',
        textAlign: 'center',
        margin: '0 5px',
        fontFamily: 'var(--main-heading-family)',
    },
})