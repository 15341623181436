import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CounterBox from "../../Components/CounterBox/CounterBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modifier_success } from "../../redux/reducers/Modifier/ModifierSlice";
import plusIcon from '../../images/plusIcon.svg';
import minimizeIcon from '../../images/minimizeIcon.svg';
const CounteItem = (props) => {
    const { classes, data ,id} = props;
    const [counter, setCounter] = useState(0);
    const dispatch = useDispatch()
    const [modifieritem, setmodifieritem] = useState([])
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    
    useEffect(() => {
        setmodifieritem(data)
        localStorage.setItem("modifierItem", JSON.stringify(data))
        dispatch(Modifier_success(data))
        
    }, [data])

    const increase = (modifier_id) => {
        const itemss = modifieritem?.map((item) => {
            return modifier_id == item?.modifier_id ? { ...item, modifier_quantity: item?.modifier_quantity + 1 } : item
        })
        setmodifieritem(itemss)
        localStorage.setItem("modifierItem", JSON.stringify(itemss))
        dispatch(Modifier_success(itemss))
        setCounter(counter + 1);
        // localStorage.setItem(itemName, counter + 1)
    };

    const decrease = (modifier_id) => {
        // if (counter === 0) {
        //     return;
        // }
        const itemss = modifieritem?.map((item) => {
            return modifier_id == item?.modifier_id ? { ...item, modifier_quantity: (item?.modifier_quantity>0 ? item?.modifier_quantity - 1:item?.modifier_quantity )} : item
        })
        setmodifieritem(itemss)
        localStorage.setItem("modifierItem", JSON.stringify(itemss))
        dispatch(Modifier_success(itemss))
        setCounter(counter - 1);
        // localStorage.setItem(itemName, counter - 1)
    };
    // console.log("modifieritem", data,id)
    return (
        <>

            {
                modifieritem?.map((index) => {
                    return <div className={classes.itemAdd} key={index?.modifier_id}>
                        <h5>{index?.modifier_name}</h5>
                        <div className={`${CommonStyle.my1rem} ${CommonStyle.flexSpaceBetween}`}>
                            <div className={CommonStyle.flexCenter}>
                                <div className={`${classes.smallImgContent}  ${CommonStyle.objectFit} ${CommonStyle.pr10} ${CommonStyle.borderRadius10}`}>
                                    <img src={index?.modifier_image} alt="Not Found" className={CommonStyle.imgCover} />
                                </div>

                                <div className={classes.countBox}>
                                    <NavLink onClick={() => decrease(index?.modifier_id)}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                                    <span className={classes.countNumber}>{index?.modifier_quantity}</span>
                                    <NavLink onClick={() => increase(index?.modifier_id)}><img src={plusIcon} alt="Not Found" /></NavLink>
                                </div>
                                {/* <CounterBox /> */}
                            </div>
                            <div className={CommonStyle.pl10}>
                                <p className={`${CommonStyle.textRight} ${CommonStyle.fw700}`}>{companydata?.currency_symbol}{index?.modifier_price}</p>
                            </div>
                        </div>
                    </div>
                })
            }
            {/* <div className={classes.itemAdd}>
                <h5>{itemName}</h5>
                <div className={`${CommonStyle.my1rem} ${CommonStyle.flexSpaceBetween}`}>
                    <div className={CommonStyle.flexCenter}>
                        <div className={`${classes.smallImgContent}  ${CommonStyle.objectFit} ${CommonStyle.pr10} ${CommonStyle.borderRadius10}`}>
                            <img src={url} alt="Not Found" className={CommonStyle.imgCover} />
                        </div>

                        <div className={classes.countBox}>
                            <NavLink onClick={() => decrease()}><img src="./images/minimizeIcon.png" alt="Not Found" /></NavLink>
                            <span className={classes.countNumber}>{counter}</span>
                            <NavLink onClick={() => increase()}><img src="./images/plusIcon.png" alt="Not Found" /></NavLink>
                        </div>
                       
                    </div>
                    <div className={CommonStyle.pl10}>
                        <p className={`${CommonStyle.textRight} ${CommonStyle.fw700}`}>${price}</p>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default withStyles(Styles)(CounteItem)