import React, { useEffect, useState } from 'react'
import { Styles } from './Style';
import { withStyles } from '@mui/styles';
import StrConstent from '../../Common/StrConstent';
import closeWhite from '../../images/closeWhite.svg';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import LargeBtn from '../../KioskComponents/LargeBtn/LargeBtn';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StripeCheckoutAPI } from '../../redux/Action/Stripepayment/StripeCheckout';
import TimerCount from '../../Timer/TimerCount';
import Loader from '../../KioskComponents/Loader/Loader';
const KioPaymentFailed = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const Stripepayment = useSelector(state => state?.StripepaymentSlice)

    const handletryagainpayment = () => {
        dispatch(StripeCheckoutAPI())
    }
    return (
        <>
         {
            Stripepayment?.loading ==true && (
                <Loader/>
            )
        }
           <TimerCount/>
            <ContainerBox>
                <div className={classes.checkBox}>
                    <img src={closeWhite} alt="Not Found" />
                </div>
                <div className={`${classes.paymentOuter}`}>
                    <h2 className={CommonStyle.mb10}>{StrConstent.paymentFailed.heading}</h2>
                    <p>{StrConstent.paymentFailed.description}</p>
                </div>
                <p className={CommonStyle.textCenter}>In case of any payment is deducted from your account will revert within 72 hrs.</p>
                <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.kioBottomBtnContainer} ${classes.tryAgain}`}>
                    <LargeBtn label="try again"  clickHandler={handletryagainpayment} />
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioPaymentFailed);