import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FlashDealDetailsSlice = createSlice({
    name: 'FlashDealDetails',
    initialState,
    reducers: {

        FlashDealDetails_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        FlashDealDetails_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        FlashDealDetails_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_FlashDealDetails_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  FlashDealDetails_request, FlashDealDetails_success,FlashDealDetails_failure, Clear_FlashDealDetails_Cart } = FlashDealDetailsSlice.actions

export default FlashDealDetailsSlice.reducer