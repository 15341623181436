import { Hipster_Base_URL } from "./HeaderAPI";

export const APIURL = {
    companydata: `${Hipster_Base_URL}`,
    latlongfetchapi: `${Hipster_Base_URL}/customer/items`,
    categories: `${Hipster_Base_URL}/customer/categories`,
    choosemode: `${Hipster_Base_URL}/customer/modes/list`,
    outletmode: `${Hipster_Base_URL}/customer/outlets`,
    itemdetails: `${Hipster_Base_URL}/customer/outlet-item-detail`,
    continueasguest: `${Hipster_Base_URL}/customer/continue-as-guest`,
    continuewithphone: `${Hipster_Base_URL}/customer/continue-with-phone`,
    otpverified: `${Hipster_Base_URL}/customer/otp-verified`,
    addstripecard:`${Hipster_Base_URL}/customer/cards/add`,
    getstripecard:`${Hipster_Base_URL}/customer/cards/list`,
    addlogincart:`${Hipster_Base_URL}/customer/cart/add-item`,
    getlogincart: `${Hipster_Base_URL}/customer/cart`,
    deletelogincart:`${Hipster_Base_URL}/customer/cart/remove-item`,
    getlogincartitem: `${Hipster_Base_URL}/customer/cart/item`,
    updateitemquantity:`${Hipster_Base_URL}/customer/cart/update-item-quantity`,
    updateLoginItemCart: `${Hipster_Base_URL}/customer/cart/update-item`,
    couponlist:`${Hipster_Base_URL}/customer/coupons/list`,
    applycoupon:`${Hipster_Base_URL}/customer/coupon/apply`,
    removecoupon:`${Hipster_Base_URL}/customer/coupon/remove`,
    stripecheckout:`${Hipster_Base_URL}/customer/checkout`,
    checkoutstatus:`${Hipster_Base_URL}/customer/cart/checkout-order`,
    paymentorderid:`${Hipster_Base_URL}/customer/orders`,
    orderAgain: `${Hipster_Base_URL}/customer/orders-again`,
    orderpdfreceipt:`${Hipster_Base_URL}/customer/order-pdf-receipt`,
    orderhistorypdf:`${Hipster_Base_URL}/customer/order-pdf`,
    outletSchdeule:`${Hipster_Base_URL}/customer/outlet-schedule`,
    recomendedorder:`${Hipster_Base_URL}/customer/recomended-items`,
    timeslotcheck:`${Hipster_Base_URL}/customer/check-time-slot`,
    cartclear:`${Hipster_Base_URL}/customer/cart-clear`,
    profileupdate:`${Hipster_Base_URL}/customer/profile/details`,
    profileget:`${Hipster_Base_URL}/customer/profile/details`,
    profilenumberchange:`${Hipster_Base_URL}/customer/profile/change-phone-number`,
    profilenumberverify:`${Hipster_Base_URL}/customer/profile/verify-phone-number`,
    faq:`${Hipster_Base_URL}/customer/faqs`,
    rewards:`${Hipster_Base_URL}/customer/rewards/point`,
    outletphonemode:`${Hipster_Base_URL}/get-outlet-details-header`,
    ordershistory:`${Hipster_Base_URL}/customer/orders-history`,
    ordersdetails:`${Hipster_Base_URL}/customer/myorders-new`,
    Notification:`${Hipster_Base_URL}/customer/notifications`,
    voucherkoisk:`${Hipster_Base_URL}/customer/vouchers/list`,
    orderdetailsid:`${Hipster_Base_URL}/customer/orders`,
    addaddress:`${Hipster_Base_URL}/customer/address`,
    updateaddaddress:`${Hipster_Base_URL}/customer/address`,
    getaddress:`${Hipster_Base_URL}/customer/address`,
    getidaddress:`${Hipster_Base_URL}/customer/address`,
    deleteaddress:`${Hipster_Base_URL}/customer/address`,
    isdefaultaddress:`${Hipster_Base_URL}/customer/address/set-default`,
    reservation:`${Hipster_Base_URL}/acecard/booking/add`,
    reservationoutlet:`${Hipster_Base_URL}/acecard/outlets`,
    categoryfaq:`${Hipster_Base_URL}/customer/faq/categories`,
    getintouch:`${Hipster_Base_URL}/customer/get-in-touch`,
    changePassword:`${Hipster_Base_URL}/customer/change-password`,
    flashDeal:`${Hipster_Base_URL}/customer/flashdeal`,
    flashDealDetails:`${Hipster_Base_URL}/customer/flashdeal-details`,
    flashDeallimit:`${Hipster_Base_URL}/customer/flashdeals_with_limited_numbers`,
    flashDealcoupon:`${Hipster_Base_URL}/customer/coupons`,
    flashDealAddcoupon:`${Hipster_Base_URL}/customer/addCoupon`,
    flashDeallist:`${Hipster_Base_URL}/customer/flashdeal/list`,
    flashDealapply:`${Hipster_Base_URL}/customer/flashdeal/apply`,
    flashDealRemove:`${Hipster_Base_URL}/customer/flashdeal/remove`,
    signin:`${Hipster_Base_URL}/customer/signin`,
    signup:`${Hipster_Base_URL}/customer/signup`,
    forgotpassword:`${Hipster_Base_URL}/customer/forgot-pwd`,
    
   
    
    
    
}