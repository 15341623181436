import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Order_Pdf_Receipt_failure, Order_Pdf_Receipt_request, Order_Pdf_Receipt_success } from "../../reducers/OrderPdfReceipt/OrderPdfReceiptSlice";
import { Headers } from "../../../APIHandler/HeaderAPI";

export const OrderPdfReceiptAPI = (payload) => async (dispatch, getState) => {
    dispatch(Order_Pdf_Receipt_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const paymentorderID = localStorage.getItem("paymentorderID")
  
  
  
    try {

        const { data } = await axios.get(`${APIURL.orderpdfreceipt}/${paymentorderID}/${payloads}` , {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },
            // responseType: "arraybuffer",
        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(Order_Pdf_Receipt_success(data))
        // const pdfData = await data.arrayBuffer();
        // const pdfBlob = new Blob([data], { type: 'application/pdf' })
        // const url = window.URL.createObjectURL(pdfBlob)
        // // console.log("pdf", url)
        // const link = document.createElement("a")
        // link.href = url;
        // link.setAttribute("download", "receipt.pdf")
        // document.body.appendChild(link);
        // link.click()
    } catch (error) {
        dispatch(Order_Pdf_Receipt_failure(error?.response?.data))
    }
}

export const OrderdetailPdfReceiptAPI = (payload) => async (dispatch, getState) => {
    dispatch(Order_Pdf_Receipt_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const paymentorderID = localStorage.getItem("paymentorderID")
    try {

        const { data } = await axios.get(`${APIURL.orderhistorypdf}/${payload}`, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },
            responseType: "arraybuffer",
        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(Order_Pdf_Receipt_success(data))
        // const pdfData = await data.arrayBuffer();
        const pdfBlob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(pdfBlob)
        // console.log("pdf", url)
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute("download", "receipt.pdf")
        document.body.appendChild(link);
        link.click()
    } catch (error) {
        dispatch(Order_Pdf_Receipt_failure(error?.response?.data))
    }
}