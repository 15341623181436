import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import StrConstent from '../../Common/StrConstent';
import NoData from '../../images/404.svg'
const KioskNotFound = (props) => {
    const { classes } = props
    return (
        <>
            <div className={classes.notFountOuter}>
                <div className={classes.notFountInner}>
                    <div className={classes.imgContent}>
                        <img src={NoData} alt="Not Found" />
                    </div>
                    <h3>{StrConstent.notFound.heading}</h3>
                    <p>{StrConstent.notFound.discription}</p>
                </div>
            </div>
        </>
    )
}
export default withStyles(Styles)(KioskNotFound);