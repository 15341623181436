import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ReservationSlice = createSlice({
    name: 'Reservation',
    initialState,
    reducers: {

        Reservation_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Reservation_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Reservation_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Reservation_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { Reservation_request, Reservation_success,Reservation_failure, Clear_Reservation_Cart } = ReservationSlice.actions

export default ReservationSlice.reducer