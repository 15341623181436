import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const OrderDetailsIdSlice = createSlice({
    name: 'OrderDetailsId',
    initialState,
    reducers: {

        Order_Details_ID_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Order_Details_ID_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Order_Details_ID_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Order_Details_ID_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { Order_Details_ID_request,Order_Details_ID_success,Order_Details_ID_failure, Clear_Order_Details_ID_Cart } = OrderDetailsIdSlice.actions

export default OrderDetailsIdSlice.reducer