import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    order: {
        width: 'auto',
    },
    topBox: {
        padding: '15px',
        borderRadius: '10px',
        backgroundColor: ColorTheme.white,
        marginBottom: '3px',
        boxSizing: 'border-box',
    },
    bottonBox: {
        padding: '15px',
        borderRadius: '10px',
        alignItems: 'center',
        backgroundColor: ColorTheme.white,
        boxSizing: 'border-box',
    },
    imgContent: {
        width: "60px",
        height: '60px',
        borderRadius: '10px',
        overflow: 'hidden',
        "@media(max-width:640px)": {
            width: "55px",
            height: '55px',
        },
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    textContent: {
        paddingLeft: "15px",
        width: 'calc(100% - 60px)',
    },
    counter: {
        display: 'flex',
        alignItems: 'center',
    },
    btn: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        height: ' 30px',
        width: ' 30px',
        border: '1px solid !important',
        borderColor: ColorTheme.yellow,
        color: ColorTheme.yellow,
        borderRadius: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    counterNumber: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        minWidth: ' 30px',
        textAlign: 'center',
        margin: '0 5px',
    },
    mt10: {
        margin: '8px 0 10px',
    },
    productName: {
        width: '90%',
        paddingRight: '10px',
    },
    deleteIcon: {
        width: '10%',
        "& svg": {
            width: '30px',
            color: ColorTheme.fontColor,
        }
    }
})