import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FlashDealCouponRemoveSlice = createSlice({
    name: 'FlashDealCouponRemove',
    initialState,
    reducers: {
        FlashDealCouponRemove_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        FlashDealCouponRemove_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        FlashDealCouponRemove_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_FlashDealCouponRemove_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  FlashDealCouponRemove_request, FlashDealCouponRemove_success,FlashDealCouponRemove_failure, Clear_FlashDealCouponRemove_Cart } = FlashDealCouponRemoveSlice.actions

export default FlashDealCouponRemoveSlice.reducer