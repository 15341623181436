import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,

}

export const LoginDrawerSlice = createSlice({
  name: 'baseUrl',
  initialState,
  reducers: {
    Login_Drawer_request: (state, action) => {
      state.value=false;

    },
    Login_Drawer_success: (state, action) => {

      state.value = action.payload;

    }
  }
})

// Action creators are generated for each case reducer function
export const {Login_Drawer_request, Login_Drawer_success } = LoginDrawerSlice.actions

export default LoginDrawerSlice.reducer