import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ChangePasswordSlice = createSlice({
    name: 'ChangePassword',
    initialState,
    reducers: {

        ChangePassword_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        ChangePassword_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        ChangePassword_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_ChangePassword_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { ChangePassword_request, ChangePassword_success,ChangePassword_failure, Clear_ChangePassword_Cart } = ChangePasswordSlice.actions

export default ChangePasswordSlice.reducer