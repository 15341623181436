export const Styles = Theme => ({
    rightSection : {
        width : '60%',
        '@media(max-width: 800px)' : {
            marginRight : '20px',
            marginLeft: '0 !important'
        },
        '@media(max-width: 767px)' : {
            width: '80%',
            margin: '100px auto !important'
        },
        '@media(max-width: 575px)' : {
            width: '100%',
            marginTop: '40px !important'
        },
    },
    fdetail : {
        display: 'flex',
        gap: '20px',
        '@media(max-width: 767px)' : {
            display : 'block'
        },
    },
    proDimension : {
        width: '40%',
        height : '498px',
        '@media(max-width: 920px)' : {
            width: '100%',
        },
        '@media(max-width: 767px)' : {
                width: '50%',
                height : 'auto',
                margin : 'auto'
        },
        '@media(max-width: 575px)' : {
            width : '100%'
         },
        '& img' : {
            objectFit: 'contain',
            '@media(max-width: 1668px)' : {
                height : 'auto'
            },
            '@media(max-width: 920px)' : {
                height : 'auto'
            }
        },
    },
    fdIcon : {
        width : '87px',
        height:  '24px'
    },
    borderBottomSolidDark : {
        borderBottom : '2px solid #3F4444 !important'
    },
    borderBottomSolidLight : {
        borderBottom : '2px solid #73727233 !important',
        margin : '30px 0 30px'
    },
    desc : {
        color: '#3F4444BF',
        fontSize: '16px'
    },
    productSlider : {
        width: 'auto',
        '& > div' : {
            width : '100%'
        }
    }
  })