import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Outlet_Schdeule_failure, Outlet_Schdeule_request, Outlet_Schdeule_success } from "../../reducers/OutletSchdeule/OutletSchdeuleSlice";
import { Headers } from "../../../APIHandler/HeaderAPI";

export const OutletScheduleAPI = (payload) => async (dispatch, getState) => {
    dispatch(Outlet_Schdeule_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const outletID = localStorage.getItem("outletID")

    try {

        const { data } = await axios.get(`${APIURL.outletSchdeule}/${outletID}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
      dispatch(Outlet_Schdeule_success(data))
        
       
    } catch (error) {
        dispatch(Outlet_Schdeule_failure(error?.response?.data))
    }
}
