import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1,

}

export const ItemQuantitySlice = createSlice({
  name: 'ItemQuantity',
  initialState,
  reducers: {
 
    ItemQuantity_success: (state, action) => {

      state.value = action.payload;
 
    }
  }
})

// Action creators are generated for each case reducer function
export const { ItemQuantity_success } = ItemQuantitySlice.actions

export default ItemQuantitySlice.reducer