import * as React from 'react';
import { withStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { Styles } from "./Style";
import StarIcon from '@mui/icons-material/Star';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { NavLink } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';
import { Button } from '@mui/material';
const RewardPoint = (props) => {
    const { classes, button, link, linksTxt, label, clickHandler, givenPoint, totalPoint, path} = props;
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 12,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            height: 6,
            margin: 3,
            backgroundColor: theme.palette.mode === 'light' ? ColorTheme.black : ColorTheme.white,
        },
    }));
    return (
        <>
            <div className={classes.review}>
                {
                    link == "link" &&
                    <NavLink to={path} className={CommonStyle.links}>{linksTxt}</NavLink>
                }
                <div className={classes.reviewContent}>
                    <h3>Reward Points</h3>
                    <div className={classes.flexEnd}><h3>{givenPoint} /</h3><small>{totalPoint}</small><StarIcon /></div>
                </div>
                <BorderLinearProgress variant="determinate" value={(givenPoint/125)*100 >100 ?100:(givenPoint/125)*100} className={CommonStyle.progressBar} />
                {
                    button == "button" &&
                    <div className={`${classes.customBtn} ${CommonStyle.mt1rem} ${CommonStyle.flex} ${CommonStyle.JustifyEnd} ${classes.disable}`}>
                        <Button onClick={clickHandler} className={classes.disable}>{label}</Button>
                    </div>
                }
            </div>
        </>
    )
}
export default withStyles(Styles)(RewardPoint);