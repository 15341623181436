import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    vouchersCart: {
        background: ColorTheme.lightYellow,
        padding: '20px 30px',
        borderRadius: '25px',
        marginBottom: '20px',
    },
    kioBottomBtn: {
        "& div": {
            maxWidth: '500px',
            margin: '0',
            width: '100%',
        },
        "& button": {
            width: '100%',
        }
    },
    errorMessage: {
        color: ColorTheme.red,
        fontWeight: '700',
    },
})