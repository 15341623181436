import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BackArrow from "../../Components/BackArrow/BackArrow";
import ContainerBox from "../../KioskComponents/ContainerBox/ContainerBox";
import { useNavigate } from "react-router";
// import LargeBtn from "../../Components/LargeBtn/LargeBtn";
// import OrderBox from "../../kioskComponents/OrderBox/OrderBox";
import { NavLink } from "react-router-dom";
import BottomDrawer from "../../Components/BottomDrawer/BottomDrawer";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import { Login_Drawer_success } from "../../redux/reducers/LoginDrawer";
import { useEffect, useState } from "react";
import RoundedDrawer from "../../Components/BottomDrawer/RoundedDrawer";
import CustomButton from "../../Components/CustomButton/CustomButton";
import BorderButton from "../../Components/BorderButton/BorderButton";
import { FormLabel } from "@mui/material";
import { ContinueasguestAPI } from "../../redux/Action/Continueasguest/Continueasguest";
import { GetLoginCartAPI } from "../../redux/Action/LoginCart/GetLoginCartAction";
import Loader from "../../Components/Loader/Loader";
import { Clear_Modifier } from "../../redux/reducers/Modifier/ModifierSlice";
import RecommendedItem from "../../Components/RecommendedItem/RecommendedItem";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import { Clear_Login_Cart } from "../../redux/reducers/LoginCart/GetLoginCartSlice";
import moment from "moment";
import { OutletScheduleAPI } from "../../redux/Action/OutletSchedule/OutletSchedule";

import CommonTimeSlot from "../../Components/CommonTimeSlot/CommonTimeSlot";
import { RecomendedOrderAPI } from "../../redux/Action/RecomendedOrder/RecomendedOrder";
import { useBreakpoint } from "../../CustomHooks/useBreakpoint";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { Clear_Login_Cart_failure } from "../../redux/reducers/LoginCart/LoginCartSlice";
import KioskTimeFormate from "./KioskTimeFormate";
import { PhoneverifymethodAPI } from "../../redux/Action/Phoneverifymethod/Phoneverifymethod";
import OrderBox from "../../KioskComponents/OrderBox/OrderBox";
import LargeBtn from "../../KioskComponents/LargeBtn/LargeBtn";
import { Clear_Otp_verified } from "../../redux/reducers/Continuewithphone/Otpverified";
import { validatePhone } from "../../Common/Validations";
import { useTranslation } from 'react-i18next';
import TimeFormate from "../../Pages/Cart/TimeFormate";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const KioskCart = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const Logindrawer = useSelector(state => state?.LoginDrawerSlice?.value)
    const [signdrawer, setsigndrawer] = useState(false)
    // const [guestModal, setGuestModal] = useState(false);
    const [paymaentModal, setPaymaentModal] = useState(false);
    const addtocart = localStorage.getItem("addtocart")
    const [cartitem, setcartitem] = useState([]);
    const [guestnumber, setguestnumber] = useState("")
    const Continueasguest = useSelector(state => state?.ContinueasguestSlice?.value)
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const Logindetails = localStorage.getItem("Logindetails");
    const UpdateLoginCart = useSelector(state => state?.UpdateLoginCartSlice?.value)
    const [nodata, setnodata] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [withoutlogintotalamount, setwithoutlogintotalamount] = useState(0)
    const StateChangeSlice = useSelector(state => state?.StateChangeSlice?.value)
    const Totalamountwithoutlogin = useSelector(state => state?.TotalamountwithoutloginSlice?.value)
    const OutletSchdeule = useSelector(state => state?.OutletSchdeuleSlice)
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    const outletID = localStorage.getItem("outletID")
    const RecomendedOrder = useSelector(state => state?.RecomendedOrderSlice)
    const TimeSlotCheck = useSelector(state => state?.TimeSlotCheckSlice)
    const outlet_mode = localStorage.getItem("outlet_mode")
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const [error, sererror] = useState("")
    const device = useBreakpoint();
    const Phoneverifymethod = useSelector(state => state?.PhoneverifymethodSlice)
    const kioskmode = localStorage.getItem("kioskmode")
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const { t } = useTranslation();
    const timeslot = localStorage.getItem("timeslot")
    const [guestlengtherror, setguestlengtherror] = useState(false);
    const [guesterror, setguesterror] = useState(false);

    useEffect(() => {
        if (outletID) {
            dispatch(OutletScheduleAPI())
        }
        dispatch(PhoneverifymethodAPI())
        dispatch(Clear_Login_Cart_failure())
        dispatch(Clear_Modifier())
        if (Logindetail) {
            dispatch(GetLoginCartAPI())
        }
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))
        setnodata(addtocartlogin?.value ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))
    }, [])
    // arrow
    function SampleNextArrow(props) {
        console.log(props);
        const { className, style, onClick } = props;
        return (
            <div
                className={`${classes.arrow} ${classes.arrowLeft}`}
                onClick={onClick}><ArrowBackIosNewIcon />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${classes.arrow} ${classes.arrowRight}`}
                onClick={onClick}><ArrowForwardIosIcon />
            </div>
        );
    }
    var settings = {
        dots: false,
        arrows: false,
        infinite: RecomendedOrder?.value?.data?.length > 2 ? true : false,
        slidesToShow: RecomendedOrder?.value?.data?.length > 2 ? 3 : 2,
        speed: 500,
        slidesToScroll: 1,
        centerMode: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerPadding: '40px 0 0 0',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                    centerPadding: '20px 0 0 0',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '15px 0 0 0',
                    slidesToShow: 1,
                    infinite: true,
                }
            }
        ]
    };
    useEffect(() => {
        // dispatch(ContinueasguestAPI())
        const logincheck = (Logindetails && Logindetails != undefined) ? true : false
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))
        setnodata(logincheck ? (addtocartlogin?.value ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : [])) : (addtocart ? JSON.parse(addtocart) : []))
    }, [addtocartlogin, UpdateLoginCart])

    const handleNavigate = () => {
        const logincheck = (Logindetails && Logindetails != undefined) ? true : false
        console.log("modecheck", nodata, addtocartlogin, JSON.parse(addtocart), outlet_mode)
        // setGuestModal(true)
        if (logincheck) {

            if (nodata?.data?.order_mode == "Schedule Pick-Up") {
                // if (logincheck) {
                if (TimeSlotCheck?.value?.success == true || timeslot) {
                    sererror(false)
                    navigate("/proceed-to-pay")
                } else if (TimeSlotCheck?.value?.success == undefined) {
                    sererror(true)
                }
                else {
                    // console.log("vikalp")
                    // sererror(true)

                    // } else {
                        // console.log("vikalp", TimeSlotCheck?.value?.success, nodata)
                        if (TimeSlotCheck?.value?.success == true || timeslot) {
                            sererror(false)
                            setPaymaentModal(false);
                            dispatch(Drawer_success(true))
                            dispatch(Login_Drawer_success(true))
                            setsigndrawer(true)
                        }
                        else if (TimeSlotCheck?.value?.success == undefined) {
                            sererror(true)
                        } else {
        
        
                            // setPaymaentModal(false);
                            // dispatch(Drawer_success(true))
                            // dispatch(Login_Drawer_success(true))
                            // setsigndrawer(true)
        
                        }
                    // setPaymaentModal(false);
                    // dispatch(Drawer_success(true))
                    // dispatch(Login_Drawer_success(true))
                    // setsigndrawer(true)
                }
                // }
            }
            else {


                // if (logincheck) {
                //     sererror(false)
                //     navigate("/proceed-to-pay")
                // } else {
                navigate("/proceed-to-pay")
                // }
            }
        } else {



            if (outlet_mode == "Schedule Pick-Up") {
                // if (logincheck) {
                if (TimeSlotCheck?.value?.success == true || timeslot) {
                    sererror(false)
                    setPaymaentModal(false);
                    dispatch(Drawer_success(true))
                    dispatch(Login_Drawer_success(true))
                    setsigndrawer(true)
                    // navigate("/proceed-to-pay")
                } else if (TimeSlotCheck?.value?.success == undefined) {
                    sererror(true)
                } else {
                    //         console.log("vikalp")
                    //         sererror(true)
                    //     }
                    // } else {
                    console.log("TimeSlotCheck?.value?.success", TimeSlotCheck)


                }
            }
            else {


                // if (logincheck) {
                //     sererror(false)
                //     navigate("/proceed-to-pay")
                // } else {

                setPaymaentModal(false);
                dispatch(Drawer_success(true))
                dispatch(Login_Drawer_success(true))
                setsigndrawer(true)
                // }
            }
        }
    }

    useEffect(() => {
        if (Totalamountwithoutlogin?.length > 0) {


            const arr = Totalamountwithoutlogin?.map((item, i) => {
                return (item?.item_price * item?.itemQuantity)
            }).reduce((data, i) => data + i)
            // console.log("arr", arr)
            setwithoutlogintotalamount(arr)
        } else {
            setwithoutlogintotalamount(0)
        }
    }, [Totalamountwithoutlogin])
    useEffect(() => {
        dispatch(Clear_Otp_verified())
        dispatch(Drawer_success(false))
        const addtocarts = addtocart ? JSON.parse(addtocart) : []
        if (addtocarts?.length > 0) {


            const arr = addtocarts?.map((item, i) => {
                return (item?.item_price * item?.itemQuantity)
            })?.reduce((data, i) => data + i)
            // console.log("arr", arr)
            setwithoutlogintotalamount(arr)
        } else {
            setwithoutlogintotalamount(0)
        }
    }, [])

    useEffect(() => {
        if (addtocartlogin?.error?.message) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [addtocartlogin])

    const handleClickguest = () => {
        // setGuestModal(false);
        setsigndrawer(false);
        setPaymaentModal(true);
    }

    const handlecontinueasguestsubmit = () => {
        // dispatch(ContinueasguestAPI(guestnumber))
        // console.log("guestnumber", guestnumber)
        // navigate("/proceed-to-pay")
        if (guestnumber?.length >= 8 && guestnumber?.length <= 10) {

            dispatch(ContinueasguestAPI(guestnumber))
            setguesterror(false)
            setguestlengtherror(false)
        } else if (guestnumber?.length < 8 || guestnumber?.length > 10) {
            setguesterror(false)
            setguestlengtherror(true)

        } else if (guestnumber == "") {
            setguesterror(true)
            setguestlengtherror(false)

        }
    }
    useEffect(() => {
        // console.log("Continueasguest", Continueasguest)
        if (Continueasguest?.success == true) {
            localStorage.setItem("Continueasguest", JSON.stringify(Continueasguest))
            navigate("/proceed-to-pay")
        }
    }, [Continueasguest])

    useEffect(() => {
        localStorage.setItem("grandTotal", addtocartlogin?.value?.data?.grand_total)
    }, [addtocartlogin])
    // console.log("UpdateLoginCart", nodata)
    useEffect(() => {
        if (LoginCartSlice?.value?.success) {
            dispatch(Clear_Login_Cart_failure())
        }
    }, [LoginCartSlice])


    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    // console.log("RecomendedOrder", RecomendedOrder)
    // useEffect(() => {
    //     const arr = []
    //     const cartid = cartitem != undefined && cartitem?.map((itemid) => {
    //         arr.push(itemid?.id)
    //     })
    //     const obj = {
    //         itemId: arr,
    //         outletID: addtocartlogin?.value?.data?.outlet?.outlet_id
    //     }
    //     if (cartid?.length > 0 && cartitem != undefined) {

    //         // dispatch(RecomendedOrderAPI(obj))
    //     }
    //     // console.log("arrrr", addtocartlogin?.value?.data)
    // }, [cartitem,addtocartlogin])

    // console.log("Phoneverifymethod", Phoneverifymethod)
    const handleKeyDown = (event) => {
        if (event.keyCode === 69 || event.keyCode == 45 || event.keyCode == 95 || event.key == "-") {
            event.preventDefault();
        }
    };

    const phonemethod = () => {
        return <>
            {
                (Phoneverifymethod?.value?.data?.kiosk_otp_verifications == true && (kioskmode == 1)) && navigate("/login")

                // && <>
                //     <div className={classes.topBox}>
                //         <div className={classes.productImg}>
                //             <img src="./images/product-1.png" alt="Not Found" />
                //         </div>
                //         <div className={classes.spacingTop}>
                //             <CustomButton variant="text" label={"Sign in"} clickHandler={() => navigate("/login")} />
                //         </div>
                //         {/* <p classes={CommonStyle.flexJustifyCenter}>New here? <NavLink to="/login" className={CommonStyle.links}>Register</NavLink> to get $5 off your first order</p> */}
                //     </div>
                // </>
            }
            {
                (Phoneverifymethod?.value?.data?.qr_otp_verifications == true && (kioskmode == 2)) && navigate("/login")
                // <>
                //     <div className={classes.topBox}>
                //         <div className={classes.productImg}>
                //             <img src="./images/product-1.png" alt="Not Found" />
                //         </div>
                //         <div className={classes.spacingTop}>
                //             <CustomButton variant="text" label={"Sign in"} clickHandler={() => navigate("/login")} />
                //         </div>
                //         {/* <p classes={CommonStyle.flexJustifyCenter}>New here? <NavLink to="/login" className={CommonStyle.links}>Register</NavLink> to get $5 off your first order</p> */}
                //     </div>
                // </>
            }

            {
                (Phoneverifymethod?.value?.data?.kiosk_otp_verifications == false && (kioskmode == 1)) && <>
                    <div className={classes.drawerOuter}>
                        <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                            <FormLabel>{t("Phone Number*")}</FormLabel>
                            <p className={CommonStyle.mb20}>{t("This is for us to notify you when your order is ready for collection")} </p>
                            <input type="number" name="number" placeholder={t("Enter your mobile no.")} maxlength="10" minLength="10" value={guestnumber} onChange={(e) => {

                                setguesterror(false)
                                setguestlengtherror(false)
                                setguestnumber(e.target.value)

                            }}
                                onInput={(e) => {
                                    if (e?.target?.value != "") {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            {guesterror && (

                                <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("This field is required.")} </p>
                            )}
                            {guestlengtherror && (

                                <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("Phone number length must be in between 8 to 10 digits.")} </p>
                            )}
                        </div>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                            <LargeBtn number={"2"} label={t("Continue to Payment")} amount={`${companydata?.currency_symbol}${withoutlogintotalamount?.toFixed(2)}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                        </div>
                    </div>
                </>
            }
            {
                (Phoneverifymethod?.value?.data?.qr_otp_verifications == false && (kioskmode == 2)) && <>
                    <div className={classes.drawerOuter}>
                        <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                            <FormLabel>{t("Phone Number*")}</FormLabel>
                            <p className={CommonStyle.mb20}>{t("This is for us to notify you when your order is ready for collection")} </p>
                            <input type="number" name="number" maxlength="10" minLength="10" placeholder={t("Enter your mobile no.")} value={guestnumber} onChange={(e) => {

                                setguesterror(false)
                                setguestlengtherror(false)
                                setguestnumber(e.target.value)

                            }}
                                onInput={(e) => {
                                    if (e?.target?.value != "") {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 10);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            {guesterror && (

                                <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("This field is required.")} </p>
                            )}
                            {guestlengtherror && (

                                <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("Phone number length must be in between 8 to 10 digits.")} </p>
                            )}
                        </div>
                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                            <LargeBtn number={"2"} label={t("Continue to Payment")} amount={`${companydata?.currency_symbol}${withoutlogintotalamount?.toFixed(2)}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                        </div>
                    </div>
                </>
            }


        </>
    }
    console.log("Phoneverifymethod",addtocartwithoutlogin?.length)

    return (
        <>
            {
                addtocartlogin?.loading == true && (
                    <Loader />
                )
            }


            {
                RecomendedOrder?.loading == true && (
                    <Loader />
                )
            }

            <ContainerBox>
                <div className={classes.cartOuter}>
                    {
                        isappwebviewcheck != "acecard" && (

                            <BackArrow clickHandler={() => navigate("/")} />
                        )
                    }
                    {
                        (nodata?.data?.order_mode == "Schedule Pick-Up" || outlet_mode == "Schedule Pick-Up") && (
                            <>
                                <TimeFormate />
                            </>
                        )
                    }
                    {error == true && (
                        <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>{t("Please Select TimeSlot.")}</p>
                    )}
                    <h2 className={`${CommonStyle.mb20} ${CommonStyle.mt20}`}>{t("Cart")}</h2>
                    {nodata?.length == 0 ?
                        <div className={CommonStyle.nodata}>
                            <h4>{t("No Cart Item")}</h4>
                        </div>
                        :
                        <div className={classes.cartInner}>
                            {cartitem?.map((data) => (
                                <OrderBox key={data?.cart_item_id} url={data?.url} heading={data?.name} description={data} price={data?.item_price} quantity={data?.itemQuantity} />
                            ))}
                        </div>
                    }
                    <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.my2rem} ${CommonStyle.JustifyEnd}`}>
                        <CustomButton label={t("Add More Items")} clickHandler={() => navigate("/")} />
                    </div>
                    {/* {RecomendedOrder?.value?.data?.length > 0 && (
                        <h2 className={CommonStyle.mt2rem}>{StrConstent.recommended.heading}</h2>
                    )
                    }
                    <div className={CommonStyle.my25}>
                        <Slider {...settings}>
                            {RecomendedOrder?.value?.data?.map((data) => (
                                <RecommendedItem key={data?.cart_item_id} url={data?.url} heading={data?.name} price={data?.item_price} />
                            ))}
                        </Slider>
                    </div> */}

                    {
                        (nodata?.length > 0 && Logindetail) ?
                            <div className={CommonStyle.bottomBtn}><LargeBtn number={`${Logindetail ? (addtocartlogin?.value?.data?.cart_items?.length !== undefined ? addtocartlogin?.value?.data?.cart_items?.length : 0) : addtocartwithoutlogin?.length}`} label={t("proceed to pay")} amount={`${companydata?.currency_symbol}${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total?.toFixed(2) : withoutlogintotalamount?.toFixed(2)}`} clickHandler={() => handleNavigate()} /></div>
                            : addtocartwithoutlogin?.length > 0 && !Logindetail ?
                                <div className={CommonStyle.bottomBtn}><LargeBtn number={`${Logindetail ? (addtocartlogin?.value?.data?.cart_items?.length !== undefined ? addtocartlogin?.value?.data?.cart_items?.length : 0) : addtocartwithoutlogin?.length}`} label={t("proceed to pay")} amount={`${companydata?.currency_symbol}${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total?.toFixed(2) : withoutlogintotalamount?.toFixed(2)}`} clickHandler={() => handleNavigate()} /></div>
                                : null}

                    {/* <div className={CommonStyle.bottomBtn}><LargeBtn number={`${Logindetail ? (addtocartlogin?.value?.data?.cart_items?.length !== undefined ? addtocartlogin?.value?.data?.cart_items?.length : 0) : addtocartwithoutlogin?.length}`} label={"proceed to pay"} amount={`$${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount}`} clickHandler={() => handleNavigate()} /></div> */}
                    {/* <div className={CommonStyle.bottomBtn}><LargeBtn number={"2"} label={"proceed to pay"} amount={`$${addtocartlogin?.value?.data?.grand_total ? addtocartlogin?.value?.data?.grand_total : withoutlogintotalamount}`} clickHandler={() => handleNavigate()} /></div> */}

                    {
                        device == "sm" ? <>
                            {signdrawer == true && (
                                <RoundedDrawer>
                                    {phonemethod()}
                                </RoundedDrawer>
                            )}
                        </> : <>
                            {signdrawer == true && (
                                <>
                                    {
                                        DrawerSlice == true && <CustomModal
                                            open={true}
                                            handleClose={() => dispatch(Drawer_success(false))}
                                            description={""}>
                                            <div className={CommonStyle.smallModal}>
                                                {phonemethod()}
                                            </div>
                                        </CustomModal>
                                    }
                                </>
                            )}
                        </>
                    }


                    {/* {
                        device == "sm" ? <>
                            {paymaentModal == true && (
                                <RoundedDrawer>
                                    <div className={classes.drawerOuter}>
                                        <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                                            <FormLabel>Phone Number*</FormLabel>
                                            <p className={CommonStyle.mb20}>This is for us to notify you when your order is ready for collection </p>
                                            <input type="number" name="number" placeholder="Enter your mobile no." value={guestnumber} onChange={(e) => setguestnumber(e.target.value)} />
                                        </div>
                                        <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                                            <LargeBtn number={"2"} label={"Continue to Payment"} amount={`$${withoutlogintotalamount}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                                        </div>
                                    </div>
                                </RoundedDrawer>
                            )}
                        </> : <>
                            {paymaentModal == true && (
                                <>
                                    {DrawerSlice == true && <CustomModal
                                        open={true}
                                        // title={StrConstent.cart.cardClear.heading}
                                        handleClose={() => dispatch(Drawer_success(false))}
                                        description={""}>
                                        <div className={classes.drawerOuter}>
                                            <div className={`${CommonStyle.fromControl} ${CommonStyle.inputField}`}>
                                                <FormLabel>Phone Number*</FormLabel>
                                                <p className={CommonStyle.mb20}>This is for us to notify you when your order is ready for collection </p>
                                                <input type="number" name="number" placeholder="Enter your mobile no." value={guestnumber} onChange={(e) => setguestnumber(e.target.value)} />
                                            </div>
                                            <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20}`}>
                                                <LargeBtn number={"2"} label={"Continue to Payment"} amount={`$${ withoutlogintotalamount}`} clickHandler={() => { handlecontinueasguestsubmit() }} />
                                            </div>
                                        </div>
                                    </CustomModal>}
                                </>
                            )}
                        </>
                    } */}

                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioskCart);