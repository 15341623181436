import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = (Theme) => ({
    datapicker: {
        width: '100%',
        position: 'relative',
        "& > div": {
            width: '100%',
        },
        "& input": {
            minHeight: 'auto',
            width: '100%',
            paddingRight: '50px',
            fontSize: '0.875rem',
            lineHeight: '1.188rem',
            color: ColorTheme.fontColor,
        },
        "& button": {
            position: 'absolute',
            right: '20px',
            opacity: '0',
            width: '80px',
            height: '50px',
        },
        "& .MuiOutlinedInput-root ": {
            paddingRight: '0',
        },
        "& img": {
            position: 'absolute',
            right: '30px',
            top: '15px',
            pointerEvents: 'none',
        }
    },
    "& .MuiPickersDay-root": {
        backgroundColor: `${ColorTheme.yellow} !important`,
        color: `${ColorTheme.secondaryColor} !important`,
    },
})