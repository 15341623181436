import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const RecomendedOrderSlice = createSlice({
    name: 'RecomendedOrder',
    initialState,
    reducers: {

        Recomended_Order_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Recomended_Order_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Recomended_Order_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Recomended_Order_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { Recomended_Order_request, Recomended_Order_success,Recomended_Order_failure, Recomended_Order_Cart } = RecomendedOrderSlice.actions

export default RecomendedOrderSlice.reducer