import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import BaseUrlSliceReducer from './reducers/BaseUrlSlice';
import LocationlatlongSliceReducer from './reducers/Location/LocationSlice';
import CategorieswithoutloginSliceReducer from './reducers/CategoriesWithoutLogin/CategoriesWitoutlogin';
import ChoosemodeSliceReducer from './reducers/choosemode/ChoosemodeSlice';
import OutletSliceReducer from './reducers/Outlet/OutletSlice';
import modeSliceReducer from './reducers/OutletFetch';
import DrawerSliceReducer from './reducers/DrawerSlice';
import ItemdetailsSliceReducer from './reducers/Itemdetails/ItemdetailsSlice';
import LoginDrawerSliceReducer  from './reducers/LoginDrawer';
import ModifierSliceReducer from './reducers/Modifier/ModifierSlice';
import ItemQuantitySliceReducer from './reducers/Modifier/ItemQuantitySlice';
import SugarlevelSliceReducer  from './reducers/SugarLevelSlice';
import ContinueasguestSliceReducer from './reducers/Continueasguest/ContinueasguestSlice';
import ContinuewithphoneSliceReducer from './reducers/Continuewithphone/ContinuewithphoneSlice';
import OtpverifiedSliceReducer  from './reducers/Continuewithphone/Otpverified';
import AddStripeCardSliceReducer  from './reducers/Card/AddStripeCard';
import GetStripeCardSliceReducer  from './reducers/Card/GetStripeCard';
import LoginCartSliceReducer from './reducers/LoginCart/LoginCartSlice';
import GetLoginCartSliceReducer from './reducers/LoginCart/GetLoginCartSlice';
import DeleteLoginCartSliceReducer from './reducers/LoginCart/DeleteLoginCartSlice';
import GetLoginCartItemSliceReducer  from './reducers/LoginCart/GetloginCartitemSlice';
import UpdateItemQuantitySliceReducer from './reducers/LoginCart/UpdateItemQuantitySlice';
import UpdateLoginCartSliceReducer from './reducers/LoginCart/UpdateLoginCartSlice';
import CouponlistCSliceReducer  from './reducers/Coupon/CouponlistSlice';
import ApplyCouponSliceReducer from './reducers/Coupon/ApplyCouponSlice';
import RemoveCouponSliceReducer from './reducers/Coupon/RemoveCouponSlice';
import StripepaymentSliceReducer  from './reducers/Stripepayment/StripeCheckoutSlice';
import StateChangeSliceReducer from './reducers/StateChangeSlice';
import TotalamountwithoutloginSliceReducer  from './reducers/Totalamountwithoulogin';
import CheckoutStatusSliceReducer from './reducers/Stripepayment/CheckoutStatusSlice';
import FilteridcartmatchSliceReducer  from './reducers/Filteridcartmatch';
import PaymentOrderIdSliceReducer from './reducers/Stripepayment/PaymentOrderIdSlice';
import OrderAgainSliceReducer from './reducers/OrderAgain/OrderAgainSlice';
import OrderPdfReceiptSliceReducer from './reducers/OrderPdfReceipt/OrderPdfReceiptSlice';
import OutletSchdeuleSliceReducer from './reducers/OutletSchdeule/OutletSchdeuleSlice';
import RecomendedOrderSliceReducer  from './reducers/recomendedorder/RecomendedOrder';
import TimeSlotCheckSliceReducer  from './reducers/TimeSlotCheck/TimeSlotCheck';
import CartClearSliceReducer from './reducers/CartClear/CartClearSlice';
import ProfileUpdateSliceReducer from './reducers/ProfileUpdate/ProfileUpdateSlice';
import ProfileGetSliceReducer  from './reducers/ProfileUpdate/ProfilegetSlice';
import ProfilenumberchangeSliceReducer from './reducers/ProfileUpdate/ProfilenumberchangeSlice';
import VerifynumberSliceReducer  from './reducers/ProfileUpdate/verifynumberSlice';
import FaqSliceReducer from './reducers/Faq/FaqSlice';
import RewardsPointsSliceReducer  from './reducers/rewardspoint/RewardPoints';
import PhoneverifymethodSliceReducer from './reducers/Phoneverifymethod/PhoneverifymethodSlice';
import LogoutDrawerSliceReducer from './reducers/LogoutDrawerSlice';
import OrderHistorySliceReducer  from './reducers/OrderHistory/OrderHistorySlice';
import HeaderCheckSliceReducer  from './reducers/HeaderSlice';
import OrderDetailsSliceReducer  from './reducers/OrderDetails/OrderDetailsSlice';
import NotificationSliceReducer from './reducers/Notification/NotificationSlice';
import VoucherKioskSliceReducer from './reducers/Voucherkiosk/VoucherKioskSlice';
import OrderDetailsIdSliceReducer  from './reducers/Orderdetailsid/OrderDetailsidSlice';
import AddaddressSliceReducer from './reducers/Addaddress/AddaddressSlice';
import GetAddressSliceReducer from './reducers/Addaddress/GetAddressSlice';
import GetIdAddressSliceReducer  from './reducers/Addaddress/GetIdaddressSlice';
import DeleteaddressSliceReducer from './reducers/Addaddress/DeleteaddressSlice';
import IsDefaultaddressSliceReducer from './reducers/Addaddress/IsDefaultaddressSlice';
import UpdateAddressSliceReducer  from './reducers/Addaddress/UpdateAdressSlice';
import OutletIDSliceReducer  from './reducers/Outlet/OutletIdSlice';
import HeaderHeightSliceReducer  from './reducers/HeaderHeight';
import FooterHeightSliceReducer  from './reducers/FooterHeight';
import FooterHideSliceReducer  from './reducers/Footerhideshow';
import AddtocartRefreshSliceReducer  from './reducers/Addtocartrefresh';
import AddtocartitemwithoutloginSliceReducer  from './reducers/addtocartitemSlice';
import CartDraweOpenSliceReducer  from './reducers/CartdraweropenSlice';
import CartItemAddedSliceReducer  from './reducers/cartItemAddedSlice';
import SubcategoriesSliceReducer from './reducers/SubcategoriesSlice';
import AddRefreshcountSliceReducer  from './reducers/AddrefreshCountSlice';
import CartClearcountSliceReducer  from './reducers/CartClearslicecount';
import ReservationSliceReducer from './reducers/Reservation/ReservationSlice';
import ReservationOutletSliceReducer  from './reducers/reservationOutlets/ReservationOutlets';
import CategoryFaqSliceReducer from './reducers/Faq/CategoryFaqSlice';
import GetinTouchSliceReducer  from './reducers/getinTouch/GetinTouch';
import ChangePasswordSliceReducer from './reducers/ChangePassword/ChangePasswordSlice';
import FlashDealSliceReducer from './reducers/FlashDeal/FlashDealSlice';
import FlashDealDetailsSliceReducer  from './reducers/FlashDeal/FlashDealDetails';
import FlashDealLimitSliceReducer  from './reducers/FlashDeal/FlashDealLimit';
import FlashDealCouponSliceReducer from './reducers/FlashDeal/FlashDealCouponSlice';
import FlashDealAddCouponSliceReducer  from './reducers/FlashDeal/FlashDealAddCoupon';
import FlashDealCouponListSliceReducer from './reducers/FlashDeal/FlashDealCouponListSlice';
import FlashDealDrawerSliceReducer from './reducers/FlashDealDrawerSlice';
import FlashDealApplySliceReducer from './reducers/FlashDeal/FlashDealApplySlice';
import FlashDealCouponRemoveSliceReducer from './reducers/FlashDeal/FlashDealCouponRemoveSlice';
import SigninSliceReducer from './reducers/Signin/SigninSlice';
import SignupSliceReducer from './reducers/Signin/SignupSlice';
import ForgotPasswordSliceReducer from './reducers/ForgotPassword/ForgotPasswordSlice';

// import rootReducer from './reducers/index'

const rootReducer = combineReducers({

    Baseurlapi: BaseUrlSliceReducer,
    Locationlatlong: LocationlatlongSliceReducer,
    Categorieswithoutlogin: CategorieswithoutloginSliceReducer,
    Choosemode: ChoosemodeSliceReducer,
    Outletfetch: OutletSliceReducer,
    modeSlice: modeSliceReducer,
    DrawerSlice:DrawerSliceReducer,
    Itemdetails:ItemdetailsSliceReducer,
    LoginDrawerSlice:LoginDrawerSliceReducer,
    ModifierSlice:ModifierSliceReducer,
    ItemQuantitySlice:ItemQuantitySliceReducer,
    SugarlevelSlice:SugarlevelSliceReducer,
    ContinueasguestSlice:ContinueasguestSliceReducer,
    ContinuewithphoneSlice:ContinuewithphoneSliceReducer,
    OtpverifiedSlice:OtpverifiedSliceReducer,
    AddStripeCardSlice:AddStripeCardSliceReducer,
    GetStripeCardSlice:GetStripeCardSliceReducer,
    LoginCartSlice:LoginCartSliceReducer,
    GetLoginCartSlice:GetLoginCartSliceReducer,
    DeleteLoginCartSlice:DeleteLoginCartSliceReducer,
    GetLoginCartItemSlice:GetLoginCartItemSliceReducer,
    UpdateItemQuantitySlice:UpdateItemQuantitySliceReducer,
    UpdateLoginCartSlice:UpdateLoginCartSliceReducer,
    CouponlistCSlice:CouponlistCSliceReducer,
    ApplyCouponSlice:ApplyCouponSliceReducer,
    RemoveCouponSlice:RemoveCouponSliceReducer,
    StripepaymentSlice:StripepaymentSliceReducer,
    StateChangeSlice:StateChangeSliceReducer,
    TotalamountwithoutloginSlice:TotalamountwithoutloginSliceReducer,
    CheckoutStatusSlice:CheckoutStatusSliceReducer,
    FilteridcartmatchSlice:FilteridcartmatchSliceReducer,
    PaymentOrderIdSlice:PaymentOrderIdSliceReducer,
    OrderAgainSlice:OrderAgainSliceReducer,
    OrderPdfReceiptSlice:OrderPdfReceiptSliceReducer,
    OutletSchdeuleSlice:OutletSchdeuleSliceReducer,
    RecomendedOrderSlice:RecomendedOrderSliceReducer,
    TimeSlotCheckSlice:TimeSlotCheckSliceReducer,
    CartClearSlice:CartClearSliceReducer,
    ProfileUpdateSlice:ProfileUpdateSliceReducer,
    ProfileGetSlice:ProfileGetSliceReducer,
    ProfilenumberchangeSlice:ProfilenumberchangeSliceReducer,
    VerifynumberSlice:VerifynumberSliceReducer,
    FaqSlice:FaqSliceReducer,
    RewardsPointsSlice:RewardsPointsSliceReducer,
    PhoneverifymethodSlice:PhoneverifymethodSliceReducer,
    LogoutDrawerSlice:LogoutDrawerSliceReducer,
    OrderHistorySlice:OrderHistorySliceReducer,
    HeaderCheckSlice:HeaderCheckSliceReducer,
    OrderDetailsSlice:OrderDetailsSliceReducer,
    NotificationSlice:NotificationSliceReducer,
    VoucherKioskSlice:VoucherKioskSliceReducer,
    OrderDetailsIdSlice:OrderDetailsIdSliceReducer,
    AddaddressSlice:AddaddressSliceReducer,
    GetAddressSlice:GetAddressSliceReducer,
    GetIdAddressSlice:GetIdAddressSliceReducer,
    DeleteaddressSlice:DeleteaddressSliceReducer,
    IsDefaultaddressSlice:IsDefaultaddressSliceReducer,
    UpdateAddressSlice:UpdateAddressSliceReducer,
    OutletIDSlice:OutletIDSliceReducer,
    HeaderHeightSlice:HeaderHeightSliceReducer,
    FooterHeightSlice:FooterHeightSliceReducer,
    FooterHideSlice:FooterHideSliceReducer,
    AddtocartRefreshSlice:AddtocartRefreshSliceReducer,
    AddtocartitemwithoutloginSlice:AddtocartitemwithoutloginSliceReducer,
    CartDraweOpenSlice:CartDraweOpenSliceReducer,
    CartItemAddedSlice:CartItemAddedSliceReducer,
    SubcategoriesSlice:SubcategoriesSliceReducer,
    AddRefreshcountSlice:AddRefreshcountSliceReducer,
    CartClearcountSlice:CartClearcountSliceReducer,
    ReservationSlice:ReservationSliceReducer,
    ReservationOutletSlice:ReservationOutletSliceReducer,
    CategoryFaqSlice:CategoryFaqSliceReducer,
    GetinTouchSlice:GetinTouchSliceReducer,
    ChangePasswordSlice:ChangePasswordSliceReducer,
    FlashDealSlice:FlashDealSliceReducer,
    FlashDealDetailsSlice:FlashDealDetailsSliceReducer,
    FlashDealLimitSlice:FlashDealLimitSliceReducer,
    FlashDealCouponSlice:FlashDealCouponSliceReducer,
    FlashDealAddCouponSlice:FlashDealAddCouponSliceReducer,
    FlashDealCouponListSlice:FlashDealCouponListSliceReducer,
    FlashDealDrawerSlice:FlashDealDrawerSliceReducer,
    FlashDealApplySlice:FlashDealApplySliceReducer,
    FlashDealCouponRemoveSlice:FlashDealCouponRemoveSliceReducer,
    SigninSlice:SigninSliceReducer,
    SignupSlice:SignupSliceReducer,
    ForgotPasswordSlice:ForgotPasswordSliceReducer
});

// export const store = configureStore({
//   reducer: {},
// })

export const store = createStore(rootReducer, applyMiddleware(thunk))