import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    footerOuter: {
        padding: "2rem 0",
        background: `${ColorTheme.lightBrown} !important`,
        position: 'sticky',
        top: '100vh',
        "@media(max-width:640px)": {
            // display:'none',
            // padding: "0 0",
        }
    },
    footerInner: {
        display: "flex",
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        "& > li": {
            flex: '1',
            "@media(max-width:640px)": {
                flex: '50%',
            }
        },
    },
    links: {
        "& h6": {
            paddingBottom: '8px',
            fontFamily: 'var(--sub-heading)',
            fontWeight: '700',
            textTransform: 'capitalize'
        },
        "& a": {
            textTransform: 'capitalize',
            paddingBottom: '8px',
            transition: '.5s ease',
            fontSize:'16px',
            "&:hover": {
                transition: '.5s ease',
                color: ColorTheme.black,
            }
        }
    },
    address: {
        "@media(max-width:640px)": {
            width: '100% !important',
            marginTop: "20px",
            flexBasis: 'auto !important',
        },
        "& > ul": {
            display: 'flex',
            marginBottom: "20px",
            "& li": {
                marginRight: '12px',
            },
            "& svg": {
                height: '35px',
                width: '35px',
            }
        }
    },
    logo: {
        maxWidth: "140px",
        marginBottom: '20px',
        "& img": {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
        }
    },
    bottomPadding: {
        "@media(max-width:640px)": {
            paddingBottom: '8rem',
        }
    },
    h6: {
        textTransform: 'capitalize',
    }
})