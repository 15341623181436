import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    toogleBtnBox: {
        flexWrap: 'wrap',
        "@media(max-width:640px)": {
            width: '100%',
        },
        "& button": {
            width: "auto",
            minWidth: '150px',
            height: "auto",
            padding: "15px",
            borderRadius: "10px !important",
            background: ColorTheme.white,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "80px",
            minWidth: '180px',
            border: "none",
            margin: "0 15px 15px 0",
            "&:hover": {
                background: ColorTheme.white,
            },
            "&:last-child": {
                marginRight: '0',
            },
            "@media(max-width:640px)": {
                width: '47%',
                minWidth: 'auto',
            },
        },
    },
    active: {
        backgroundColor: ColorTheme.yellow,
        "& h5": {
            color: `${ColorTheme.secondaryColor} !important`,
        },
        "& p": {
            color: `${ColorTheme.secondaryColor} !important`,
        }
    }
})