
import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Signin_failure, Signin_request, Signin_success } from "../../reducers/Signin/SigninSlice";

export const EmailSigninAPI = (payload) => async (dispatch, getState) => {
    dispatch(Signin_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    // console.log("timeslotpayload", payload?.timeslot)
    try {

        const { data } = await axios.post(`${APIURL.signin}`, payload, {
            headers: {
                "url":Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        localStorage.setItem("Logindetails",JSON.stringify(data))
        dispatch(Signin_success(data))


    } catch (error) {
        dispatch(Signin_failure(error?.response?.data))
    }
}
