import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    customSelect: {
        border: '1px solid #f1eedb',
        background: 'var(--bs-white) !important',
        borderRadius: '30px',
        marginBottom: '25px',
        overflow: 'hidden',
        "& div": {
            width: '100%',
            fontFamily: 'var(--sub-heading-family)',
            background: `${ColorTheme.yellow} !important`,
            color: `${ColorTheme.secondaryColor} !important`,
        },
        "& p": {
            color: `${ColorTheme.secondaryColor} !important`,
            marginBottom: '2px',
        },
        "& span": {
            color: ColorTheme.white,
            fontStyle: 'inherit',
            fontSize: '13px',
        },
    },
    "& .MuiMenuItem-root": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
    "& .Mui-selected": {
        backgroundColor: `${ColorTheme.yellow} !important`,
    },
})
