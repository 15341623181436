import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ContinuewithphoneSlice = createSlice({
    name: 'Continuewithphone',
    initialState,
    reducers: {
        Continue_with_phone_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Continue_with_phone_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Continue_with_phone_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Continue_with_phone: (state, action) => {
            state.error = {};
            state.loading = false;
            state.value = {};
        }
    }
})

// Action creators are generated for each case reducer function
export const { Continue_with_phone_request, Continue_with_phone_success, Continue_with_phone_failure,Clear_Continue_with_phone } = ContinuewithphoneSlice.actions

export default ContinuewithphoneSlice.reducer