import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    rewardBox: {
        margin: '2rem 0',
        "& > a": {
            padding: '20px',
            borderRadius: '10px',
            background: ColorTheme.white,
        }
    },
    stareImg: {
        marginRight: '15px',
        "& img": {
            height: '75px',
            width: '75px',
        }
    },
    frequentlyQues: {
        "& > div": {
            marginBottom: '10px !important',
            "@media(max-width:640px)": {
                marginBottom: '0 !important',
                borderBottom: `1px solid ${ColorTheme.lightGray}`,
            }
        }
    },
    accordionContainer: {
        "& > div": {
            background: ColorTheme.lightBrown,
            "@media(max-width:640px)": {
                background: ColorTheme.transparent,
                padding: '0 0 !important',
                marginBottom: '0',
            },
            "& h6": {
                fontFamily: "var(--sub-heading-family)",
            }
        },
    },
    congratBox: {
        margin: '20px auto',
        textAlign: 'center',
    },
    successMark: {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        objectFit: 'cover',
        backgroundColor: ColorTheme.green,
        margin: '30px auto 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media(max-width:640px)": {
            width: '65px',
            height: '65px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
            maxWidth: '55px',
            "@media(max-width:640px)": {
                maxWidth: '40px',
            },
        }
    },
    checkBox: {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        objectFit: 'cover',
        backgroundColor: ColorTheme.red,
        margin: '10px auto 23px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media(max-width:640px)": {
            width: '65px',
            height: '65px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
            maxWidth: '35px',
            "@media(max-width:640px)": {
                maxWidth: '25px',
            },
        }
    },
    arrowContent: {
        // width: '20%',
        "& a": {
            height: '35px',
            width: '35px',
            background: ColorTheme.lightBrown,
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            "@media(max-width:768px)": {
                background: ColorTheme.lightBrown,
            },
            "& img": {
                height: '18px',
                width: '18px',
            }
        }
    }
})