import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const AddtocartitemwithoutloginSlice = createSlice({
  name: 'Addtocartitemwithoutlogin',
  initialState,
  reducers: {
 
    Addtocart_item_withoutlogin_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Addtocart_item_withoutlogin_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
    Addtocart_item_withoutlogin_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const { Addtocart_item_withoutlogin_request ,Addtocart_item_withoutlogin_success,Addtocart_item_withoutlogin_failure} = AddtocartitemwithoutloginSlice.actions

export default AddtocartitemwithoutloginSlice.reducer