import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Styles } from "./Style";
import { NavLink } from "react-router-dom";
import plusIcon from './../../images/plusIcon.svg';
import minimizeIcon from './../../images/minimizeIcon.svg'
import { useDispatch } from "react-redux";
import { Sugar_success } from "../../redux/reducers/SugarLevelSlice";
const SugarPercentage = (props) => {
    const { classes,sugarlevel } = props;
    const [counter, setCounter] = useState(0);
    const dispatch=useDispatch();
    useEffect(()=>{
        setCounter(sugarlevel!=undefined?sugarlevel:0)
    },[sugarlevel])
    const increase = () => {
        if (counter === 100) {
            return;
        }
        setCounter(counter + 25);
    };

    const decrease = () => {
        if (counter === 0) {
            return;
        }
        setCounter(counter - 25);
    };
    useEffect(() => {
        dispatch(Sugar_success(counter))
        localStorage.setItem("sugarlevel", counter)
    }, [counter])
    // console.log("sugarlevel",sugarlevel)
    return (
        <div className={classes.countBox}>
            <NavLink onClick={() => decrease()}><img src={minimizeIcon} alt="Not Found" /></NavLink>
            <span className={classes.countNumber}>{counter}%</span>
            <NavLink onClick={() => increase()}><img src={plusIcon} alt="Not Found" /></NavLink>
        </div>
    )
}

export default withStyles(Styles)(SugarPercentage); 
