import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    card: {
        padding: '20px',
        borderRadius: '30px',
        backgroundColor: ColorTheme.lightYellow,
        "& > ul": {
            "& > li": {
                padding: '8px 0',
                borderBottom: '1px solid #D5D4DC',
                "&:last-child": {
                    borderBottom: 'none',
                }
            },
        }
    },
    removeBtn: {
        "& button": {
            border: `2px solid ${ColorTheme.yellow}`,
            backgroundColor: `${ColorTheme.transparent} !important`,
            minWidth:'100px',
            color: `${ColorTheme.fontColor} !important`,
            "& svg": {
                color: `${ColorTheme.fontColor} !important`,
            },
            "&:hover": {
                color: `${ColorTheme.fontColor} !important`,
            },
            "& span": {
                marginRight: '0 !important',
            }
        }
    },
    proceedBtn: {
        "& button": {
            justifyContent: 'center',
        }
    }
})