import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BackArrow from "../../Components/BackArrow/BackArrow";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import SearchBox from "../../Components/SearchBox/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { OutletfetchAPI } from "../../redux/Action/Outlet/OutletAction";
import Loader from "../../Components/Loader/Loader";
import { ItemQuantity_success } from "../../redux/reducers/Modifier/ItemQuantitySlice";
import ProductOutlet from "../../Components/ProductOutlet/ProductOutlet";
import { useTranslation } from 'react-i18next';
const PickUpOutlet = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const Outletfetch = useSelector(state => state?.Outletfetch)
    const modename = useSelector(state => state?.modeSlice?.value);
    const [isOpen, setIsOpen] = useState(false);
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const [pickupsearch, setpickupsearch] = useState("")
    const [pickupsearchcheck, setpickupsearchcheck] = useState(false)
    const snackHandler = (event, reason) => {
        setIsOpen(false);

    };
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(ItemQuantity_success(1));
        dispatch(OutletfetchAPI(modename))
    }, [])
    useEffect(() => {
        if (Outletfetch?.error?.message) {
            setIsOpen(true)
        }
    }, [Outletfetch])
    const searchtextbox = () => {
        dispatch(OutletfetchAPI(modename, pickupsearch))
    }
    useEffect(() => {
        console.log("pickupsearch",pickupsearch)
        const delay = 500; 
        const timerId = setTimeout(() => {
            if(pickupsearchcheck==true){

                searchtextbox();
            }
        }, delay);

        return () => clearTimeout(timerId);
    }, [pickupsearch]);
  
    return (
        <>
            {
                Outletfetch?.loading == true && (
                    <Loader />
                )
            }
          
            <ContainerBox>
                <div className={classes.orderPickUp}>
                    {
                        isappwebviewcheck != "acecard" && (

                            <BackArrow clickHandler={() => navigate(-1)} />

                        )
                    }
                    <h2 className={CommonStyle.mb10}>{t("Pick-up Now")}</h2>
                    <SearchBox setpickupsearch={setpickupsearch} setpickupsearchcheck={setpickupsearchcheck} pickupsearch={pickupsearch} />
                    <div className={classes.outletOuter}>
                        {
                            Outletfetch?.value?.data?.outlets?.map((data) => (
                                <ProductOutlet key={data?.outlet_id} url={data?.outlet_image} title={data?.outlet_name} offer={data?.item_price} description={data?.description} price={data?.item_price} discount={data?.item_price} item_id={data?.outlet_id} address_line1={data?.address_line1} open_today={data?.open_today} timeSlot={data?.timeSlot} schedule={data?.schedule} operating_hours={data?.operating_hours}/>
                            ))
                        }
                     
                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(PickUpOutlet);