import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FlashDealApplySlice = createSlice({
    name: 'FlashDealApply',
    initialState,
    reducers: {

        FlashDealApply_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        FlashDealApply_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        FlashDealApply_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_FlashDealApply_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  FlashDealApply_request, FlashDealApply_success,FlashDealApply_failure, Clear_FlashDealApply_Cart } = FlashDealApplySlice.actions

export default FlashDealApplySlice.reducer