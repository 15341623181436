import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const OutletSchdeuleSlice = createSlice({
    name: 'OutletSchdeule',
    initialState,
    reducers: {

        Outlet_Schdeule_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Outlet_Schdeule_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Outlet_Schdeule_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Outlet_Schdeule_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { Outlet_Schdeule_request, Outlet_Schdeule_success,Outlet_Schdeule_failure, Outlet_Schdeule_Cart } = OutletSchdeuleSlice.actions

export default OutletSchdeuleSlice.reducer