import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { GetLoginCartAPI } from "./GetLoginCartAction";
import { Update_Login_Cart_failure, Update_Login_Cart_request, Update_Login_Cart_success } from "../../reducers/LoginCart/UpdateLoginCartSlice";
import { Headers } from "../../../APIHandler/HeaderAPI";
export const UpdateLoginCartItemAPI = (payload) => async (dispatch, getState) => {
    dispatch(Update_Login_Cart_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.post(`${APIURL.updateLoginItemCart}`, payloads, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        // console.log("updateitem", data)
        dispatch(Update_Login_Cart_success(data))
        dispatch(GetLoginCartAPI())
    } catch (error) {
        dispatch(Update_Login_Cart_failure(error?.response?.data))
    }
}
