import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Headers } from "../../../APIHandler/HeaderAPI"
import { Add_Address_failure, Add_Address_request, Add_Address_success } from "../../reducers/Addaddress/AddaddressSlice"
import { Get_Address_failure, Get_Address_request, Get_Address_success } from "../../reducers/Addaddress/GetAddressSlice"
import { Is_Default_address_failure, Is_Default_address_request, Is_Default_address_success } from "../../reducers/Addaddress/IsDefaultaddressSlice"
import { GetAddressAPI } from "./GetAddress"


export const IsDefaultAddressAPI = (address) => async (dispatch, getState) => {
    dispatch(Is_Default_address_request())

    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));


    try {
        const addtocart = localStorage.getItem("addtocart")

        const { data } = await axios.get(`${APIURL.isdefaultaddress}/${address}`, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Is_Default_address_success(data))
        dispatch(GetAddressAPI())

    } catch (error) {
        dispatch(Is_Default_address_failure(error?.response?.data))
        // console.log("otpverified",error?.response?.data)
    }
}