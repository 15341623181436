import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import BackArrow from '../../Components/BackArrow/BackArrow';
import { NavLink } from "react-router-dom";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import StrConstent from "../../Common/StrConstent";
import OrderCardBox from "../../Components/OrderCardBox/OrderCardBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { OrderHistoryAPI } from "../../redux/Action/Orderhistory/OrderHistory";
import { OrderDetailAPI } from "../../redux/Action/OrderDetails/OrderDetails";
import Loader from "../../Components/Loader/Loader";
import { useTranslation } from "react-i18next";
const OrderDetails = (props) => {
    const { classes } = props;
    const orderhistory = useSelector(state => state?.OrderHistorySlice)
    const OrderDetails = useSelector(state => state?.OrderDetailsSlice)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(OrderDetailAPI())
        dispatch(OrderHistoryAPI())
    }, [])
    let navigate = useNavigate();



    // console.log("OrderDetails", OrderDetails)
    return (
        <>
        {
            OrderDetails?.loading  && (
                <Loader/>
            )
        }
            <ContainerBox>
                <BackArrow
                    clickHandler={() => {
                        navigate(-1)
                    }} />
                <div className={CommonStyle.flexSpaceBetween}>
                    <h3 className={CommonStyle.mb20}>{StrConstent.orderDetails.heading}</h3>
                    <NavLink to="/order-history" className={CommonStyle.links}>View order history</NavLink>
                </div>

                {OrderDetails?.value?.data?.orders?.length==0 ?
                    <div className={CommonStyle.nodata}>
                            <h4>{t("No order found")}</h4>
                        </div>:
                <>{    OrderDetails?.value?.data?.orders?.map((data) => {
                        return <>

                            <div className={classes.labelBox}>
                                <h4>{data?.month}</h4>
                            </div>

                            <div className={classes.cardOuter}>
                                <ul>
                                    {
                                        Object.keys(data?.orders).map((KeyOrders, index) => {
                                            const childOrders = data?.orders[KeyOrders];
                                            return (
                                                <Fragment key={Math.random()}>
                                                    <li key={index}>
                                                        <div className={classes.dateBox}>
                                                            <h3>{childOrders?.date.split(" ")[0]}</h3>
                                                            <h6>{childOrders?.date.split(",")[1]}</h6>
                                                        </div>
                                                        <p className={classes.ltLine}>
                                                            <span></span>
                                                        </p>
                                                        {childOrders?.orders?.map((order, index) => (
                                                            <Fragment key={index}>

                                                                <OrderCardBox
                                                                    data={order}
                                                                    isRecurring={true}
                                                                />

                                                            </Fragment>
                                                        ))}
                                                        {/* <OrderCardBox /> */}
                                                    </li>
                                                </Fragment>
                                            )

                                        })
                                    }
                                    {/* <li>
                                        <div className={classes.dateBox}>
                                            <h3>20</h3>
                                            <h6>THU</h6>
                                        </div>
                                        <p className={classes.ltLine}>
                                            <span></span>
                                        </p>
                                        <OrderCardBox />
                                    </li>
                                    <li>
                                        <div className={classes.dateBox}>
                                            <h3>21</h3>
                                            <h6>Fri</h6>
                                        </div>
                                        <p className={classes.ltLine}>
                                            <span></span>
                                        </p>

                                        <OrderCardBox />
                                    </li>
                                    <li>
                                        <div className={classes.dateBox}>
                                            <h3>22</h3>
                                            <h6>SAt</h6>
                                        </div>
                                        <p className={classes.ltLine}>
                                            <span></span>
                                        </p>
                                        <OrderCardBox />
                                    </li> */}
                                </ul>
                            </div>
                        </>
                    })}
                    </>
                }
            </ContainerBox>
        </>
    );
};
export default withStyles(Styles)(OrderDetails);
