import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    timePicker: {
        border: '1px solid #f1eedb',
        borderRadius: '32px',
        width: '100%',
        "&  div": {
            width: '100%',
            padding: '0',
            borderRadius: '32px',
            position: 'relative',
        },
        "& > div": {
            background: ColorTheme.white,
            padding: '0',
            "& input": {
                border: 'none',
                padding: '8px 20px'
            },
            "& button": {
                position: 'absolute',
                right: '15px',
                opacity: '0',
                width: '80px',
                height: '50px',
            },
            "& .MuiOutlinedInput-root ": {
                paddingRight: '0',
            },
        },
    },
    timePickerIcon: {
        position: 'absolute',
        right: '25px',
        top: '18px',
        height: '20px',
        pointerEvents: 'none',
        margin: '0  !important',
        "& svg": {
            height: '22px',
            color: ColorTheme.fontColor,
        }
    },
})
