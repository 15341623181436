// flashDeal

import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { FlashDeal_failure, FlashDeal_request, FlashDeal_success } from "../../reducers/FlashDeal/FlashDealSlice";
import { FlashDealDetails_failure, FlashDealDetails_request, FlashDealDetails_success } from "../../reducers/FlashDeal/FlashDealDetails";
import { FlashDealLimit_failure, FlashDealLimit_request, FlashDealLimit_success } from "../../reducers/FlashDeal/FlashDealLimit";
import { FlashDealCoupon_failure, FlashDealCoupon_request, FlashDealCoupon_success } from "../../reducers/FlashDeal/FlashDealCouponSlice";
import { FlashDealAddCoupon_failure, FlashDealAddCoupon_request, FlashDealAddCoupon_success } from "../../reducers/FlashDeal/FlashDealAddCoupon";
import { FlashDealCouponList_failure, FlashDealCouponList_request, FlashDealCouponList_success } from "../../reducers/FlashDeal/FlashDealCouponListSlice";
import { FlashDealApply_failure, FlashDealApply_request, FlashDealApply_success } from "../../reducers/FlashDeal/FlashDealApplySlice";
import { FlashDealCouponRemove_failure, FlashDealCouponRemove_request, FlashDealCouponRemove_success } from "../../reducers/FlashDeal/FlashDealCouponRemoveSlice";
import { GetLoginCartAPI } from "../LoginCart/GetLoginCartAction";

export const FlashDealAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDeal_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.get(`${APIURL.flashDeal}`, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(FlashDeal_success(data))

    } catch (error) {

        dispatch(FlashDeal_failure(error?.response?.data))
    }
}
export const FlashDealLimitAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDealLimit_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.get(`${APIURL.flashDeallimit}`, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(FlashDealLimit_success(data))

    } catch (error) {

        dispatch(FlashDealLimit_failure(error?.response?.data))
    }
}
export const FlashDealDetailsAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDealDetails_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.get(`${APIURL.flashDealDetails}?id=${payload}`, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(FlashDealDetails_success(data))
        localStorage.setItem("profile", JSON.stringify(data))

    } catch (error) {

        dispatch(FlashDealDetails_failure(error?.response?.data))
    }
}

export const FlashDealCouponAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDealCoupon_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.get(`${APIURL.flashDealcoupon}?filter=${payload}`, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(FlashDealCoupon_success(data))

    } catch (error) {

        dispatch(FlashDealCoupon_failure(error?.response?.data))
    }
}
export const FlashDealAddCouponAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDealAddCoupon_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.post(`${APIURL.flashDealAddcoupon}`, payload, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        dispatch(FlashDealAddCoupon_success(data))

    } catch (error) {

        dispatch(FlashDealAddCoupon_failure(error?.response?.data))
    }
}
export const FlashDealCouponListAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDealCouponList_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.get(`${APIURL.flashDeallist}?outlet_id=${payload}`, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(FlashDealCouponList_success(data))

    } catch (error) {

        dispatch(FlashDealCouponList_failure(error?.response?.data))
    }
}



export const FlashDealApplyCouponAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDealApply_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.post(`${APIURL.flashDealapply}`, payload, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        localStorage.setItem("coupon_codeflash", payload?.coupon_code)
        localStorage.setItem("promotion_idflash", payload?.promotion_id)
        dispatch(FlashDealApply_success(data))
        dispatch(GetLoginCartAPI())
    } catch (error) {

        dispatch(FlashDealApply_failure(error?.response?.data))
    }
}
export const FlashDealRemoveCouponAPI = (payload) => async (dispatch, getState) => {
    dispatch(FlashDealCouponRemove_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.post(`${APIURL.flashDealRemove}`, payload, {
            headers: {
                "url": Headers?.url,
                "mode": Headers?.mode,
                "outlet": Headers?.outlet,
                "language": Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },

        })
        dispatch(FlashDealCouponRemove_success(data))
        localStorage.removeItem("coupon_codeflash")
        localStorage.removeItem("promotion_idflash")
        window.location.reload()
    } catch (error) {

        dispatch(FlashDealCouponRemove_failure(error?.response?.data))
    }
}