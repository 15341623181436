import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { GetLoginCartAPI } from "./GetLoginCartAction";
import { Headers } from "../../../APIHandler/HeaderAPI";
export const UpdateItemQuantityAPI = (payload) => async (dispatch, getState) => {

    const payloads = payload
    // console.log("Continueasguest", payloads)
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const cart_identifier = localStorage.getItem("cart_identifier")
    try {

        const { data } = await axios.patch(`${APIURL.updateitemquantity}/${cart_identifier}/${payloads?.cart_item_id}`, payloads, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        // console.log("updateitem", data)
        dispatch(GetLoginCartAPI())
    } catch (error) {

    }
}
