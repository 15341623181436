import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Stripe_payment_failure, Stripe_payment_request } from "../../reducers/Stripepayment/StripeCheckoutSlice"
import { Checkout_status_failure, Checkout_status_request, Checkout_status_success } from "../../reducers/Stripepayment/CheckoutStatusSlice"
import { Headers } from "../../../APIHandler/HeaderAPI"
import { PaymentOrderIdAPI } from "./PaymentOrderId"


export const CheckoutstatusAPI = (payload) => async (dispatch, getState) => {

    const payloads = payload
    dispatch(Checkout_status_request())
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const order_mode=localStorage.getItem("outlet_mode")
    const timeslot=localStorage.getItem("timeslot")
    const kiosk_order_mode=localStorage.getItem("kiosk_order_mode")
    // console.log("payload", payload);
    const url = window.location.href
    const kiosk = url?.includes("kiosk") ? "1" : url?.includes("qr") ? "2" : "3";
    const cart_identifier = localStorage.getItem("cart_identifier")
    try {

        const { data } = await axios.post(`${APIURL.checkoutstatus}`, {
            cart_id: cart_identifier,
            payment_session_id: payload?.payment_session_id,
            status_key: payload?.status_key,
            order_mode:order_mode?order_mode:"Pick-Up Today",
            order_time_slot: order_mode=="Schedule Pick-Up"?timeslot:"",
            kiosk_order_mode:kiosk_order_mode
        }, {
            headers: {
                "url":Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Checkout_status_success(data))
        if(kiosk==1||kiosk==2){
            localStorage.removeItem("addtocart")
        }
        // console.log("checkoutdata", data?.data?.order_id)
        localStorage.setItem("paymentorderID", data?.data?.order_id)
        localStorage.setItem("paymentordernumber", data?.data?.order_number)
        localStorage.setItem("paymentorderoutletname", data?.data?.outlet_name)
        dispatch(PaymentOrderIdAPI(data?.data?.order_id))
      

    } catch (error) {
        dispatch(Checkout_status_failure(error?.response?.data))
        // console.log("checkouterror", error)
    }
}
