import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const LoginCartSlice = createSlice({
  name: 'LoginCart',
  initialState,
  reducers: {
 
    Login_Cart_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Login_Cart_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
    Login_Cart_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    },
    Clear_Login_Cart_failure: (state, action) => {
      state.error = {};
      state.value = {};
      state.loading = false;
  }
  }
})

// Action creators are generated for each case reducer function
export const { Login_Cart_failure ,Login_Cart_success,Login_Cart_request,Clear_Login_Cart_failure} = LoginCartSlice.actions

export default LoginCartSlice.reducer