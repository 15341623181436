import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Apply_Coupon_failure, Apply_Coupon_request, Apply_Coupon_success } from "../../reducers/Coupon/ApplyCouponSlice";
import { Headers } from "../../../APIHandler/HeaderAPI";
export const ApplyCouponListAPI = (payload) => async (dispatch, getState) => {
    dispatch(Apply_Coupon_request())
   
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const cart_identifier= localStorage.getItem("cart_identifier")
    try {

        const { data } = await axios.post(`${APIURL.applycoupon}`,{
            cart_id: cart_identifier,
            coupon_code: payload
        }, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        localStorage.setItem("couponname",payload)
        // console.log("applycoupon", data)
        dispatch(Apply_Coupon_success(data))
   
    } catch (error) {
        dispatch(Apply_Coupon_failure(error?.response?.data))
    }
}
