import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    customBtn: {
        "& button": {
            background: ColorTheme.black,
            letterSpacing: "0",
            width: "auto",
            minWidth: '200px',
            "&:hover": {
                background: ColorTheme.black,
            },
        },
    }
})