import React from 'react'
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
const PrivacyPolicy = (props) => {
    const { classes } = props;
    return (
        <>
            <ContainerBox>
                <h2 className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>{StrConstent.privacyPolicy.heading}</h2>
                <p className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>{StrConstent.privacyPolicy.description}</p>
                <p className={CommonStyle.mt20}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                <ul className={`${CommonStyle.listDots} ${classes.listing}`}>
                    <li>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                    </li>
                    <li>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                    </li>
                    <li>
                        <p>It is a long established fact that a reader will be distracted by the readable. </p>
                    </li>
                    <li>
                        <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                    </li>
                    <li>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                    </li>
                    <li>
                        <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                    </li>
                    <li>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </li>
                    <li>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                    </li>
                    <li>
                        <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                    </li>
                    <li>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </li>
                    <li>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                    </li>
                </ul>
                <p className={CommonStyle.mt20}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                <ul className={`${CommonStyle.listDots} ${classes.listing}`}>
                    <li>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                    </li>
                    <li>
                        <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                    </li>
                    <li>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </li>
                    <li>
                        <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                    </li>
                    <li>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </li>
                    <li>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                    </li>
                    <li>
                        <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                    </li>
                    <li>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </li>
                    <li>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                    </li>
                </ul>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(PrivacyPolicy);