import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    frequentlyQues: {
        marginBottom: '30px',
        "& > div": {
            marginBottom: '10px !important',
            "@media(max-width:640px)": {
                marginBottom: '0 !important',
                borderBottom: `1px solid ${ColorTheme.lightGray}`,
            }
        }
    },
    accordionContainer: {
        "& div": {
            "@media(max-width:640px)": {
                background: ColorTheme.transparent,
                padding: '0 0 !important',
                marginBottom: '0',
            },
            "& h6": {
                fontFamily: "var(--sub-heading-family)",
            }
        },
    },
    tabContainer: {
        "& div": {
            "&:nth-child(2)": {
                "& > span": {
                    backgroundColor: `${ColorTheme.yellow} !important`,
                }
            },
            '& .Mui-selected': {
                color: `${ColorTheme.yellow} !important`,
            },

        }
    },
    accordionContainer: {
        "& > div": {
            background: ColorTheme.lightBrown,
            "@media(max-width:640px)": {
                background: ColorTheme.transparent,
                padding: '0 0 !important',
                marginBottom: '0',
            },
            "& h6": {
                fontFamily: "var(--sub-heading-family)",
            }
        }
    }
})