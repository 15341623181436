import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Filterid_cartmatch_success } from "../../redux/reducers/Filteridcartmatch";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from '../../KioskComponents/CustomModal/CustomModal';
import KioskQuantityCount from '../../KioskComponents/Counter/Counter';
import StrConstent from '../../Common/StrConstent';
import { useState } from 'react';
const KioMenuItem = (props) => {
    const { classes, url, productName, price, item_id ,tabcheck} = props;
    const addtocart = localStorage.getItem("addtocart")
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const Filteridcartmatch = useSelector(state => state?.FilteridcartmatchSlice?.value)
    const [popup,setpopup]=useState(false)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
   
    // console.log("tabcheck",tabcheck)
    const handleClick = (item) => {

        const filteridmatch = addtocartwithoutlogin?.filter((data) => data?.id == item_id)
        if (filteridmatch?.length > 0) {
            dispatch(Filterid_cartmatch_success(filteridmatch))
            dispatch(Drawer_success(true))
            setpopup(true)
            // console.log("filteridmatch", filteridmatch)
            localStorage.setItem("itemId", item_id)
        } else {
            // console.log("filteridmatch", filteridmatch)
            // console.log("item", item_id);
            localStorage.setItem("itemId", item_id)
            navigate("/kio-select-outlet")
        }
        // }
    }
    const samedatapopup = () => {
        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.costomizeContent}>
                    {Filteridcartmatch?.map((data) => {
                        return <div className={classes.costomizeItem} key={data?.cart_item_id}>
                            <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                                <div className={CommonStyle.flexCenterStart}>
                                    <div>
                                        <h4>{data?.name}</h4>
                                        <p dangerouslySetInnerHTML={{
                                            __html: (data?.description),
                                        }}></p>
                                        <p>
                                            {data?.item_size} / {data?.modifier?.length>0 && data?.modifier?.map((data) => {
                                                return " " + data?.modifier_name + " " + data?.modifier_quantity + " / "
                                            })} {data?.sugarlevel ?"sugarlevel "+data?.sugarlevel:""}
                                        </p>
                                    </div>
                                </div>
                                <div className={CommonStyle.ml10}>
                                    <KioskQuantityCount itemQuantity={data?.itemQuantity} item_price={data?.item_price} cart_item_id={data?.unique_id} />
                                    {/* <h5 className={`${CommonStyle.mt10} ${CommonStyle.textCenter}`}>$ {data?.totalamount}</h5> */}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </React.Fragment>
    }
    return (
        <>
            <div className={classes.cartItem} onClick={(item) => handleClick(item)}>
                <div className={classes.imgContent}>
                    <img src={url} alt="Not Found" />
                </div>
                <h4>{productName}</h4>
                <h4 className={`${classes.price} ${CommonStyle.fw400}`}>{companydata?.currency_symbol}{price}</h4>
            </div>
            <CustomModal
                open={popup}
                // tabcheck={tabcheck}
                // title={StrConstent.cart.cardClear.heading}
                handleClose={() => setpopup(false)}
                description={""}>
                <h3 className={CommonStyle.mb20}>{StrConstent.menu.costomizations.heading}</h3>
                {samedatapopup()}
                <NavLink to="/kio-select-outlet" className={`${CommonStyle.links} ${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20}`}>Add New costomizations</NavLink>
            </CustomModal>
        </>
    )
}
export default withStyles(Styles)(KioMenuItem);