import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  error:{},
  loading: false,
}

export const GetAddressSlice = createSlice({
  name: 'GetAddress',
  initialState,
  reducers: {
    Get_Address_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Get_Address_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    },
    Get_Address_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    },
    Clear_Get_Address: (state, action) => {
        state.error = {};
        state.value = {};
        state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const {Get_Address_success, Get_Address_failure,Clear_Get_Address ,Get_Address_request} = GetAddressSlice.actions

export default GetAddressSlice.reducer