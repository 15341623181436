import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const OutletIDSlice = createSlice({
    name: 'OutletIDSlice',
    initialState,
    reducers: {
        Outlet_ID_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Outlet_ID_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Outlet_ID_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.value = {};
        },
        Clear_Outlet_ID: (state, action) => {
            state.error = {};
            state.loading = false;
            state.value = {};
        }
    }
})

// Action creators are generated for each case reducer function
export const { Outlet_ID_request, Outlet_ID_success, Outlet_ID_failure,Clear_Outlet_ID } = OutletIDSlice.actions

export default OutletIDSlice.reducer