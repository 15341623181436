import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const OtpverifiedSlice = createSlice({
    name: 'Otpverified',
    initialState,
    reducers: {
        Otp_verified_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Otp_verified_success: (state, action) => {
            state.error ={};
            state.value = action.payload;
            state.loading = false;
        },
        Otp_verified_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.value = {};
        },
        Clear_Otp_verified: (state, action) => {
            state.error ={};
            state.value = {};
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Otp_verified_request, Otp_verified_success, Otp_verified_failure ,Clear_Otp_verified} = OtpverifiedSlice.actions

export default OtpverifiedSlice.reducer