import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ForgotPasswordSlice = createSlice({
    name: 'ForgotPassword',
    initialState,
    reducers: {

        ForgotPassword_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        ForgotPassword_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        ForgotPassword_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_ForgotPassword_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  ForgotPassword_request, ForgotPassword_success,ForgotPassword_failure, Clear_ForgotPassword_Cart } = ForgotPasswordSlice.actions

export default ForgotPasswordSlice.reducer