export const Styles = Theme => ({
    spacingTop: {
        margin: '2rem 0',
        display: 'inline-block',
    },
    bottomPadding: {
        "@media(max-width:640px)": {
            paddingBottom: '9rem !important',
        }
    },
    bottomPaddingRemove: {
        "@media(max-width:640px)": {
            paddingBottom: '0',
        }
    },
})

