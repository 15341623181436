import { Styles } from "./Style";
import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import searchIcon from '../../images/searchIcon.svg';
import locationIcon from '../../images/locationIcon.svg';
import { useTranslation } from 'react-i18next';
const SearchBox = (props) => {
    const { classes, pickupsearch,setpickupsearchcheck, setpickupsearch } = props;
    const { t } = useTranslation();
    return (
        <>
            <div className={classes.searchBox} >
                <img src={searchIcon} alt="Not Found" />
                <input type="text" placeholder={t("Your address, city, or zip code")} onChange={(e) => {
                    setpickupsearchcheck(true)
                    setpickupsearch(e.target.value)}} />
                {/* <span to="" className={classes.close}><img src={locationIcon} alt="Not Found" /></span> */}
            </div>
        </>
    );
};
export default withStyles(Styles)(SearchBox);
