import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
import longArrowLeft from '../../images/longArrowLeft.svg';
const BackArrow = (props) => {
    const { classes, clickHandler } = props;
    return (
        <>
            <div className={classes.back}>
                <Button onClick={clickHandler}>
                    <img src={longArrowLeft} alt="Not Found" />
                    {/* <span>Go Back </span> */}
                </Button>
            </div>
        </>
    );
};
export default withStyles(Styles)(BackArrow);
