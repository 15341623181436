import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Get_Login_Cart_failure, Get_Login_Cart_request, Get_Login_Cart_success } from "../../reducers/LoginCart/GetLoginCartSlice"
import { Get_Login_Cart_Item_request, Get_Login_Cart_Item_success } from "../../reducers/LoginCart/GetloginCartitemSlice"
import { Headers } from "../../../APIHandler/HeaderAPI"


export const GetLoginCartItemAPI = (payload) => async (dispatch, getState) => {
    dispatch(Get_Login_Cart_Item_request())
    const payloads = payload

    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    // console.log("token", Logindetails?.data?.user_data?.auth_token);
    const cart_identifier = localStorage.getItem("cart_identifier")
    try {

        const { data } = await axios.get(`${APIURL.getlogincartitem}/${cart_identifier}/${payloads}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })

        dispatch(Get_Login_Cart_Item_success(data))


    } catch (error) {
        dispatch(Get_Login_Cart_failure(error?.response?.data))

    }
}
