import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    imgContainer: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover !important',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: ' center bottom',
        display: 'flex',
        alignItems: 'center',
        maxHeight:'650px',
        "@media(max-width:1200px)": {
            maxHeight: '530px',
        },
        "@media(max-width:640px)": {
            maxHeight: '350px',
        },
    },
    hero: {
        marginBottom: '3rem',
        position: 'relative',
        "& li": {
            borderRadius: '100%',
            border: `2px solid ${ColorTheme.yellow}`,
            background: ColorTheme.transparent,
        }
    },
})