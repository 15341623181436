import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    customBtn: {
        margin: '40px 0 0',
        maxWidth: '500px',
        width: '100%',
        "& button": {
            background: ColorTheme.yellow,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: '18px !important',
            // lineHeight: '22px !important',
            fontWeight: '400 !important',
            borderRadius: '32px !important',
            padding: '12px 24px !important',
            whiteSpace: 'nowrap !important',
            color: `${ColorTheme.secondaryColor} !important`,
            fontFamily: 'var(--main-heading-family) !important',
            "@media(max-width: 640px)": {
                // fontSize: '16px !important',
                // lineHeight: '18px !important',
            },
            "&:hover": {
                background: ColorTheme.yellow,
            },
            "& span:last-child": {
                paddingLeft: '5px',
            },
            "@media(max-width:640px)": {
                whiteSpace: 'normal !important',
            }
        },
        "@media(max-width: 991px)": {
            maxWidth: '400px',
        },
        "@media(max-width: 767px)": {
            maxWidth: '350px',
        },
        "@media(max-width: 640px)": {
            margin: '0',
            maxWidth: '100%',
            // fontSize: '16px !important',
            // lineHeight: '18px !important',
        },
        "@media(max-width:475px)": {
            // fontSize: '14px !important',
            // lineHeight: '16px !important',
        },
    },
    circle: {
        minWidth: '26px',
        minHeight: '26px',
        fontSize: '18px',
        padding: '2px',
        lineHeight: '20px',
        fontWeight: '500',
        borderRadius: '100%',
        border: `1.5px solid ${ColorTheme.fontColor}`,
        border: `1.5px solid ${ColorTheme.secondaryColor}`,
        // fontFamily: 'var( --sub-heading-family)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight:'5px',
        "@media(max-width:640px)": {
            minWidth: '20px',
            minHeight: '20px',
            fontSize: '16px !important',
            lineHeight: '18px !important',
        },
    },
    amount:{
        marginLeft:'5px',
    }
})