import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import food from '../../images/food.jpg';
import food1 from '../../images/food1.jpg';
import foot2 from '../../images/foot2.jpg';
const OurStory = (props) => {
    const { classes } = props;
    return (
        <>
            <ContainerBox>
                <h2 className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>{StrConstent.ourStory.heading}</h2>
                <p className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>{StrConstent.ourStory.description}</p>
                <h3 className={CommonStyle.mb20}>brewing coffee that's bettr for the world</h3>
                <p className={CommonStyle.mb10}>
                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing .</p>
                <p className={CommonStyle.mb10}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
                    typesetting.
                </p>
                <p className={CommonStyle.mb10}>
                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <div className={classes.flex}>
                    <div className={classes.leftColumn}>
                        <h3 className={CommonStyle.my1rem}>CHANGING LIVES THROUGH COFFEE</h3>
                        <p className={CommonStyle.mb10}>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        <p className={CommonStyle.mb20}>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        <ul className={`${CommonStyle.listDots} ${CommonStyle.mb20}`}>
                            <li>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                            </li>
                            <li>
                                <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                            </li>
                            <li>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </li>
                            <li>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                            </li>
                            <li>
                                <p>It is a long established fact that a reader will be distracted by the readable. </p>
                            </li>
                        </ul>
                    </div>
                    <div className={classes.rightColumn}>
                        <img src={food1} alt="Not Found" />
                    </div>
                </div>
                <div className={`${classes.flex} ${classes.flexDirectionReverse} ${CommonStyle.my1rem}`}>
                    <div className={`${classes.leftColumn} ${classes.paddingLeft}`}>
                        <h3 className={CommonStyle.my1rem}>CHANGING LIVES THROUGH COFFEE</h3>
                        <p className={CommonStyle.mb10}>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        <p className={CommonStyle.mb20}>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        <ul className={`${CommonStyle.listDots} ${CommonStyle.mb20}`}>
                            <li>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                            </li>
                            <li>
                                <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution </p>
                            </li>
                            <li>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </li>
                            <li>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text </p>
                            </li>
                            <li>
                                <p>It is a long established fact that a reader will be distracted by the readable. </p>
                            </li>
                        </ul>
                    </div>
                    <div className={classes.rightColumn}>
                        <img src={food} alt="Not Found" />
                    </div>
                </div>
                <div className={CommonStyle.my1rem}>
                    <h3 className={CommonStyle.my1rem}>sustainability in our roots</h3>
                    <p className={CommonStyle.mb20}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia,
                        looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in
                        classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"
                        (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance.
                        The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
                    <div className={classes.imgContent} ><img src={foot2} alt="Not Found" /></div>
                </div>
            </ContainerBox >
        </>
    )
}
export default withStyles(Styles)(OurStory);