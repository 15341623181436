export const Styles = Theme => ({
    timeSlotBox: {
        border: '1px solid #f1eedb',
        background: 'var(--bs-white) !important',
        borderRadius: '30px',
        marginTop: '8px',
        // paddingLeft: '12px',
        "& div": {
            width: '100%',
            fontWeight: '500 !important',
        },
    }
})