import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const TimeSlotCheckSlice = createSlice({
    name: 'TimeSlotCheck',
    initialState,
    reducers: {

        TimeSlot_Check_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        TimeSlot_Check_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        TimeSlot_Check_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        TimeSlot_Check_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { TimeSlot_Check_request, TimeSlot_Check_success,TimeSlot_Check_failure, TimeSlot_Check_Cart } = TimeSlotCheckSlice.actions

export default TimeSlotCheckSlice.reducer