
import React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
const SmallButton = (props) => {
    const { classes, label, clickHandler, icon} = props;
    return (
        <div className={classes.customBtn}>
            <Button startIcon={icon} onClick={clickHandler}>
                {label}
            </Button>
        </div>
    );
};
export default withStyles(Styles)(SmallButton);
