import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const ProfileUpdateSlice = createSlice({
    name: 'ProfileUpdate',
    initialState,
    reducers: {

        Profile_Update_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Profile_Update_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Profile_Update_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Profile_Update_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  Profile_Update_request, Profile_Update_success,Profile_Update_failure, Profile_Update_Cart } = ProfileUpdateSlice.actions

export default ProfileUpdateSlice.reducer