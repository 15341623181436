import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { v4 as uuid } from 'uuid';
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import BackArrow from "../../Components/BackArrow/BackArrow";
import ContainerBox from "../../KioskComponents/ContainerBox/ContainerBox";
import { useNavigate, useParams } from "react-router";
import ToggleButton from '@mui/material/ToggleButton';
import GroupToggleBtn from "../../Components/GroupToggleBtn/GroupToggleBtn";
import CounteItem from "../../Components/CounteItem/CounteItem";
import QuantityCount from "../../Components/QuantityCount/QuantityCount";
import LargeBtn from "../../Components/LargeBtn/LargeBtn";
import { useDispatch, useSelector } from "react-redux";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import Loader from "../../Components/Loader/Loader";
import { useEffect, useState } from "react";
import { ItemdetailsfetchAPI } from "../../redux/Action/Itendetails/ItemdetailsAction";
import SugarPercentage from "../../Components/SugarPercentage/SugarPercentage";
import product7 from './../../images/product-7.png';
import product11 from './../../images/product-11.png';
import { AddLoginCartAPI } from "../../redux/Action/LoginCart/LoginCartAction";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import { UpdateLoginCartItemAPI } from "../../redux/Action/LoginCart/UpdateLogincartItemAction";
import { GetLoginCartItemAPI } from "../../redux/Action/LoginCart/Getlogincartitem";
import { PhoneverifymethodAPI } from "../../redux/Action/Phoneverifymethod/Phoneverifymethod";
import { useTranslation } from 'react-i18next';
import { Cart_Item_Added_success, Cart_add_clear_item } from "../../redux/reducers/cartItemAddedSlice";
import { Addtocart_Refres } from "../../redux/reducers/Addtocartrefresh";
const KioskSelectOutlets = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const itemdetailslocalstorage = JSON.parse(localStorage.getItem("itemdetails"))
    const itemdetails = useSelector(state => state?.Itemdetails)
    const dispatch = useDispatch();
    const unique_id = uuid();
    const [productdata, setproductdata] = useState([])
    const outletID = localStorage.getItem("outletID")
    const [selectedSize, setSelectedSize] = useState({ size: "", price: "" });
    const modifierItemlocal = (localStorage.getItem("modifierItem"));
    const modifierItem = useSelector(state => state?.ModifierSlice)
    const [modifieramount, setmodifieramount] = useState(0)
    const [itemamount, setitemamount] = useState(0);
    const ItemQuantity = useSelector(state => state?.ItemQuantitySlice?.value)
    const addtocartss = (localStorage.getItem("addtocart"))
    const sugarlevel = localStorage.getItem("sugarlevel")
    const [addtocart, setaddtocart] = useState([])
    const [editcartitem, seteditcartitem] = useState([]);
    const [itemsize, edititemsize] = useState([])
    const Sugarlevelvalue = useSelector(state => state?.SugarlevelSlice?.value)
    const Logindetails = localStorage.getItem("Logindetails")
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const [isOpen, setIsOpen] = useState(false);
    const GetLoginCartItem = useSelector(state => state?.GetLoginCartItemSlice?.value)
    const cart_identifier = localStorage.getItem("cart_identifier");
    const logincheck = (Logindetails && Logindetails != undefined) ? true : false;
    const outlet_mode = localStorage.getItem("outlet_mode")
    const Phoneverifymethod = useSelector(state => state?.PhoneverifymethodSlice)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const { id } = useParams();
    const { t } = useTranslation();
    const address_id = localStorage.getItem("address_id")
    const [refreshcount,setrefreshcount]=useState(0)
    useEffect(() => {

        dispatch(PhoneverifymethodAPI())
    }, [])
    useEffect(() => {

        const addfilteritem = (addtocartss != "undefined" && addtocartss != "undefined" && addtocartss) ? JSON.parse(addtocartss) : []
        const filteritem = addfilteritem?.filter((index) => index?.unique_id == id)
        // console.log("addtocart", filteritem)
        seteditcartitem(filteritem)
    }, [])

    useEffect(() => {
        const modifier = modifierItem?.value?.map((item) => {
            return item?.modifier_quantity * item?.modifier_price
        })

        if (modifier !== undefined && modifier.length > 0) {
            const modifierprice = modifier?.reduce((index, i) => {
                return index + i
            })
            // console.log("modifier", modifierprice)
            setmodifieramount(modifierprice)
        }

    }, [modifierItem])
    useEffect(() => {
        const modifier = modifierItem?.value?.map((item) => {
            return item?.modifier_quantity * item?.modifier_price
        })

        if (modifier !== undefined && modifier.length > 0) {
            const modifierprice = modifier?.reduce((index, i) => {
                return index + i
            })
            // console.log("modifier", modifierprice)
            setmodifieramount(modifierprice)
        }

    }, [])
    useEffect(() => {
        if (outletID == null) {
            localStorage.setItem("outletID", Phoneverifymethod?.value?.data?.outlet_id)
            dispatch(ItemdetailsfetchAPI(Phoneverifymethod?.value?.data?.outlet_id))
        } else {

            dispatch(ItemdetailsfetchAPI(outletID))
        }
    }, [Phoneverifymethod])
    useEffect(() => {

        if (itemdetails?.value?.size) {
            const itemsizes = {
                size: itemdetails?.value?.size[0]?.size,
                price: itemdetails?.value?.size[0]?.price
            }
            const edititemsize = {
                size: itemsize?.selectedsize?.size,
                price: (itemsize?.selectedsize?.price)
            }
            // console.log("edititemsize", edititemsize)
            setSelectedSize(
                id ? edititemsize : itemsizes
            )
        }
    }, [itemdetails])


    useEffect(() => {

        if (GetLoginCartItem?.data?.selectedsize) {
            const edititemsize = {
                size: GetLoginCartItem?.data?.selectedsize?.size,
                price: (GetLoginCartItem?.data?.selectedsize?.price?.split(".")[0])
            }

            setSelectedSize(
                edititemsize
            )
        }
    }, [GetLoginCartItem])

    useEffect(() => {
        const amountperoneQuanity = (selectedSize?.price + modifieramount);
        const amounnt = (parseFloat(selectedSize?.price) + modifieramount) * (ItemQuantity)
        // console.log("setitemamount", selectedSize?.price, modifieramount, ItemQuantity)

        setitemamount(
            amounnt?.toFixed(2)
        )

        // localStorage.setItem("itemamount", amounnt)
    }, [itemdetails, selectedSize, modifieramount, ItemQuantity])
    // console.log("totalamount", selectedSize?.price, modifieramount, ItemQuantity)

    useEffect(() => {
        if (id) {

            dispatch(GetLoginCartItemAPI(id))
        }
    }, [id])



    const handleNavigate = () => {

        dispatch(Drawer_success(true))
        const logincheck = (Logindetails && Logindetails != undefined) ? true : false;

        const itemdetailsdata = {
            "order_mode": outlet_mode,
            "cart_id": cart_identifier,
            "cart_item_id": id,
            "unique_id": unique_id,
            "outlet_id": id ? itemsize?.outlet_id : outletID,
            "address_id": outlet_mode == "Schedule Delivery" ? address_id : null,
            "id": id ? itemsize?.id : itemdetails?.value?.item_id,
            "name": id ? itemsize?.name : itemdetails?.value?.item_name,
            "url": id ? itemsize?.url : itemdetails?.value?.image_url,
            "modifier": modifierItem?.value,
            "totalamount": itemamount,
            "selectedsize": selectedSize,
            "item_price": (+selectedSize?.price + +modifieramount),
            "item_one_quantity_price": selectedSize?.price,
            "itemQuantity": ItemQuantity,
            "sugarlevel": Sugarlevelvalue,
            "size": id ? itemsize?.size : itemdetails?.value?.size,
            "description": id ? itemsize?.description : itemdetails?.value?.item_description,
            "is_sugar_enabled": id ? itemsize?.is_sugar_enabled : itemdetails?.value?.is_sugar_enabled

        }

        if (logincheck) {
            // console.log("ididid", id)
            dispatch(Addtocart_Refres(refreshcount + 29))
            if (id) {
                dispatch(UpdateLoginCartItemAPI(itemdetailsdata))
                return navigate("/cart")
            } else {
                dispatch(Cart_Item_Added_success([itemdetailsdata]))
                setTimeout(()=>{
                  dispatch(Cart_add_clear_item())

              },5000)
                dispatch(AddLoginCartAPI([itemdetailsdata]))
                return navigate("/")
            }

        } else {


            if (id) {
                const filterobj = addtocart?.filter(item => item?.unique_id != id)
                // console.log("vikalp", itemdetailsdata, itemsize)
                // console.log("vikalp", itemsize)
                localStorage.setItem("addtocart", JSON.stringify([...filterobj, itemdetailsdata]))
                dispatch(Addtocart_Refres(refreshcount + 6))
                return navigate("/cart")

            }
            else {
                dispatch(Addtocart_Refres(refreshcount + 27))
                for (let i = 0; i < addtocart?.length; i++) {

                    if (((addtocart[i]?.id) == (itemdetailsdata?.id)) &&
                        (addtocart[i]?.name == itemdetailsdata?.name) &&
                        (JSON.stringify(addtocart[i]?.selectedsize) == JSON.stringify(itemdetailsdata?.selectedsize)) &&
                        (addtocart[i]?.sugarlevel === itemdetailsdata?.sugarlevel) &&
                        (addtocart[i]?.outlet_id === itemdetailsdata?.outlet_id)
                    ) {
                        dispatch(Addtocart_Refres(refreshcount + 21))
                        const cartsame = addtocart[i]?.modifier?.every((data, ii) => {
                            return data?.modifier_quantity == itemdetailsdata?.modifier[ii]?.modifier_quantity
                        })
                        if (cartsame == true) {
                            addtocart[i].itemQuantity = addtocart[i].itemQuantity + ItemQuantity
                            localStorage.setItem("addtocart", JSON.stringify(addtocart))
                            return navigate("/")
                        }


                    }
                }
                if (addtocart?.length > 0) {
                    dispatch(Cart_Item_Added_success([itemdetailsdata]))
                    setTimeout(()=>{
                      dispatch(Cart_add_clear_item())
    
                  },5000)

                    if ((addtocart[0]?.outlet_id === itemdetailsdata?.outlet_id)) {
                        setaddtocart([...addtocart, itemdetailsdata])
                        localStorage.setItem("addtocart", JSON.stringify([...addtocart, itemdetailsdata]))
                        navigate("/")
                    } else {
                        localStorage.removeItem("addtocart")
                        localStorage.setItem("addtocart", JSON.stringify([itemdetailsdata]))
                        navigate("/")
                    }
                } else {
                    dispatch(Cart_Item_Added_success([itemdetailsdata]))
                    setTimeout(()=>{
                      dispatch(Cart_add_clear_item())
    
                  },5000)
                    setaddtocart([...addtocart, itemdetailsdata])
                    localStorage.setItem("addtocart", JSON.stringify([...addtocart, itemdetailsdata]))
                    navigate("/")
                }
            }
        }
    }

    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);

    };
    useEffect(() => {
        setaddtocart(((addtocartss != "undefined" && addtocartss != "undefined" && addtocartss) ? JSON.parse(addtocartss) : []))
    }, [])
    useEffect(() => {
        edititemsize(id ? (logincheck ? GetLoginCartItem?.data : (editcartitem[0])) : (itemdetails?.value))
    }, [editcartitem, itemdetails, GetLoginCartItem])

    return (
        <>
            {
                LoginCartSlice?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}

                        description={LoginCartSlice?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                itemdetails?.loading == true && (
                    <Loader />
                )
            }
            {
                Phoneverifymethod?.loading == true && (
                    <Loader />
                )
            }
            <ContainerBox>
                {
                    isappwebviewcheck != "acecard" && (

                        <BackArrow clickHandler={() => navigate(-1)} />
                    )
                }
                {/* <h2 className={CommonStyle.mb10}>{StrConstent.chooseMode.heading}</h2> */}
                <div className={classes.selectOutlets}>
                    <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.my1rem}`}>
                        <div className={CommonStyle.flexCenter}>
                            <div className={`${classes.itemImgContent} ${CommonStyle.objectFit} ${CommonStyle.mr10} ${CommonStyle.borderRadius10}`}>
                                <img src={id ? (logincheck ? GetLoginCartItem?.data?.url : (editcartitem !== undefined ? editcartitem[0]?.url : itemdetailslocalstorage?.image_url)) : (itemdetails?.value?.image_url ? itemdetails?.value?.image_url : itemdetailslocalstorage?.image_url)} alt="Not Found" className={CommonStyle.imgCover} />
                            </div>
                            <h3>
                                {id ? (logincheck ? GetLoginCartItem?.data?.name : (editcartitem !== undefined ? editcartitem[0]?.name : itemdetailslocalstorage.name)) : (itemdetails?.value?.item_name ? itemdetails?.value?.item_name : itemdetailslocalstorage?.item_name)}</h3>
                        </div>
                        <div className={`${CommonStyle.pl10}}`}>
                            <h5 className={`${CommonStyle.textRight} ${CommonStyle.fw700} ${classes.price}`}>
                                {companydata?.currency_symbol}
                                {id ? (logincheck ? (
                                    GetLoginCartItem?.data?.size[0]?.price != 0 ? GetLoginCartItem?.data?.size[0]?.price : GetLoginCartItem?.data?.size[1]?.price

                                ) : (editcartitem !== undefined ? (
                                    editcartitem[0]?.size[0]?.price != 0 ? editcartitem[0]?.size[0]?.price : editcartitem[0]?.size[1]?.price

                                )
                                    : (
                                        itemdetailslocalstorage?.size[0]?.price != 0 ? itemdetailslocalstorage?.size[0]?.price : itemdetailslocalstorage?.size[1]?.price

                                    )
                                ))
                                    : (itemdetails?.value?.size ? <>
                                        {itemdetails?.value?.size[0]?.price != 0 ? itemdetails?.value?.size[0]?.price
                                            : itemdetails?.data?.size[1]?.price
                                        }
                                    </> : <>
                                        {

                                            itemdetailslocalstorage?.size[0]?.price != 0 ? itemdetailslocalstorage?.size[0]?.price : itemdetailslocalstorage?.size[1]?.price
                                        }
                                    </>)
                                }
                            </h5>
                        </div>
                    </div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: id ? (logincheck ? GetLoginCartItem?.data?.description : (editcartitem !== undefined ? editcartitem[0]?.description : (itemdetailslocalstorage?.item_description))) : (itemdetails?.value?.item_description ? itemdetails?.value?.item_description : itemdetailslocalstorage?.item_description),
                        }}
                    >
                        {/* {/ {itemdetails?.value?.item_description ? itemdetails?.value?.item_description : itemdetailslocalstorage?.item_description} /} */}
                    </p>
                    <h3 className={CommonStyle.my1rem}>{t("size")}</h3>
                         {/* {/ {console.log( itemsize!=undefined?itemsize:[],"itemsize")} /} */}
                    <GroupToggleBtn>

                        {
                            itemsize != undefined ? (itemsize?.size?.map((size, index) => {
                                const isDisplay = itemsize?.size[0]?.price != 0 ? itemsize?.size[0]?.price : itemsize?.size[1]?.price;
                                const regularPrice = (
                                    itemsize?.size[1]?.price - itemsize?.size[0]?.price
                                ).toFixed(1);
                                // console.log("index", index)
                                return (size?.size === "Regular" || size?.size === "Small") && size?.price === 0 ? null : (
                                    <ToggleButton
                                        className={
                                            selectedSize.size == size?.size ?
                                                classes.active : null}
                                        value={size?.price}
                                        key={index}
                                        selected={selectedSize.size == size?.size}
                                        onClick={() =>
                                            setSelectedSize({
                                                size: size?.size,
                                                price: size?.price,
                                            })
                                        }
                                    ><h5 className={CommonStyle.mb5}>{size?.size}</h5>
                                        <p>{size?.price > 0
                                            ? size?.size === "Small"
                                                ? "-"
                                                : isDisplay === size?.price
                                                    ? "-"
                                                    : `+${companydata?.currency_symbol}` +
                                                    parseFloat(regularPrice)

                                            : "-"}</p>
                                    </ToggleButton>
                                )

                            })) : []
                        }
                    </GroupToggleBtn>
                    {
                        itemsize?.modifier?.length > 0 && (

                            <h3 className={CommonStyle.my1rem}>{t("Customizations")}</h3>
                            )
                    }

                    <div className={classes.selectItems}>
                        <CounteItem
                            // url={data.modifier_image} 
                            // itemName={data.modifier_name} 
                            // price={data.modifier_price}  
                            id={itemsize != undefined ? itemsize?.modifier : []}
                            data={itemsize?.modifier}
                        />
                    </div>

                    <div className={`${classes.content} ${CommonStyle.my1rem}`}>
                        {itemsize?.is_sugar_enabled == 1 && (
                            <div className={CommonStyle.block}>
                                  <h3 className={CommonStyle.my10}>{t("Sugar Level")}</h3>
                                   <div className={CommonStyle.flexCenter}>
                                    <div className={`${classes.smallImgContent} ${CommonStyle.objectFit} ${CommonStyle.mr10} ${CommonStyle.borderRadius10}`}>
                                        <img src={product7} alt="Not Found" className={CommonStyle.imgCover} />
                                    </div>
                                    {/* {/ <QuantityCount /> /} */}
                                    <SugarPercentage sugarlevel={itemsize?.sugarlevel} />
                                </div>
                            </div>
                        )}
                        <div className={CommonStyle.block}>
                        <h3 className={CommonStyle.my10}>{t("Quantity")}</h3>
                                 <div className={CommonStyle.flexCenter}>
                                <div className={`${classes.smallImgContent} ${CommonStyle.objectFit} ${CommonStyle.mr10} ${CommonStyle.borderRadius10}`}>
                                    {/* <img src={product11} alt="Not Found" className={CommonStyle.imgCover} /> */}
                                    <img src={id ? (logincheck ? GetLoginCartItem?.data?.url : (editcartitem !== undefined ? editcartitem[0]?.url : itemdetailslocalstorage?.image_url)) : (itemdetails?.value?.image_url ? itemdetails?.value?.image_url : itemdetailslocalstorage?.image_url)} alt="Not Found" className={CommonStyle.imgCover} />
                                </div>
                                <QuantityCount itemQuantity={itemsize?.itemQuantity} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${CommonStyle.bottomBtn} ${CommonStyle.mt2rem}`}><LargeBtn number={ItemQuantity} label={t("Add To Cart")}  amount={`${companydata?.currency_symbol}${itemamount}`} clickHandler={() => { handleNavigate() }} /></div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioskSelectOutlets);
