import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Styles } from "./Style";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ItemQuantity_success } from "../../redux/reducers/Modifier/ItemQuantitySlice";
import minimizeIcon from './../../images/minimizeIcon.svg';
import plusIcon from './../../images/plusIcon.svg';

const Counter = (props) => {
    const { classes, itemQuantity } = props;
    const [counter, setCounter] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        setCounter(itemQuantity != undefined ? itemQuantity : 1)
        dispatch(ItemQuantity_success(itemQuantity != undefined ? itemQuantity : 1));
    }, [itemQuantity])
    const increase = () => {
        setCounter(counter + 1);
        dispatch(ItemQuantity_success(counter + 1));
    };

    const decrease = () => {
        if (counter === 1) {
            return;
        }
        setCounter(counter - 1);
        dispatch(ItemQuantity_success(counter - 1));
    };
    // console.log("itemQuantity", itemQuantity)
    return (
        <div className={classes.countBox}>
            <NavLink onClick={() => decrease()}><img src={minimizeIcon} alt="Not Found" /></NavLink>
            <span className={classes.countNumber}>{counter}</span>
            <NavLink onClick={() => increase()}><img src={plusIcon} alt="Not Found" /></NavLink>
        </div>
    );
};
export default withStyles(Styles)(Counter);
