import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const GetLoginCartSlice = createSlice({
  name: 'GetLoginCart',
  initialState,
  reducers: {
 
    Get_Login_Cart_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Get_Login_Cart_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
        state.error = {};
    },
    Get_Login_Cart_failure: (state, action) => {
      state.value = {};
        state.error = action.payload;
        state.loading = false;
    },
    Clear_Login_Cart:(state, action) => {
      state.value = {};
        state.error = {};
        state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Clear_Login_Cart,    Get_Login_Cart_failure ,    Get_Login_Cart_success,    Get_Login_Cart_request} = GetLoginCartSlice.actions

export default GetLoginCartSlice.reducer