import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    otpOuter: {
        "& p": {
            textAlign: 'center',
            "@media(max-width:640px)": {
                textAlign: 'left',
            }
        },
    },
    textContent: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        "@media(max-width:640px)": {
            justifyContent: 'flex-start',
        },
        "& a": {
            paddingLeft: '5px',
        },
        "& p": {
            color: ColorTheme.darkGray
        }
    },
    otpContainer: {
        margin: '20px auto',
        "& > div": {
            justifyContent: 'center',
            "& > input": {
                height: '60px',
                width: '60px !important',
                borderRadius: '100%',
                margin: '0 10px',
                border: 'none',
                background: ColorTheme.lightBrown,
                fontSize: '16px',
                "@media(max-width:640px)": {
                    background: ColorTheme.white,
                },
                "& placeholder": {
                    fontSize: '16px',
                }
            },
        },

        "& span": {
            display: 'none'
        }
    },
    checkbox: {
        paddingRight: '5px',
        "& span": {
            "& span": {
                background: ColorTheme.lightBrown,
                "@media(max-width:640px)": {
                    background: ColorTheme.white,
                },
            }
        }
    },
    btnContent: {
        maxWidth: '300px',
        margin: '0 auto 10px',
        "@media(max-width:640px)": {
            maxWidth: '100%',
        },
        "& div": {
            width: '100%',
        },
        "& button": {
            width: '100%',
        },
    }
})