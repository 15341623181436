import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstent from '../../Common/StrConstent';
import { useNavigate } from "react-router";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import BorderButton from '../../Components/BorderButton/BorderButton';
import CustomButton from '../../KioskComponents/CustomButton/CustomButton';
import { useState } from 'react';
import { ContinuewithphoneAPI } from '../../redux/Action/Conntinuewithphone/ContinuewithphoneSlice';
import { validatePhone } from '../../Common/Validations';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ContinueasguestAPI } from '../../redux/Action/Continueasguest/Continueasguest';
import { Clear_Apply_Coupon } from '../../redux/reducers/Coupon/ApplyCouponSlice';
import { generateRandomNumber } from '../../Common/utlis/utlis';
import Loader from '../../Components/Loader/Loader';
const KioPhoneNumber = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const [loginnumber, setloginnumber] = useState("")
    const Continuewithphone = useSelector(state => state?.ContinuewithphoneSlice?.value)
    const Continuewithphoneslice = useSelector(state => state?.ContinuewithphoneSlice)
    const Continueasguest = useSelector(state => state?.ContinueasguestSlice?.value)
    const Continueasguestslice = useSelector(state => state?.ContinueasguestSlice)
    const [errornumber, seterrornumber] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()

    function handleNavigate() {

    }
    const handleKeyDown = (event) => {
        if (event.keyCode === 69) {
            event.preventDefault();
        }
    };

    const handlesubmitlogin = () => {
        if (validatePhone(loginnumber)) {
            return
        } else {


            const payload = {
                phone_number: loginnumber
            }
            dispatch(ContinuewithphoneAPI(payload))
        }
    }
    useEffect(() => {
        dispatch(Clear_Apply_Coupon())
    }, [])
    useEffect(() => {
        if (Continuewithphone?.success == true) {
            navigate('/kio-otp')
        } else if (Continuewithphoneslice?.error?.message) {
            setIsOpen(true)
        }


    }, [Continuewithphone, Continuewithphoneslice])
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    const handlecontinueasguestsubmit = () => {
        if (loginnumber?.length < 8) {
            return seterrornumber(true)
        } else {

            dispatch(ContinueasguestAPI(loginnumber))
        }
        // console.log("guestnumber", guestnumber)
        // navigate("/proceed-to-pay")
    }
    useEffect(() => {
        // console.log("Continueasguest", Continueasguest)
        if (Continueasguest?.success == true) {
            localStorage.setItem("Continueasguest", JSON.stringify(Continueasguest))
            navigate("/kio-vouchers")
            // window.location.reload()
        }
    }, [Continueasguest])
    const handleskip = () => {
        // console.log("generateRandomNumber",generateRandomNumber())
        dispatch(ContinueasguestAPI(generateRandomNumber()))
    }
    return (
        <>
            {
                Continueasguestslice?.loading == true && (
                    <Loader />
                )
            }
            <ContainerBox>
                <h2 className={CommonStyle.mb10}>{StrConstent.kisok.phoneNumber.heading}</h2>
                <p>{StrConstent.kisok.phoneNumber.description}</p>
                <p className={`${CommonStyle.fw600}`}>+x points</p>
                <div className={`${CommonStyle.fromControl} ${classes.numberField}`}>
                    <input type="number" placeholder='Please enter the number'
                        value={loginnumber}
                        onChange={(e) => {
                            setloginnumber(e.target.value)
                            seterrornumber(false)
                        }}
                        onKeyDown={handleKeyDown}
                        onInput={(e) => {
                            if (e?.target?.value != "") {
                                e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 10);
                            }
                        }}
                    />
                </div>
                {
                    errornumber && (
                        <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>Please Enter valid mobile number.</span>
                    )
                }
                <div className={`${CommonStyle.flexCenter} ${CommonStyle.JustifySpaceBetween} ${CommonStyle.kioBottomBtnContainer}`}>
                    <BorderButton label="Skip" clickHandler={() => { handleskip() }} />
                    <CustomButton label="continue" clickHandler={() => handlecontinueasguestsubmit()
                    } />
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioPhoneNumber);