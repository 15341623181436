import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    numberField: {
        margin: '20px auto 5px',
        // maxWidth:'600px',
        "& input": {
            background: `${ColorTheme.lightYellow} !important`,
            border: 'none !important',
            // minHeight: '60px',
            // padding: '35px',
        }
    }
})