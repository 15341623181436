import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import minimizeIcon from './../../images/minimizeIcon.svg';
import plusIcon from './../../images/plusIcon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { NavLink } from "react-router-dom";
import { DeleteLoginCartAPI } from "../../redux/Action/LoginCart/DeleteLoginCart";
import { useDispatch, useSelector } from "react-redux";
import { State_Change_success } from "../../redux/reducers/StateChangeSlice";
import { UpdateItemQuantityAPI } from "../../redux/Action/LoginCart/UpdateItemQuantity";
import { useEffect, useState } from "react";
import { Addtocart_Refres } from "../../redux/reducers/Addtocartrefresh";
import { LatLongLocationAPI } from "../../redux/Action/LatLongLocation";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { Add_Refresh_count_success } from "../../redux/reducers/AddrefreshCountSlice";
const MyCardItme = (props) => {
    const { classes, url, heading, description, price, quantity, orderagain, datalength, cartitemid } = props;
    const Logindetails = localStorage.getItem("Logindetails")
    const dispatch = useDispatch();
    const addtocart = localStorage.getItem("addtocart");
    const cart_identifier = localStorage.getItem("cart_identifier")
    const [counter, setCounter] = useState(0);
    const [pluscounter, setpluscounter] = useState(1)
    const [pluscountercheck, setpluscountercheck] = useState(false)
    const [minuscounter, setminuscounter] = useState(1)
    const [refreshcount, setrefreshcount] = useState(1)
    const outletID = localStorage.getItem("outletID")
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const SubcategoriesSlice = useSelector(state => state?.SubcategoriesSlice?.value)
    useEffect(() => {
        setCounter(quantity)
    }, [])


    const handleDelete = (id) => {
        const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
        if (Logindetail) {
            dispatch(Addtocart_Refres(refreshcount + 14))
            dispatch(DeleteLoginCartAPI(id?.cart_item_id))
            dispatch(Addtocart_Refres(refreshcount + 3))
        }
        else {
            // console.log("handleDelete", id?.unique_id)
            dispatch(Addtocart_Refres(refreshcount + 11))
            setrefreshcount(refreshcount + 11)
            const deleteitem = JSON.parse(addtocart)
            const deleteitemdata = deleteitem?.filter((item) => item?.unique_id !== id?.unique_id)
            // console.log("deleteitemdata", deleteitemdata)
            localStorage.setItem("addtocart", JSON.stringify(deleteitemdata));
            dispatch(LatLongLocationAPI(SubcategoriesSlice, 1, ""))
            // window.location.reload()
        }

    }
    const increase = () => {
        // setCounter(counter + 1);
        dispatch(State_Change_success(counter + 1))
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cartitemid,
            item_quantity: counter,
            item_price: description?.item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
        dispatch(LatLongLocationAPI(SubcategoriesSlice, 1, ""))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []
        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id == cartitemid ? { ...item, itemQuantity: counter } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        dispatch(Add_Refresh_count_success(refreshcount+9))
        dispatch(Addtocart_Refres(refreshcount + 5))
        setrefreshcount(refreshcount + 5)
    };
    const decrease = () => {
        // if (counter === 1) {
        //     return;
        // }
        // setCounter(counter);
        dispatch(State_Change_success(counter))
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cartitemid,
            item_quantity: counter,
            item_price: description?.item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
        dispatch(LatLongLocationAPI(SubcategoriesSlice, 1, ""))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []
        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id == cartitemid ? { ...item, itemQuantity: counter } : item
        })
        dispatch(Add_Refresh_count_success(refreshcount+1))
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        dispatch(Addtocart_Refres(refreshcount + 8))
        setrefreshcount(refreshcount + 8)
    };
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {


                decrease();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [minuscounter]);
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {

                increase();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [pluscounter]);
    return (
        <>
            <div className={classes.productItem}>
                <NavLink to="" className={classes.deleteIcon} ><DeleteIcon onClick={() => {
                    dispatch(Addtocart_Refres(refreshcount + 79))
                    handleDelete(description)
                }} /></NavLink>
                <div className={classes.imgContent}>
                    <img src={url} alt="Not Found" />
                </div>
                <div className={classes.myCardDes}>
                    <h4 className={CommonStyle.mb10}>{heading}</h4>
                    <h5 className={CommonStyle.mb10}>{companydata?.currency_symbol}{price}</h5>
                    <div className={classes.countBox}>
                        <NavLink onClick={() => {
                            if (counter > 1) {

                                setpluscountercheck(true)
                                setCounter(counter - 1);
                                setminuscounter(minuscounter + 1)
                            }
                            // decrease()
                        }}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                        <span className={classes.countNumber}>{counter}</span>
                        <NavLink onClick={() => {
                            setpluscounter(pluscounter + 1)
                            setpluscountercheck(true)
                            setCounter(counter + 1);
                            // increase()
                        }}><img src={plusIcon} alt="Not Found" /></NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withStyles(Styles)(MyCardItme);