import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    paymentOuter: {
        "& p": {
            textAlign: 'center',
        },
        "& h2": {
            textAlign: 'center',
        }
    },
    checkBox: {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        objectFit: 'cover',
        backgroundColor: ColorTheme.red,
        margin: '10px auto 23px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media(max-width:640px)": {
            margin: '10px auto 23px',
            width: '65px',
            height: '65px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
            maxWidth: '35px',
            "@media(max-width:640px)": {
                maxWidth: '25px',
            },
        }
    },
    imgContent: {
        height: 'auto',
        width: '65px',
        borderRadius: '10px',
        "& img": {
            height: 'auto',
            maxWidth: '65px',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    tryAgain: {
        "& button": {
            justifyContent: 'center',
        }
    }
})