import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,

}

export const FooterHeightSlice = createSlice({
    name: 'FooterHeight',
    initialState,
    reducers: {

        Footer_Height_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Footer_Height_success } = FooterHeightSlice.actions

export default FooterHeightSlice.reducer