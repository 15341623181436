import React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Oval } from "react-loader-spinner";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
const Loader = (props) => {
  const { classes } = props;
  return (
    <div className={classes.loaderContainer}>
      <div>
        <Oval
          ariaLabel="loading-indicator"
          height={40}
          width={40}
          strokeWidth={5}
          strokeWidthSecondary={5}
          color={`${ColorTheme.yellow}`}
          secondaryColor={`${ColorTheme.secondaryColor}`}
        />
        <p className={classes.loaderText} style={{ color: `${ColorTheme.white}` }}>... Loading</p>
      </div>
    </div>
  )
}
export default withStyles(Styles)(Loader);