import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Slider from "react-slick";
import TabPanel from '@mui/lab/TabPanel';
import { withStyles } from "@mui/styles";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Styles } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import ProductItem from '../../Components/ProductItem/ProductItem';
import RewardPoint from '../../Components/RewardPoint/RewardPoint';
import OrderBox from '../../Components/OrderBox/OrderBox';
import Loader from '../../Components/Loader/Loader';
import { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { useNavigate } from "react-router";
import { LatLongLocationAPI } from '../../redux/Action/LatLongLocation';
import { ChooseModeApi } from '../../redux/Action/choosemode/ChoosemodeAction';
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import AlertMessage from '../../Components/Alertmsg/AlertMessage';
import { Clear_Login_Cart } from '../../redux/reducers/LoginCart/GetLoginCartSlice';
import { GetLoginCartAPI } from '../../redux/Action/LoginCart/GetLoginCartAction';
import { Clear_Modifier } from '../../redux/reducers/Modifier/ModifierSlice';
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer';
import Counter from '../../Components/Counter/Counter';
import { OrderAgainAPI } from '../../redux/Action/OrderAgain/OrderAgain';
import { OutletScheduleAPI } from '../../redux/Action/OutletSchedule/OutletSchedule';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import CustomModal from '../../Components/CustomModal/CustomModal';
import { ProfilegetAPI } from '../../redux/Action/ProfileUpdate/Profileget';
import { Profile_Update_Cart } from '../../redux/reducers/ProfileUpdate/ProfileUpdateSlice';
import { RewardsPonitsAPI } from '../../redux/Action/RewardPoints/RewardPonits';
import { Container } from "@mui/material";
import { OrderHistoryAPI } from '../../redux/Action/Orderhistory/OrderHistory';
import { OrderDetailAPI } from '../../redux/Action/OrderDetails/OrderDetails';
import { NotificationAPI } from '../../redux/Action/Notification/Notification';
import { Clear_Login_Cart_failure } from '../../redux/reducers/LoginCart/LoginCartSlice';
import CustomButton from '../../Components/CustomButton/CustomButton';
import StickyScroller from "sticky-scroller";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Demo from '../Demo';
import FilterSearch from '../../Components/FilterSearch/FilterSearch';
import { Footer_Hide_success } from '../../redux/reducers/Footerhideshow';
import MultipleSelectLanguage from '../../Components/MultipleSelectLanguage/MultipleSelectLanguage';
import { Class } from '@mui/icons-material';
import DrawerRightCardItem from '../../Components/DrawerRightCardItem/DrawerRightCardItem';
import { Sub_categories_success } from '../../redux/reducers/SubcategoriesSlice';
import { Clear_location_latlong } from '../../redux/reducers/Location/LocationSlice';
import { Clear_Refresh_count_success } from '../../redux/reducers/AddrefreshCountSlice';
import { Clear_Reservation_Cart } from '../../redux/reducers/Reservation/ReservationSlice';
import useSticky from '../../CustomHooks/useSticky';
import { TimeSlot_Check_Cart } from '../../redux/reducers/TimeSlotCheck/TimeSlotCheck';
const ProductsHome = (props) => {
    const { classes } = props;
    const [desktop, setDesktop] = useState(window.innerWidth > 991);
    const { sticky, stickyRef } = useSticky();  // header sticky on scroll
    let navigate = useNavigate();
    const device = useBreakpoint();
    const dispatch = useDispatch()
    const [value, setValue] = useState('');
    const [scrollPosition, setScrollPosition] = useState(0);
    const Logindetails = localStorage.getItem("Logindetails");
    const Filteridcartmatch = useSelector(state => state?.FilteridcartmatchSlice?.value)
    const disptach = useDispatch()
    const Locationlatlong = useSelector(state => state?.Locationlatlong);
    const [subcategories, setsubcategories] = useState("");
    const [subcategoriesclass, setsubcategoriesclass] = useState(false);
    const Categorieswithoutlogin = useSelector(state => state?.Categorieswithoutlogin)
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const [isOpen, setIsOpen] = useState(false);
    const ItemQuantitySlice = useSelector(state => state?.ItemQuantitySlice?.value)
    const OrderAgain = useSelector(state => state?.OrderAgainSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const [showModel, setShowModel] = useState(false)
    const outletId = localStorage.getItem("outletID")
    const RewardsPoints = useSelector(state => state?.RewardsPointsSlice)
    const outlet_mode = localStorage.getItem("outlet_mode")
    const ReservationSlice = useSelector(state => state?.ReservationSlice)
    const outletname = localStorage.getItem("outletname")
    const [page, setpage] = useState(1);
    const [notiData, setNotiData] = useState([])
    const AddtocartRefresh = useSelector(state => state?.AddtocartRefreshSlice?.value)
    const companyoutletcount = localStorage.getItem("companyoutletcount")
    const FooterHeightSlice = useSelector(state => state?.FooterHeightSlice?.value)
    const [filterproductsearch, setfilterproductsearch] = useState("")
    const HeaderHeightSlice = useSelector(state => state?.HeaderHeightSlice?.value)
    const GetLoginCartSlice = useSelector(state => state?.GetLoginCartSlice)
    const [logincartloading, setlogincartloading] = useState(false);
    const [GetLoginCartloading, setGetLoginCartloading] = useState(false);
    const AddRefreshcount = useSelector(state => state?.AddRefreshcountSlice?.value)

    var settings = {
        dots: false,
        arrows: false,
        speed: 500,
        infinite: OrderAgain?.value?.data?.orders?.length > 3 ? true : false,
        slidesToShow: OrderAgain?.value?.data?.orders?.length > 3 ? 4 : 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '40px 0 0 0',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: OrderAgain?.value?.data?.orders?.length >= 2 ? 2 : 1,
                    infinite: true,
                    centerPadding: '20px 0 0 0',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '15px 0 0 0',
                    slidesToShow: 1,
                    infinite: true,
                }
            }
        ]
    };
    useEffect(() => {
        if (LoginCartSlice?.loading == true) {
            setlogincartloading(true)
            setTimeout(() => {
                setlogincartloading(false)
            }, 1000)
        }
    }, [LoginCartSlice])
    useEffect(() => {
        if (GetLoginCartSlice?.loading == true) {
            setGetLoginCartloading(true)
            setTimeout(() => {
                setGetLoginCartloading(false)
            }, 1000)
        }
    }, [GetLoginCartSlice])
    useEffect(() => {
        localStorage.removeItem("timeslot")
        setValue("")
        window.scrollTo(0, 0);
    }, []);


    // console.log("Filteridcartmatch", Locationlatlong)
    const searchtextbox = () => {
        dispatch(Sub_categories_success(value))
        disptach(LatLongLocationAPI(value, page, filterproductsearch))
    }
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            searchtextbox();
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [filterproductsearch]);
    console.log("productsearch", filterproductsearch)
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    //   const [page, setPage] = useState(1);
    const [searchBoxtext, setsearchboxtext] = useState(false)

    const fetchMoreData = () => {
        // Simulate fetching more data from an API
        // console.log("vikalploader")
        setLoading(true);
        if (+Locationlatlong?.value?.data?.pagination?.lastPage > page) {
            dispatch(Footer_Hide_success(false))
            setpage(page + 1)
        } else {
            dispatch(Footer_Hide_success(true))
        }
        setTimeout(() => {
            // For demonstration purposes, let's just add some dummy data
            setLoading(false);
        }, 1000); // Simulating a delay as if fetching from an API
    };
    const [scrollHeights, setscrollHeight] = useState()
    const handleScroll = () => {
        const container = document.getElementById('scroll-container');
        const scrollTopheight = container?.scrollTop;
        const { clientHeight, scrollHeight } = document.documentElement;
        const scrollTop = window.scrollY;
        // console.log("vikalp", scrollTop, clientHeight, scrollHeight)
        // Check if the user has scrolled to the bottom
        //   console.log('vikalp', scrollTop);
        setscrollHeight(scrollHeight)
        if (scrollTop + clientHeight >= scrollHeight - 380 - FooterHeightSlice && !loading) {
            fetchMoreData();
        }
    };
    const handleScrollheight = () => {
        const container = document.getElementById('scroll-container');
        const scrollTopheight = container?.scrollTop;
    }
    useEffect(() => {
        const container = document.getElementById('scroll-container');
        container.addEventListener('scroll', handleScrollheight);
        return () => {
            container.removeEventListener('scroll', handleScrollheight);
        };
    });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem("subcategoryid", newValue)
        dispatch(Sub_categories_success(newValue))
        disptach(LatLongLocationAPI(newValue, page, ""))
        setfilterproductsearch("")
        setsearchboxtext(false)
        setNotiData([])
        setpage(1)
        setsubcategories("")
    };
    useEffect(() => {
        dispatch(Clear_Login_Cart_failure())
        setNotiData([])
        setsubcategories("")
        if (Logindetail) {
            dispatch(NotificationAPI())
            dispatch(OrderDetailAPI())
            dispatch(OrderHistoryAPI())
            dispatch(ProfilegetAPI())
            dispatch(OrderAgainAPI())
        }
        setsearchboxtext(false)
        dispatch(Drawer_success(false))
        dispatch(Profile_Update_Cart())
        dispatch(Clear_Modifier())
        dispatch(Clear_Login_Cart())
        localStorage.removeItem("modifierItem")
        localStorage.removeItem("addressmodenavigate")
        localStorage.removeItem("couponname")
        disptach(ChooseModeApi())
        disptach(TimeSlot_Check_Cart())
        if (Logindetail) {
            dispatch(GetLoginCartAPI())
            dispatch(RewardsPonitsAPI())
        }
    }, [])
    const handlecustomizenavigate = () => {
        navigate("/select-outlet")
        // if (outletname && outlet_mode) {
        //     navigate("/select-outlet")
        // } else if (outlet_mode && outlet_mode != "Schedule Delivery") {
        //     if (companyoutletcount == "1" || companyoutletcount == 1) {
        //         navigate("/select-outlet")
        //     } else {
        //         navigate("/pickup-outlet")
        //     }
        // }
        // else if (outlet_mode && outlet_mode == "Schedule Delivery") {
        //     navigate("/delivery-address")
        // }
        // else {
        //     navigate("/choose-mode")
        // }
    }
    // console.log("vvvvv", subcategories, value)
    useEffect(() => {
        if (outletId) {
            dispatch(OutletScheduleAPI())
        }
        // console.log("vvvvvvvalue",value)
        dispatch(Sub_categories_success(value))
        disptach(LatLongLocationAPI(value, page, ""))
        setfilterproductsearch("")
    }, [value])

    const handleChangesubcategories = (data) => {
        // console.log("datadata", data)
        setsubcategories(data)
    }
    const handleClose = () => {
        setShowModel(false)
    }
    useEffect(() => {
        if (subcategories != "") {
            // console.log("vvvvvvvalue", subcategories)
            dispatch(Sub_categories_success(subcategories))
            disptach(LatLongLocationAPI(subcategories, page, ""))
            setfilterproductsearch("")
        }
    }, [subcategories])
    // console.log("subcategories", subcategories)

    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };

    const handleNavigate = () => {
        navigate("/cart")
        dispatch(Drawer_success(true))
        setShowModel(true)
    }
    useEffect(() => {
        if (Locationlatlong?.error?.message) {
            setIsOpen(true)
        }
    }, [Locationlatlong])
    // console.log("LoginDrawerSlice", DrawerSlice)
    const mobileWidth = () => {
        if (window.innerWidth <= 991) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", mobileWidth);
        return () => window.removeEventListener("scroll", mobileWidth);
    })


    // header fixed in top
    const handleScrollTabbar = () => {
        const position = window.scrollY;
        // console.log("scrollY", position)
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollTabbar);
        return () => {
            window.removeEventListener("scroll", handleScrollTabbar);
        };
    }, []);

    // bottom buttom poistion get of div
    // const lastDivRef = useRef(null);
    // const [totalHeight, setTotalHeight] = useState(0);
    // // const [scrollPosition, setScrollPosition] = useState(0);
    // const [lastDivBottom, setLastDivBottom] = useState(0);
    // const [isVisible, setIsVisible] = useState(false);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         // const position = window.pageYOffset;
    //         // setScrollPosition(position);
    //         // if (lastDivRef.current) {
    //         //     const lastDivRect = lastDivRef.current.getBoundingClientRect();
    //         //     const lastDivBottomPos = lastDivRect.bottom + window.scrollY;
    //         //     setLastDivBottom(lastDivBottomPos);
    //         //     setTotalHeight(lastDivBottomPos - position);
    //         // }
    //         const documentHeight = document.documentElement.scrollHeight;
    //         const scrollY = window.scrollY || window.pageYOffset;
    //         const windowHeight = window.innerHeight;
    //         const scrollBottomThreshold = 450;
    //         if (documentHeight - scrollY - windowHeight <= scrollBottomThreshold) {
    //             setIsVisible(true);
    //         } else {
    //             setIsVisible(false);
    //         }
    //         console.log(totalHeight, 'totalHeight');
    //     };
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);
    // translater
    const { t } = useTranslation();
    const handleClick = (e) => {
        i18next.changeLanguage(e.target.value);
    }
    const samedatapopup = () => {
        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.costomizeContent}>
                    {Filteridcartmatch?.map((data) => {
                        return <div className={classes.costomizeItem} key={data?.cart_item_id}>
                            <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                                <div className={CommonStyle.flexCenterStart}>
                                    <div>
                                        <h4>{data?.name}</h4>
                                        <p dangerouslySetInnerHTML={{
                                            __html: (data?.description),
                                        }}></p>
                                        <p>
                                            {data?.item_size} / {data?.modifier?.map((data) => {
                                                return " " + data?.modifier_quantity > 0 ? data?.modifier_name + " " + data?.modifier_quantity + " / " : ""
                                            })} {data?.sugarlevel ? "sugarlevel " + data?.sugarlevel : ""}
                                        </p>
                                    </div>
                                </div>
                                <div className={CommonStyle.ml10}>
                                    <Counter itemQuantity={data?.itemQuantity} item_price={data?.item_price} cart_item_id={Logindetail ? data?.cart_item_id : data?.unique_id} />
                                    {/* <h5 className={`${CommonStyle.mt10} ${CommonStyle.textCenter}`}>$ {data?.totalamount}</h5> */}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </React.Fragment>
    }
    // console.log("LoginCartSlice", LoginCartSlice)

    useEffect(() => {
        if (LoginCartSlice?.value?.message) {
            setIsOpen(true)
        }
    }, [LoginCartSlice])

    useEffect(() => {
        dispatch(Sub_categories_success(value))
        disptach(LatLongLocationAPI(value, page, ""))
        setfilterproductsearch("")
    }, [page])
    // Clear_Continue_as_guest


    useEffect(() => {
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined) {
            // console.log("vvvvvvvvvvv")
            if (searchBoxtext == true) {
                setNotiData(Locationlatlong?.value?.data?.items);
                // dispatch(Clear_location_latlong())
            }
            else {
                if (subcategories != "" && notiData?.length > 0) {

                    if (notiData?.length > 0) {
                        if (page > 1) {
                            console.log("vvvv454")
                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                            dispatch(Clear_location_latlong())
                        } else {
                            console.log("vvvv458")
                            setNotiData(Locationlatlong?.value?.data?.items);
                            // dispatch(Clear_location_latlong())
                        }
                    } else {
                        console.log("vvvv461")
                        setNotiData(Locationlatlong?.value?.data?.items);
                        // dispatch(Clear_location_latlong())
                    }
                } else {

                    if (notiData?.length > 0) {
                        if (page > 1) {
                            // console.log("vvvv467")
                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                            dispatch(Clear_location_latlong())
                        } else {
                            // console.log("vvvv471")
                            setNotiData(Locationlatlong?.value?.data?.items);
                            // dispatch(Clear_location_latlong())
                        }
                    } else {
                        // console.log("vvvv475")
                        setNotiData(Locationlatlong?.value?.data?.items);
                        // dispatch(Clear_location_latlong())
                    }
                }
            }
        }
    }, [Locationlatlong, AddtocartRefresh]);
    useEffect(() => {
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined) {
            if (searchBoxtext == true) {
                setNotiData(Locationlatlong?.value?.data?.items);
            }
            else {
                if (subcategories != "" && notiData?.length > 0) {
                    if (notiData?.length > 0) {
                        if (page > 1) {
                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                        } else {
                            setNotiData(Locationlatlong?.value?.data?.items);
                        }
                    } else {
                        setNotiData(Locationlatlong?.value?.data?.items);
                    }
                } else {

                    if (notiData?.length > 0) {
                        if (page > 1) {
                            // console.log("notificationpage", NotificationSlice?.value?.data?.notifications)
                            setNotiData([...notiData, ...Locationlatlong?.value?.data?.items]);
                        }
                    } else {
                        setNotiData(Locationlatlong?.value?.data?.items);
                    }
                }
            }
        }
    }, []);

    const ItemData = () => {
        if (Locationlatlong?.value?.data?.items != null && Locationlatlong?.value?.data?.items != undefined && AddRefreshcount && AddRefreshcount != 0) {
            console.log("datavikalps")
            setNotiData(Locationlatlong?.value?.data?.items);
            dispatch(Clear_Refresh_count_success(0))
            setpage(1)
        }
    }

    useEffect(() => {
        ItemData()
        // console.log("AddRefreshcount", AddRefreshcount)

    }, [AddRefreshcount, Locationlatlong])
    //top bar sticky
    const componentRef = useRef(null);
    const [topbarsticky, settopbarsticky] = useState("")
    useEffect(() => {
        if (componentRef.current) {
            const height = componentRef.current.clientHeight;
            settopbarsticky(componentRef.current.clientHeight)
        }
    })
    useEffect(() => {
        if (ReservationSlice?.value?.message) {

            setIsOpen(true)
            setTimeout(() => {
                dispatch(Clear_Reservation_Cart())

            }, 3000)
        }
    }, [ReservationSlice])

    useEffect(() => {
        if (outlet_mode == "" || outletId == "" || outletId==null || outletId==undefined ||outlet_mode==null ||outlet_mode==undefined) {
            navigate("/")
        }
    }, [])

    // console.log("topbarsticky", topbarsticky, HeaderHeightSlice)
    return (
        <>
            {
                Locationlatlong?.loading == true && (
                    <Loader />
                )
            }
            {
                Categorieswithoutlogin?.loading == true && (
                    <Loader />
                )
            }
            {
                GetLoginCartloading?.loading == true && (
                    <Loader />
                )
            }
            {GetLoginCartloading == true && (
                <Loader />
            )
            }
            {
                logincartloading == true && (
                    <Loader />
                )
            }
            {
                Locationlatlong?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={Locationlatlong?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            {
                (LoginCartSlice?.value?.message || LoginCartSlice?.error?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={(LoginCartSlice?.value?.message || LoginCartSlice?.error?.message)}
                        title={LoginCartSlice?.value?.message ? "Success" : "Error"}
                        type={LoginCartSlice?.value?.message ? "Success" : "Error"}
                        open={isOpen}
                    />
                )
            }
            {
                ReservationSlice?.value?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={ReservationSlice?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={isOpen}
                    />
                )
            }
            <Container maxWidth="xl" className={`${classes.sectionSpacing} ${classes.bottomSpacing} ${classes.topPadding} ${CommonStyle.relative}}`}>
                {/* {
                    (Logindetails && OrderAgain?.value?.data?.orders?.length > 0) && (
                        <>
                            <div className={`${CommonStyle.mt20}`}>
                                <RewardPoint givenPoint={RewardsPoints?.value?.data?.rewards_point ? RewardsPoints?.value?.data?.rewards_point : 0} totalPoint="125" />
                            </div>
                            <h2 className={CommonStyle.mb20}>{StrConstent.orderAgain.heading}</h2>
                        </>
                    )
                } */}
                {/* {desktop ? (
                    <>
                        {
                            OrderAgain?.value?.data?.orders?.length > 3 ? <div className={`${classes.orderSlider}`}>
                                <Slider {...settings}>
                                    {OrderAgain?.value?.data?.orders?.map((data) => (
                                        <OrderBox datalength={addtocartlogin?.value?.data?.cart_items?.length} url={data.url} heading={data.name} description={data} price={data.item_price} quantity={data?.itemQuantity} orderagain={true} key={data?.url} />
                                    ))}
                                </Slider>
                            </div> :
                                <div className={`${classes.orderContainer} ${CommonStyle.flexCenterStart}`}>
                                    {OrderAgain?.value?.data?.orders?.map((data) => (
                                        <OrderBox datalength={addtocartlogin?.value?.data?.cart_items?.length} key={data?.url} url={data.url} heading={data.name} description={data} price={data.item_price} quantity={data?.itemQuantity} orderagain={true} />
                                    ))}
                                </div>}
                    </>
                ) : (
                    <div className={`${classes.orderSlider}`}>
                        <Slider {...settings}>
                            {OrderAgain?.value?.data?.orders?.map((data) => (
                                <OrderBox datalength={addtocartlogin?.value?.data?.cart_items?.length} url={data.url} heading={data.name} description={data} price={data.item_price} quantity={data?.itemQuantity} orderagain={true} key={data?.url} AddtocartRefresh={AddtocartRefresh} />
                            ))}
                        </Slider>
                    </div>
                )
                } */}
                {/* </Container> */}
                <div id="scroll-container" className={CommonStyle.mt30}>
                    {/* <DrawerRightCardItem/> */}
                    <TabContext value={value}>
                        <div className={`${classes.fixedHeader} ${scrollPosition > 0 && `${CommonStyle.fixedTab}`}`} ref={componentRef}>
                            <div>
                                <div className={classes.flex}>
                                    {/* <h2>{StrConstent.menu.heading}</h2> */}
                                    <h2 className=''>{t("menu")}</h2>
                                    <div className={classes.filterBox}><FilterSearch filterproductsearch={filterproductsearch} setfilterproductsearch={setfilterproductsearch} setsearchboxtext={setsearchboxtext} /></div>
                                </div>
                                <TabList onChange={handleChange} variant="scrollable" scrolndlButtons={false} className={`${CommonStyle.tabsContent} ${classes.tabContainer}`}>
                                    <Tab label={t("ALL")} value={""} />
                                    {
                                        Categorieswithoutlogin?.value?.data?.map((data) => {
                                            return <Tab label={data?.category_name} value={data?.category_id} key={data?.category_id} />
                                        })
                                    }
                                </TabList>
                            </div>
                        </div>
                        {
                            Categorieswithoutlogin?.value?.data?.map((data) => {
                                return <>
                                    <div className={classes.chipsContent} key={data?.category_id}>
                                        {
                                            data?.category_id == value && (data?.child_categories?.map((childdata) => {
                                                return <Chip label={childdata?.category_name} className={
                                                    subcategories == childdata?.category_id
                                                        ? classes.active
                                                        : null
                                                } onClick={() => { handleChangesubcategories(childdata?.category_id) }} key={childdata?.category_id} />
                                            }))
                                        }
                                    </div>
                                </>
                            })
                        }
                        <TabPanel value={value} className={CommonStyle.p0}>
                            <div className={classes.productContainer}>
                                {notiData?.map((data) => {
                                    return <ProductItem key={data?.item_id} url={data?.image_url} title={data?.item_name} offer={data?.discount_off} price={data?.item_price} discount={data?.discount_off} item_id={data?.item_id} show_detail_page={data?.show_detail_page} itemdetails={data} />
                                })
                                }
                            </div>
                        </TabPanel>
                    </TabContext>
                    {/* {+Locationlatlong?.value?.data?.pagination?.lastPage > page && <div className={`${CommonStyle.flex} ${CommonStyle.JustifyCenter} ${CommonStyle.mt2rem}`}>
                        <CustomButton label={"Load more"} clickHandler={() => setpage(page + 1)} />
                    </div>} */}
                    {/* <div className={`${isVisible ? 'visible' : ''}`}> */}
                    {/* <div className={`${CommonStyle.bottomBtn} ${CommonStyle.JustifyCenter}`}>
                        <LargeBtn label={t("View Cart")} clickHandler={() => { handleNavigate() }} Logindetail={Logindetail} />
                    </div> */}
                    {/* </div> */}
                    {
                        device == "sm" ? <>
                            <RoundedDrawer>
                                <h3 className={CommonStyle.mb20}>{t("Repeat last used customizations?")}</h3>
                                {samedatapopup()}
                                <div className={CommonStyle.flexJustifyCenter}><CustomButton clickHandler={() => handlecustomizenavigate()} className={`${CommonStyle.links} ${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20} ${CommonStyle.cursorPointer}`} label={"Add New customizations"} /></div>
                            </RoundedDrawer>
                        </> : <>
                            {DrawerSlice == true && <CustomModal
                                open={true}
                                // title={StrConstent.cart.cardClear.heading}
                                handleClose={() => dispatch(Drawer_success(false))}
                                description={""}>
                                <h3 className={CommonStyle.mb20}>{t("Repeat last used customizations?")}</h3>
                                {samedatapopup()}
                                <div className={CommonStyle.flexJustifyCenter}><CustomButton clickHandler={() => handlecustomizenavigate()} className={`${CommonStyle.links} ${CommonStyle.block} ${CommonStyle.textCenter} ${CommonStyle.mt20} ${CommonStyle.cursorPointer}`} label={t("Add New customizations")} /></div>
                            </CustomModal>}
                        </>
                    }
                </div>
                {/* <Demo /> */}
            </Container >
        </>
    )
}
export default withStyles(Styles)(ProductsHome);

