import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,

}

export const AddRefreshcountSlice = createSlice({
    name: 'AddRefreshcount',
    initialState,
    reducers: {

        Add_Refresh_count_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Clear_Refresh_count_success: (state, action) => {

            state.value = 0;
            state.loading = false;
        },
        
    }
})

// Action creators are generated for each case reducer function
export const { Add_Refresh_count_success,Clear_Refresh_count_success } = AddRefreshcountSlice.actions

export default AddRefreshcountSlice.reducer