import * as React from 'react';
import { useState } from 'react';
import { withStyles } from "@mui/styles";
import blackLogo from "../../images/blackLogo.svg";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import KioMenuItem from '../../KioComponents/KioMenuItem/KioMenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Container } from '@mui/material';
import { LatLongLocationAPI } from '../../redux/Action/LatLongLocation';
import { useEffect } from 'react';
import { OutletScheduleAPI } from '../../redux/Action/OutletSchedule/OutletSchedule';
import { Header_check_success } from '../../redux/reducers/HeaderSlice';
import KioMyCartItem from '../../KioComponents/KioMyCartItem/KioMyCartItem';
import Loader from '../../KioskComponents/Loader/Loader';
import BorderButton from '../../Components/BorderButton/BorderButton';
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import { NavLink, useNavigate } from 'react-router-dom';
import { Clear_Modifier } from '../../redux/reducers/Modifier/ModifierSlice';
const KioMenu = (props) => {
    const { classes } = props;
    const [value, setValue] = useState("")
    const Categorieswithoutlogin = useSelector(state => state?.Categorieswithoutlogin)
    const [subcategories, setsubcategories] = useState("");
    const disptach = useDispatch()
    const Locationlatlong = useSelector(state => state?.Locationlatlong);
    const outletId = localStorage.getItem("outletID")
    const navigate = useNavigate();
    const addtocart = localStorage.getItem("addtocart")
    const addtocarts = addtocart ? JSON.parse(addtocart) : []
    const companydata = JSON.parse(localStorage.getItem("companydata"))
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
        disptach(LatLongLocationAPI(newValue))
        setsubcategories("")
        // console.log("newValue", newValue)
    };
    useEffect(() => {
        localStorage.removeItem("grandTotal")
        disptach(Clear_Modifier())
        disptach(Header_check_success(true))
    }, [])
    const handleChangesubcategories = (data) => {
        // console.log("datadata", data)
        setsubcategories(data)

    }
    useEffect(() => {
        if(subcategories!=""){

            disptach(LatLongLocationAPI(subcategories))
        }
    }, [subcategories])
    useEffect(() => {
        if (outletId) {

            disptach(OutletScheduleAPI())
        }
        disptach(LatLongLocationAPI(value))
       
    }, [value])

    console.log("valuevalue", value)

    const checkOutHandle = () => {
        navigate("/kio-cart")
    }
    return (
        <>
            {Categorieswithoutlogin?.loading && (
                <Loader />
            )
            }
            {
                Locationlatlong?.loading && (
                    <Loader />
                )
            }
            <ContainerBox maxWidth="xl">
                <div className={addtocarts?.length > 0 ? classes.menuLeft : classes.cartSectionRemove}>
                    <h2>{StrConstent.menu.heading}</h2>
                    <TabContext value={value}>
                        <TabList onChange={handleChange} variant="scrollable" scrollButtons={false} className={`${CommonStyle.tabsContent} ${classes.tabContainer}`}>
                            <Tab label="ALL" value={""} />
                            {
                                Categorieswithoutlogin?.value?.data?.map((data) => {
                                    return <Tab label={data?.category_name} value={data?.category_id} key={data?.category_id} />
                                })
                            }
                        </TabList>
                        {/* <TabList onChange={handleChange} variant="scrollable" scrollButtons={false} className={`${CommonStyle.tabsContent} ${classes.tabContainer}`}>
                        <Tab label="ALL" value="1" />
                        <Tab label="Popular" value="2" />
                        <Tab label="Yakult Series" value="3" />
                        <Tab label="Refreshing & Fruity" value="4" />
                        <Tab label="Hollin Refreshing Tea" value="5" />
                    </TabList> */}
                        {
                            Categorieswithoutlogin?.value?.data?.map((data) => {
                                return <>
                                    <div className={classes.chipsContent} key={data?.category_id}>
                                        {
                                            data?.category_id == value && (data?.child_categories?.map((childdata) => {
                                                return <Chip label={childdata?.category_name} className={
                                                    subcategories == childdata?.category_id
                                                        ? classes.active
                                                        : null
                                                } onClick={() => { handleChangesubcategories(childdata?.category_id) }} key={childdata?.category_id} />
                                            }))
                                        }
                                    </div>
                                </>
                            })
                        }
                        <TabPanel value={value} className={`${CommonStyle.p0} ${CommonStyle.mt1rem}  ${CommonStyle.mb2rem}  ${classes.containerInner}`}>
                            <div className={classes.itemContainer}>
                                {
                                    Locationlatlong?.value?.data?.items?.map((data) => {
                                        return <KioMenuItem key={data?.item_id} url={data?.image_url} productName={data?.item_name} price={data?.item_price} item_id={data?.item_id} tabcheck={value}/>
                                    })
                                }
                            </div>
                        </TabPanel>
                    </TabContext>
                </div>
                {addtocarts?.length > 0 &&
                    <div className={classes.scrollable}>
                        <NavLink  className={`${classes.logo} ${CommonStyle.my1rem}  ${CommonStyle.block}`}>
                            <img src={companydata?.kiosk_logo} alt="Not Found" />
                        </NavLink>
                        <h2 className={`${CommonStyle.my1rem} ${CommonStyle.textCenter} ${classes.myCartHedaing}`}>{StrConstent.kisok.mycart.heading}</h2>
                        <div className={`${classes.scrollInner} ${CommonStyle.scrollBox}`}>
                            <KioMyCartItem />
                        </div>
                        <div className={classes.checkOutBtn}>
                            <BorderButton label={"Check out"} clickHandler={checkOutHandle} />
                        </div>
                    </div>}
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioMenu);