import React, { useEffect, useState } from 'react'
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { withStyles } from "@mui/styles";
import { Styles } from './Style';
import { useTranslation } from 'react-i18next';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import FdIcon from '../../images/fdIcon.png'
import Product from '../../images/productImg.png'
import ContainerBox from '../../Components/ContainerBox/ContainerBox';
import { useDispatch, useSelector } from 'react-redux';
import { FlashDealCouponAPI } from '../../redux/Action/FlashDeal/FlashDeal';
import Loader from '../../Components/Loader/Loader';

const List = (props) => {
    const { classes } = props;
    const { t } = useTranslation();
    const [value, setValue] = useState("1");
    const dispatch = useDispatch()
    const FlashDealCouponSlice = useSelector(state => state?.FlashDealCouponSlice)
    useEffect(() => {
        dispatch(FlashDealCouponAPI("valid"))
    }, [])
    useEffect(() => {
        if(value==1){

            dispatch(FlashDealCouponAPI("valid"))
        }else if(value==2){
            dispatch(FlashDealCouponAPI("used"))
            
        }else if(value==3){
            dispatch(FlashDealCouponAPI("invalid"))

        }
    }, [value])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    console.log("value",value)
    return (
        <>
        {
            FlashDealCouponSlice?.loading && (
                <Loader/>
            )
        }
        <div className={CommonStyle.mt70}>
            <ContainerBox maxWidth="xl">
                <div>
                    <div className={CommonStyle.mb40}>
                        <h2 className={`${CommonStyle.mb10} ${classes.upperCase} ${CommonStyle.mt30}`}>{t("my coupons")}</h2>
                        {/* <p className={classes.tagLine}>To redeem this coupon, please let the outlet staff scan your QR code or have the coupon code.</p> */}
                    </div>
                    <TabContext value={value}>
                        <TabList aria-label="lab API tabs example" className={`${CommonStyle.tabsContent} ${classes.tabContainer}`} onChange={handleChange}>
                            <Tab label={t("Valid")} value="1" />
                            <Tab label={t("used")} value="2" />
                            <Tab label={t("expired")} value="3" />
                        </TabList>
                        <TabPanel value={value} className={CommonStyle.py20}>
                            {/* <p className={CommonStyle.mb20}>To use your vouchers, simply find them under the 'Promotions' section when making a payment.</p> */}
                            <div className={`${classes.gridDimension} ${classes.gap20}`}>
                                {FlashDealCouponSlice?.value?.data?.map((data) => {
                                    return <div className={`${classes.itemContainer} ${classes.allFDContainer}`}>
                                        <div className={classes.itemSlider}>
                                            <div className={`${classes.allItemTop}`}>
                                                <div className={`${CommonStyle.relative}`}>
                                                    <img src={data?.promotion?.original} alt='Product Icon' className={`${classes.allProdImg} ${classes.rounded20}`} />
                                                </div>
                                                <div className={CommonStyle.ml10} style={{width:"100%"}}>
                                                    <img src={FdIcon} className={classes.fdProdIcon} alt="Icon" />
                                                    <h3 className={classes.sliderHeading}>{data?.promotion?.name} </h3>
                                                    {/* <p className={classes.sliderContent}>{data?.promotion?.description}</p> */}
                                               {value==2 && <>   <div style={{display:"flex",justifyContent:"space-between"}}>

                                                    <h5 className={classes.sliderContent} style={{marginBottom:"2px"}}>Used On</h5>
                                                    <h5 className={classes.sliderContent} style={{marginBottom:"2px"}}>{data?.promotion?.redeemed_at?.split(" ")[0]}</h5>
                                                   </div>
                                                   <div style={{display:"flex",justifyContent:"space-between"}}>

                                                    <h5 className={classes.sliderContent} style={{marginTop:"2px"}}>PromoCode</h5>
                                                    <h5 className={classes.sliderContent} style={{marginTop:"2px"}}>{data?.promotion?.code}</h5>
                                                   </div>
                                                   </>
                                                   }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </TabPanel>
                        {/* <TabPanel value="2" className={CommonStyle.py20}>
                            Tab 2
                        </TabPanel>
                        <TabPanel value="3" className={CommonStyle.py20}>
                            Tab 3
                        </TabPanel> */}
                    </TabContext>
                </div>
            </ContainerBox>
        </div>
        </>
    )
}

export default withStyles(Styles)(List)
