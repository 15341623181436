import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  error:{},
  loading: false,
}

export const GetIdAddressSlice = createSlice({
  name: 'GetIdAddress',
  initialState,
  reducers: {
    Get_ID_Address_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Get_ID_Address_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
      state.error = {};
    },
    Get_ID_Address_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_ID_Get_Address: (state, action) => {
      state.value = {};
      state.error = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Get_ID_Address_success, Get_ID_Address_failure, Clear_ID_Get_Address, Get_ID_Address_request } = GetIdAddressSlice.actions

export default GetIdAddressSlice.reducer