import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    leftBox: {
        width: '45% !important',
        "@media(max-width:768px)": {
            width: '100% !important',
        }
    },
    chooseMode: {
        padding: '0',
        width: '55% !important',
        background: `${ColorTheme.transparent} !important`,
        "& > div": {
            "&:last-child": {
                marginBottom: '0',
            }
        },
        "@media(max-width:768px)": {
            width: '100% !important',
            background: ColorTheme.white,
        }
    },
    chooseModeBox: {
        padding: '0 20px 0 0',
        borderRadius: '10px',
        background: ColorTheme.lightBrown,
        marginBottom: '20px',
        cursor: "pointer",
        "@media(max-width:768px)": {
            background: ColorTheme.white,
        }
    },
    arrowContent: {
        width: '10%',
        "& a": {
            height: '35px',
            width: '35px',
            background: ColorTheme.lightBrown,
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            "@media(max-width:768px)": {
                background: ColorTheme.lightBrown,
            },
            "& img": {
                height: '18px',
                width: '18px',
            }
        }
    },
    productItem: {
        "& a": {
            // padding: '20px !important',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            backgroundColor: ColorTheme.lightBrown,
            position: 'relative',
            overflow: 'hidden',
            marginBottom: '20px',
        }
    },
    offerPrice: {
        borderRadius: '0px 32px 32px 0',
        padding: '10px 20px',
        backgroundColor: ColorTheme.yellow,
        position: 'absolute',
        top: '0',
        left: '0',
        "& h5": {
            color: `${ColorTheme.secondaryColor} !important`,
        }
    },
    imgContent: {
        width: "100%",
        height: '220px',
        borderRadius: '10px 10px 0 0',
        overflow: 'hidden',
        "@media(max-width:640px)": {
            height: '180px',
            // width: "82px",
            // height: '82px',
        },
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
        }
    },
    textContent: {
        // paddingLeft: "15px",
        padding: "20px",
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: 'auto',
        boxSizing: 'border-box',
        // width: 'calc(100% - 100px)',
        width: '100%',
        "& h4": {
            marginBottom: '5px',
        }
    },
    flex: {
        display: 'flex',
        alignItems: "flex-end",
        justifyContent: 'flex-end',
        "& h3": {
            marginLeft: '4px',
            lineHeight: '25px'
        }
    },

})