import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    qrCodeOuter: {
        height: '100%',
        marginBottom: '2rem',
        "@media(max-width:640px)": {
            textAlign: 'center',
        }
    },
    qrCode: {
        padding: '40px',
        borderRadius: '10px',
        background: ColorTheme.lightBrown,
        marginTop: '20px',
        boxSizing: 'border-box',
        "@media(max-width:640px)": {
            padding: '30px',
        },
        "& svg": {
            margin: 'auto',
            display: 'block',
            "@media(max-width:640px)": {
                maxWidth: '100% !important',
            }
        }
    },
    qrScanCode: {
        width: '100%',
        objectFit: 'cover',
        "& path": {
            "&:nth-child(1)": {
                stroke: '#F1EEDB',
            }
        }
    },
    orderNumber: {
        "& h4": {
            fontFamily: 'var(--sub-heading-family) !important',
            fontWeight: '700'
        },
        "@media(max-width:640px)": {
            textAlign: 'center',
        }
    },
    btnButton: {
        display: 'flex',
        justifyContent: 'center',
    },
    setBirthday: {
        textAlign: 'center',
        "& button": {
            "&:first-child": {
                margin: '15px 0 0',
            }
        }
    },
    activateBox: {
        padding: '20px',
        textAlign: "center",
    },
    btnContainer: {
        justifyContent: 'center',
        "@media(max-width:640px)": {
            flexDirection: 'column',
        },
        "& div": {
            "@media(max-width:640px)": {
                width: '100%',
            }
        },
        "& button": {
            "@media(max-width:640px)": {
                width: '100%',
                margin: '15px 0 20px',
            }
        }
    },
    mark: {
        "& path": {
            fill: `${ColorTheme.yellow}`,
        }
    }
})