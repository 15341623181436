import { useRef } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Filterid_cartmatch_success } from "../../redux/reducers/Filteridcartmatch";
import { Login_Drawer_success } from "../../redux/reducers/LoginDrawer";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import { useSyncRefHeight } from "../../CustomHooks/ElementSameHeight";
import minimizeIcon from './../../images/minimizeIcon.svg';
import plusIcon from './../../images/plusIcon.svg';
import AddIcon from '@mui/icons-material/Add';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { v4 as uuid } from 'uuid';
import { AddLoginCartAPI } from "../../redux/Action/LoginCart/LoginCartAction";
import { useState } from "react";
import { useEffect } from "react";
import { UpdateItemQuantityAPI } from "../../redux/Action/LoginCart/UpdateItemQuantity";
import { ItemQuantity_success } from "../../redux/reducers/Modifier/ItemQuantitySlice";
import Loader from "../Loader/Loader";
import { Addtocart_Refres, Addtocart_Refresh_success } from "../../redux/reducers/Addtocartrefresh";
import { Addtocart_item_withoutlogin_success } from "../../redux/reducers/addtocartitemSlice";
import { Cart_Item_Added_success, Cart_add_clear_item } from "../../redux/reducers/cartItemAddedSlice";
import { FlashDealRemoveCouponAPI } from "../../redux/Action/FlashDeal/FlashDeal";
const ProductItem = (props) => {
    const { classes, url, title, offer, price, discount, item_id, show_detail_page, itemdetails } = props;
    const GetLoginCart = useSelector(state => state?.GetLoginCartSlice?.value)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addtocart = localStorage.getItem("addtocart")
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    const Logindetails = localStorage.getItem("Logindetails");
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const ItemQuantitySlice = useSelector(state => state?.ItemQuantitySlice?.value)
    const outlet_mode = localStorage.getItem("outlet_mode")
    const outletname = localStorage.getItem("outletname")
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const companyoutletcount = localStorage.getItem("companyoutletcount")
    const cart_identifier = localStorage.getItem("cart_identifier");
    const unique_id = uuid();
    const outletID = localStorage.getItem("outletID")
    const address_id = localStorage.getItem("address_id")
    const [addtocarts, setaddtocart] = useState([])
    const [counter, setCounter] = useState(1)
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const GetLoginCartSlice = useSelector(state => state?.GetLoginCartSlice)

    const [logincartloading, setlogincartloading] = useState(false);
    const [GetLoginCartloading, setGetLoginCartloading] = useState(false);
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const [filterdatacheck, setfilterdatacheck] = useState("")

    const urlmatch = window?.location?.href
    const checkproductitem = urlmatch?.includes("product-items") ? true : false;
    const [pluscounter, setpluscounter] = useState(1)
    const [pluscountercheck, setpluscountercheck] = useState(false)
    const [minuscounter, setminuscounter] = useState(1)
    const AddtocartRefresh = useSelector(state => state?.AddtocartRefreshSlice?.value)
    const Addtocartitemwithoutlogin = useSelector(state => state?.AddtocartitemwithoutloginSlice?.value)
    const [refreshpage, setrefreshpage] = useState(1)
    // const AddtocartRefresh = useSelector(state => state?.AddtocartRefreshSlice?.value)
    // console.log("show_detail_page",show_detail_page)
    // const cart_identifier = localStorage.getItem("cart_identifier")
    const coupon_codeflash = localStorage.getItem("coupon_codeflash")
    const promotion_idflash = localStorage.getItem("promotion_idflash")
    useEffect(()=>{
        if(promotion_idflash !=null && promotion_idflash && coupon_codeflash!=null && coupon_codeflash){

        const obj = {
            coupon_code: coupon_codeflash,
            promotion_id: promotion_idflash,
            cart_id: cart_identifier
        }
        dispatch(FlashDealRemoveCouponAPI(obj))
    }
    },[])
    useEffect(() => {
        if (LoginCartSlice?.loading == true) {
            setlogincartloading(true)
            setTimeout(() => {
                setlogincartloading(false)
            }, 1000)
        }
    }, [LoginCartSlice])
    useEffect(() => {
        if (GetLoginCartSlice?.loading == true) {
            setGetLoginCartloading(true)
            setTimeout(() => {
                setGetLoginCartloading(false)
            }, 1000)
        }
    }, [GetLoginCartSlice])
    useEffect(() => {
        if (Logindetail) {
            let filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
            let filteridmatchdata = GetLoginCart?.data?.cart_items?.length > 0 && GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)?.map((data) => {
                return data?.itemQuantity
            })?.reduce((ac, intial) => {
                return ac + intial
            }, 0)
            setCounter(filteridmatchdata ? filteridmatchdata : 1)
            console.log("filteridmatcharray", filteridmatch)
        } else {

            let filteridmatch = addtocartwithoutlogin?.filter((data) => data?.id == item_id)

            let filteridmatchdata = addtocartwithoutlogin?.length > 0 && addtocartwithoutlogin?.filter((data) => data?.id == item_id)?.map((data) => {
                return data?.itemQuantity
            })?.reduce((ac, intial) => {
                return ac + intial
            }, 0)
            console.log("filteridmatchdata", filteridmatchdata)
            setCounter(filteridmatchdata)
        }
    }, [GetLoginCart, AddtocartRefresh,ItemQuantitySlice])
    useEffect(() => {
        if (Logindetail) {
            let filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
            let filteridmatchdata = GetLoginCart?.data?.cart_items?.length > 0 && GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)?.map((data) => {
                return data?.itemQuantity
            })?.reduce((ac, intial) => {
                return ac + intial
            }, 0)
            setCounter(filteridmatchdata ? filteridmatchdata : 1)
            console.log("filteridmatch", filteridmatch)
        } else {
            let filteridmatch = addtocartwithoutlogin?.filter((data) => data?.id == item_id)
            let filteridmatchdata = addtocartwithoutlogin?.length > 0 && addtocartwithoutlogin?.filter((data) => data?.id == item_id)?.map((data) => {
                return data?.itemQuantity
            })?.reduce((ac, intial) => {
                return ac + intial
            }, 0)
            console.log("filteridmatchdata", filteridmatchdata)
            setCounter(filteridmatchdata)
        }
    }, [])

    const increase = () => {

        // setCounter(counter + 1);
        const cart_item_id_filter = addtocartlogin?.value?.data?.cart_items?.filter((data => data?.id == item_id))
        let cart_item_id = Logindetail ? cart_item_id_filter[0]?.cart_item_id : itemdetails?.unique_id
        // dispatch(ItemQuantity_success(counter + 1));
        // console.log("cart_item_id", cart_item_id_filter[0]?.cart_item_id)
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cart_item_id,
            item_quantity: counter,
            item_price: price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []

        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.id == itemdetails?.item_id ? { ...item, itemQuantity: counter } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        //    console.log("addtocarts",changeaddtocarts)

    };
    // useEffect(() => {
    //     const filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
    //     dispatch(Filterid_cartmatch_success(filteridmatch))
    // }, [counter])
    const decrease = () => {

        if (counter === 1) {
            return;
        }
        const cart_item_id_filter = addtocartlogin?.value?.data?.cart_items?.filter((data => data?.id == item_id))
        let cart_item_id = Logindetail ? cart_item_id_filter[0]?.cart_item_id : itemdetails?.unique_id
        // setCounter(counter - 1);
        // dispatch(ItemQuantity_success(counter - 1));
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cart_item_id,
            item_quantity: counter,
            item_price: price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []

        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.id == itemdetails?.item_id ? { ...item, itemQuantity: counter } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        //    console.log("addtocarts",changeaddtocarts)
    };
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {
                dispatch(Addtocart_Refres(counter + 1))
                decrease();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [minuscounter, AddtocartRefresh]);
    useEffect(() => {
        const delay = 500; // Adjust the debounce delay in milliseconds
        const timerId = setTimeout(() => {
            if (pluscountercheck == true) {
                dispatch(Addtocart_Refres(counter + 1))
                increase();
                setpluscountercheck(false)
            }
        }, delay);

        // Cleanup the timer when the component unmounts or when inputValue changes
        return () => clearTimeout(timerId);
    }, [pluscounter, AddtocartRefresh]);
    // useEffect(() => {
    //     setCounter(itemQuantity != undefined ? itemQuantity : 1)
    //     dispatch(ItemQuantity_success(itemQuantity != undefined ? itemQuantity : 1));
    // }, [itemQuantity])
    useEffect(() => {
        dispatch(Addtocart_item_withoutlogin_success(((addtocartwithoutlogin != "undefined" && addtocartwithoutlogin != "undefined" && addtocartwithoutlogin) ? addtocartwithoutlogin : [])))
        setaddtocart(((addtocartwithoutlogin != "undefined" && addtocartwithoutlogin != "undefined" && addtocartwithoutlogin) ? addtocartwithoutlogin : []))
    }, [])


    // console.log("Addtocartitemwithoutlogin",Addtocartitemwithoutlogin)

    const handleClick = (item) => {
        // console.log("logindetails", Logindetail)
        if (Logindetail) {
            const filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
            if (filteridmatch?.length > 0) {
                dispatch(Filterid_cartmatch_success(filteridmatch))
                dispatch(Drawer_success(true))
                console.log("filteridmatch", filteridmatch)
                localStorage.setItem("itemId", item_id)
                // console.log("item", item_id);
                // localStorage.setItem("itemId", item_id)
                // navigate("/choose-mode")
            } else {
                const itemdetailsdata = {
                    "order_mode": outlet_mode,
                    "cart_id": cart_identifier,
                    "cart_item_id": "",
                    "unique_id": unique_id,
                    "outlet_id": outletID,
                    "address_id": outlet_mode == "Schedule Delivery" ? address_id : null,
                    "id": itemdetails?.item_id,
                    "name": title,
                    "url": url,
                    "modifier": [],
                    "totalamount": price,
                    "selectedsize": itemdetails?.size[0],
                    "item_price": price,
                    "item_one_quantity_price": price,
                    "itemQuantity": 1,
                    "sugarlevel": 0,
                    "size": itemdetails?.size,
                    "description": itemdetails?.item_description,
                    "is_sugar_enabled": itemdetails?.is_sugar_enabled

                }
                dispatch(AddLoginCartAPI([itemdetailsdata]))
                dispatch(Cart_Item_Added_success([itemdetailsdata]))
                setTimeout(() => {
                    dispatch(Cart_add_clear_item())

                }, 5000)
                // console.log("filteridmatch", filteridmatch)
                // console.log("item", item_id);
                // localStorage.setItem("itemId", item_id)
                // navigate("/select-outlet")
                // if( outletname && outlet_mode ){

                //     navigate("/select-outlet")
                // }else if(outlet_mode && outlet_mode!="Schedule Delivery"){
                //     if (companyoutletcount == "1" || companyoutletcount == 1) {
                //         navigate("/select-outlet")
                //     } else {

                //         navigate("/pickup-outlet")
                //     }
                // }
                // else if(outlet_mode && outlet_mode=="Schedule Delivery"){
                //     navigate("/delivery-address")
                // }
                // else {

                //     navigate("/choose-mode")
                // }
            }
        }
        else {

            const filteridmatch = addtocartwithoutlogin?.filter((data) => data?.id == item_id)
            if (filteridmatch?.length > 0) {
                dispatch(Filterid_cartmatch_success(filteridmatch))
                dispatch(Drawer_success(true))
                // console.log("filteridmatch", filteridmatch)
                localStorage.setItem("itemId", item_id)
            } else {
                const itemdetailsdata = {
                    "order_mode": outlet_mode,
                    "cart_id": cart_identifier,
                    "cart_item_id": "",
                    "unique_id": unique_id,
                    "outlet_id": outletID,
                    "address_id": outlet_mode == "Schedule Delivery" ? address_id : null,
                    "id": itemdetails?.item_id,
                    "name": title,
                    "url": url,
                    "modifier": [],
                    "totalamount": price,
                    "selectedsize": itemdetails?.size[0],
                    "item_price": price,
                    "item_one_quantity_price": price,
                    "itemQuantity": 1,
                    "sugarlevel": 0,
                    "size": itemdetails?.size,
                    "description": itemdetails?.item_description,
                    "is_sugar_enabled": itemdetails?.is_sugar_enabled

                }
                dispatch(Addtocart_Refresh_success(refreshpage + 1))
                setrefreshpage(refreshpage + 1)
                dispatch(Addtocart_item_withoutlogin_success([...Addtocartitemwithoutlogin, itemdetailsdata]))
                dispatch(Cart_Item_Added_success([itemdetailsdata]))
                setTimeout(() => {
                    dispatch(Cart_add_clear_item())

                }, 5000)
                setaddtocart([...Addtocartitemwithoutlogin, itemdetailsdata])
                localStorage.setItem("addtocart", JSON.stringify([...Addtocartitemwithoutlogin, itemdetailsdata]))

                // console.log("filteridmatch", filteridmatch)
                console.log("outletname", outletname);
                localStorage.setItem("itemId", item_id)
                setCounter(1)
                // navigate("/select-outlet")
                // if( outletname && outlet_mode ){
                //     navigate("/select-outlet")
                // }else if(outlet_mode && outlet_mode!="Schedule Delivery"){
                //     if (companyoutletcount == "1" || companyoutletcount == 1) {
                //         navigate("/select-outlet")
                //     } else {
                //         navigate("/pickup-outlet")
                //     }
                // }
                // else if(outlet_mode && outlet_mode=="Schedule Delivery"){
                //     navigate("/delivery-address")
                // }
                // else {
                //     navigate("/choose-mode")
                // }
            }
        }
    }
    // console.log("addtocartwithoutlogin", addtocartwithoutlogin)

    // box height
    const boxHeightRef = useRef(null);
    useSyncRefHeight(
        [
            ['boxHeight', boxHeightRef],
        ],
        // trigger hook when items of footerItems changes, since it may change height
        [price],
    );
    useEffect(() => {
        let filteridmatch
        if (Logindetail && GetLoginCart?.data?.cart_items) {
            filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
            setfilterdatacheck(filteridmatch)
        } else if (!Logindetail) {
            filteridmatch = addtocartwithoutlogin?.filter((data) => data?.id == item_id)
            setfilterdatacheck(filteridmatch)
        }

    }, [GetLoginCart, refreshpage, Addtocartitemwithoutlogin])

    const handlenavigatepage = () => {
        if (outletname && outlet_mode!="Schedule Delivery" && outlet_mode) {

            navigate("/select-outlet")
        } else if (outlet_mode && outlet_mode != "Schedule Delivery") {
            if (companyoutletcount == "1" || companyoutletcount == 1) {
                navigate("/select-outlet")
            } else {

                navigate("/pickup-outlet")
            }
        }
        else if (outlet_mode && outlet_mode == "Schedule Delivery") {

            navigate("/delivery-address")
        }
        else {

            navigate("/choose-mode")
        }
        // navigate("/choose-mode")
    }
    const addtocartcheck = () => {
        let filteridmatch
        if (Logindetail) {
            filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
        } else {
            filteridmatch = addtocartwithoutlogin?.filter((data) => data?.id == item_id)
        }
        // console.log("filteridmatch", filteridmatch?.length)
        return <>
            {filterdatacheck?.length == 0 && <div class={classes.quickAddButton} onClick={(item) => {
                // console.log("addtocart", addtocarts)
                localStorage.setItem("itemId", item_id)
                if (checkproductitem) {
                    if (show_detail_page == true) {
                        navigate("/select-outlet")
                    } else {
                        handleClick(item)
                    }
                } else {
                    handlenavigatepage()
                }
            }}>
                <AddIcon />
            </div>

            }

            {filterdatacheck?.length > 0 && <div className={classes.countBox}>
                <NavLink onClick={() => {
                    if (show_detail_page == true) {
                        if (counter === 1) {
                            return;
                        }
                        handleClick()
                    } else {
                        setminuscounter(minuscounter + 1)
                        if (counter === 1) {
                            return;
                        } else {
                            setpluscountercheck(true)
                            setCounter(counter - 1);
                        }
                        // decrease()
                    }
                }}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                <span className={classes.countNumber}>{counter}</span>
                <NavLink onClick={() => {
                    if (show_detail_page == true) {
                        handleClick()
                    } else {
                        setpluscounter(pluscounter + 1)
                        setpluscountercheck(true)
                        setCounter(counter + 1);
                        // increase()
                    }
                }}><img src={plusIcon} alt="Not Found" /></NavLink>
            </div>}
        </>
    }
    // console.log("GetLoginCartSlice",GetLoginCartSlice?.loading,GetLoginCartloading,LoginCartSlice?.loading)
    return (
        <>

            <div className={classes.productItem} >
                {/* <NavLink> */}
                {
                    discount != null && discount != "" &&
                    <div className={classes.offerPrice}><h6>{discount} OFF</h6></div>
                }
                <div className={classes.imgContent}>
                    <img src={url} alt="Not Found" />
                </div>
                <div className={classes.textContent}>
                    <h5 className={CommonStyle.mb5}>{title}</h5>
                    <div className={`${filterdatacheck?.length == 0 && `${classes.bottomContent}`}`}>
                        <div className={classes.flex}>
                            {
                                discount != null && discount != "" &&
                                <h6><strike>{companydata?.currency_symbol}{itemdetails?.actual_item_price}</strike></h6>
                            }
                            <h5>{companydata?.currency_symbol}{price}</h5>
                        </div>
                        {addtocartcheck()}
                    </div>
                </div>
                {/* </NavLink> */}
            </div>
        </>
    )
}
export default withStyles(Styles)(ProductItem);