import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstent from "../../Common/StrConstent";
import { useNavigate, useParams } from "react-router";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { FormLabel } from "@mui/material";
import AddressTypeSelectBox from "./KioskAddressTypeSelectBox";
import CustomButton from '../../Components/CustomButton/CustomButton';
import BorderButton from "../../Components/BorderButton/BorderButton";
import CustomCheckbox from "../../Components/CustomCheckbox/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { AddAddressAPI } from "../../redux/Action/Addaddress/Addaddress";
import { validateRequired } from "../../Common/Validations";
import { validatePostalCode } from "../../Common/Validations";
import { validateUnitNumber } from "../../Common/Validations";
import Loader from "../../Components/Loader/Loader";
import { GetIDAddressAPI } from "../../redux/Action/GetAddress/GetidAddress";
import { UpdateAddressAPI } from "../../redux/Action/GetAddress/UpdateAdress";
const KioskAddAddress = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { classes } = props;
    const [address_line1, setaddress_line1] = useState("")
    const [address_line2, setaddress_line2] = useState("")
    const [unitNumber, setunitNumber] = useState("")
    const [postalcode, setpostalcode] = useState("")
    const [addressname, setaddressname] = useState("")
    const [erroraddress_line1, seterroraddress_line1] = useState("")
    const [errorunitNumber, seterrorunitNumber] = useState("")
    const [errorpostalcode, seterrorpostalcode] = useState("")
    const [erroraddressname, seterroraddressname] = useState("")
    const AddaddressSlice = useSelector(state => state?.AddaddressSlice)
    const [is_default, setis_default] = useState(false)
    const GetIdAddressSlice = useSelector(state => state?.GetIdAddressSlice)
    const UpdateAddressSlice = useSelector(state => state?.UpdateAddressSlice)
    const [isaddress,setisaddress]=useState(false)
   
    const { id } = useParams();
    const RestHandler = () => {
        setaddress_line1("")
        setaddress_line2("")
        setunitNumber("")
        setpostalcode("")
        setaddressname("")
        setisaddress(true)
    }
    useEffect(() => {
        if (id) {
            dispatch(GetIDAddressAPI(id))
        }
    }, [])
    useEffect(() => {
        if (id) {
            // GetIdAddressSlice?.value?.data?.address_data
            setaddress_line1(GetIdAddressSlice?.value?.data?.address_data?.address_line1)
            setaddress_line2(GetIdAddressSlice?.value?.data?.address_data?.address_line2)
            setunitNumber(GetIdAddressSlice?.value?.data?.address_data?.unit_number)
            setpostalcode(GetIdAddressSlice?.value?.data?.address_data?.postal_code)
            setaddressname(GetIdAddressSlice?.value?.data?.address_data?.custom_address_tag)

        }
    }, [GetIdAddressSlice])

    const ConfirmHandler = () => {

        // console.log("addressname", addressname)
        if (
            validateRequired(address_line1) &&
            validatePostalCode(postalcode) &&
            validateRequired(addressname) &&
            (addressname === "Custom" || addressname === "")
        ) {

            seterroraddress_line1(true)
            seterrorpostalcode(true)
            seterrorunitNumber(false)
            seterroraddressname(true)
            return
        } else if (validateRequired(address_line1) &&
            validatePostalCode(postalcode)) {
            seterroraddress_line1(true)
            seterrorpostalcode(true)
            seterrorunitNumber(false)
            seterroraddressname(false)

            return
        } else if (
            validatePostalCode(postalcode) &&
            validateRequired(addressname) &&
            (addressname === "Custom" || addressname === "")
        ) {
            seterroraddress_line1(false)
            seterrorpostalcode(true)
            seterrorunitNumber(false)
            seterroraddressname(true)
            return
        } else if (validateRequired(address_line1) &&
            validateRequired(addressname) &&
            (addressname === "Custom" || addressname === "")) {
            seterroraddress_line1(true)
            seterrorpostalcode(false)
            seterrorunitNumber(false)
            seterroraddressname(true)
            return
        }
        else if (validatePostalCode(postalcode)) {
            seterroraddress_line1(false)
            seterrorpostalcode(true)
            seterrorunitNumber(false)
            seterroraddressname(false)
            return
        } else if (validateRequired(address_line1)) {
            seterroraddress_line1(true)
            seterrorpostalcode(false)
            seterrorunitNumber(false)
            seterroraddressname(false)
            return
        }
        else if ((unitNumber !== "" && validateUnitNumber(unitNumber))) {
            seterroraddress_line1(false)
            seterrorpostalcode(true)
            seterrorunitNumber(false)
            seterroraddressname(false)
            return
        } else if (validateRequired(addressname) &&
            (addressname === "Custom" || addressname === "")) {
            seterroraddress_line1(false)
            seterrorpostalcode(false)
            seterrorunitNumber(false)
            seterroraddressname(true)
            return
        }

        else {



            const obj = {
                "address_line1": address_line1,
                "address_line2": address_line2,
                "unit_number": unitNumber,
                "postal_code": postalcode,
                "custom_address_tag": addressname,
                is_default: is_default,
            }
            // IsDefaultAddressAPI`
            if (id) {
                dispatch(UpdateAddressAPI(obj,id))
            } else {

                dispatch(AddAddressAPI(obj))
            }
        }
    }
    const handleKeyDown = (event) => {
        // if (
        //   event.keyCode === 69 ||
        // ) {
        //   event.preventDefault();
        // }

        if (
            (event.key >= 0 && event.key <= 9) || (event.keyCode >= 37 && event.keyCode <= 40) ||
            (event.keyCode >= 8 && event.keyCode <= 10)
        ) {

        } else {
            event.preventDefault();
        }
    };
    console.log("addressname", AddaddressSlice)
    useEffect(() => {
        if (AddaddressSlice?.value?.success) {
            navigate("/delivery-address")
        }
    }, [AddaddressSlice])

    useEffect(() => {
        if (UpdateAddressSlice?.value?.success == true) {
            navigate("/delivery-address")
        }
        console.log("UpdateAddressSlice", UpdateAddressSlice)
    }, [UpdateAddressSlice])

    const handledefaultcheck = (e) => {
        setis_default(e)
    }
    console.log("handledefaultcheck", addressname)

    return (
        <>
            {
                AddaddressSlice?.loading == true && (
                    <Loader />
                )
            }
            {
                GetIdAddressSlice?.loading == true && (
                    <Loader />
                )
            }
            <ContainerBox>
                <div className={`${CommonStyle.outer}`}>
                    <div className={CommonStyle.leftContainer}>
                        <BackArrow clickHandler={() => navigate("/delivery-address")} />
                        <h2>{StrConstent.addAddress.heading}</h2>
                    </div>
                    <div className={`${CommonStyle.rightContainer} ${CommonStyle.scrollBox}`}>
                        <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20}`}>
                            <FormLabel>Address Line 1*</FormLabel>
                            <input type="text" name="" value={address_line1} placeholder="Enter Address Line 1" onChange={(e) => {
                                seterroraddress_line1(false)
                                setaddress_line1(e.target.value)
                            }} />
                            {
                                erroraddress_line1 && (
                                    <p className={`${CommonStyle.errorMessage}`}>This field is required.</p>
                                )
                            }
                        </div>
                        <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20}`}>
                            <FormLabel>Address Line 2</FormLabel>
                            <input type="text" name="" value={address_line2} placeholder="Enter address line 2 (optional)" onChange={(e) => setaddress_line2(e.target.value)} />
                        </div>
                        <div className={`${CommonStyle.flex} ${CommonStyle.itemStart} ${CommonStyle.JustifySpaceBetween} ${classes.fields}`}>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20}`}>
                                <FormLabel>Unit Number</FormLabel>
                                <input type="number" name="" value={unitNumber} placeholder="Enter unit number" onChange={(e) => setunitNumber(e.target.value)} onKeyDown={handleKeyDown} />
                                {
                                    errorunitNumber && (
                                        <p className={`${CommonStyle.errorMessage}`}>This field is required.</p>
                                    )
                                }
                            </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20} ${CommonStyle.pl20}`}>
                                <FormLabel>Postal Code*</FormLabel>
                                <input type="number" name="" value={postalcode} placeholder="Enter postal code"
                                    onChange={(e) => {
                                        if (e.target.value.length > 6) {
                                            return false;
                                        } else {
                                            //   if (e.target.value.length == 6) {
                                            //     // langlong(e.target.value);
                                            //   }
                                            seterrorpostalcode(false)
                                            setpostalcode(e.target.value)
                                            //   inputHandler(e);
                                        }
                                    }}
                                    onKeyDown={handleKeyDown}
                                />
                                {
                                    errorpostalcode && (
                                        <p className={`${CommonStyle.errorMessage}`}>This field is required.</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={`${CommonStyle.fromControl} ${CommonStyle.mb20}`}>
                            <FormLabel>Address Name*</FormLabel>
                            <AddressTypeSelectBox isaddress={isaddress} setisaddress={setisaddress} setaddressname={setaddressname} addressname={addressname} errorstatus={seterroraddressname} editid={id ? true : false} GetIdAddressSlice={GetIdAddressSlice} />
                            {
                                erroraddressname && (
                                    <p className={`${CommonStyle.errorMessage}`}>This field is required.</p>
                                )
                            }
                        </div>
                        <label className={`${CommonStyle.customCheckContainer} ${classes.checkbox}`}>
                            <CustomCheckbox
                                onChange={handledefaultcheck}
                                isDefaultSelected={is_default}
                                name={"is_default"}
                            /> <span>{StrConstent.addAddress.checkBoxTxt.heading}</span>
                        </label>
                        <div className={`${CommonStyle.flex} ${CommonStyle.JustifyEnd} ${classes.bottomBtn}`}>
                            <BorderButton label="reset" clickHandler={RestHandler} />
                            <div className={CommonStyle.pl10}>
                                <CustomButton label={StrConstent.buttonTxt.confirm.heading} clickHandler={ConfirmHandler} />
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerBox>
        </>
    );
};
export default withStyles(Styles)(KioskAddAddress);
