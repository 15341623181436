import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import KioCounter from "../KioCounter/KioCounter";
import SmallButton from "../../KioskComponents/SmallButton/SmallButton";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import plusIcon from '../../images/plusIcon.svg';
import minimizeIcon from '../../images/minimizeIcon.svg';
import { State_Change_success } from "../../redux/reducers/StateChangeSlice";
import { UpdateItemQuantityAPI } from "../../redux/Action/LoginCart/UpdateItemQuantity";
import { Totalamount_withoutlogin_success } from "../../redux/reducers/Totalamountwithoulogin";
import { AddLoginCartAPI } from "../../redux/Action/LoginCart/LoginCartAction";
import CustomButton from "../../KioskComponents/CustomButton/CustomButton";
import BorderButton from "../../KioskComponents/BorderButton/BorderButton";
import CustomModal from "../../KioskComponents/CustomModal/CustomModal";
import Loader from "../../KioskComponents/Loader/Loader";
const KioCartItem = (props) => {
    const { classes, url, heading, description, price, quantity, orderagain } = props;
    const [counter, setCounter] = useState(0);
    const navigate = useNavigate();
    const addtocart = localStorage.getItem("addtocart");
    const dispatch = useDispatch();
    const Logindetails = localStorage.getItem("Logindetails")
    const cart_identifier = localStorage.getItem("cart_identifier")
    const outlet_mode = localStorage.getItem("outlet_mode")
    const [deleteid, setdeleteid] = useState("")
    const [deletepopup, setdeletepopup] = useState(false)
    const [initalloder,setinitialloader]=useState(false);
    useEffect(() => {
        setCounter(quantity)
    }, [])
    const increase = () => {
        setCounter(counter + 1);
        dispatch(State_Change_success(counter + 1))
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: description?.cart_item_id,
            item_quantity: counter + 1,
            item_price: description?.item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
    };

    const decrease = (counter) => {
        // console.log("countervikalp",counter)
        if (counter === 1) {
            return;
        }
        setCounter(counter - 1);
        dispatch(State_Change_success(counter - 1))
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: description?.cart_item_id,
            item_quantity: counter - 1,
            item_price: description?.item_price
        }
        // console.log("obj", obj)
        dispatch(UpdateItemQuantityAPI(obj))
    };

    useEffect(() => {
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []

        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id == description?.unique_id ? { ...item, itemQuantity: counter } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
        // console.log("changeaddtocarts", changeaddtocarts)
        dispatch(Totalamount_withoutlogin_success(changeaddtocarts))
    }, [counter])

    const handleEdit = (id) => {
        const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
        // if (Logindetail) {
        //     dispatch(GetLoginCartItemAPI(id?.cart_item_id))
        //     navigate(`/select-outlet/${id?.cart_item_id}`)
        // } else {

        // console.log("handleEdit", id)
        navigate(`/kio-select-outlet/${id?.unique_id}`)
        window.location.reload()
        // }
    }

    const handleDelete = (id) => {

        setdeleteid(id)
        setdeletepopup(true)
        // const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
        // // if (Logindetail) {
        // //     dispatch(DeleteLoginCartAPI(id?.cart_item_id))
        // // }
        // // else {
        // // console.log("handleDelete", id?.unique_id)
        // const deleteitem = JSON.parse(addtocart)
        // const deleteitemdata = deleteitem?.filter((item) => item?.unique_id !== id?.unique_id)
        // // console.log("deleteitemdata", deleteitemdata)
        // localStorage.setItem("addtocart", JSON.stringify(deleteitemdata));
        // window.location.reload()
        // }

    }

    const handleaddtocart = () => {
        const itemdetailsdata = {
            "order_mode": outlet_mode,
            "cart_id": cart_identifier,
            "cart_item_id": description?.cart_item_id,
            "outlet_id": description?.outlet_id,
            "address_id": null,
            "id": description?.id,
            "name": description?.name,
            "url": description?.url,
            "modifier": description?.modifier,
            "totalamount": price * counter,
            "selectedsize": description?.selectedSize,
            "item_price": description?.item_price,
            "item_one_quantity_price": +description?.selectedsize?.price,
            "itemQuantity": counter,
            "sugarlevel": description?.sugarlevel,
            "size": description?.size,
            "description": description?.description,
            "is_sugar_enabled": description?.is_sugar_enabled,

        }
        dispatch(AddLoginCartAPI([itemdetailsdata]))
        return navigate("/cart")
    }

    const handleCancle = () => {
        setdeletepopup(false)
    }
    const handleDeleteClear = (id) => {
        const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
        // if (Logindetail) {
        //     dispatch(DeleteLoginCartAPI(id?.cart_item_id))
        // }
        // else {
        // console.log("handleDelete", id?.unique_id)
        const deleteitem = JSON.parse(addtocart)
        const deleteitemdata = deleteitem?.filter((item) => item?.unique_id !== id?.unique_id)
        // console.log("deleteitemdata", deleteitemdata)
        localStorage.setItem("addtocart", JSON.stringify(deleteitemdata));
        setinitialloader(true)
        setTimeout(()=>{
            setinitialloader(false)
        },1000)
        window.location.reload()
        
        // }

    }

    return (
        <>
        {
            initalloder==true && (
                <Loader/>
            )
        }
            <div className={classes.cartItem}>
                <div className={`${classes.topContainer} ${CommonStyle.flexCenterStart}`}>
                    <div className={classes.imgContent}>
                        <img src={url} alt="Not Found" />
                    </div>
                    <div className={classes.productInfo}>
                        <h4 className={CommonStyle.mb5}>{heading}</h4>
                        <p className={CommonStyle.mb10}>{description?.selectedsize?.size} / {description?.modifier?.length > 0 && description?.modifier?.map((data) => {
                            return " " + data?.modifier_name + " " + data?.modifier_quantity + " / "
                        })}{description?.sugarlevel ? "sugarlevel " + description?.sugarlevel : ""}</p>
                        {/* <KioCounter /> */}
                        <div className={classes.counter}>
                            <NavLink onClick={() => decrease(counter)} className={classes.btn}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                            <span className={classes.counterNumber}>{counter}</span>
                            <NavLink onClick={() => increase()} className={classes.btn}><img src={plusIcon} alt="Not Found" /></NavLink>
                        </div>
                    </div>
                </div>
                <div className={`${CommonStyle.flexCenter} ${CommonStyle.JustifySpaceBetween}`}>
                    <h3>S${`${price * counter}`}</h3>
                    <div className={CommonStyle.flexCenterStart}>
                        <div className={classes.removeBtn}><SmallButton label={"Remove"} icon={<DeleteIcon />} clickHandler={() => {
                            // handleDelete(description)
                            setdeleteid(description)
                            setdeletepopup(true)
                        }} /></div>
                        <SmallButton label={"Edit"} icon={<ModeEditIcon />} clickHandler={() => handleEdit(description)} />
                    </div>
                </div>
            </div>
            {
                deletepopup == true && (
                    <>

                        <CustomModal
                            open={deletepopup}
                            // title={StrConstent.cart.cardClear.heading}
                            handleClose={() => setdeletepopup(false)}
                            description={""}
                        >
                            <div>
                                <h3 className={CommonStyle.mb20}>
                                    Are you sure you want to Delete?
                                </h3>
                                <div className={`${CommonStyle.flexJustifyCenter} ${CommonStyle.mt20} ${CommonStyle.footerModal}`}>
                                    <BorderButton label={"Cancel"} clickHandler={handleCancle} />
                                    <CustomButton label={"Confirm"} clickHandler={() => handleDeleteClear(deleteid)} />
                                </div>

                            </div>
                        </CustomModal>

                    </>
                )
            }
        </>
    )
}
export default withStyles(Styles)(KioCartItem);