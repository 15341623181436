import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Outlet_failure, Outlet_request, Outlet_success } from "../../reducers/Outlet/OutletSlice"
import { Headers } from "../../../APIHandler/HeaderAPI"
import { Outlet_ID_failure, Outlet_ID_request, Outlet_ID_success } from "../../reducers/Outlet/OutletIdSlice"
export const OutletIdfetchAPI = (payload,search) => async (dispatch, getState) => {
    dispatch(Outlet_ID_request())
    const itemId = localStorage.getItem("itemId")
    // console.log("payload", payload);
    const Pick = payload == "Pick-Up Today" ? "pickup" : "Schedule";
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");
    // const outletID = localStorage.getItem("outletID");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
     const latlongapi= `${APIURL.outletSchdeule}/${payload}?timezone=${timeZone}`
    //  ?mode=${Pick}&item_id=${itemId}&longitude=${longitude}&latitude=${latitude}&search_text=${search}`
     const withoulatlongapi= `${APIURL.outletmode}?mode=${Pick}&item_id=${itemId}&search_text=${search}`
    //  const Locationapi = (latitude && longitude) ? latlongapi : withoulatlongapi
     const Locationapi = latlongapi
        const { data } = await axios.get(Locationapi, {
            headers: Headers
        })

        // console.log("dataoutlet", data);
        dispatch(Outlet_ID_success(data))

    } catch (error) {
        dispatch(Outlet_ID_failure(error?.response?.data))
    }
}