import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Profile_Update_failure, Profile_Update_request, Profile_Update_success } from "../../reducers/ProfileUpdate/ProfileUpdateSlice";
import { ProfilegetAPI } from "./Profileget";

export const ProfileUpdateAPI = (payload) => async (dispatch, getState) => {
    dispatch(Profile_Update_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    
    try {

        const { data } = await axios.post(`${APIURL.profileupdate}/${Logindetails?.data?.user_data?.user_id}`,payload, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },
            
        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(Profile_Update_success(data))
        dispatch(ProfilegetAPI())
        // localStorage.setItem("profile",JSON.stringify(data))
       
    } catch (error) {
        dispatch(Profile_Update_failure(error?.response?.data))
    }
}
