import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CheckIcon from '@mui/icons-material/Check';
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import StrConstent from "../../Common/StrConstent";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
const HowItWorks = (props) => {
    const { classes } = props;
    return (
        <>
            <ContainerBox>
                <h2 className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>{StrConstent.howItWorks.heading}</h2>
                <p className={`${CommonStyle.mb20} ${CommonStyle.textCenter}`}>{StrConstent.howItWorks.description}</p>
                <h3 className={CommonStyle.mb20}>{StrConstent.howItWorks.employersWorks.heading}</h3>
                <div className={classes.boxWrapper}>
                    <div className={classes.boxIcon}>
                        <CheckIcon />
                    </div>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
                <div className={classes.boxWrapper}>
                    <div className={classes.boxIcon}>
                        <CheckIcon />
                    </div>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
                <div className={classes.boxWrapper}>
                    <div className={classes.boxIcon}>
                        <CheckIcon />
                    </div>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
                <div className={classes.boxWrapper}>
                    <div className={classes.boxIcon}>
                        <CheckIcon />
                    </div>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
                <div className={classes.boxWrapper}>
                    <div className={classes.boxIcon}>
                        <CheckIcon />
                    </div>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
                <div className={classes.boxWrapper}>
                    <div className={classes.boxIcon}>
                        <CheckIcon />
                    </div>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
                <div className={classes.boxWrapper}>
                    <div className={classes.boxIcon}>
                        <CheckIcon />
                    </div>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(HowItWorks);