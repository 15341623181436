import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  error: {},
  loading: false,
}

export const AddaddressSlice = createSlice({
  name: 'Addaddress',
  initialState,
  reducers: {
    Add_Address_request: (state, action) => {
      state.value = {};
      state.loading = true;
    },
    Add_Address_success: (state, action) => {

      state.value = action.payload;
      state.error = {};

      state.loading = false;
    },
    Add_Address_failure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.value = {};
    },
    Clear_Add_Address: (state, action) => {
      state.error = {};

      state.value = {};
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Add_Address_success, Add_Address_failure, Clear_Add_Address, Add_Address_request } = AddaddressSlice.actions

export default AddaddressSlice.reducer