import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstent from "../../Common/StrConstent";
import { useNavigate } from "react-router";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { Link, NavLink } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { Clear_Add_Address } from "../../redux/reducers/Addaddress/AddaddressSlice";
import { GetAddressAPI } from "../../redux/Action/GetAddress/GetAddress";
import { DeleteAddressAPI } from "../../redux/Action/GetAddress/DeleteAddress";
import { IsDefaultAddressAPI } from "../../redux/Action/GetAddress/Isdefaultaddress";
import Loader from "../../Components/Loader/Loader";
import { GetIDAddressAPI } from "../../redux/Action/GetAddress/GetidAddress";
import { Clear_Update_Address_Address } from "../../redux/reducers/Addaddress/UpdateAdressSlice";
import { Clear_Login_Cart_failure } from "../../redux/reducers/LoginCart/LoginCartSlice";
const KioskDeliveryAddress = (props) => {
    let navigate = useNavigate();
    const { classes } = props;
    const dispatch = useDispatch();
    const GetAddressSlice = useSelector(state => state?.GetAddressSlice)
    const DeleteaddressSlice = useSelector(state => state?.DeleteaddressSlice)
    const companyoutletcount = localStorage.getItem("companyoutletcount")
   
    useEffect(() => {
        dispatch(Clear_Login_Cart_failure())
        dispatch(Clear_Update_Address_Address())
        dispatch(GetAddressAPI())
        dispatch(Clear_Add_Address())
    }, [])
    const handleDelete = (id) => {
        dispatch(DeleteAddressAPI(id))
    }
    const handleEdit = (id) => {
        // console.log("vikalpvikalp",id)
        localStorage.setItem("address_id", id)
        dispatch(GetIDAddressAPI(id))
        navigate(`/add-address/${id}`)
    }
    const handlesetisdefault = (id) => {
        dispatch(IsDefaultAddressAPI(id))
        localStorage.setItem("address_id", id)
        // if (companyoutletcount == "1" || companyoutletcount == 1) {
        //     navigate("/select-outlet")
        // } else {

        //     navigate("/pickup-outlet")
        // }
            navigate("/select-outlet")

    }
    // console.log("GetAddressSlice", GetAddressSlice?.value?.data?.address)
    return (
        <>
            {GetAddressSlice?.loading == true && (
                <Loader />
            )}
            {DeleteaddressSlice?.loading == true && (
                <Loader />
            )}
            <ContainerBox>
                <div className={`${CommonStyle.outer}`}>
                    <div className={CommonStyle.leftContainer}>
                        <BackArrow clickHandler={() => navigate(-1)} />
                        <h2>{StrConstent.deliveryAddress.heading}</h2>
                    </div>
                    <div className={`${CommonStyle.rightContainer} ${CommonStyle.scrollBox}`}>
                        <NavLink to="/add-address" className={`${CommonStyle.links} ${CommonStyle.textRight} ${CommonStyle.block}`}>{StrConstent.deliveryAddress.addNewAddress.heading}</NavLink>
                      {
                        GetAddressSlice?.value?.data?.address?.length==0 && (

                            <div className={classes.cartNodata}><p className={`${CommonStyle.mb10} ${CommonStyle.textcenter}`}>{StrConstent.deliveryAddress.noAddressTo.heading}</p></div>
                        )
                      }
                        {
                            GetAddressSlice?.value?.data?.address?.map((data) => {
                                return <div className={`${classes.cartItem} ${classes.primary} ${CommonStyle.cursorPointer}`} >
                                    <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                                        <div className={classes.addressBox} onClick={() => handlesetisdefault(data?.address_id)}>
                                                <h3 className={`${CommonStyle.flexBaseline} ${classes.addressHeading}`}>{data?.custom_address_tag}
                                                    {
                                                        data?.is_default && (

                                                            <span className={CommonStyle.pl5}>
                                                                (Primary)
                                                            </span>
                                                        )
                                                    }
                                                </h3>
                                                <p className={CommonStyle.mt10}>{data?.address_line1}</p>
                                        </div>
                                        <div className={classes.icons}>
                                            <span onClick={() => handleEdit(data?.address_id)}>
                                                <EditIcon />
                                            </span>
                                            <span onClick={() => handleDelete(data?.address_id)}>
                                                <DeleteIcon />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })
                        }


                        {/* <div className={classes.cartItem}>
                            <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                                <div className={CommonStyle.flexBaseline}><h3>Address 1</h3></div>
                                <div className={classes.icons}>
                                    <span>
                                        <EditIcon />
                                    </span>
                                    <span>
                                        <DeleteIcon />
                                    </span>
                                </div>
                            </div>
                            <p className={CommonStyle.my1rem}>53 Tanjong Pagar Road #33-02 Singapore 234567</p>
                        </div>
                        <div className={classes.cartItem}>
                            <div className={`${CommonStyle.flexCenterStart} ${CommonStyle.JustifySpaceBetween}`}>
                                                <div className={CommonStyle.flexBaseline}><h3>Address 2</h3></div>
                                                <div className={classes.icons}>
                                                    <span>
                                                        <EditIcon />
                                                    </span>
                                                    <span>
                                                        <DeleteIcon />
                                                    </span>
                                                </div>
                                            </div>
                                            <p className={CommonStyle.my1rem}>53 Tanjong Pagar Road #33-02 Singapore 234567</p>
                                        </div> */}

                        {/* <div className={classes.btnBox}>
                            <div className={classes.addItems}>
                                <Link to="/add-address" className={CommonStyle.links}>
                                    <span>Add new address</span>
                                </Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </ContainerBox>
        </>
    );
};
export default withStyles(Styles)(KioskDeliveryAddress);

