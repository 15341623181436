import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import calender from '../../images/calender.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { supplyDateFormat } from '../../Common/utlis/utlis'
import { useEffect } from 'react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
const CustomDatePicker = (props) => {
  const { classes,
    dateValue = "",
    maxFutureDate = "",
    allowedDays = null,
    onBinding,

    name = "",
    isPastDisable = false,
    isWeekDayDisable = false,
    allowAfter = 0,
    isFutureDisable = false,
    isBlank = false,
    editdata = null,
    selectmode = "" } = props;


  // onBinding={bindingHandler}
  //                 allowedDays={null}
  //                 isFutureDisable={true}
  //                 dateValue={dob}
  //                 isBlank={dob != "" ? false : true}
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (value != "") {

      onBinding({ [name]: value?.$d });
    }
  }, [value]);
  //   useEffect(() => {
  //     if (dateValue != "") {
  //       setValue(new Date(dateValue));
  //     }
  //   }, [dateValue]);
  // console.log("dateofbirth", value, dateValue)
  const theme = createTheme({

    components: {

      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: `${ColorTheme.secondaryColor} !important`,
              backgroundColor: `${ColorTheme.yellow} !important`
            },
            "&:hover": {
              color: `${ColorTheme.secondaryColor} !important`,
              backgroundColor: `${ColorTheme.yellow} !important`
            }
          }
        }
      },

    }
  })
  return (
    <>
      <div className={`${classes.datapicker} ${CommonStyle.datepikcerContent}`}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label=""
              value={dayjs(dateValue?.dob)}
              disableFuture={true}
              disabled={dateValue?.dob != "" ? true : false}
              // slots={{
              //     openPickerIcon: CalendarTodayIcon
              // }}
              // maxDate={} 
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <><TextField {...params} /></>}
            />
            <img src={calender} alt="Not Found" className={classes.calenderIcon} disabled={dateValue?.dob != "" ? true : false} />
          </LocalizationProvider>
        </ThemeProvider>
      </div>
    </>
  )
}
export default withStyles(Styles)(CustomDatePicker);

