import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const GetLoginCartItemSlice = createSlice({
  name: 'GetLoginCartItem',
  initialState,
  reducers: {
 
    Get_Login_Cart_Item_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Get_Login_Cart_Item_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
        state.loading = {};
    },
    Get_Login_Cart_Item_failure: (state, action) => {
      state.value = {};
        state.error = action.payload;
        state.loading = false;
    },
    Clear_Login_Cart_data: (state, action) => {
      state.value = {};
        state.error = {};
        state.loading = false;
    },
    
  }
})

// Action creators are generated for each case reducer function
export const {     Get_Login_Cart_Item_failure ,    Get_Login_Cart_Item_success,    Get_Login_Cart_Item_request,Clear_Login_Cart_data} = GetLoginCartItemSlice.actions

export default GetLoginCartItemSlice.reducer