import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
 
}

export const FlashDealDrawerSlice = createSlice({
  name: 'FlashDealDrawer',
  initialState,
  reducers: {
    FlashDealDrawer_request: (state, action) => {
      state.value=false;

    },
    FlashDealDrawer_success: (state, action) => {

      state.value = action.payload;

    }
  }
})

// Action creators are generated for each case reducer function
export const {FlashDealDrawer_request, FlashDealDrawer_success } = FlashDealDrawerSlice.actions

export default FlashDealDrawerSlice.reducer