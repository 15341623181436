import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    drawerOuter: {
        "& > div": {
            "& > div": {
                background: ColorTheme.silkGray,
            }
        }
    },
    border36: {
        border: '1px solid #0000004D',
        width: '36px',
        margin: '0 auto 5px',
        display: 'block',
    },
    border20: {
        border: '1px solid #0000004D',
        width: '20px',
        margin: 'auto',
        display: 'block',
        marginBottom: '25px',
    },
    modallInner: {
        maxHeight: '550px',
        padding: '2rem 2rem  1rem',
        "@media(max-width:640px)": {
            padding: '1.5rem 20px 2rem',
        },
        "& h3": {
            textAlign: 'center !important',
        }
    }
})