import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    item: {
        height: '70px',
        width: '70px',
        overflow: 'hidden',
        borderRadius: '10px',
        marginBottom: '20px',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        }
    },
    productInfo: {
        padding: '10px 0 0 15px',
    },
    counter: {
        display: 'flex',
        alignItems: 'center',
    },
    btn: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        height: ' 35px',
        width: ' 35px',
        border: '2px solid !important',
        borderColor: ColorTheme.yellow,
        color: ColorTheme.yellow,
        borderRadius: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& img":{
            width:'15px',
        }
    },
    counterNumber: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
        minWidth: ' 30px',
        textAlign: 'center',
        margin: '0 5px',
        fontFamily:'var(--main-heading-family)',
    },
})