import React from "react-dom";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "../Pages/Home/Home";
import ProductsHome from "../Pages/ProductsHome/ProductHome";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Helmet } from "react-helmet";
import ChooseMode from "../Pages/ChooseMode/ChooseMode";
import PickUpOutlet from "../Pages/PickUpOutlet/PickUpOutlet";
import SelectOutlets from "../Pages/SelectOutlets/SelectOutlets";
import Cart from "../Pages/Cart/Cart";
import VerifyOtp from "../Pages/VerifyOtp/VerifyOtp";
import Login from "../Pages/Login/Login";
import ProceedToPay from "../Pages/ProceedToPay/ProceedToPay";
import OrderSummary from "../Pages/OrderSummary/OrderSummary";
import PaymentFailed from "../Pages/PaymentFailed/PaymentFailed";
import ScanQrCode from "../Pages/ScanQrCode/ScanQrCode";
import NotFound from "../Pages/NotFound/NotFound";
import Reward from "../Pages/Reward/Reward";
import Vouchers from "../Pages/Vouchers/Vouchers";
import VoucherDetail from "../Pages/Vouchers/VoucherDetail";
import Profile from "../Pages/Profile/Profile";
import Notifications from "../Pages/Notifications/Notifications";
import OrderDetails from "../Pages/OrderDetails/OrderDetails";
import KioskLogin from "../KioskPages/KioskLogin/KioskLogin";
import KioskHome from "../KioskPages/KioskHome/KioskHome";
import KioskSelectOutlets from "../KioskPages/KioskSelectOutlets/KioskSelectOutlets";
import KioskCart from "../KioskPages/KioskCart/KioskCart";
import KioskProfile from "../KioskPages/KioskProfile/KioskProfile";
import KioskNotFound from "../KioskPages/KioskNotFound/KioskNotFound";
import KioskOrderSummary from "../KioskPages/KioskOrderSummary/KioskOrderSummary";
import KioskPaymentFailed from "../KioskPages/KioskPaymentFailed/KioskPaymentFailed";
import KioskVerifyOtp from "../KioskPages/KioskVerifyOtp/KioskVerifyOtp";
import KioskProceedToPay from "../KioskPages/KioskProceedToPay/KioskProceedToPay";
import KioHome from '../KioPages/kioHome/kioHome';
import KioSelectionMode from "../KioPages/KioSelectionMode/KioSelectionMode";
import KioMenu from "../KioPages/KioMenu/KioMenu";
import KioHeader from "../KioComponents/KioHeader/KioHeader";
import KioSelectOutlet from "../KioPages/KioSelectOutlet/KioSelectOutlet";
import KioCart from "../KioPages/KioCart/KioCart";
import { useDispatch, useSelector } from "react-redux";
import { Header_check_success } from "../redux/reducers/HeaderSlice";
import KioPhoneNumber from "../KioPages/KioPhoneNumber/KioPhoneNumber";
import KioOtp from "../KioPages/KioOtp/KioOtp";
import KioVouchers from "../KioPages/KioVouchers/KioVouchers";
import KioProceedToPay from "../KioPages/KioProceedToPay/KioProceedToPay";
import KioOrderReady from "../KioPages/KioOrderReady/KioOrderReady";
import OrderHistory from "../Pages/OrderDetails/OrderHistory";
import Faqs from "../Pages/Faqs/Faqs";
import GetInTouch from "../Pages/GetInTouch/GetInTouch";
import KioPaymentFailed from "../KioPages/KioPaymentFailed/KioPaymentFailed";
import AddAddress from "../Pages/AddAddres/AddAddress";
import DeliveryAddress from "../Pages/DeliveryAddress/DeliveryAddress";
import KioskChooseMode from "../KioskPages/KioskChooseMode/KioskChooseMode";
import { ContinueasguestAPI } from "../redux/Action/Continueasguest/Continueasguest";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../Pages/TermsConditions/TermsConditions";
import HowItWorks from "../Pages/HowItWorks/HowItWorks";
import OurStory from "../Pages/OurStory/OurStory";
import KioskAddAddress from "../KioskPages/KioskAddAddres/KioskAddAddress";
import KioskDeliveryAddress from "../KioskPages/KioskDeliveryAddress/KioskDeliveryAddress";
import { useTranslation } from 'react-i18next';
import Reservation from "../Pages/Reservation/Reservation";
import Reservationbtn from "../Components/Resevationbtn/Reservationbtn";
import { ReservationoutletAPI } from "../redux/Action/reservationoutlets/ReservationOutlets";
import { FaqAPI } from "../redux/Action/Faq/Faq";
import { CategoryFaqAPI } from "../redux/Action/Faq/CategoryFaq";

import ForgotPassword from "../Pages/Login/ForgotPassword";
import ChangePasswordPage from "../Pages/Login/ChangePassword";

import FlashDealList from "../Pages/FlashDeal/List/FlashDealList";
import FlashDealDetail from "../Pages/FlashDeal//Detail/FlashDealDetail";
import List from "../Pages/Coupon/List";
import Signup from "../Pages/Login/Signup";
import WebReservation from "../Pages/Reservation/WebReservation";


const Navigation = () => {
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const url = window.location.href
    const kiosk = url?.includes("kiosk") ? "1" : url?.includes("qr") ? "2" : "3";
    const HeaderCheckslice = useSelector(state => state?.HeaderCheckSlice?.value)
    const [iswebviewexists, setisWebviewexists] = useState("")
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    // console.log("kiosk", kiosk)
    const urlproductitemcheck = url?.includes("product-items")
    const urlreservationcheck = url?.includes("reservation")
    const urlresetpasswordcheck = url?.includes("reset-password")
    const headercheck = url?.includes("kio-home") || url?.includes("kio-select-mode") || url.charAt(url.length - 1) == "/" ? false : true
    const headerkiophone = (url?.includes("kio-phone-number") || url?.includes("kio-otp")) && true
    const dispatch = useDispatch()
    const FooterHideSlice = useSelector(state => state?.FooterHideSlice?.value)
    const qrmobilenumber = localStorage.getItem("qrmobilenumber")
    const { i18n } = useTranslation();
    const languagemulti = localStorage.getItem("languagemulti")
    const languagecounter = localStorage.getItem("languagecounter")
    //const acecardbookatable=url?.includes("ace-card") ? true:false;
    const acecardbookatable=true;
    localStorage.setItem("kioskmode", kiosk);
    const ContinueasguestSlice = useSelector(state => state?.ContinueasguestSlice)
    const Logindetails = localStorage.getItem("Logindetails")
    const logincheck = (Logindetails && Logindetails != undefined) ? true : false;

    const navigate = useNavigate();
    // console.log("headercheck", headerkiophone);
    // Check for common webview user agent strings
    const isWebview = /WebView/i.test(navigator.userAgent);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        // Access the "version" parameter
        const versionParam = urlParams.get('isapp');
        const versionParammobile = urlParams.get('mobile');
        const versionParamlanguage = urlParams.get('locale');
        const versionParamemail = urlParams.get('email');
        const checkisapp = versionParam == "acecard" ? true : false
        console.log("versionParamlanguage", versionParamlanguage)

        if (versionParam == "acecard") {
            setisWebviewexists(versionParam)

            localStorage.setItem("isappwebviewcheck", versionParam)
            localStorage.setItem("languagecounter", 2)
            const versionParamlanguagecode = versionParamlanguage == "en" ? "en" : versionParamlanguage == "th" ? "thai" : versionParamlanguage == "zh-Hans" ? "chi" : "en"
            // if (languagecounter != 2) {
            localStorage.setItem("languagemulti", versionParamlanguagecode)
            const latestemail = versionParamemail?.includes(" ") ? `${versionParamemail?.split(" ")[0]}+${versionParamemail?.split(" ")[1]}` : versionParamemail
            console.log("email", latestemail, versionParamemail)
            localStorage.setItem("email", latestemail)

            i18n.changeLanguage([versionParamlanguagecode]);
            // }
            if (versionParammobile != "" && versionParammobile) {
                localStorage.setItem("qrmobilenumber", versionParammobile)
                dispatch(ContinueasguestAPI(versionParammobile))
                navigate("/")
                if (logincheck) {

                    // window.location.reload()
                }
            }
        }
    }, [])

 
    useEffect(() => {
        dispatch(ReservationoutletAPI())
        dispatch(CategoryFaqAPI())
        if (headercheck) {
            dispatch(Header_check_success(true))
        } else {
            dispatch(Header_check_success(false))
        }
    }, [])

    // added class in body tag in kiosk mode
    const location = useLocation();
    useEffect(() => {
        if (isappwebviewcheck == "acecard") {
            document.body.classList.add('ace-padding-top');
        }
        else {
            document.body.classList.remove('ace-padding-top');
        }
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{companydata?.title}</title>
                <link rel="icon" href={companydata?.favicon} />
                <link
                    href={companydata?.google_font_link}
                    rel="stylesheet" />
            </Helmet>
            {
                kiosk == "3" && (
                    <>
                        <div className={`${location.pathname === "/" ? "home" : "body-top-padding"} ${isappwebviewcheck == "acecard" && "acecard-padding-add"}`}>
                            { (isappwebviewcheck != "acecard") && (
                                <Header />
                            )}
                            <Routes >
                                <Route path="/login" element={<Login />} />
                                <Route path="/sign-up" element={<Signup />} />
                                <Route path="/" exact element={<Home />} />
                                <Route path="/choose-mode" element={<ChooseMode />} />
                                <Route path="/pickup-outlet" element={<PickUpOutlet />} />
                                <Route path="/select-outlet" element={<SelectOutlets />} />
                                <Route path="/product-items" element={<ProductsHome />} />
                                <Route path="/select-outlet/:id" element={<SelectOutlets />} />
                                <Route path="/cart" element={<Cart />} />
                                <Route path="/verify-otp" element={<VerifyOtp />} />
                                <Route path="/verify-otp-number" element={<VerifyOtp />} />
                                <Route path="/proceed-to-pay" element={<ProceedToPay />} />
                                <Route path="/success" element={<OrderSummary />} />
                                <Route path="/orderdetail/:id" element={<OrderSummary />} />
                                <Route path="/cancel" element={<PaymentFailed />} />
                                <Route path="/qr-code" element={<ScanQrCode />} />
                                <Route path="/reward" element={<Reward />} />
                                <Route path="/vochers" element={<Vouchers />} />
                                <Route path="/voucher-details" element={<VoucherDetail />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/notifications" element={<Notifications />} />
                                <Route path="/order-details" element={<OrderDetails />} />
                                <Route path="/order-history" element={<OrderHistory />} />
                                <Route path="/faqs" element={<Faqs />} />
                                <Route path="/get-in-touch" element={<GetInTouch />} />
                                <Route path="/add-address" element={<AddAddress />} />
                                <Route path="/add-address/:id" element={<AddAddress />} />
                                <Route path="/delivery-address" element={<DeliveryAddress />} />
                                <Route path="/reservation" element={<Reservation />} />
                                <Route path="/web/reservation" element={<WebReservation />} />
                                <Route path="/reset-password/:id" element={<ChangePasswordPage />} />
                                <Route path="/forget-password" element={<ForgotPassword />} />
                                <Route path="/flashDeal" element={<FlashDealList />} />
                                <Route path="/fd-detail/:id" element={<FlashDealDetail />} />
                                <Route path="/coupon-list" element={<List />} />


                                <Route path="*" element={<NotFound />} />
                            </Routes>
                            {
                                !urlresetpasswordcheck && (companydata?.is_reservation_show==1 &&  urlreservationcheck == false && acecardbookatable==true) && (

                                    <Reservationbtn />
                                )
                            }
                            {!urlresetpasswordcheck && urlproductitemcheck == true ? <>
                                {(isappwebviewcheck != "acecard") && FooterHideSlice == true && (
                                    <Footer />
                                )}
                            </> :
                                <>
                                    { !urlresetpasswordcheck &&(isappwebviewcheck != "acecard") && (
                                        <Footer />
                                    )}
                                </>

                            }
                        </div>
                    </>
                )
            }
            {
                kiosk == "2" && (
                    <>
                        {/* <div className={`${location.pathname === "/" ? "home" : "body-top-padding"} 
                        ${isappwebviewcheck == "acecard" && "padding-remove"}
                        `}> */}
                        <div className={`${location.pathname === "/" ? "home" : "body-top-padding"} 
                        ${isappwebviewcheck == "acecard" && "acecard-padding-add"}
                        `}>
                            {/* {(isappwebviewcheck != "acecard") && (
                                <Header />
                                )} */}
                            <Header />
                            <Routes>
                                <Route path="/login" element={<KioskLogin />} />
                                <Route path="/" exact element={<KioskHome />} />
                                <Route path="/select-outlet" element={<KioskSelectOutlets />} />
                                <Route path="/select-outlet/:id" element={<KioskSelectOutlets />} />
                                <Route path="/cart" element={<KioskCart />} />
                                <Route path="/verify-otp" element={<KioskVerifyOtp />} />
                                <Route path="/verify-otp-number" element={<KioskVerifyOtp />} />
                                <Route path="/proceed-to-pay" element={<KioskProceedToPay />} />
                                <Route path="/success" element={<KioskOrderSummary />} />
                                <Route path="/cancel" element={<KioskPaymentFailed />} />
                                <Route path="/choose-mode" element={<KioskChooseMode />} />
                                <Route path="/orderdetail/:id" element={<OrderSummary />} />
                                <Route path="/order-details" element={<OrderDetails />} />
                                <Route path="/order-history" element={<OrderHistory />} />
                                {/* <Route path="/qr-code" element={<ScanQrCode />} />
                                <Route path="/reward" element={<Reward />} />
                                <Route path="/vochers" element={<Vouchers />} />
                                <Route path="/voucher-details" element={<VoucherDetail />} /> */}
                                <Route path="/profile" element={<KioskProfile />} />
                                {/* <Route path="/notifications" element={<Notifications />} />
                                <Route path="/order-details" element={<OrderDetails />} /> */}
                                <Route path="*" element={<KioskNotFound />} />
                                <Route path="/add-address" element={<KioskAddAddress />} />
                                <Route path="/add-address/:id" element={<KioskAddAddress />} />
                                <Route path="/delivery-address" element={<KioskDeliveryAddress />} />
                                <Route path="/reservation" element={<Reservation />} />
                              
                            </Routes>
                            {
                                !urlresetpasswordcheck && (companydata?.is_reservation_show==1 &&  urlreservationcheck == false && acecardbookatable==true) && (

                                    <Reservationbtn />
                                )
                            }
                            {(isappwebviewcheck != "acecard") && (
                                <Footer />
                            )}

                        </div>
                    </>
                )
            }
            {
                (kiosk == "1") && (
                    <>
                        <div className="kiosk-mode">
                            {HeaderCheckslice == true ? (
                                <KioHeader />
                            ) : headerkiophone ? <KioHeader /> : ""}
                            <Routes>
                              
                                <Route path="*" element={<KioskNotFound />} />
                                {/* kisok pages */}
                                <Route path="/" exact element={<KioHome />} />
                                <Route path="/kio-home" element={<KioHome />} />
                                <Route path="/kio-select-mode" element={<KioSelectionMode />} />
                                <Route path="/kio-menu" element={<KioMenu />} />
                                <Route path="/kio-select-outlet" element={<KioSelectOutlet />} />
                                <Route path="/kio-select-outlet/:id" element={<KioSelectOutlet />} />
                                <Route path="/kio-cart" element={<KioCart />} />
                                <Route path="/kio-phone-number" element={<KioPhoneNumber />} />
                                <Route path="/kio-otp" element={<KioOtp />} />
                                <Route path="/kio-proceed-to-pay" element={<KioProceedToPay />} />
                                <Route path="/kio-vouchers" element={<KioVouchers />} />
                                <Route path="/success" element={<KioOrderReady />} />
                                <Route path="/cancel" element={<KioPaymentFailed />} />
                            </Routes>
                        </div>
                    </>
                )
            }

        </>
    );
};

export default Navigation;