import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    imgContent: {
        height: '250px',
        width: '250px',
        overflow: 'hidden',
        borderRadius: '30px',
        marginBottom: '20px',
        "@media(max-width:1024px)": {
            height: '160px',
            width: '160px',
        },
        "@media(max-width:640px)": {
            height: '120px',
            width: '120px',
        },
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        }
    },
    detail: {
        padding: '15px',
        width: '80%',
    },
    price: {
        marginTop: '15px',
        fontFamily: 'var(--sub-heading-family)',
        color: ColorTheme.darkGray,
    },
    sugarLevel: {
        paddingBottom: '20px',
        flexWrap: 'wrap',
        "& button": {
            fontSize: '1.5rem !important',
            padding: '10px',
            border: `2px solid ${ColorTheme.yellow} !important`,
            fontFamily: 'var(--main-heading-family) !important',
            minWidth: '120px',
            minHeight: '50px',
            width: 'auto',
            "@media(max-width:640px)":{
                minWidth: '100px',
            }
        }
    },
    groupToggleBtn: {
        "& button": {
            border: `2px solid ${ColorTheme.yellow} !important`,
            "@media(max-width:640px)": {
                width: '48%',
            }
        }
    },
    active: {
        backgroundColor: `${ColorTheme.yellow} !important`,
        background: `${ColorTheme.yellow} !important`,
        color: `${ColorTheme.secondaryColor} !important`,
        "& h5": {
            color: `${ColorTheme.secondaryColor} !important`,
        },
        "& p": {
            color: `${ColorTheme.secondaryColor} !important`,
        }
    },
})