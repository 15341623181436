import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    customBtn: {
        // width:"100%",
        "& button": {
            padding: "15px 24px",
            background: ColorTheme.yellow,
            borderWidth: '2px !important',
            borderStyle: 'solid !important',
            borderColor: ColorTheme.yellow,
            letterSpacing: "0",
            width: "100%",
            minWidth: '200px',
            fontSize: 'var(--bs-buttonFont) !important',
            lineHeight: 'narmal !important',
            fontWeight: '400 !important',
            borderRadius: '32px !important',
            padding: '12px 24px !important',
            whiteSpace: 'nowrap !important',
            color: `${ColorTheme.secondaryColor} !important`,
            fontFamily: 'var(--main-heading-family) !important',
            "@media(max-width:640px)": {
                minWidth: '180px',
                // fontSize: '16px !important',
                // lineHeight: '18px !important',
            },
            "@media(max-width:475px)": {
                minWidth: '150px',
                // fontSize: '14px !important',
                // lineHeight: '16px !important',
            },
            "@media(max-width:375px)": {
                minWidth: '130px',
            },
            "&:hover": {
                background: ColorTheme.yellow,
            },
        },
    },
    customflashBtn: {
        width:"100%",
        "& button": {
            padding: "15px 24px",
            background: ColorTheme.yellow,
            borderWidth: '2px !important',
            borderStyle: 'solid !important',
            borderColor: ColorTheme.yellow,
            letterSpacing: "0",
            width: "100%",
            minWidth: '200px',
            fontSize: 'var(--bs-buttonFont) !important',
            lineHeight: 'narmal !important',
            fontWeight: '400 !important',
            borderRadius: '32px !important',
            padding: '12px 24px !important',
            whiteSpace: 'nowrap !important',
            color: `${ColorTheme.secondaryColor} !important`,
            fontFamily: 'var(--main-heading-family) !important',
            "@media(max-width:640px)": {
                minWidth: '180px',
                // fontSize: '16px !important',
                // lineHeight: '18px !important',
            },
            "@media(max-width:475px)": {
                minWidth: '150px',
                // fontSize: '14px !important',
                // lineHeight: '16px !important',
            },
            "@media(max-width:375px)": {
                minWidth: '130px',
            },
            "&:hover": {
                background: ColorTheme.yellow,
            },
        },
    },
})