export const Styles = Theme => ({
    accordionBox: {
        borderRadius: "10px !important",
        boxShadow: "none !important",
        padding: "20px",
        marginBottom:"20px",
        "&::before": {
            content: "''",
            display: "none",
        },
        "& > div": {
            padding: "0 !important",
            margin: "0 !important",
            alignItems: "flex-start",
        },
        "& > div > div": {
            padding: "0 !important",
            margin: "0 !important"
        }
    },
    top: {
        "& h5": {
            lineHeight: 'normal',
        }
    },
    btBox: {
        paddingTop: "10px",
        "& p": {
            fontSize: '1rem',
            "&:first-child": {
                paddingBottom: '5px',
            }
        }
    },
    content: {
        padding: " 20px 0 0 !important",
        "& h5": {
            marginBottom: "5px",
            fontSize: '1.125rem',
        },
    },
    bottomBtn: {
        marginTop: '20px',
        "@media(max-width:640px)": {
            justifyContent: 'center',
            "& div": {
                width: '50%',
                "& > div ": {
                    width: '100%',
                },
                "& button": {
                    width: '100%',
                    padding: '10px !important',
                    minWidth: 'auto',
                },
            },
        },
        "@media(max-width:475px)": {
            display: 'block',
            "& div": {
                width: '100%',
                paddingLeft:'0',
                paddingTop: '5px',
            }
        },
    }
})