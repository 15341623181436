import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { TimeSlot_Check_failure, TimeSlot_Check_request, TimeSlot_Check_success } from "../../reducers/TimeSlotCheck/TimeSlotCheck";
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Rewards_Points_failure, Rewards_Points_request, Rewards_Points_success } from "../../reducers/rewardspoint/RewardPoints";

export const RewardsPonitsAPI = (payload) => async (dispatch, getState) => {
    dispatch(Rewards_Points_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    // console.log("timeslotpayload", payload?.timeslot)
    try {

        const { data } = await axios.get(`${APIURL.rewards}`, {
            headers: {
                "url":Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Rewards_Points_success(data))


    } catch (error) {
        dispatch(Rewards_Points_failure(error?.response?.data))
    }
}
