import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useSelector } from 'react-redux';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Hong Bao 101 True Digital Park',
    'Hong Bao The Rest Area Prachachuen',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const OutletSelect = (props) => {
    const { classes,setSelectvalue,  setouleterror } = props;
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const ReservationOutletSlice = useSelector(state => state?.ReservationOutletSlice)
    const handleChange = (event) => {
        console.log("ReservationoutletAPI",event?.target?.value)
        // const {
        //     target: { value },
        // } = event;
        setPersonName(event?.target?.value?.name)
        setSelectvalue(event?.target?.value?.id)
        setouleterror(false)
        const seatsfilter=ReservationOutletSlice?.value?.data?.filter((data)=>{
            return data?.id==event?.target?.value?.id
        })
        console.log("seatsfilter",seatsfilter,ReservationOutletSlice?.value?.data,event?.target?.value)
        if(seatsfilter?.length>0){
            localStorage.setItem("noofseats",seatsfilter[0]?.limit_of_booking)
        }
        // setPersonName(
        //     // On autofill we get a stringified value.
        //     typeof value === 'string' ? value.split(',') : value,
        // );
    };
console.log("ReservationoutletAPI",ReservationOutletSlice)
    return (
        <div className={classes.selectBox}>
            <Select
                displayEmpty
                value={personName}
                onChange={handleChange}
                MenuProps={
                    {
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: `${ColorTheme.yellow} !important`,
                                color: ColorTheme.secondaryColor,
                            }
                        },
                        disableScrollLock: true
                    }
                }
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <p className={classes.placeholderTxt}>Select Oultets</p>;
                    }
                    return selected;
                }}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {/* <MenuItem disabled value="">
                    <p>Select Oultets</p>
                </MenuItem> */}
                {ReservationOutletSlice?.value?.data?.map((data) => (
                    <MenuItem
                        key={data?.id}
                        value={data}
                        // style={getStyles(data?.name, personName, theme)}
                    >
                        {data?.name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
export default withStyles(Styles)(OutletSelect);