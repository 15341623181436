export const Styles = Theme => ({
    orderPickUp: {
        "@media(max-width:640px)": {
            display: 'block',
            height: 'auto',
        }
    },
    outletOuter: {
        padding: '2rem 0',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '25px',
        "@media(max-width:1200px)": {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        "@media(max-width:991px)": {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        "@media(max-width:640px)": {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
        // "& > div": {
        //     display: 'table-cell',
        // }
    },
})