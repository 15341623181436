export const Styles = Theme => ({
    flexDimension : {
        display : 'flex',
        justifyContent: 'center',
        alignItems : 'center'
    },
    gap20 : {
        gap : '20px'
    },
    searchBox : {
        maxWidth: "500px",
        margin: '30px 0 30px auto'
    },
    fdIcon : {
        margin: 'auto 0 auto -20px',
        width: '20px',
        height: '20px',
    },
    iconHeight : {
        height: '48px'
    },
    alignment : {
        display : 'flex',
        alignItems: 'center',

    },
    upperCase : {
        textTransform : 'uppercase !important'
    },
    sliderItemTop : {
        display : 'flex !important',
        padding : '10px 14px',
        '& > div ' : {
            height : '96px'
        }
    },
    fdProdIcon : {
        width : '35px',
        height : '35px',
        marginBottom : '10px'
    },
    prodImg : {
        width : '94px',
        height : '96px'
    },
    sliderHeading : {
        fontSize : '22px',
        WebkitLineClamp: 1, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical'  
    },
    sliderContent : {
        fontSize : '12px',
        margin : '10px 0',
        WebkitLineClamp: 2, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical'  
    },
    borderDash : {
        borderBottom : '1px dashed rgba(0, 0, 0, 0.10)',
        margin : '10px 0'
    },
    borderBottomSolidDark : {
        borderBottom : '2px solid #3F4444 !important'
    },
    borderBottomSolidLight : {
        borderBottom : '2px solid #73727233 !important',
        margin : '30px 7px 30px'
    },
    rounded20 : {
        borderRadius : '20px',
    },
    gridDimension : {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)', /* Each row contains four items */
        gap: '20px',
        '@media(max-width: 1368px)' : {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        '@media(max-width: 1024px)' : {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },

        '@media(max-width: 640px)' : {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }, 
    },
    itemContainer : {
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        position : 'relative',
        backgroundColor : '#fff',
        margin: '10px 0',
        '&::before, &::after' : {
            content: '""',
            top: '58%',
            transform: 'rotate(90deg)',
            height: '30px',
            width: '33px',
            backgroundColor: '#f7f7f7',
            position: 'absolute',
            zIndex: '9',
        },
        '&::before' : {
            left: '-7px',
            borderRadius: '150px 150px 0 0',
            boxShadow :'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.1)'
        },
        '&::after' : {
            right: '-4px',
            borderRadius: '0 0 150px 150px',
            boxShadow : 'inset 0px -4px 4px 0px rgba(0, 0, 0, 0.1)'
        },
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        width: '46px',
        transform : 'translateY(-50%)'
    },
    nextArrow: {
        right: '-6%',
    },
    prevArrow: {
        left: '-6%',
        
    },
    allItemTop : {
        padding : '20px',
        display : 'flex !important',
        '& > div' : {
            height : '105px'
        }
    },
    allFDContainer : {  
        '&::before, &::after' : {
            top: '45%',
            height : '20px',
            width : '31px'
        },
        '&::before' : {
            left : '-9px'
        },
        '&::after' : {
            right : '-9px'
        }
    },
    allProdImg : {
        width : '107px',
        height : '105px'
    },
    digits : {
        width : '40px',
        height : '40px',
        border : '1px solid #000',
        borderRadius : '16px',
        backgroundColor : '#fff',
        bottom : '-5px',
        right : '0',
        '& p' : {
            width : '32px',
            height : '32px',
            backgroundColor : '#000',
             color : '#fff',
             borderRadius : '12px'
        }
    }
})