
import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Signin_failure, Signin_request, Signin_success } from "../../reducers/Signin/SigninSlice";
import { Signup_failure, Signup_request, Signup_success } from "../../reducers/Signin/SignupSlice";

export const SignupAPI = (payload) => async (dispatch, getState) => {
    dispatch(Signup_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    // console.log("timeslotpayload", payload?.timeslot)
    try {

        const { data } = await axios.post(`${APIURL.signup}`, payload, {
            headers: {
                "url":Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Signup_success(data))


    } catch (error) {
        dispatch(Signup_failure(error?.response?.data))
    }
}
