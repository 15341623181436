import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({

    productItem: {
        zIndex: '9',
        overflow: 'hidden',
        borderRadius: '15px',
        cursor: 'pointer',
    },
    mainDiv: {
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        backgroundColor: ColorTheme.lightBrown,
        position: 'absolute',
        overflow: 'hidden',
        zIndex: '9',
        height: '100%'
    },
    offerPrice: {
        borderRadius: '0px 32px 32px 0',
        padding: '10px 20px',
        backgroundColor: ColorTheme.yellow,
        position: 'absolute',
        top: '0',
        left: '0',
        "& h5": {
            color: `${ColorTheme.secondaryColor} !important`,
        }
    },
    imgContent: {
        width: "100%",
        height: '205px',
        borderRadius: '10px 10px 0 0',
        overflow: 'hidden',
        "@media(max-width:640px)": {
            height: '180px',
        },
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
        }
    },
    textContent: {
        // paddingLeft: "15px",
        padding: "20px 20px 0",
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: '100px',
        boxSizing: 'border-box',
        // width: 'calc(100% - 100px)',
        width: '100%',
    },
    flex: {
        display: 'flex',
        alignItems: "flex-end",
        justifyContent: 'flex-end',
        "& h3": {
            marginLeft: '4px',
            lineHeight: '25px'
        }
    },
    title: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        minHeight: '42px'
    },
    address: {
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '52px',

    },
    disableOutletBadge: {
        top: '0',
        backgroundColor: '#000',
        color: '#fff',
        padding: '10px'
    },
    hoverOpen: {
        backgroundColor: '#000 !important',
        padding: '10px',
        color: '#fff',
        position: 'absolute',
        bottom: '0',
        justifyContent: 'center',
        alignItems: 'center'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(0, 0, 0)',
        borderRadius: '0 15px 15px 0',
        display: 'flex',
        justifyContent: 'center',
        animation: '$slideOut 0.3s ease-out forwards',
        zIndex: '100',
        opacity: '0',
        alignItems: 'center'
    },
    overlayActive: {
        // transform: 'translateX(0%)',
        width: '100%',
        opacity: '1',
        animation: '$slideIn 0.3s ease-in forwards'
    },
    closeOverlay: {
        top: '10px',
        right: '10px',
        color: '#fff',
        fontSize: '16px',
        cursor: 'pointer',
        zIndex: '999'
    },
    '@keyframes slideIn': {
        from: {
            transform: 'translateX(100%)', // Start position off-screen
            opacity: '0'
        },
        to: {
            transform: 'translateX(0)', // Slide in to position
            opacity: '1'
        }
    },

    '@keyframes slideOut': {
        from: {
            transform: 'translateX(0)', // Start position on-screen
            opacity: '1'
        },
        to: {
            transform: 'translateX(100%)', // Slide out off-screen
            opacity: '0'

        }
    },
    overlayContent: {
        color: '#fff',
        padding: '20px 0',
        width: "100%",
        borderRadius: '4px',
        pointerEvents: 'auto',
        transform: 'translateX(0)',
        backgroundColor: '#000',
        "& ul": {
            height: '100%',
            width: "100%",
            display: 'flex',
            margin: "0px 30px",
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '10px'
        },
        "& ul li:first-child": {
            fontWeight: '700'
        },
        "& ul li": {
            color: '#fff',
            fontSize: '14px',
            lineHeight: '25px',
            display: "flex",
            marginBottom: "5px",
            justifyContent: "space-between",
            alignItems: "center",
            '@media(max-width: 1368px)': {
                fontSize: '12px',
                lineHeight: '30px'
            },
            '@media(max-width: 1200px)': {
                fontSize: '14px',
                lineHeight: '30px'
            }
        },
        "& ul li div": {
            display: "flex",
            flexDirection: "column",
            '@media(max-width: 1368px)': {
                fontSize: '12px',
                lineHeight: '15px'
            },
            '@media(max-width: 1200px)': {
                fontSize: '14px',
                lineHeight: '15px'
            }
        }
    },
    timing: {
        display: 'inline-block',
        paddingLeft: '12px',
        fontSize: '14px'
    },
    timeName: {
        lineHeight: '30px',
        fontWeight: '700',
        fontSize: '14px',

        '@media(max-width: 1368px)': {
            fontSize: '12px',
            lineHeight: '20px'
        },
        '@media(max-width: 1200px)': {
            fontSize: '14px',
            lineHeight: '30px'
        }
    }
})