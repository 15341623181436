export const Styles = Theme => ({
    dimension : {
        backgroundColor : '#000',
        color: '#fff !important',
        height: '55px',
        borderRadius: '100px',
        '@media(max-width: 767px)' : {
            width : '100%',
            margin : 'auto'
        }
    },
    iconDimension : {
        width: '39px',
        height : '39px',
        marginLeft: '8px'
    },
    arrowDimension : {
        width: '41px',
        height : '23px',
        marginRight: '8px'
    }
  })