import React, { useEffect, useState } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import GroupToggleBtn from "../../Components/GroupToggleBtn/GroupToggleBtn";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { ToggleButton } from '@mui/material';
import KioCounter from '../../KioComponents/KioCounter/KioCounter';
import { useNavigate, useParams } from 'react-router-dom';
import KioQuantityCount from '../../KioComponents/kioQuantityCount/KioQuantityCount';
import BorderButton from '../../Components/BorderButton/BorderButton';
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import StrConstent from '../../Common/StrConstent';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ItemdetailsfetchAPI } from '../../redux/Action/Itendetails/ItemdetailsAction';
import { GetLoginCartItemAPI } from '../../redux/Action/LoginCart/Getlogincartitem';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import { PhoneverifymethodAPI } from '../../redux/Action/Phoneverifymethod/Phoneverifymethod';
import KioViewCart from '../../KioComponents/KioViewCart/KioViewCart';
import Loader from '../../KioskComponents/Loader/Loader';
const KioSelectOutlet = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const [counter, setCounter] = useState(0);
    const itemdetailslocalstorage = JSON.parse(localStorage.getItem("itemdetails"))
    const itemdetails = useSelector(state => state?.Itemdetails)
    const dispatch = useDispatch();
    const unique_id = uuid();
    const [productdata, setproductdata] = useState([])
    const outletID = localStorage.getItem("outletID")
    const [selectedSize, setSelectedSize] = useState({ size: "", price: "" });
    const modifierItemlocal = (localStorage.getItem("modifierItem"));
    const modifierItem = useSelector(state => state?.ModifierSlice)
    const [modifieramount, setmodifieramount] = useState(0)
    const [itemamount, setitemamount] = useState(0);
    const ItemQuantity = useSelector(state => state?.ItemQuantitySlice?.value)
    const addtocartss = (localStorage.getItem("addtocart"))
    const sugarlevel = localStorage.getItem("sugarlevel")
    const [addtocart, setaddtocart] = useState([])
    const [editcartitem, seteditcartitem] = useState([]);
    const [itemsize, edititemsize] = useState([])
    const Sugarlevelvalue = useSelector(state => state?.SugarlevelSlice?.value)
    const Logindetails = localStorage.getItem("Logindetails")
    const LoginCartSlice = useSelector(state => state?.LoginCartSlice)
    const [isOpen, setIsOpen] = useState(false);
    const GetLoginCartItem = useSelector(state => state?.GetLoginCartItemSlice?.value)
    const cart_identifier = localStorage.getItem("cart_identifier");
    const logincheck = (Logindetails && Logindetails != undefined) ? true : false;
    const outlet_mode = localStorage.getItem("outlet_mode")
    const Phoneverifymethod = useSelector(state => state?.PhoneverifymethodSlice)
    const [SugarPercentage, setsugarpercentage] = useState([0, 25, 50, 75, 100])
    const { id } = useParams();
    const [sugarper, setsugarper] = useState(0);
    const [loaders, setloaders] = useState(false);
    const companydata = JSON.parse(localStorage.getItem("companydata"))
  
    useEffect(() => {
        setloaders(true)
        setTimeout(() => {
            setloaders(false)

        }, 1500)

        dispatch(Drawer_success(false))
        dispatch(PhoneverifymethodAPI())
    }, [])
    const handleToggleChange = (id) => {
        // console.log("SugarPercentage", id)
        setsugarper(id)
    }
    useEffect(() => {

        const addfilteritem = (addtocartss != "undefined" && addtocartss != "undefined" && addtocartss) ? JSON.parse(addtocartss) : []
        const filteritem = addfilteritem?.filter((index) => index?.unique_id == id)
        // console.log("addtocart", filteritem)
        seteditcartitem(filteritem)
    }, [])

    useEffect(() => {
        const modifier = modifierItem?.value?.map((item) => {
            return item?.modifier_quantity * item?.modifier_price
        })

        if (modifier !== undefined && modifier.length > 0) {
            const modifierprice = modifier?.reduce((index, i) => {
                return index + i
            })
            // console.log("modifier", modifierprice)
            setmodifieramount(modifierprice)
        }

    }, [modifierItem, itemdetails])
    useEffect(() => {
        const modifier = modifierItem?.value?.map((item) => {
            return item?.modifier_quantity * item?.modifier_price
        })

        if (modifier !== undefined && modifier.length > 0) {
            const modifierprice = modifier?.reduce((index, i) => {
                return index + i
            })
            // console.log("modifier", modifierprice)
            setmodifieramount(modifierprice)
        }

    }, [])
    useEffect(() => {
        if (outletID == null) {
            localStorage.setItem("outletID", Phoneverifymethod?.value?.data?.outlet_id)
            dispatch(ItemdetailsfetchAPI(Phoneverifymethod?.value?.data?.outlet_id))
        } else {

            dispatch(ItemdetailsfetchAPI(outletID))
        }
    }, [Phoneverifymethod])
    useEffect(() => {

        if (itemdetails?.value?.size) {
            const itemsizes = {
                size: itemdetails?.value?.size[0]?.size,
                price: itemdetails?.value?.size[0]?.price
            }
            const edititemsize = {
                size: itemsize?.selectedsize?.size,
                price: (itemsize?.selectedsize?.price)
            }
            // console.log("edititemsize", edititemsize)
            setSelectedSize(
                id ? edititemsize : itemsizes
            )
        }
    }, [itemdetails])


    // useEffect(() => {

    //     if (GetLoginCartItem?.data?.selectedsize) {
    //         const edititemsize = {
    //             size: GetLoginCartItem?.data?.selectedsize?.size,
    //             price: (GetLoginCartItem?.data?.selectedsize?.price?.split(".")[0])
    //         }

    //         setSelectedSize(
    //             edititemsize
    //         )
    //     }
    // }, [GetLoginCartItem])
    useEffect(() => {
        const amountperoneQuanity = (selectedSize?.price + modifieramount);
        const amounnt = (parseInt(selectedSize?.price) + modifieramount) * (ItemQuantity)
        // console.log("setitemamount", selectedSize?.price, modifieramount, ItemQuantity)

        setitemamount(
            amounnt
        )

        // localStorage.setItem("itemamount", amounnt)
    }, [itemdetails, selectedSize, modifieramount, ItemQuantity, modifierItem])
    // console.log("totalamount", selectedSize?.price, modifieramount, ItemQuantity)

    useEffect(() => {
        if (id) {

            dispatch(GetLoginCartItemAPI(id))
        }
    }, [id])



    const handleNavigate = () => {
        dispatch(Drawer_success(true))
        const logincheck = (Logindetails && Logindetails != undefined) ? true : false;

        const itemdetailsdata = {
            "order_mode": outlet_mode,
            "cart_id": cart_identifier,
            "cart_item_id": id,
            "unique_id": unique_id,
            "outlet_id": "",
            "address_id": null,
            "id": id ? itemsize?.id : itemdetails?.value?.item_id,
            "name": id ? itemsize?.name : itemdetails?.value?.item_name,
            "url": id ? itemsize?.url : itemdetails?.value?.image_url,
            "modifier": modifierItem?.value,
            "totalamount": itemamount,
            "selectedsize": selectedSize,
            "item_price": (+selectedSize?.price + +modifieramount),
            "item_one_quantity_price": selectedSize?.price,
            "itemQuantity": ItemQuantity,
            "sugarlevel": sugarper,
            "size": id ? itemsize?.size : itemdetails?.value?.size,
            "description": id ? itemsize?.description : itemdetails?.value?.item_description,
            "is_sugar_enabled": id ? itemsize?.is_sugar_enabled : itemdetails?.value?.is_sugar_enabled

        }


        if (id) {
            const filterobj = addtocart?.filter(item => item?.unique_id != id)
            // console.log("vikalp", itemdetailsdata, itemsize)
            // console.log("vikalp", itemsize)
            localStorage.setItem("addtocart", JSON.stringify([...filterobj, itemdetailsdata]))
            dispatch(Drawer_success(false))
            return navigate("/kio-menu")

        }
        else {
            for (let i = 0; i < addtocart?.length; i++) {

                if (((addtocart[i]?.id) == (itemdetailsdata?.id)) &&
                    (addtocart[i]?.name == itemdetailsdata?.name) &&
                    (JSON.stringify(addtocart[i]?.selectedsize) == JSON.stringify(itemdetailsdata?.selectedsize)) &&
                    (addtocart[i]?.sugarlevel === itemdetailsdata?.sugarlevel) &&
                    (addtocart[i]?.outlet_id === itemdetailsdata?.outlet_id)
                ) {
                    // for (let j = 0; j < addtocart[i]?.modifiers?.length; j++) {
                    //     if ((addtocart[i]?.modifiers[j]?.modifier_id == itemdetailsdata?.modifiers[j]?.modifier_id) &&
                    //         (addtocart[i]?.modifiers[j]?.modifier_name == itemdetailsdata?.modifiers[j]?.modifier_name) &&
                    //         (addtocart[i]?.modifiers[j]?.modifier_price == itemdetailsdata?.modifiers[j]?.modifier_price) &&
                    //         (addtocart[i]?.modifiers[j]?.modifier_quantity === itemdetailsdata?.modifiers[j]?.modifier_quantity)
                    //     ) {
                    //         console.log("modifierItem",j)


                    //         addtocart[i].itemQuantity = addtocart[i].itemQuantity + ItemQuantity
                    //         localStorage.setItem("addtocart", JSON.stringify(addtocart))
                    //         return navigate("/")
                    //     }
                    // }
                    // console.log("vikalp")
                    const cartsame = addtocart[i]?.modifier?.every((data, ii) => {
                        return data?.modifier_quantity == itemdetailsdata?.modifier[ii]?.modifier_quantity
                    })
                    if (cartsame == true) {
                        addtocart[i].itemQuantity = addtocart[i].itemQuantity + ItemQuantity
                        localStorage.setItem("addtocart", JSON.stringify(addtocart))
                        dispatch(Drawer_success(false))
                        return navigate("/kio-menu")
                    }


                }
            }
            if (addtocart?.length > 0) {


                if ((addtocart[0]?.outlet_id === itemdetailsdata?.outlet_id)) {
                    // console.log("addtocartvikalp", addtocart[0]?.outlet_id, itemdetailsdata?.outlet_id)
                    setaddtocart([...addtocart, itemdetailsdata])
                    localStorage.setItem("addtocart", JSON.stringify([...addtocart, itemdetailsdata]))
                    dispatch(Drawer_success(false))
                    navigate("/kio-menu")
                } else {
                    // console.log("addtocartvikalp", addtocart[0]?.outlet_id, itemdetailsdata?.outlet_id)
                    localStorage.removeItem("addtocart")
                    localStorage.setItem("addtocart", JSON.stringify([itemdetailsdata]))
                    dispatch(Drawer_success(false))
                    navigate("/kio-menu")
                }
            } else {
                // console.log("addtocartvikalp")
                setaddtocart([...addtocart, itemdetailsdata])
                localStorage.setItem("addtocart", JSON.stringify([...addtocart, itemdetailsdata]))
                dispatch(Drawer_success(false))
                navigate("/kio-menu")
            }
        }

    }

// console.log("modifieramount",modifieramount)

    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);

    };
    useEffect(() => {
        setaddtocart(((addtocartss != "undefined" && addtocartss != "undefined" && addtocartss) ? JSON.parse(addtocartss) : []))
    }, [])
    useEffect(() => {
        edititemsize(id ? ((editcartitem[0])) : (itemdetails?.value))
    }, [editcartitem, itemdetails, GetLoginCartItem])
    // console.log("itemsize", itemsize)
    useEffect(() => {
        if (LoginCartSlice?.error?.message) {
            setIsOpen(true);
        }
    }, [LoginCartSlice])
    // console.log("itemdetailslocalstorage",itemdetailslocalstorage)
    return (
        <>
            {
                loaders == true && (
                    <Loader />
                )
            }
            <ContainerBox>
                <div className={CommonStyle.kioOuter}>
                    <div className={CommonStyle.flexCenter}>
                        <div>
                            <div className={classes.imgContent}>
                                <img src={id ? ((editcartitem !== undefined ? editcartitem[0]?.url : itemdetailslocalstorage?.image_url)) : (itemdetails?.value?.image_url ? itemdetails?.value?.image_url : itemdetailslocalstorage?.image_url)} alt="Not Found" />
                            </div>
                        </div>
                        <div className={CommonStyle.pl20}>
                            <h3>{id ? ((editcartitem !== undefined ? editcartitem[0]?.name : itemdetailslocalstorage.name)) : (itemdetails?.value?.item_name ? itemdetails?.value?.item_name : itemdetailslocalstorage?.item_name)}</h3>
                            <h3 className={classes.price}>

                               {companydata?.currency_symbol}
                                {id ? ((editcartitem !== undefined ? (
                                    editcartitem[0]?.size[0]?.price != 0 ? editcartitem[0]?.size[0]?.price : editcartitem[0]?.size[1]?.price

                                )
                                    : (
                                        itemdetailslocalstorage?.size[0]?.price != 0 ? itemdetailslocalstorage?.size[0]?.price : itemdetailslocalstorage?.size[1]?.price

                                    )
                                ))
                                    : (itemdetails?.value?.size ? <>
                                        {itemdetails?.value?.size[0]?.price != 0 ? itemdetails?.value?.size[0]?.price
                                            : itemdetails?.data?.size[1]?.price
                                        }
                                    </> : <>
                                        {

                                            itemdetailslocalstorage?.size[0]?.price != 0 ? itemdetailslocalstorage?.size[0]?.price : itemdetailslocalstorage?.size[1]?.price
                                        }
                                    </>)
                                }
                            </h3>
                        </div>
                    </div>
                    <div className={classes.groupToggleBtn}>
                        <GroupToggleBtn>
                            {
                                itemsize != undefined ? (itemsize?.size?.map((size, index) => {
                                    const isDisplay = itemsize?.size[0]?.price != 0 ? itemsize?.size[0]?.price : itemsize?.size[1]?.price;
                                    const regularPrice = (
                                        itemsize?.size[1]?.price - itemsize?.size[0]?.price
                                    ).toFixed(1);
                                    // console.log("index", index)
                                    return (size?.size === "Regular" || size?.size === "Small") && size?.price === 0 ? null : (
                                        <ToggleButton
                                            className={
                                                selectedSize.size == size?.size ?
                                                    classes.active : null}
                                            value={size?.price}
                                            key={index}
                                            selected={selectedSize.size == size?.size}
                                            onClick={() =>
                                                setSelectedSize({
                                                    size: size?.size,
                                                    price: size?.price,
                                                })
                                            }
                                        ><h5 className={CommonStyle.mb5}>{size?.size}</h5>
                                            <p>{size?.price > 0
                                                ? size?.size === "Small"
                                                    ? "-"
                                                    : isDisplay === size?.price
                                                        ? "-"
                                                        : `+${companydata?.currency_symbol}` +
                                                        parseFloat(regularPrice)

                                                : "-"}</p>
                                        </ToggleButton>
                                    )

                                })) : []
                            }
                        </GroupToggleBtn>
                    </div>
                    {itemsize?.modifier?.length > 0 && (

                        <h2 className={`${CommonStyle.mt20} ${CommonStyle.mb20}`}>{StrConstent?.kioSelectItem?.choice?.heading}</h2>
                    )}
                    <KioViewCart
                        id={itemsize != undefined ? itemsize?.modifier : []}
                        data={itemsize?.modifier}
                    />
                    {itemsize?.is_sugar_enabled == 1 &&
                        <>

                            <h2 className={CommonStyle.my1rem}>{StrConstent?.kioSelectItem?.sugarLevel?.heading}</h2>
                            <div className={classes.sugarLevel}>
                                <GroupToggleBtn>
                                    {SugarPercentage?.map((data) => {
                                        return <ToggleButton value={data} onClick={() => handleToggleChange(data)} className={sugarper == data ? classes.active : null} >{data}%</ToggleButton>

                                    })}
                                </GroupToggleBtn>
                            </div>
                        </>
                    }
                    <h2 className={CommonStyle.my1rem}>{StrConstent?.kioSelectItem?.quantity?.heading}</h2>
                    <KioQuantityCount itemQuantity={itemsize?.itemQuantity} />
                    <div className={`${CommonStyle.flexCenter} ${CommonStyle.JustifySpaceBetween} ${CommonStyle.kioBottomBtnContainer}`}>
                        <BorderButton label="Back" clickHandler={() => navigate(-1)} />
                        <LargeBtn number={ItemQuantity} label="Add to cart" amount={`${companydata?.currency_symbol}${itemamount}`} clickHandler={() => {
                            if (itemamount != NaN) {

                                handleNavigate()
                            }
                        }} />
                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioSelectOutlet);