import React, { useEffect, useState } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../KioskComponents/ContainerBox/ContainerBox";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useNavigate } from 'react-router';
import BackArrow from '../../Components/BackArrow/BackArrow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

// import { loadStripe } from '@stripe/stripe-js';
// import {
//     CardNumberElement,
//     CardExpiryElement,
//     CardCvcElement,
//     PaymentElement,
//     Elements,
//     useStripe,
//     useElements,
// } from '@stripe/react-stripe-js';
import SmallButton from '../../Components/SmallButton/SmallButton';
import SocialButton from '../../Components/SocialButton/SocialButton';
import CustomButton from '../../Components/CustomButton/CustomButton';
import CustomCheckbox from "../../Components/CustomCheckbox/CustomCheckbox";
import { NavLink } from 'react-router-dom';
import RoundedDrawer from '../../Components/BottomDrawer/RoundedDrawer';
import dropDownIconCircle from '../../images/dropDownIconCircle.svg';
import phonePay from '../../images/phonePay.png';
import googlePay from '../../images/googlePay.png';
import masterCart from '../../images/masterCart.svg';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import visa from '../../images/visa.svg';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import { Clear_Otp_verified } from '../../redux/reducers/Continuewithphone/Otpverified';

import { getStripeCardAPI } from '../../redux/Action/Card/GetStripeCard';
import { CouponListAPI } from '../../redux/Action/Coupon/Couponlistaction';
import { ApplyCouponListAPI } from '../../redux/Action/Coupon/applyCouponAction';
import { RemoveCouponListAPI } from '../../redux/Action/Coupon/RemoveCouponAction';
import AlertMessage from '../../Components/Alertmsg/AlertMessage';
import { StripeCheckoutAPI } from '../../redux/Action/Stripepayment/StripeCheckout';
import { Button, FormLabel } from '@mui/material';
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import { GetLoginCartAPI } from '../../redux/Action/LoginCart/GetLoginCartAction';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import Loader from '../../Components/Loader/Loader';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import CustomModal from '../../Components/CustomModal/CustomModal';
import TimerCount from '../../Timer/TimerCount';
import CommonSelectBoxAceCart from '../../Components/CommonSelectBoxAceCart/CommonSelectBoxAceCart';


// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// const CARD_ELEMENT_OPTIONS = {
//     showIcon: true,
//     style: {
//         base: {
//             color: "#3F4444",
//             fontFamily: "Noto Serif",
//             fontSmoothing: "antialiased",
//             fontSize: "16px",
//         },
//         invalid: {
//             color: "#fa755a",
//             iconColor: "#fa755a",
//         },
//     },
// };
const KioskProceedToPay = (props) => {
    let navigate = useNavigate();
    const { classes } = props;
    const [desktop, setDesktop] = useState(window.innerWidth > 640);
    const [paymentCart, setPaymentCart] = useState();
    const [promoCode, setPromoCode] = useState(false);
    const dispatch = useDispatch();
    const GetStripeCard = useSelector(state => state?.GetStripeCardSlice?.value);
    const grandTotal = localStorage.getItem("grandTotal");
    const Couponlist = useSelector(state => state?.CouponlistCSlice?.value)
    const ApplyCoupon = useSelector(state => state?.ApplyCouponSlice?.value)
    const ApplyCouponerror = useSelector(state => state?.ApplyCouponSlice)
    const couponname = localStorage.getItem("couponname")
    const OtpverifiedSlice = useSelector(state => state?.OtpverifiedSlice?.value)
    const device = useBreakpoint();
    const [isOpen, setIsOpen] = useState(false);
    const GetLoginCartSlice = useSelector(state => state?.GetLoginCartSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const [errorMessage, setErrorMessage] = useState(null);
    const Logindetails = localStorage.getItem("Logindetails");
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const url = window.location.href;
    const urlcheck = url?.includes("kiosk")
    const [GetLoginCart, setGetLoginCart] = useState(false)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    const Stripepayment = useSelector(state => state?.StripepaymentSlice)
    const outlet_mode = localStorage.getItem("outlet_mode")
    const { t } = useTranslation();
    const [cartitem, setcartitem] = useState([]);
    const addtocartlogin = useSelector(state => state?.GetLoginCartSlice)
    const addtocart = localStorage.getItem("addtocart")
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    // const companydata = JSON.parse(localStorage.getItem("companydata"))


    useEffect(() => {
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))

    }, [])
    useEffect(() => {
        setcartitem(addtocartlogin?.value?.data?.cart_items ? addtocartlogin?.value?.data?.cart_items : (addtocart ? JSON.parse(addtocart) : []))

    }, [addtocartlogin, addtocartwithoutlogin, addtocart])
    const handleNavigate = () => {
        setPaymentCart(true)
    }
    const handlePromoCodeApply = () => {
        setPromoCode(true);
        dispatch(Drawer_success(true))
    }
    useEffect(() => {
        // if (OtpverifiedSlice?.success == true) {

        // dispatch(GetLoginCartAPI())
        // }
        dispatch(Clear_Otp_verified())
        dispatch(Clear_Continue_as_guest())
    }, [])

    const inputHandler = (event) => {
        // console.log("inputHandler:", event);
    };

    const mobileWidth = () => {
        if (window.innerWidth <= 640) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
    useEffect(() => {
        dispatch(Clear_Otp_verified())
        if (couponname) {
            dispatch(ApplyCouponListAPI(couponname))
        }
        dispatch(Drawer_success(false))
        dispatch(GetLoginCartAPI())
        dispatch(CouponListAPI())
        // dispatch(getStripeCardAPI())
    }, [])
    useEffect(() => {
        if (addtocartlogin?.value?.data?.cart_items?.length == 0) {
            navigate("/")
        }
    }, [addtocartlogin])
    useEffect(() => {
        window.addEventListener("resize", mobileWidth);
        return () => window.removeEventListener("resize", mobileWidth);
    })
    const handleApplyCoupon = (id) => {
        // ApplyCouponListAPI
        dispatch(ApplyCouponListAPI(id))
        setPromoCode(false)
        dispatch(Drawer_success(false))
    }
    const handleRemovecoupon = () => {
        dispatch(RemoveCouponListAPI())
    }
    // console.log("ApplyCoupon", ApplyCouponerror)
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);

    };

    useEffect(() => {
        if (ApplyCouponerror?.error) {
            setIsOpen(true)
        }
    }, [ApplyCouponerror])

    const handlepayment = () => {
        dispatch(StripeCheckoutAPI())
    }

    const cardHandle = () => {

    }
    useEffect(() => {
        if (!Logindetail) {
            navigate("/")
        }
    }, [])


    const promoCodeHandleBtn = () => {

    }
    // console.log("GetLoginCartSlice", GetLoginCartSlice)

    const promocodehandler = () => {
        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.cart}>
                    {Couponlist?.data?.map((data) => {
                        return <div className={`${classes.cartBox} ${+grandTotal < data?.minimum_amount ? CommonStyle.disable : ""}`}>
                            <div className={CommonStyle.flexSpaceBetween}>
                                <div className={CommonStyle.pr10}>
                                    <h4>{data?.code}  ({data?.name})</h4>
                                    <p>{data?.descriptions}</p>
                                    <p className={classes.errorMessage}>{data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used ? (+grandTotal < data?.minimum_amount ? `Add items worth ${companydata?.currency_symbol}${data?.minimum_amount - +grandTotal} more to unlock.` : "") : +grandTotal < data?.minimum_amount ? `Add items worth ${companydata?.currency_symbol}${data?.minimum_amount - +grandTotal} more to unlock.` : "This coupon already used."}</p>
                                </div>
                                <div>
                                    {data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used && (
                                        +grandTotal >= data?.minimum_amount ? <SmallButton label={"Apply"} clickHandler={() => handleApplyCoupon(data?.code)} /> : null
                                    )}
                                    {/* <SmallButton label={"Apply"} clickHandler={() => handleApplyCoupon(data?.code)} /> */}
                                </div>
                            </div>
                        </div>
                    })}
                    {Couponlist?.data?.length == 0 && (
                        <h3 align="center">{StrConstent?.proceedToPay?.noCoupons?.heading}</h3>
                    )}
                </div>
            </div>
        </React.Fragment>
    }

    useEffect(() => {
        if (GetLoginCartSlice?.loading == true) {
            setGetLoginCart(true)
        } else {
            setTimeout(() => {
                setGetLoginCart(false)
            }, 1500)
        }
    }, [GetLoginCartSlice])
    return (
        <>
            {
                Stripepayment?.loading == true && (
                    <Loader />
                )
            }
            {
                GetLoginCart == true && (
                    <Loader />
                )
            }

            {
                ApplyCouponerror?.error?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={ApplyCouponerror?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }
            <div className={`${CommonStyle.spacingX0} ${CommonStyle.wrapper} ${classes.processToPay}`}>
                <ContainerBox>
                    <div className={classes.p16}>
                        {
                            isappwebviewcheck != "acecard" && (

                                <BackArrow clickHandler={() => navigate("/cart")} />
                            )
                        }

                        <h2 className={CommonStyle.mb20}>{t("proceed to pay")}</h2>
                    </div>
                    {!urlcheck ? "" :
                        <TimerCount seconds={120} />
                    }
                    {isappwebviewcheck == "acecard" &&
                        <div className={CommonStyle.pX16}><CommonSelectBoxAceCart /></div>
                    }
                    <Accordion className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<img src={dropDownIconCircle} alt="Not Found" />}>
                            <h3>{t("Order Details")}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.listing}>
                                <ul>
                                    {cartitem?.map((data) => {
                                        return <li>
                                            <div className={`${classes.leftContent} ${CommonStyle.flexCenter}`}>
                                                <div className={classes.imgContent}>
                                                    <img src={data?.url} alt="Not Found" />
                                                </div>
                                                <div className={classes.txtContent}>
                                                    <h5>{data?.name}</h5>
                                                    <p>{data?.selectedsize?.size}</p>
                                                </div>
                                            </div>
                                            <div className={classes.rightContent}>
                                                <h4>{companydata?.currency_symbol}{data?.totalamount}</h4>
                                            </div>
                                        </li>
                                    })}
                                    <li>
                                        <div className={classes.leftContent}>
                                            <p className={classes.title}>{t("Grand Total")}</p>
                                        </div>
                                        <div className={classes.rightContent}>
                                            <p>{companydata?.currency_symbol}{GetLoginCartSlice?.value?.data?.grand_total}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.leftContent}>
                                            <p className={classes.title}>{t("Promo Code")} <h6>{ApplyCoupon?.data?.coupon_used}</h6></p>
                                            <p>{t("This promotion is used only one time and on first order")} </p>
                                        </div>
                                        <div className={classes.rightContent}>
                                            <p>-{companydata?.currency_symbol}{ApplyCoupon?.success == true ? ApplyCoupon?.data?.discount_availed : 0}</p>
                                            {
                                                ApplyCoupon?.success == true && (

                                                    <SmallButton label={t("Remove")} icon={<DeleteIcon />} clickHandler={() => handleRemovecoupon()} />
                                                )
                                            }
                                            {ApplyCoupon?.success != true && (
                                                <SmallButton label={t("Apply Promo Code")} defaultExpanded={desktop} clickHandler={() => handlePromoCodeApply()} />
                                            )
                                            }
                                        </div>
                                    </li>
                                    {outlet_mode == "Schedule Delivery" && <li>
                                        <div className={classes.leftContent}>
                                            <p className={classes.title}>{t("Delivery Fee")}</p>

                                        </div>
                                        <div className={classes.rightContent}>
                                            <p>{companydata?.currency_symbol}{+GetLoginCartSlice?.value?.data?.delivery_fee}</p>

                                        </div>
                                    </li>}
                                    {/* <li>
                                        <div className={`${CommonStyle.fromControl} ${CommonStyle.wFull}`}>
                                            <FormLabel>Promo Code</FormLabel>
                                            <div className={classes.promocode}>
                                                <input type="text" name="" placeholder="Enter promo code" />
                                                <CustomButton label="Apply" clickHandler={promoCodeHandleBtn} />
                                            </div>
                                        </div>
                                    </li> */}
                                    <li>
                                        <div className={classes.leftContent}>
                                            <p className={classes.title}>{t("Total Payable")}</p>
                                        </div>
                                        <div className={classes.rightContent}>
                                            <p>{companydata?.currency_symbol}{ApplyCoupon?.success == true ? outlet_mode == "Schedule Delivery" ? +ApplyCoupon?.data?.discounted_total + +GetLoginCartSlice?.value?.data?.delivery_fee : Math.abs(+ApplyCoupon?.data?.discounted_total) : +grandTotal + +GetLoginCartSlice?.value?.data?.delivery_fee}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div className={`${CommonStyle.bottomBtn} ${CommonStyle.JustifyCenter}`}><LargeBtn label={t("proceed to pay")} clickHandler={() => { handlepayment() }} /></div>

                    {/* <div className={`${CommonStyle.my2rem} ${CommonStyle.flexJustifyCenter}`}>
                        <NavLink to="/login" className={CommonStyle.links} >Sign in </NavLink><p className={CommonStyle.pl5}> to earn 10 points for this order!</p>
                    </div> */}
                </ContainerBox>
            </div>

            {device == "sm" ? <>
                {
                    promoCode == true &&
                    (
                        <RoundedDrawer>
                            {promocodehandler()}
                        </RoundedDrawer>
                    )
                }
            </> : <>

                {DrawerSlice == true && <CustomModal
                    open={true}
                    // title={StrConstent.cart.cardClear.heading}
                    handleClose={() => dispatch(Drawer_success(false))}
                    description={""}>
                    {promocodehandler()}
                </CustomModal>}
            </>

            }

        </>
    )
}
export default withStyles(Styles)(KioskProceedToPay);