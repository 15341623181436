import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useSyncRefHeight } from "../../CustomHooks/ElementSameHeight";
import plusIcon from './../../images/plusIcon.svg';
import { useRef } from "react";
import { NavLink } from "react-router-dom";
const RecommendedItem = (props) => {
    const { classes, url, heading, price } = props;
    // box height
    const boxHeightRef = useRef(null);
    useSyncRefHeight(
        [
            ['boxHeight', boxHeightRef],
        ],
        // trigger hook when items of footerItems changes, since it may change height
        [price],
    );
    return (
        <>
            <div className={classes.itemContainer}>
                <div className={`${classes.itemInner} ${CommonStyle.flexCenterStart}`}>
                    <div className={classes.imgContent}>
                        <img src={url} alt="Not Found" />
                    </div>
                    <div className={classes.itemName} ref={boxHeightRef}>
                        <h4>{heading}</h4>
                        <div className={`${CommonStyle.flex} ${CommonStyle.JustifyEnd}`}>
                            <h3>${price}</h3>
                            {/* <NavLink className={classes.circle}><img src={plusIcon} alt="Not Found" /></NavLink> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default withStyles(Styles)(RecommendedItem);