import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Remove_Coupon_failure, Remove_Coupon_request, Remove_Coupon_success } from "../../reducers/Coupon/RemoveCouponSlice";
import { Headers } from "../../../APIHandler/HeaderAPI";
export const RemoveCouponListAPI = (payload) => async (dispatch, getState) => {
    dispatch(Remove_Coupon_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    const cart_identifier = localStorage.getItem("cart_identifier")
    const couponname = localStorage.getItem("couponname")
    try {

        const { data } = await axios.post(`${APIURL.removecoupon}`, {
            cart_id: cart_identifier,
            coupon_code: couponname
        }, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })

        dispatch(Remove_Coupon_success(data))
        localStorage.removeItem("couponname")
        window.location.reload()
    } catch (error) {
        dispatch(Remove_Coupon_failure(error?.response?.data))
    }
}
