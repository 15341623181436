import * as React from 'react';
import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
const CustomModal = (props) => {
    const { classes, open, children, title, description, modalClose, handleClose } = props
    return (
        <>
            <Modal
                open={open}
                onClose={() => handleClose()}
                onClick={modalClose}>
                <Fade in={open} onClick={modalClose}>
                    <div className={classes.modalContent}>
                        <div className={classes.modalInner}>
                            {/* <Button onClick={modalClose} className={classes.closeIcon}><img src={times} alt="Not Found" /></Button> */}
                       {
                        title!= undefined && description!=description && description!="" &&title!="" && (

                        <div className={classes.title}><h4>{title}</h4> <p>{description}</p></div>
                        )
                       }    
                            {children}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}
export default withStyles(Styles)(CustomModal);