import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ColorTheme from '../../Common/CommonCss/ColorTheme';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import china from './../../images/china.png'
import Thailand from './../../images/thailand.png'
import usa from './../../images/flag.png'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names =
    [
        {
            lang: "English",
            code: "en",
            countrycode: "US",
            icon: usa,
        },
        {
            lang: "Chinese",
            code: "chi",
            countrycode: "CN",
            icon: china,

        },
        {
            lang: "Thailand",
            code: "thai",
            countrycode: "TH",
            icon: Thailand
        },

    ]

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultipleSelectLanguage = (props) => {
    const { classes } = props;
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const { i18n } = useTranslation();
    const languagemulti = localStorage.getItem("languagemulti")
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    React.useEffect(() => {
        if (languagemulti != "") {
            i18n.changeLanguage([languagemulti])
        }
    }, [])

    // React.useEffect(() => {
    //     if (isappwebviewcheck == "acecard") {
    //         i18n.changeLanguage(['thai'])
    //     }
    // }, [])
    const handleChange = (event) => {
        // const selectedLanguages = Array.from(event.target.selectedOptions).map((option) => option.value);
        const {
            target: { value },
        } = event;
        i18n.changeLanguage(typeof value === 'string' ? value.split(',') : value);
        localStorage.setItem("languagemulti", typeof value === 'string' ? value.split(',') : value)

        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // console.log("event ", typeof value === 'string' ? value.split(',') : value)
        window.location.reload()
    };
    // console.log("event", personName)
    return (
        <div className={classes.customSelect}>
            <Select
                displayEmpty
                value={personName}
                // onClick={handleChange}
                // onChange={props.onChange}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <div className={classes.langContent}><div className={classes.langIconContent}>{languagemulti == "chi" ? <img src={china} alt="" width="20px" height="20px" /> : languagemulti == "thai" ? <img src={Thailand} alt="" width="20px" height="20px" /> : languagemulti == "en" ? <img src={usa} alt="" width="20px" height="20px" /> : <img src={usa} alt="" width="20px" height="20px" />}  </div>{languagemulti != "" && languagemulti ? languagemulti : "en"}</div>;
                    } else {
                        return <div className={classes.langContent}><div className={classes.langIconContent}>{selected.join(', ') == "chi" ? <img src={china} alt="" width="20px" height="20px" /> : selected.join(', ') == "thai" ? <img src={Thailand} alt="" width="20px" height="20px" /> : selected.join(', ') == "en" ? <img src={usa} alt="" width="20px" height="20px" /> : <img src={usa} alt="" width="20px" height="20px" />}  </div>{selected.join(', ')}</div>;
                    }
                    // return selected.join(', ');
                }}
                MenuProps={
                    {
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: `${ColorTheme.yellow} !important`,
                                color: `${ColorTheme.secondaryColor} !important`,
                            }
                        },
                        disableScrollLock: true
                    }
                }
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {names?.map((name) => (
                    <MenuItem
                        key={name?.code}
                        value={name?.code}
                        className={`${classes.menuItem}`}
                    >
                        <div className={classes.langContent}><div className={classes.langIconContent}>
                        <img src={name?.icon} alt="" width="20px" height="20px" />
                        </div><span className={classes.ml5}>{name?.lang}</span></div>
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
export default withStyles(Styles)(MultipleSelectLanguage);