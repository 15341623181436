import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Styles } from "./Style";
import plusIcon from '../../images/plusIcon.svg';
import minimizeIcon from '../../images/minimizeIcon.svg';
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { ItemQuantity_success } from '../../redux/reducers/Modifier/ItemQuantitySlice';
const KioQuantityCount = (props) => {
    const { classes, itemQuantity } = props;
    const [counter, setCounter] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        setCounter(itemQuantity != undefined ? itemQuantity : 1)
        dispatch(ItemQuantity_success(itemQuantity != undefined ? itemQuantity : 1));
    }, [itemQuantity])
    const increase = () => {
        setCounter(counter + 1);
        dispatch(ItemQuantity_success(counter + 1));
    };
    const decrease = () => {
        if (counter === 1) {
            return;
        }
        setCounter(counter - 1);
        dispatch(ItemQuantity_success(counter - 1));
    };
    return (
        <>
            <div className={classes.counter}>
                <NavLink onClick={() => decrease()} className={classes.btn}><img src={minimizeIcon} alt="Not Found" /></NavLink>
                <span className={classes.counterNumber}>{counter}</span>
                <NavLink onClick={() => increase()} className={classes.btn}><img src={plusIcon} alt="Not Found" /></NavLink>
            </div>
        </>
    )
}
export default withStyles(Styles)(KioQuantityCount)