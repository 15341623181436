import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1,
  loading: false,
  
}

export const AddtocartRefreshSlice = createSlice({
  name: 'AddtocartRefreshSlice',
  initialState,
  reducers: {
 

    Addtocart_Refresh_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
    Addtocart_Refres: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
  
  }
})

// Action creators are generated for each case reducer function
export const {Addtocart_Refresh_success,Addtocart_Refres} = AddtocartRefreshSlice.actions

export default AddtocartRefreshSlice.reducer