import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const PhoneverifymethodSlice = createSlice({
    name: 'Phoneverifymethod',
    initialState,
    reducers: {

        Phone_verify_method_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Phone_verify_method_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Phone_verify_method_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Phone_verify_method_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  Phone_verify_method_Cart, Phone_verify_method_failure,Phone_verify_method_success, Phone_verify_method_request } = PhoneverifymethodSlice.actions

export default PhoneverifymethodSlice.reducer