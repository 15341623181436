import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Styles } from "./Style";
import { NavLink } from "react-router-dom";
import plusicon from '../../images/plusIcon.svg';
import minimizeIcon from '../../images/minimizeIcon.svg';
const CounterBox = (props) => {
    const { classes } = props;
    const [counter, setCounter] = useState(0);
    const increase = () => {
        setCounter(counter + 1);
    };

    const decrease = () => {
        if (counter === 0) {
            return;
        }
        setCounter(counter - 1);
    };

    return (
        <div className={classes.countBox}>
            <NavLink onClick={() => decrease()}><img src={minimizeIcon} alt="Not Found" /></NavLink>
            <span className={classes.countNumber}>{counter}</span>
            <NavLink onClick={() => increase()}><img src={plusicon} alt="Not Found" /></NavLink>
        </div>
    );
};
export default withStyles(Styles)(CounterBox);
