import axios from "axios"
import { Headers, Hipster_Base_URL } from "../../../APIHandler/HeaderAPI"
import { reloadHandler } from "../../../Common/utlis/utlis";
import { APIURL } from "../../../APIHandler/APIURL";
import { Choose_mode_failure, Choose_mode_request, Choose_mode_success } from "../../reducers/choosemode/ChoosemodeSlice";

export const ChooseModeApi = (payload) => async (dispatch, getState) => {
    dispatch(Choose_mode_request())
    try {
        const { data } = await axios.get(`${APIURL.choosemode}`, {
            headers: Headers
        })

        // console.log("data", data);
        dispatch(Choose_mode_success(data))

    } catch (error) {
        reloadHandler(error?.response?.status)
        // console.log("error", error);
        dispatch(Choose_mode_failure())
    }
}