import * as React from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import KioCounter from '../KioCounter/KioCounter';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate } from 'react-router-dom';
const KioMyCartItem = (props) => {
    const { classes } = props;
    const addtocart = localStorage.getItem("addtocart")
    const addtocarts = addtocart ? JSON.parse(addtocart) : []
    const navigate=useNavigate()
    const companydata = JSON.parse(localStorage.getItem("companydata"))
   
    const handleEdit = (id) => {
        // if (Logindetail) {
        //     dispatch(GetLoginCartItemAPI(id?.cart_item_id))
        //     navigate(`/select-outlet/${id?.cart_item_id}`)
        // } else {

        // console.log("handleEdit", id)
        navigate(`/kio-select-outlet/${id?.unique_id}`)
        window.location.reload()
        // }
    }
    return (
        <>
            {addtocarts?.map((item) => {
                return <div className={classes.cartContainer}>
                    <div className={classes.imgContent}>
                        <img src={item?.url} alt="Not Found" />
                        <div className={classes.edit} onClick={()=>handleEdit(item)}><ModeEditIcon /></div>
                    </div>
                    <h4>{item?.name}</h4>
                    <h3>{companydata?.currency_symbol}{item?.item_price}</h3>
                    <p>
                        {item?.selectedsize?.size} / {item?.modifier?.map((data) => {
                            return " " + data?.modifier_name + " " + data?.modifier_quantity + " / "
                        })}{item?.sugarlevel ?"sugarlevel "+item?.sugarlevel:""}
                    </p>
                    {/* <p>Large / Honey Pearls / Sugar Level (50%)</p> */}
                    {/* {
                        addtocarts?.map((data)=>{
                            return 
                        })
                    } */}
                    <KioCounter itemQuantity={item?.itemQuantity} item_price={item?.item_price} cart_item_id={item?.unique_id} />


                </div>
            })
            }

        </>
    )
}
export default withStyles(Styles)(KioMyCartItem);