import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    mt1rem: {
        marginTop: '1rem',
    },
    nextBtn: {
        "& div": {
            width: '100%',
            maxWidth: '500px',
        },
        "& button": {
            width: '100%',
        }
    },
    otpContainer: {
        margin: '20px auto',
        "& > div": {
            justifyContent: 'center',
            "& > input": {
                height: '80px',
                width: '80px !important',
                borderRadius: '10px',
                margin: '0 10px',
                border: 'none',
                background: ColorTheme.lightYellow,
                fontSize: '1.25rem',
                "& placeholder": {
                    fontSize: '1rem',
                },
                "@media(max-width:640px)": {
                    height: '60px',
                    width: '60px !important',
                }
            },
        },

        "& span": {
            display: 'none'
        }
    },
})