import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    outer: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    imgContainer: {
        width: '100%',
        height: 'calc(100% - 85px)',
        maxHeight: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center bottom',
    },
    touchBtn: {
        padding: '2rem !important',
        fontSize: 'var(--bs-h2FontSize) !important',
        lineHeight: '1.5rem !important',
        textAlign: 'center',
        width: '100%',
        minHeight: '90px',
        backgroundColor: `${ColorTheme.yellow} !important`,
        color: `${ColorTheme.secondaryColor} !important`,
        fontFamily: 'var(--main-heading-family) !important',
        position: 'absolute !important',
        bottom: '0',
        left: '0',
        right: '0',
        height: '90px',
        // transform: 'translateX(-50%)',
        borderRadius: '0 !important',
        boxSizing: 'border-box !important'
    },
})
