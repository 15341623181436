import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    cartItem: {
        marginBottom: '20px',
        background: ColorTheme.lightYellow,
        padding: '25px',
        borderRadius: '35px',
        display: 'block',
    },
    imgContent: {
        height: '80px',
        width: '80px',
        overflow: 'hidden',
        borderRadius: '10px',
        margin: '0',
        "& img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        }
    },
    productInfo: {
        paddingLeft: '20px',
    },
    topContainer: {
        marginBottom: "10px",
    },
    removeBtn: {
        "& button": {
            border: `1px solid ${ColorTheme.yellow}`,
            margin: '0 15px',
        }
    },
    counter: {
        display: 'flex',
        alignItems: 'center',
        "& img": {
            width: '15px',
        }
    },
    btn: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        height: ' 35px',
        width: ' 35px',
        border: '2px solid !important',
        borderColor: ColorTheme.yellow,
        color: ColorTheme.yellow,
        borderRadius: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    counterNumber: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
        minWidth: ' 30px',
        textAlign: 'center',
        margin: '0 5px',
        fontFamily: 'var(--main-heading-family)',
    },
})

