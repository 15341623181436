import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Coupon_list_failure, Coupon_list_request, Coupon_list_success } from "../../reducers/Coupon/CouponlistSlice";
import { Headers } from "../../../APIHandler/HeaderAPI";
export const CouponListAPI = (payload) => async (dispatch, getState) => {
    dispatch(Coupon_list_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    try {

        const { data } = await axios.get(`${APIURL.couponlist}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
   
        dispatch(Coupon_list_success(data))
   
    } catch (error) {
        dispatch(Coupon_list_failure(error?.response?.data))
    }
}
