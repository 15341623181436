import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    boxOuter: {
        padding: "60px 0 200px",
        height: 'calc(100vh - 100px)',
        background: "#F7F7F7",
        width: "100%",
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '80px',
        position: 'relative',
        "@media(max-width:768px)": {
            width: "350px !important",
        },
        "@media(max-width:475px)": {
            width: "300px !important",
        },
    },

    btnContent: {
        "@media (max-width: 991px)": {
            // margin: "20px 0 0 auto",
        },
    },
    dFlex: {
        display: "flex",
        justifyContent: "flex-end",
    },
    scrollInner: {
        height: 'calc(100% - 140px)',
        padding: '50px 20px',
        // overflow: 'auto',
    },
    checkouBtn: {
        left: '0',
        bottom: '0',
        position: 'absolute',
        width: '100%',
        left: '0',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px',
        height: '150px',
        boxSizing: 'border-box',
        padding: '20px',
        display: 'flex',
        flexDirection: "column",
        background: ColorTheme.white,
        "& div": {
            width: '100%',
        },
        "& button": {
            width: '100%',
            marginBottom: '15px',
        }
    },
    cardHeader: {
        marginBottom: '15px',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '80px',
        width: '100%',
        background: ColorTheme.white,
        zIndex: '9',
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px',
    },
    cardHeaderInner: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        padding: '1rem',
        width: '100%',
    },
    totalamount: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    nodata: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        "& svg": {
            height: '100px',
            width: '100px',
            marginBottom: '15px',
        }
    },

});