import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FlashDealCouponSlice = createSlice({
    name: 'FlashDealCoupon',
    initialState,
    reducers: {

        FlashDealCoupon_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        FlashDealCoupon_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        FlashDealCoupon_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_FlashDealCoupon_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  FlashDealCoupon_request, FlashDealCoupon_success,FlashDealCoupon_failure, Clear_FlashDealCoupon_Cart } = FlashDealCouponSlice.actions

export default FlashDealCouponSlice.reducer