import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const TotalamountwithoutloginSlice = createSlice({
  name: 'Totalamountwithoutlogin',
  initialState,
  reducers: {
 
    Totalamount_withoutlogin_request: (state, action) => {
        state.value = {};
        state.loading = true;
    },
    Totalamount_withoutlogin_success: (state, action) => {

        state.value = action.payload;
        state.loading = false;
    },
    Totalamount_withoutlogin_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const { Totalamount_withoutlogin_failure ,Totalamount_withoutlogin_success,Totalamount_withoutlogin_request} = TotalamountwithoutloginSlice.actions

export default TotalamountwithoutloginSlice.reducer