import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const OrderAgainSlice = createSlice({
    name: 'OrderAgain',
    initialState,
    reducers: {

        Order_Again_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Order_Again_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Order_Again_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Order_Again_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { Order_Again_request, Order_Again_success, Order_Again_failure, Order_Again_Cart } = OrderAgainSlice.actions

export default OrderAgainSlice.reducer