import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FlashDealSlice = createSlice({
    name: 'FlashDeal',
    initialState,
    reducers: {

        FlashDeal_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        FlashDeal_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        FlashDeal_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_FlashDeal_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  FlashDeal_request, FlashDeal_success,FlashDeal_failure, Clear_FlashDeal_Cart } = FlashDealSlice.actions

export default FlashDealSlice.reducer