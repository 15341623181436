import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    itemContainer: {
        padding: '15px',
        borderRadius: '10px',
        backgroundColor: ColorTheme.white,
        marginBottom: '3px',
        marginRight: '20px',
        marginRight: '20px',
    },
    itemInner: {
        // display: 'flex',
    },
    imgContent: {
        width: "70px",
        height: '70px',
        borderRadius: '10px',
        overflow: 'hidden',
        marginRight: '15px',
        "& img": {
            width: "100%",
            height: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    itemName: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        "& h3": {
            textAlign: 'right',
        }
    },
    circle: {
        width: '26px',
        height: '26px',
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: '500',
        borderRadius: '100%',
        border: `1px solid ${ColorTheme.yellow}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '10px',
    }
})