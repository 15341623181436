import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Stripe_payment_failure, Stripe_payment_request } from "../../reducers/Stripepayment/StripeCheckoutSlice"
import { Headers } from "../../../APIHandler/HeaderAPI"


export const StripeCheckoutAPI = (payload) => async (dispatch, getState) => {

    const payloads = payload
    dispatch(Stripe_payment_request())
    const url= window.location.href
    const params= url.split("/proceed-to-pay")[0]
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    // console.log("token", Logindetails?.data?.user_data?.auth_token);
    const cart_identifier = localStorage.getItem("cart_identifier")
    try {

        const { data } = await axios.get(`${APIURL.stripecheckout}/${cart_identifier}?redirect_url=${params}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })

        // console.log("checkoutdata", data)
        window.location.href = data?.session_id

    } catch (error) {
        dispatch(Stripe_payment_failure(error?.response?.data))
        // console.log("checkouterror", error)
    }
}
