import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const SigninSlice = createSlice({
    name: 'Signin',
    initialState,
    reducers: {

        Signin_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Signin_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Signin_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Signin_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const { Signin_request, Signin_success,Signin_failure, Clear_Signin_Cart } = SigninSlice.actions

export default SigninSlice.reducer