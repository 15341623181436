

export const Styles = Theme => ({ 
    allProdImg : {
        width : '100%',
        height : '230px',
        objectFit: 'cover'
    },
    rounded20 : {
        borderRadius : '20px 20px 0 0',
    },
    fdProdIcon : {
        width : '35px',
        height : '35px',
        marginBottom : '10px'
    },
    borderDash : {
        borderBottom : '1px dashed rgba(0, 0, 0, 0.10)',
        margin : '10px 0'
    },
    prodImg : {
        width : '94px',
        height : '96px'
    },
    sliderHeading : {
        fontSize : '30px',
        WebkitLineClamp: 2, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical' ,
        '@media(max-width: 1440px)' : {
            fontSize: '18px'
        } 
    },
    sliderContent : {
        fontSize : '18px',
        margin : '10px 0',
        WebkitLineClamp: 2, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical' 
    },
    itemContainer : {
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        position : 'relative',
        backgroundColor : '#fff',
        margin: '10px 0',
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        width: '46px',
        transform : 'translateY(-50%)',
        '@media(max-width: 1369px)' : {
            top : '-27px'
        }
    },
    nextArrow: {
        right: '-6%',
        '@media(max-width: 1369px)' : { 
            right : '10px'
        }
    },
    prevArrow: {
        left: '-6%', 
        '@media(max-width: 1369px)' : { 
            left: 'auto',
            right : '100px'
        }
    },
    allItemTop : {
        padding : '20px',
        display : 'flex !important',
        '& > div' : {
            height : '105px'
        }
    },
    flexDimension : {
        display : 'flex',
        justifyContent: 'center',
        alignItems : 'center'
    },
    distance : {
        backgroundColor : '#fff',
        height : '45px',
        width  : '142px',
        border: '1px solid #000',
        borderRadius : '9px',
        right : '30px',
        bottom : '-5px',
        top: '30px',
        '@media(max-width: 1280px)' : {
            width : '120px',
            height : '40px'
        },
        '& p' : {
            color: '#000',
            fontSize: '24px',
            marginLeft: '10px',
            '@media(max-width: 1280px)' : { 
                fontSize : '18px'
            },
        },
        '& img' : {
            width: '21px',
            height: '24px'
        },
    },
    bottomContent : {
        padding: '26px 22px',
        '@media(max-width : 920px)' : {
            marginLeft : '0 !important'
        },
        '& img' : {
            width : '27px',
            height : '31px',
            marginRight : '10px',
        },
        '& span' : {
            color: '#000',
            fontWeight: '700',
            fontSize: '24px',
            '@media(max-width : 1368px)' : { 
                fontSize : '16px'
            },
        }
    }
})