const primarycolorname = () => {
    const primarycolor = JSON.parse(localStorage.getItem("companydata"));
    return primarycolor?.primary_color
}
const secondarycolorname = () => {
    const primarycolor = JSON.parse(localStorage.getItem("companydata"));
    return primarycolor?.secondary_color;
}
const secondaryfontfamily = () => {
    const primarycolor = JSON.parse(localStorage.getItem("companydata"));
    return primarycolor?.secondary_font;
}
const primaryfontfamily = () => {
    const primarycolor = JSON.parse(localStorage.getItem("companydata"));
    return primarycolor?.primary_font
}
const primaryRgba = JSON.parse(localStorage.getItem("companydata"));
const alphaValue01 = 0.1;
const alphaValue02 = 0.2;
// console.log(primaryRgba, 'primaryRgba');
const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex?.toString()?.slice(1, 3), 16);
    const g = parseInt(hex?.toString()?.slice(3, 5), 16);
    const b = parseInt(hex?.toString()?.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
// console.log("primaryfontfamily",primaryfontfamily())
const ColorTheme = ({
    white: '#fff',
    black: '#000',
    transparent: 'transparent',
    lightBrown: hexToRgba(primaryRgba?.primary_color, alphaValue01),
    yellow: primarycolorname(),
    lightYellow: hexToRgba(primaryRgba?.primary_color, alphaValue02),
    lightGray: '#DDDDDD',
    gray: ' #D5D4DC',
    darkGray: '#3F4444BF',
    silkGray: '#F7F7F7',
    paleGrey: '#E6E7E7',
    battleshipGray: '#3F44441A',
    gainsboroGrey: '#D9D9D9',
    green: '#198754',
    lightGreen: '#11906A',
    lime: '#3CB44B',
    steel: '#FFFFFF40',
    red: '#D00606',
    pink: '#FF6363',
    scarlet: '#FF4C25',
    fontColor: ' #3F4444',
    azure: '#3699FF',
    secondaryColor: secondarycolorname(),
    secondaryfontfamily: secondaryfontfamily(),
    primaryfontfamily: primaryfontfamily()
});
export default ColorTheme;


