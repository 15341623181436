import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    chipsContent: {
        margin: '1.5rem 0',
        "& div": {
            padding: '6px 8px !important',
            minWidth: "80px",
            marginRight: "15px",
            backgroundColor: `${ColorTheme.lightBrown}`,
        },
        "& span": {
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'var(--main-heading-family)',
        },
    },
    active: {
        backgroundColor: `${ColorTheme.yellow} !important`,
        color: `${ColorTheme.secondaryColor} !important`,
    },
    tabContainer: {
        "& div": {
            "&:nth-child(2)": {
                "& > span": {
                    backgroundColor: `${ColorTheme.yellow} !important`,
                }
            },
            '& .Mui-selected': {
                color: `${ColorTheme.yellow} !important`,
            },
            "& + span": {
                backgroundColor: `${ColorTheme.yellow} !important`,
            },
        }
    },
    productContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '25px',
        "@media(max-width:991px)": {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        "@media(max-width:640px)": {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    },
    orderSlider: {
        marginBottom: '20px',
        "& > div > div > div> div > div": {
            paddingRight: '15px',
        },
    },
    orderContainer: {
        "& > div": {
            width: '33%',
            marginRight: '15px',
            "&:last-child": {
                marginRight: '0',
            }
        }
    },
    costomizeItem: {
        padding: '15px',
        backgroundColor: ColorTheme.silkGray,
        marginBottom: '1rem',
        borderRadius: '10px',
        "@media(max-width:640px)": {
            backgroundColor: ColorTheme.white,
        },
        "& div": {
            backgroundColor: ColorTheme.silkGray,
            "@media(max-width:640px)": {
                backgroundColor: ColorTheme.white,
            },
            "@media(max-width:475px)": {
                "&:nth-child(2)": {
                    maxWidth: 'fit-content',
                    marginTop: '15px',
                    marginLeft: 'auto !important',
                }
            }
        },
        "& > div": {
            "@media(max-width:475px)": {
                display: 'block',
            }
        }
    },
    // visible:{
    //     position:'inherit',
    //     boxShadow:'none',
    //     backgroundColor:ColorTheme.transparent,
    //     transition: '1s ease',
    // }
    sectionSpacing: {
        margin: '0 auto 2rem !important',
        paddingBottom: '2rem',
        minHeight: '700px',
    },
    bottomSpacing: {
        "@media(max-width:640px)": {
            // paddingBottom: '9rem',
        }
    },
    paddingTop: {
        paddingTop: '320px',
        "& h2": {
            display: 'none',
        },
        "@media(max-width:640px)": {
            paddingTop: '350px',
        }
    },
    fixedHeader: {
        paddingTop: '2rem',
        "& > div": {
            padding: '0',
        },
    },
    flex: {
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'space-between',
        "@media(max-width:640px)": {
            display: 'block',
        },
    },
    filterBox: {
        maxWidth: '500px',
        marginLeft: '10px',
        width: '100%',
        "@media(max-width:640px)": {
            margin: "15px 0",
            maxWidth: '100%',
        },
    },
    fixedHeader: {
        "@media(max-width:640px)": {
            height: '175px'
        },
    }
})
