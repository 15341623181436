import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const VerifynumberSlice = createSlice({
    name: 'Verifynumber',
    initialState,
    reducers: {

        Verify_number_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Verify_number_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Verify_number_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Verify_number_change: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  Verify_number_request, Verify_number_success,Verify_number_failure, Clear_Verify_number_change } = VerifynumberSlice.actions

export default VerifynumberSlice.reducer