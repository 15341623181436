import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,

}

export const CartClearcountSlice = createSlice({
    name: 'CartClear',
    initialState,
    reducers: {

        Cart_Clear_count_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
    
    }
})

// Action creators are generated for each case reducer function
export const { Cart_Clear_count_success } = CartClearcountSlice.actions

export default CartClearcountSlice.reducer