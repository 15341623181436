import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
  error: {}
}

export const ReservationOutletSlice = createSlice({
  name: 'ReservationOutlet',
  initialState,
  reducers: {
    Reservation_Outlet_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Reservation_Outlet_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    },
    Reservation_Outlet_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    },
    Clear_Reservation_Outlet_failure: (state, action) => {
        state.error = {};
        state.value={};
        state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { Reservation_Outlet_request,Reservation_Outlet_success,  Reservation_Outlet_failure , Clear_Reservation_Outlet_failure} = ReservationOutletSlice.actions

export default ReservationOutletSlice.reducer