import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    paymentOuter: {
        "@media(max-width:640px)": {
            textAlign: 'center',
        },
    },
    checkBox: {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        objectFit: 'cover',
        backgroundColor: ColorTheme.red,
        margin: '10px 0 23px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media(max-width:640px)": {
            margin: '10px auto 23px',
            width: '65px',
            height: '65px',
        },
        "& img": {
            height: 'auto',
            width: '100%',
            maxWidth: '35px',
            "@media(max-width:640px)": {
                maxWidth: '25px',
            },
        }
    },
    listing: {
        "& li": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px 0',
            borderBottom: '1.09px solid #D5D4DC',
            "&:last-child": {
                borderBottom: 'none',
                paddingBottom: '0',
            }
        },
    },
    leftContent: {
        width: '70%',
    },
    title: {
        color: ColorTheme.fontColor,
    },
    rightContent: {
        width: '30%',
        textAlign: 'right',
        marginLeft: '10px !important',
        justifyContent: 'flex-end',
        "& p": {
            textAlign: 'right',
            color: ColorTheme.fontColor,
        },
        "& button": {
            float: 'right',
            marginLeft: '5px',
            marginTop: '8px',
        }
    },
    tryBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0 auto',
        "@media(max-width:640px)": {
            flexDirection: 'column',
        },
        "& a": {
            "@media(max-width:640px)": {
                marginTop: '10px',
            }
        }
    },
    imgContent: {
        height: 'auto',
        width: '65px',
        borderRadius: '10px',
        "& img": {
            height: 'auto',
            maxWidth: '65px',
            objectFit: 'cover',
            borderRadius: '10px',
        }
    },
    txtContent: {
        paddingLeft: '10px',
        textAlign: 'left',
    },
    downloadReceipt: {
        marginLeft: 'auto',
        "@media(max-width:640px)": {
            width: '100%',
            "& div": {
                width: '100%',
            },
            "& button": {
                width: '100%',
                backgroundColor: ColorTheme.white,
            }
        }
    },
    proceedBtn: {
        "& button": {
            justifyContent: 'center',
        }
    },
    accordionContainer: {
        "& > div": {
            background: ColorTheme.lightBrown,
        },
    },
})