import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { Headers } from "../../../APIHandler/HeaderAPI";
import { Profile_Get_failure, Profile_Get_request, Profile_Get_success } from "../../reducers/ProfileUpdate/ProfilegetSlice";
import { Faq_failure, Faq_request, Faq_success } from "../../reducers/Faq/FaqSlice";

export const FaqAPI = (payload) => async (dispatch, getState) => {
  dispatch(Faq_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
    
    try {

        const { data } = await axios.get(`${APIURL.faq}/${payload}`, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            },
            
        })
        // window.location.href=`data:application/pdf;charset=utf-8,${encodeURIComponent(data)}`
        dispatch(Faq_success(data))
       
    } catch (error) {

        dispatch(Faq_failure(error?.response?.data))
    }
}
