import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const RemoveCouponSlice = createSlice({
    name: 'RemoveCoupon',
    initialState,
    reducers: {
        Remove_Coupon_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Remove_Coupon_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Remove_Coupon_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { Remove_Coupon_request, Remove_Coupon_success, Remove_Coupon_failure } = RemoveCouponSlice.actions

export default RemoveCouponSlice.reducer