import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
    loading: false,
    error: {}
}

export const FilteridcartmatchSlice = createSlice({
    name: 'Filteridcartmatch',
    initialState,
    reducers: {
        Filterid_cartmatch_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Filterid_cartmatch_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Filterid_cartmatch_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.value = {};
        }
    }
})

// Action creators are generated for each case reducer function
export const { Filterid_cartmatch_request, Filterid_cartmatch_success ,Filterid_cartmatch_failure} = FilteridcartmatchSlice.actions

export default FilteridcartmatchSlice.reducer