import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Styles } from "./Style";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ItemQuantity_success } from "../../redux/reducers/Modifier/ItemQuantitySlice";
import minimizeIcon from './../../images/minimizeIcon.svg';
import plusIcon from './../../images/plusIcon.svg';
import { UpdateItemQuantityAPI } from "../../redux/Action/LoginCart/UpdateItemQuantity";
import { Filterid_cartmatch_success } from "../../redux/reducers/Filteridcartmatch";

const KioskQuantityCount = (props) => {
    const { classes, itemQuantity, item_price, Quantity,cart_item_id } = props;
    const [counter, setCounter] = useState(1);
    const dispatch = useDispatch();
    const addtocart = localStorage.getItem("addtocart");
    const Logindetails = localStorage.getItem("Logindetails")
    const cart_identifier = localStorage.getItem("cart_identifier")
    const item_id = localStorage.getItem("item_id")
    const GetLoginCart = useSelector(state => state?.GetLoginCartSlice?.value)
    const ItemQuantitySlice=useSelector(state=>state?.ItemQuantitySlice?.value)
//    console.log("itemQuantity",itemQuantity)
    useEffect(() => {
        setCounter(itemQuantity != undefined ? itemQuantity : 1)
        dispatch(ItemQuantity_success(itemQuantity != undefined ? itemQuantity : 1));
    }, [itemQuantity])
    const increase = () => {
        setCounter(counter + 1);
        dispatch(ItemQuantity_success(counter + 1));
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cart_item_id,
            item_quantity: counter + 1,
            item_price: item_price
        }
        // console.log("obj", obj)
        // dispatch(UpdateItemQuantityAPI(obj))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []

        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id ==cart_item_id? { ...item, itemQuantity: counter+1 } : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
    //    console.log("addtocarts",changeaddtocarts)
        
    };
    // useEffect(() => {
    //     const filteridmatch = GetLoginCart?.data?.cart_items?.filter((data) => data?.id == item_id)
    //     dispatch(Filterid_cartmatch_success(filteridmatch))
    // }, [counter])
    const decrease = () => {
        if (counter === 1) {
            return;
        }
        setCounter(counter - 1);
        dispatch(ItemQuantity_success(counter - 1));
        const obj = {
            cart_id: cart_identifier,
            cart_item_id: cart_item_id,
            item_quantity: counter - 1,
            item_price: item_price
        }
        // console.log("obj", obj)
        // dispatch(UpdateItemQuantityAPI(obj))
        const addtocarts = (addtocart != "undefined" && addtocart != "undefined" && addtocart) ? JSON.parse(addtocart) : []

        const changeaddtocarts = addtocarts?.map((item) => {
            return item?.unique_id ==cart_item_id? { ...item, itemQuantity: counter -1} : item
        })
        localStorage.setItem("addtocart", JSON.stringify(changeaddtocarts))
    //    console.log("addtocarts",changeaddtocarts)
    };
    // console.log("counter", counter,ItemQuantitySlice)
   
    return (
        <div className={classes.countBox}>
            <NavLink onClick={() => decrease()}><img src={minimizeIcon} alt="Not Found" /></NavLink>
            <span className={classes.countNumber}>{counter}</span>
            <NavLink onClick={() => increase()}><img src={plusIcon} alt="Not Found" /></NavLink>
        </div>
    );
};
export default withStyles(Styles)(KioskQuantityCount);
