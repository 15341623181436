import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FlashDealAddCouponSlice = createSlice({
    name: 'FlashDealAddCoupon',
    initialState,
    reducers: {

        FlashDealAddCoupon_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        FlashDealAddCoupon_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        FlashDealAddCoupon_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_FlashDealAddCoupon_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  FlashDealAddCoupon_request, FlashDealAddCoupon_success,FlashDealAddCoupon_failure, Clear_FlashDealAddCoupon_Cart } = FlashDealAddCouponSlice.actions

export default FlashDealAddCouponSlice.reducer