import moment from "moment";

export const reloadHandler = (access) => {
  // console.log("access", access);
  if (access === 401) {
    localStorage.removeItem("companydata");
    window.location.reload();
  }
};

export const convertDDMMYYY = (response) => {
  const yourDate = new Date(response)?.toString();
  const dmyDate = moment(yourDate)?.format("DD MMM YYYY hh:mm A");
  return dmyDate;
};

export const supplyDateFormat = (date) => {
  const dd = String(date?.getDate())?.padStart(2, "0");
  const mm = String(date?.getMonth() + 1)?.padStart(2, "0"); //January is 0!
  const yyyy = date?.getFullYear();
  date = yyyy + "-" + mm + "-" + dd;
  return date;
};
export const supplyTimeFormat = (date) => {
  const dd = String(date?.getDate())?.padStart(2, "0");
  const mm = String(date?.getMonth() + 1)?.padStart(2, "0"); //January is 0!
  const yyyy = date?.getFullYear();
  date = yyyy + "-" + mm + "-" + dd;
  return date;
};

export const generateRandomNumber = () => {
  const min = 1000000000; // Smallest 10-digit number
  const max = 9999999999; // Largest 10-digit number
  const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNum.toString();
}
