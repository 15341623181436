import { Container } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const ContainerBox = (props) => {
    const { children, classes } = props;
    const location = useLocation();
    const companydata = JSON.parse(localStorage.getItem("companydata"));
    const isappwebviewcheck = localStorage.getItem("isappwebviewcheck")
    // console.log(isappwebviewcheck, 'isappwebviewcheck')
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* <div className={(isappwebviewcheck != "acecard") ? classes.aceSpaceRemove : classes.acecardApp}>
                <div className={`${companydata?.is_header_hide == 0 ? classes.spacingManage : classes.sectionSpacing}`}> */}
            <Container maxWidth="lg" className={`${classes.spacingTop} ${companydata?.is_footer_hide == 0 && location.pathname === "/" ? classes.bottomPadding : classes.bottomPaddingRemove} ${companydata?.is_footer_hide == 0 && location.pathname === "/cart" ? classes.bottomPadding : classes.bottomPaddingRemove} ${companydata?.is_footer_hide == 0 && location.pathname === "/cancel" ? classes.bottomPadding : classes.bottomPaddingRemove} ${companydata?.is_footer_hide == 0 && location.pathname === "/proceed-to-pay" ? classes.bottomPadding : classes.bottomPaddingRemove} ${companydata?.is_footer_hide == 0 && location.pathname === "/select-outlet" ? classes.bottomPadding : classes.bottomPaddingRemove} ${companydata?.is_footer_hide == 0 && location.pathname === "/select-outlet/id" ? classes.bottomPadding : classes.bottomPaddingRemove} ${location.pathname === "/voucher-details" ? classes.bottomPadding : classes.bottomPaddingRemove}`} >
                {children}
            </Container>
            {/* </div>
            </div> */}
        </>
    )
}
export default withStyles(Styles)(ContainerBox);
