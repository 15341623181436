import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    customSelect: {
        marginRight: '15px',
        "@media(max-width:360px)": {
            marginRight: '8px',
        },
        "& > div": {
            margin: '0 !important',
            background: `${ColorTheme.white} !important`,
            borderRadius: '8px !important',
            padding: '0 !important',
            height: '48px !important',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none !important',
            "@media(max-width:375px)": {
                height: '40px !important',
            },
            "& div": {
                "&::after": {
                    content: '',
                    display: 'none',
                },
            },
            "& > div": {
                padding: '10px !important'
            }
        },
        "& span": {
            fontStyle: 'inherit',
            fontSize: '18px',
            fontWeight: '500',
            fontFamily: 'var(--main-heading-family)',
        },
    },
    langContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        textTransform: 'capitalize',
        fontSize: '16px',
        "@media(max-width:375px)": {
            fontSize: '14px',
        },
        "@media(max-width:340px)": {
            fontSize: '12px',
        }
    },
    langIconContent: {
        height: '22px',
        width: '22px',
        fontSize: '16px',
        marginRight: '5px',
        "@media(max-width:375px)": {
            height: '20px',
            width: '20px',
            fontSize: '15px',
        },
        "@media(max-width:340px)": {
            width: '15px',
            fontSize: '14px',
        }
    },
    selectLang: {
        backgroundColor: `${ColorTheme.yellow} !important`,
        color: `${ColorTheme.secondaryColor} !important`,
    }
})