import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = (theme) => ({
    alertSuccess: {
        "& > div > div": {
            backgroundColor: `${ColorTheme.lime} !important`,
        },
    },
    alertInfo: {
        "& > div > div": {
            backgroundColor: `${ColorTheme.azure} !important`,
        },
    },
    alertUnSuccess: {
        "& > div > div": {
            backgroundColor: `${ColorTheme.scarlet} !important`,
        },
    },
    alertInner: {
        "& > div": {
            overflow: "hidden",
            backgroundColor: `${ColorTheme.white} !important`,
            margin: "0 10px !important",
            boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important",
            borderRadius: "0 !important",
            padding: "0 10px !important",
            marginTop: '4rem !important',
            "& > div ": {
                width: '100%',
                padding: '10px 0 !important',
            }
        },
        "@media(max-width:640px)": {
            boxShadow: "none !important",
        }
    },
    alertBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        "@media(max-width:640px)": {
            alignItems: "flex-start",
        },
        "& button": {
            padding: '3px !important',
            "& svg": {
                height: "20px",
                width: "20px",
                color: `${ColorTheme.white} !important`,
            },
        },
    },
    // imgContent: {
    //     minHeight: "25px",
    //     minWidth: "25px",
    //     objectFit: "cover",
    //     marginRight: '5px',
    //     width: "10%",
    //     "& img": {
    //         height: "auto",
    //         width: "auto",
    //         maxWidth: '35px',
    //     },
    // },
    txtContent: {
        padding: "0 5px 0 0",
        width: "100%",
        minWidth: "200px !important",
        maxWidth: "500px",
        "& h5": {
            margin: "0 0 2px",
            textAlign: "left !important",
            color: ColorTheme.white,
        },
        "& p": {
            textAlign: "left !important",
            fontSize: '1rem',
            fontWeight: '600',
            color: ColorTheme.white,
            fontFamily: 'var(--sub-heading-family) !important',
        },
    },
})