import React, { useState, useEffect } from 'react';
import { withStyles } from "@mui/styles";
import { Styles } from './Style'; // assuming Style.js is exporting Styles

const CountdownTimer = ({ initialTime, classes }) => { // Destructure classes directly

  const targetTime = new Date(initialTime).getTime(); // Convert target time to milliseconds
  const [countdown, setCountdown] = useState(0); // Initial countdown value
// console.log("targetTime",targetTime,countdown)
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const difference = targetTime - now;

      // Check if the countdown has reached 0 or passed the target time
      if (difference <= 0) {
        clearInterval(interval);
        setCountdown(0);
      } else {
        setCountdown(difference);
      }
    }, 1000); // Update countdown every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [targetTime]);
  const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);


  return (
    <>
      <div className={classes.counters}> 
          <div>
            <h3>{days}</h3>
            <p>Day</p>
         </div>
         <div className={classes.colon}> :</div>
         <div>
            <h3> {hours}  </h3>
            <p>Hrs</p>
         </div>
         <div className={classes.colon}>:</div>
         <div>
            <h3>{minutes < 10 ? '0' : ''}{minutes}</h3>
            <p>Min</p>
         </div>
         <div className={classes.colon}>:</div>
          <div>
            <h3>{seconds < 10 ? '0' : ''}{seconds}</h3>
            <p>Sec</p>
          </div>
       </div>
    </>
  );
};

export default withStyles(Styles)(CountdownTimer);
