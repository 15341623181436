import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  loading: false,
}

export const CategorieswithoutloginSlice = createSlice({
  name: 'categorieswithoutlogin',
  initialState,
  reducers: {
    Categories_without_login_request: (state, action) => {
      state.value={};
      state.loading = true;
    },
    Categories_without_login_success: (state, action) => {

      state.value = action.payload;
      state.loading = false;
    },
    Categories_without_login_failure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    }
  }
})

// Action creators are generated for each case reducer function
export const {Categories_without_login_request, Categories_without_login_success,Categories_without_login_failure } = CategorieswithoutloginSlice.actions

export default CategorieswithoutloginSlice.reducer