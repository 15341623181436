import ColorTheme from "../../Common/CommonCss/ColorTheme";
export const Styles = Theme => ({
    countBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: ColorTheme.white,
        borderRadius: '10px',
        minWidth: '120px',
        "& a": {
            minWidth: '25px',
            textAlign: 'center',
            padding: '10px',
            minHeight: '12px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius:'5px',
            border: `1px solid ${ColorTheme.lightGray}`,
        }
    },
    countNumber: {
        margin: "0 5px",
        fontSize: "18px",
        lineHeight: "18px",
        color: ColorTheme.fontColor,
        fontWeight: "400",
        minWidth: '25px',
        textAlign: 'center',
        padding: '10px',
    }
})