import React, { useEffect, useState } from 'react'
import { Styles } from './Style';
import { withStyles } from '@mui/styles';
import StrConstent from '../../Common/StrConstent';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from '../../KioComponents/ContainerBox/ContainerBox';
import SmallButton from "../../KioskComponents/SmallButton/SmallButton";
import LargeBtn from '../../Components/LargeBtn/LargeBtn';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from '../../CustomHooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import { Drawer_success } from '../../redux/reducers/DrawerSlice';
import { Clear_Otp_verified } from '../../redux/reducers/Continuewithphone/Otpverified';
import { Clear_Continue_as_guest } from '../../redux/reducers/Continueasguest/ContinueasguestSlice';
import { ApplyCouponListAPI } from '../../redux/Action/Coupon/applyCouponAction';
import { GetLoginCartAPI } from '../../redux/Action/LoginCart/GetLoginCartAction';
import { CouponListAPI } from '../../redux/Action/Coupon/Couponlistaction';
// import { getStripeCardAPI } from '../../redux/Action/Card/GetStripeCard';
import { RemoveCouponListAPI } from '../../redux/Action/Coupon/RemoveCouponAction';
import { StripeCheckoutAPI } from '../../redux/Action/Stripepayment/StripeCheckout';
import TimerCount from '../../Timer/TimerCount';
import { Clear_Apply_Coupon } from '../../redux/reducers/Coupon/ApplyCouponSlice';
import { Clear_Continue_with_phone } from '../../redux/reducers/Continuewithphone/ContinuewithphoneSlice';
import Loader from '../../Components/Loader/Loader';
const KioProceedToPay = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const [desktop, setDesktop] = useState(window.innerWidth > 640);
    const [paymentCart, setPaymentCart] = useState();
    const [promoCode, setPromoCode] = useState(false);
    const dispatch = useDispatch();
    const GetStripeCard = useSelector(state => state?.GetStripeCardSlice?.value);
    const grandTotal = localStorage.getItem("grandTotal");
    const Couponlist = useSelector(state => state?.CouponlistCSlice?.value)
    const ApplyCoupon = useSelector(state => state?.ApplyCouponSlice?.value)
    const ApplyCouponerror = useSelector(state => state?.ApplyCouponSlice)
    const couponname = localStorage.getItem("couponname")
    const OtpverifiedSlice = useSelector(state => state?.OtpverifiedSlice?.value)
    const device = useBreakpoint();
    const [isOpen, setIsOpen] = useState(false);
    const GetLoginCartSlice = useSelector(state => state?.GetLoginCartSlice)
    const DrawerSlice = useSelector(state => state?.DrawerSlice?.value)
    const [errorMessage, setErrorMessage] = useState(null);
    const Logindetails = localStorage.getItem("Logindetails");
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const url = window.location.href;
    const urlcheck = url?.includes("kiosk")
    const [GetLoginCart, setGetLoginCart] = useState(false)
    const [initalloder, setinitialloader] = useState(false);
    const Stripepayment = useSelector(state => state?.StripepaymentSlice)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
   
    useEffect(() => {
        setinitialloader(true)
        setTimeout(() => {
            setinitialloader(false)

        }, 1500)
    }, [])

    const handleNavigate = () => {
        setPaymentCart(true)
    }
    const handlePromoCodeApply = () => {
        setPromoCode(true);
        navigate("/kio-vouchers")
    }
    useEffect(() => {
        // if (OtpverifiedSlice?.success == true) {

        // dispatch(GetLoginCartAPI())
        // }
        dispatch(Clear_Otp_verified())
        dispatch(Clear_Continue_as_guest())
    }, [])

    const inputHandler = (event) => {
        // console.log("inputHandler:", event);
    };

    const mobileWidth = () => {
        if (window.innerWidth <= 640) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }
  
    useEffect(() => {
        dispatch(Clear_Otp_verified())
        if (couponname) {
            dispatch(ApplyCouponListAPI(couponname))
        }
        dispatch(Drawer_success(false))
        dispatch(GetLoginCartAPI())
        dispatch(CouponListAPI())
        // dispatch(getStripeCardAPI())
    }, [])
    useEffect(() => {
        window.addEventListener("resize", mobileWidth);
        return () => window.removeEventListener("resize", mobileWidth);
    })

    const removeHandle = () => {

    }

    const paymentHandle = () => {

    }


    const handleApplyCoupon = (id) => {
        // ApplyCouponListAPI
        dispatch(ApplyCouponListAPI(id))
        setPromoCode(false)
        dispatch(Drawer_success(false))
    }
    const handleRemovecoupon = () => {
        dispatch(RemoveCouponListAPI())
    }
    // console.log("ApplyCoupon", ApplyCouponerror)
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);

    };

    useEffect(() => {
        if (ApplyCouponerror?.error) {
            setIsOpen(true)
        }
    }, [ApplyCouponerror])

    const handlepayment = () => {
        dispatch(Clear_Apply_Coupon())
        dispatch(StripeCheckoutAPI())
    }

    const cardHandle = () => {

    }
    useEffect(() => {
        dispatch(Clear_Continue_with_phone())
        if (!Logindetail) {
            navigate("/")
        }
    }, [])


    const promoCodeHandleBtn = () => {

    }
    // console.log("GetLoginCartSlice", GetLoginCartSlice)

    const promocodehandler = () => {
        return <React.Fragment>
            <div className={`${CommonStyle.largeModal} ${CommonStyle.scrollBox}`}>
                <div className={classes.cart}>
                    {Couponlist?.data?.map((data) => {
                        return <div className={`${classes.cartBox} ${+grandTotal < data?.minimum_amount ? CommonStyle.disable : ""}`}>
                            <div className={CommonStyle.flexSpaceBetween}>
                                <div className={CommonStyle.pr10}>
                                    <h4>{data?.code}  ({data?.name})</h4>
                                    <p>{data?.descriptions}</p>
                                    <p className={classes.errorMessage}>{data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used ? (+grandTotal < data?.minimum_amount ? `Add items worth $${data?.minimum_amount - +grandTotal} more to unlock.` : "") : +grandTotal < data?.minimum_amount ? `Add items worth $${data?.minimum_amount - +grandTotal} more to unlock.` : "This coupon already used."}</p>
                                </div>
                                <div>
                                    {data?.per_customer_usage != null && data?.per_customer_usage > data?.customer_used && (
                                        +grandTotal > data?.minimum_amount ? <SmallButton label={"Apply"} clickHandler={() => handleApplyCoupon(data?.code)} /> : null
                                    )}
                                    {/* <SmallButton label={"Apply"} clickHandler={() => handleApplyCoupon(data?.code)} /> */}
                                </div>
                            </div>
                        </div>
                    })}
                    {Couponlist?.data?.length == 0 && (
                        <h3 align="center">{StrConstent.proceedToPay.noCoupons.heading}</h3>
                    )}
                </div>
            </div>
        </React.Fragment>
    }

    useEffect(() => {
        if (GetLoginCartSlice?.loading == true) {
            setGetLoginCart(true)
        } else {
            setTimeout(() => {
                setGetLoginCart(false)
            }, 1500)
        }
    }, [GetLoginCartSlice])
    // console.log("ApplyCoupon",ApplyCoupon?.data?.coupon_used)
    return (
        <>
 {
            Stripepayment?.loading ==true && (
                <Loader/>
            )
        }
        {
            initalloder==true && (
                <Loader/>
            )
        }
            <ContainerBox>
                <h2 className={CommonStyle.mb20}>{StrConstent.kisok.proceedToPay.heading}</h2>
                <div className={classes.card}>
                    <h4>{StrConstent.kisok.orderDetails.heading}</h4>
                    {/* {!urlcheck? "":
                    <TimerCount seconds={120}/>
                    } */}
                    <ul>
                        <li className={`${CommonStyle.flexCenter} ${CommonStyle.JustifySpaceBetween}`}>
                            <h5>Grand Total</h5>
                            <p>{companydata?.currency_symbol}{GetLoginCartSlice?.value?.data?.grand_total}</p>
                        </li>
                        <li className={`${CommonStyle.flexCenter} ${CommonStyle.JustifySpaceBetween}`}>
                            <h5>Promotion
                            <p>{ApplyCoupon?.data?.coupon_used}</p>
                            </h5>
                            <div className={classes.removeBtn}>
                                {
                                    ApplyCoupon?.success == true && (

                                        <SmallButton label={"Remove"} icon={<DeleteIcon />} clickHandler={() => handleRemovecoupon()} />
                                    )
                                }
                                {ApplyCoupon?.success != true && (
                                    <SmallButton label={"Apply Promo Code"} defaultExpanded={desktop} clickHandler={() => handlePromoCodeApply()} />
                                )
                                }

                            </div>
                        </li>
                        <li className={`${CommonStyle.flexCenter} ${CommonStyle.JustifySpaceBetween}`}>
                            <h5>Total Payable</h5>
                            <p>{companydata?.currency_symbol}{ApplyCoupon?.success == true ? ApplyCoupon?.data?.discounted_total : grandTotal}</p>
                        </li>
                    </ul>
                </div>

                {/* <h3 className={CommonStyle.my2rem}>{StrConstent.kisok.selectPaymentMethod.heading}</h3> */}
                {/* <div className={classes.card}>
                    <div className={classes.leftBox}>
                        <h5>Debit/Credit Card</h5>
                        <p>Vissa / Master / American Express / etc.</p>
                    </div>
                    <div className={classes.rightBox}></div>
                </div> */}
                <div className={`${CommonStyle.flexCenter} ${CommonStyle.JustifyCenter} ${CommonStyle.kioBottomBtnContainer} ${classes.proceedBtn}`}>
                    <LargeBtn label="proceed to pay" clickHandler={() => { handlepayment() }} />
                </div>
                <TimerCount seconds={120} />
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(KioProceedToPay);