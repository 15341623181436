import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import CustomCheckbox from "../../Components/CustomCheckbox/CustomCheckbox";
import CustomButton from '../../Components/CustomButton/CustomButton';
import { FormLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContinuewithphoneAPI } from "../../redux/Action/Conntinuewithphone/ContinuewithphoneSlice";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail, validatePhone } from "../../Common/Validations";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import Loader from "../../Components/Loader/Loader";
import { useTranslation } from 'react-i18next';
import Box from '@mui/joy/Box';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';
import { EmailSigninAPI } from "../../redux/Action/Signin/Signin";
import { Clear_Signin_Cart } from "../../redux/reducers/Signin/SigninSlice";
import { SignupAPI } from "../../redux/Action/Signin/Signup";
import { Clear_Signup_Cart } from "../../redux/reducers/Signin/SignupSlice";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
const Signup = (props) => {
    const { classes } = props;
    const [loginnumber, setloginnumber] = useState("")
    const [emailaddress, setemailaddress] = useState("")
    const [password, setpassword] = useState("")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [emailerrormsg, setemailerrmsg] = useState(false)
    const [passworderrormsg, setpassworderrmsg] = useState(false)
    const Continuewithphone = useSelector(state => state?.ContinuewithphoneSlice?.value)
    const Continuewithphoneslice = useSelector(state => state?.ContinuewithphoneSlice)
    const SigninSlice = useSelector(state => state?.SigninSlice)
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const [firstname, setfirstName] = useState("")
    const [firstnameerror, setfirstNameerror] = useState("")
    const [Lastname, setLastName] = useState("")
    const [Lastnameerror, setLastNameerror] = useState("")
    const [Email, setEmail] = useState("")
    const [Emailerror, setEmailerror] = useState("")
    const [ContactNumber, setContactNumber] = useState("")
    const [ContactNumbererror, setContactNumbererror] = useState("")
    const SignupSlice = useSelector(state => state?.SignupSlice)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
   
    const handlesubmitlogin = () => {

        if (firstname == ""  || password == "" || Email == "" ) {

            if (firstname == "") {
                setfirstNameerror(true)
            }
           
            if (Email == "") {
                setEmailerror(true)
            }
          
            if (password == "") {
                setpassworderrmsg(true)
            }

        } else if (firstname != "" || Lastname != "" || password != "" || Email != "" || ContactNumber != "") {


            const obj = {
                first_name: firstname,
                last_name: Lastname,
                contact_number: ContactNumber,
                email: Email,
                password: password
            }
            dispatch(SignupAPI(obj))
        }
    }
    // console.log("Continuewithphone", Continuewithphoneslice)

    const handleKeyDown = (event) => {
        if (event.keyCode === 69) {
            event.preventDefault();
        }
    };
    const snackHandler = (event, reason) => {
        // if (reason === "clickaway") return;
        setIsOpen(false);
    };
    useEffect(() => {
        if (SignupSlice?.error?.message) {
            setIsOpen(true)
            setTimeout(() => {
                dispatch(Clear_Signup_Cart())
            }, 3000)

        } else if (SignupSlice?.value?.message) {
         
            navigate("/login")
        }
    }, [SignupSlice])
    const handleLogin=()=>{
        navigate("/login")
    }
    return (
        <>

            {SignupSlice?.loading == true && (
                <Loader />
            )
            }
            {
                (SignupSlice?.error?.message) && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={SignupSlice?.error?.message}
                        title={"Error"}
                        type={"Error"}
                        open={isOpen}
                    />
                )
            }

            {
                Continuewithphoneslice?.loading && (
                    <Loader />
                )
            }
            <ContainerBox>
                <div className={classes.loginWrapper}>
                    <div className={classes.signuoaccOuter}>
                        <div className={classes.accInner}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        
                            <h2 className={CommonStyle.mb20}>{t("Signup")}</h2>
                            <h5 className={CommonStyle.mb20} style={{ cursor: "pointer" }} onClick={() => handleLogin()}>{t("Back to Login")}</h5>
                          
                          </div>
                            {/* <FormLabel>
                                Sign up
                            </FormLabel> */}

                            <div className={`${CommonStyle.flexSpaceBetween} ${classes.firstName} ${CommonStyle.itemStart}`}>

                                <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem}`} >
                                    <div className={CommonStyle.flexSpaceBetween}>
                                        <FormLabel>First name*</FormLabel>
                                    </div>
                                    <input type="text" name="first name" className={`${CommonStyle.mb5}`} placeholder={t("Enter Name")} value={firstname} onChange={(e) => {
                                        setfirstNameerror(false)
                                        setfirstName(e.target.value)
                                    }}

                                    />
                                    {firstnameerror && (
                                        <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20} ${CommonStyle.mt10}`}>
                                           Please Enter First Name.
                                        </span>
                                    )}
                                </div>
                                <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem}`} >
                                <div className={CommonStyle.flexSpaceBetween}>
                                        <FormLabel>Last name</FormLabel>
                                    </div>
                                    <input type="text" name="Last Name" className={`${CommonStyle.mb5}`} placeholder={t("Enter Last Name")} value={Lastname} onChange={(e) => {
                                        setLastNameerror(false)
                                        setLastName(e.target.value)
                                    }}

                                    />
                                  

                                </div>
                            </div>


                            {/* {emailerrormsg && (
                                <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                    {StrConstent.login.errorMsg.emailNumberValid}
                                </span>
                            )} */}
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem} ${classes.bottommargin}`}>
                            <div className={CommonStyle.flexSpaceBetween}>
                                        <FormLabel>Email address*</FormLabel>
                                    </div>
                                <input type="text" name="email" className={`${CommonStyle.mb5}`} placeholder={t("Enter your Email Address.")} value={Email} onChange={(e) => {
                                    setEmailerror(false)
                                    setEmail(e.target.value)
                                }}

                                />
                                {Emailerror && (
                                    <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20} ${CommonStyle.mt10}`}>
                                        Please Enter a Valid Email address.
                                    </span>
                                )}
                            </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem} ${classes.bottommargin}`}>
                            <div className={CommonStyle.flexSpaceBetween}>
                                        <FormLabel>Contact Number</FormLabel>
                                    </div>
                                <input type="number" name="contactnumber" className={`${CommonStyle.mb5}`} placeholder={t("Enter your Contact Number.")} value={ContactNumber} onChange={(e) => {
                                    setContactNumbererror(false)
                                    setContactNumber(e.target.value)
                                }}
                                onKeyDown={handleKeyDown}
                                        onInput={(e) => {
                                            if (e?.target?.value != "") {
                                                e.target.value = Math.max(0, parseInt(e.target.value))
                                                    .toString()
                                                    .slice(0, 10);
                                            }
                                        }}
                                />
                               
                            </div>
                            {/* <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem} ${classes.bottommargin}`}>
                            <div className={CommonStyle.flexSpaceBetween}>
                                        <FormLabel>Password *</FormLabel>
                                    </div>
                                <input type="text" name="password" className={`${CommonStyle.mb5}`} placeholder={t("Create your Password")} value={password} onChange={(e) => {
                                    setpassworderrmsg(false)
                                    setpassword(e.target.value)
                                }}

                                />
                               
                            </div> */}
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.my1rem} ${classes.inputContainer}`}>
                                <FormLabel>{t("Password*")}</FormLabel>
                                <input type={showConfirmPassword ? "text" : "password"} name="Confirmpassword" placeholder={t("Create your Password")} value={password} onChange={(e) => {
                                     setpassworderrmsg(false)
                                        setpassword(e.target.value)
                                }}
                                    // className={`${classes.Input} form-input`}
                                />
                                <span className={`${classes.eyeIcon} icon`} onClick={() => setShowConfirmPassword(!showConfirmPassword)} >
                                    {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </span>
                                {passworderrormsg && (
                                    <span className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20} ${CommonStyle.mt10}`}>
                                        Please Enter Valid Password (minimum 6 characters)
                                    </span>
                                )}

                            </div>


                        </div>
                        <div className={`${CommonStyle.mt1rem} ${CommonStyle.fullBtn}`}>
                            <CustomButton label={t("Sign up")} clickHandler={() => handlesubmitlogin()} />
                        </div>

                    </div>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(Signup);