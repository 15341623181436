import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router-dom";
import portrait from '../../images/iceCream.png';
import landscape from '../../images/iceCreamVertical.png';
import { Button } from "@mui/material";
import { PhoneverifymethodAPI } from "../../redux/Action/Phoneverifymethod/Phoneverifymethod";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { Header_check_success } from "../../redux/reducers/HeaderSlice";
const KioHome = (props) => {
    const { classes } = props;
    //  let navigate = useNavigate();
    const outletId = localStorage.getItem("outletID")
    const dispatch = useDispatch();
    const isMobileScreen = useMediaQuery({ query: '(max-width: 1080px)' });
    const Phoneverifymethod = useSelector(state => state?.PhoneverifymethodSlice)
    useEffect(() => {
        // dispatch(PhoneverifymethodAPI())
        dispatch(Header_check_success(false))
        localStorage.setItem("headercheck", false)
        // if (outletId) {
        dispatch(PhoneverifymethodAPI())
        // }

    }, [])
    const navigate = useNavigate()
    const handleNavigate = () => {
        if (Phoneverifymethod?.value?.data?.take_away == true) {
            return navigate("/kio-select-mode")
        } else {
            return navigate("/kio-menu")
        }
    }
    return (
        <>
            <div className={classes.outer} onClick={() => { handleNavigate() }}>
                {
                    isMobileScreen ?
                        <div className={`${classes.imgContainer} ${classes.portrait}`} style={{ backgroundImage: `url(${Phoneverifymethod?.value?.data?.kiosk_banner != "" ? Phoneverifymethod?.value?.data?.kiosk_banner : portrait})` }}></div>
                        :
                        // <div className={`${classes.imgContainer} ${classes.landscape}`} style={{ backgroundImage: `url(${Phoneverifymethod?.value?.data?.kiosk_banner_landscape != "" ? landscape : Phoneverifymethod?.value?.data?.kiosk_banner_landscape})` }}></div>
                        <div className={`${classes.imgContainer} ${classes.landscape}`} style={{ backgroundImage: `url(${Phoneverifymethod?.value?.data?.kiosk_banner_landscape != "" ? landscape : Phoneverifymethod?.value?.data?.kiosk_banner_landscape})` }}></div>
                }
                <Button type="btn" className={classes.touchBtn} >Touch to start</Button>
                {/* <div className={`${classes.imgContainer} ${classes.portrait}`} style={{ backgroundImage: `url(${Phoneverifymethod?.value?.data?.kiosk_banner != "" ? Phoneverifymethod?.value?.data?.kiosk_banner : portrait})` }}></div>
                <div className={`${classes.imgContainer} ${classes.landscape}`} style={{ backgroundImage: `url(${Phoneverifymethod?.value?.data?.kiosk_banner_landscape != "" ? Phoneverifymethod?.value?.data?.kiosk_banner_landscape: portrait})` }}></div> */}
            </div>
        </>
    )
}
export default withStyles(Styles)(KioHome);
