import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const GetStripeCardSlice = createSlice({
    name: 'GetStripeCard',
    initialState,
    reducers: {
        Get_Stripe_Card_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Get_Stripe_Card_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        Get_Stripe_Card_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        Get_Stripe_Card_guest:(state,action)=>{
            state.value = {};
            state.loading = true;
        }
    }
})

// Action creators are generated for each case reducer function
export const {   Get_Stripe_Card_request,  Get_Stripe_Card_success,   Get_Stripe_Card_failure ,  Get_Stripe_Card_guest} = GetStripeCardSlice.actions

export default GetStripeCardSlice.reducer