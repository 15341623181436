import { Container } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useEffect } from "react";
const ContainerBox = (props) => {
    const { classes, children } = props
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Container maxWidth="lg" className={classes.sectionSpacing}>
                {children}
            </Container>
        </>
    )
}
export default withStyles(Styles)(ContainerBox);