import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import searchIcon from '../../images/searchIcon.svg';
import StrConstent from "../../Common/StrConstent";
import { useTranslation } from 'react-i18next';
const FilterSearch = (props) => {
    const { classes, setfilterproductsearch, filterproductsearch, setsearchboxtext } = props;
    const { t } = useTranslation();
    return (
        <>
            <div className={classes.searchBox}>
                <input type="text" placeholder={t("Find a food items")} value={filterproductsearch} onChange={(e) => {
                    setfilterproductsearch(e.target.value)
                    setsearchboxtext(true)
                }} />
                <img src={searchIcon} alt="Not found" />
            </div>
        </>
    )
}
export default withStyles(Styles)(FilterSearch);
