import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Filterid_cartmatch_success } from "../../redux/reducers/Filteridcartmatch";
import { Login_Drawer_success } from "../../redux/reducers/LoginDrawer";
import { Drawer_success } from "../../redux/reducers/DrawerSlice";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useSyncRefHeight } from "../../CustomHooks/ElementSameHeight";
import { OutletIdfetchAPI } from "../../redux/Action/Outlet/OutletIdAction";
import { State_Change_success } from "../../redux/reducers/StateChangeSlice";
const ProductOutlet = (props) => {
    const { classes, url, title, offer, price, discount, item_id, description, address_line1, timeSlot, open_today, schedule, operating_hours } = props;
    const GetLoginCart = useSelector(state => state?.GetLoginCartSlice?.value)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addtocart = localStorage.getItem("addtocart")
    const addtocartwithoutlogin = addtocart ? JSON.parse(addtocart) : []
    const Logindetails = localStorage.getItem("Logindetails");
    const Logindetail = (Logindetails && Logindetails != undefined) ? true : false
    const ItemQuantitySlice = useSelector(state => state?.ItemQuantitySlice?.value)
    const outlet_mode = localStorage.getItem("outlet_mode")
    const outletname = localStorage.getItem("outletname")
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const companyoutletcount = localStorage.getItem("companyoutletcount")
    const maxHeights = useSelector(state => state?.StateChangeSlice?.value)
    const [height, setheight] = useState(0)
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const divRef = useRef(null);
    const handleClick = (item) => {
        // console.log("logindetails", Logindetail)
        // if(outlet_mode){

        localStorage.setItem("outletID", item_id)
        localStorage.setItem("outletname", title)
        navigate("/select-outlet")
        // }else {
        //     localStorage.setItem("outletID", item_id)
        //     localStorage.setItem("outletname", title)
        //     dispatch(OutletIdfetchAPI(item_id))
        //     navigate("/choose-mode")
        // }
        // if (Logindetail) {
        //         // console.log("filteridmatch", filteridmatch)
        //         // console.log("item", item_id);
        //         localStorage.setItem("outletID", item_id)
        //         if( outletname && outlet_mode ){
        //             navigate("/select-outlet")
        //         }else if(outlet_mode && outlet_mode!="Schedule Delivery"){
        //             if (companyoutletcount == "1" || companyoutletcount == 1) {
        //                 navigate("/select-outlet")
        //             } else {
        //                 navigate("/pickup-outlet")
        //             }
        //         }
        //         else if(outlet_mode && outlet_mode=="Schedule Delivery"){
        //             navigate("/delivery-address")
        //         }
        //         else {
        //             navigate("/choose-mode")
        //         }
        // }
        // else {
        //         // console.log("filteridmatch", filteridmatch)
        //         console.log("outletname", outletname);
        //         localStorage.setItem("outletID", item_id)
        //         if( outletname && outlet_mode ){
        //             navigate("/select-outlet")
        //         }else if(outlet_mode && outlet_mode!="Schedule Delivery"){
        //             if (companyoutletcount == "1" || companyoutletcount == 1) {
        //                 navigate("/select-outlet")
        //             } else {
        //                 navigate("/pickup-outlet")
        //             }
        //         }
        //         else if(outlet_mode && outlet_mode=="Schedule Delivery"){
        //             navigate("/delivery-address")
        //         }
        //         else {
        //             navigate("/choose-mode")
        //         }
        // }
    }

    useEffect(() => {
        // console.log("divRef", +maxHeights, divRef?.current?.offsetHeight)
        if (divRef?.current?.offsetHeight != null && maxHeights != null && (+divRef?.current?.offsetHeight > +maxHeights)) {
            // setmaxHeight(divRef?.current?.offsetHeight)


            dispatch(State_Change_success(divRef?.current?.offsetHeight))
            // setheight(divRef?.current?.offsetHeight)

            localStorage.setItem("maxheight", divRef?.current?.offsetHeight)
        }

    }, [divRef?.current?.offsetHeight, maxHeights])



    const boxHeightRef = useRef(null);


    // useLayoutEffect(() => {
    //     if (height < maxHeights) {
    //         setheight(maxHeights)
    //         // console.log(maxHeights, "height")
    //         const classheight = document.querySelectorAll(".height")
    //         // classheight.current.style.height = `${maxHeights}px`;
    //         // console.log("height",classheight)
    //         for (let i = 0; i < classheight?.length; i++) {
    //             const div = classheight[i];
    //             setTimeout(() => {
    //                 // console.log(maxHeights, "height")
    //                 div.style.height = maxHeights;
    //             }, 200)

    //         }
    //     }
    // }, [maxHeights])
    useSyncRefHeight(
        [
            ['boxHeight', boxHeightRef],
        ],
        // trigger hook when items of footerItems changes, since it may change height
        [price],
    );
    const handleMouseEnter = () => {
        setIsOverlayOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOverlayOpen(false);
    };
    return (
        <>
            <div className={`${classes.productItem} ${CommonStyle.relative}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className={`height ${CommonStyle.relative} ${classes.mainDiv}`} ref={divRef} >
                    <div onClick={(item) => {
                        if (timeSlot == false || open_today == false) {
                            return
                        }
                        handleClick(item)
                    }}>
                        <div className={CommonStyle.relative}>
                            <div className={classes.imgContent}>
                                <img src={url} alt="Not Found" />
                            </div>
                            {(timeSlot == false || open_today == false) && <div className={`${CommonStyle.absolute} ${classes.disableOutletBadge}`}>
                                Closed
                            </div>}
                        </div>
                        <div className={classes.textContent}>
                            <h4 className={`${CommonStyle.mb10} ${classes.title}`}>{title}</h4>
                            <p className={classes.address}>{address_line1}</p>
                        </div>
                    </div>

                    {/* {(timeSlot == false || open_today == false) && <div className={`${classes.flex} ${classes.hoverOpen}`}>
                        Open  <span className={`${CommonStyle.block} ${classes.timing}`}>Closes 11pm</span>
                    </div>} */}
                </div>
                <div className={`${classes.overlay} ${isOverlayOpen ? classes.overlayActive : ""}`} onClick={(item) => {
                    if (timeSlot == false || open_today == false) {
                        return
                    }
                    handleClick(item)
                }}>
                    {/* <div className={`${classes.closeOverlay} ${CommonStyle.absolute}`}>X</div> */}
                    <div className={classes.overlayContent}>

                        <div className={`${CommonStyle.flex}`}>
                            {/* <div className={classes.timeName}>Hours:</div> */}
                            <ul>
                                {/* {schedule?.map((data) => {
                                    return <li >
                                        <div>
                                            {data?.day} {" " }
                                        </div> 
                                        <div>:</div>
                                        <div>
                                        <span>

                                            {data?.open} - {data?.close}
                                        </span>
                                        <span>
                                        {data?.open} - {data?.close}
                                        </span>
                                        </div>
                                        </li>
                                })
                                } */}

                                {Object?.keys(operating_hours)?.map(day => (
                                    <li key={day}>
                                        <div>{day.charAt(0).toUpperCase() + day.slice(1)}</div>
                                        <div>:</div>
                                        <div>
                                            <span>

                                                {operating_hours[day]?.includes(",") ? operating_hours[day]?.split(",")[0] : operating_hours[day]}
                                            </span>
                                          {operating_hours[day]?.includes(",") &&  <span>

                                                {operating_hours[day]?.includes(",") ? operating_hours[day]?.split(",")[1] : operating_hours[day]}
                                            </span>}
                                        </div>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withStyles(Styles)(ProductOutlet);