import * as React from 'react';
import { withStyles } from "@mui/styles"
import { Styles } from "./Style";
import StrConstent from "../../Common/StrConstent";
import { useNavigate } from 'react-router';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import BackArrow from "../../Components/BackArrow/BackArrow";
import { Box } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaqAPI } from '../../redux/Action/Faq/Faq';
import Loader from '../../Components/Loader/Loader';
const Faqs = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const [value, setValue] = React.useState('1');
    const dispatch = useDispatch();
    const [defaultfaq, setdefaultfaq] = React.useState(false)
    const FaqSlice = useSelector(state => state?.FaqSlice)
    const handleChange = (event, newValue) => {
        console.log("newValue", newValue)
        setValue(newValue);
        dispatch(FaqAPI(newValue))
        setdefaultfaq(true)
    
    };
    const CategoryFaqSlice = useSelector(state => state?.CategoryFaqSlice)
    useEffect(() => {
        if (CategoryFaqSlice?.value?.data?.faq_categories && defaultfaq == false) {
            dispatch(FaqAPI(CategoryFaqSlice?.value?.data?.faq_categories[0]?.faq_categorie_id))
            setValue(CategoryFaqSlice?.value?.data?.faq_categories[0]?.faq_categorie_id);
            setdefaultfaq(false)
        }
    }, [CategoryFaqSlice])

    const frequentlyQuestion = [
        {
            question: 'How do I use the redeemed vouchers?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
        {
            question: 'What is the expiry date of the points?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
        {
            question: 'Where can I use my points?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
        {
            question: 'Are there any restrictions to the use of points?',
            answer: 'You can find the vouchers under the ‘promotions’ tab during payment.',
        },
    ]
    // use for accordion icon
    const CustomExpandIcon = () => {
        return (
            <Box
                sx={{
                    ".Mui-expanded & > .collapsIconWrapper": {
                        display: "none",
                    },
                    ".expandIconWrapper": {
                        display: "none",
                    },
                    ".Mui-expanded & > .expandIconWrapper": {
                        display: "block",
                    }
                }}>
                <div className="expandIconWrapper"><RemoveIcon /></div>
                <div className="collapsIconWrapper"><AddIcon /></div>
            </Box>
        );
    };
    console.log(" CategoryFaqSlice?.value?.data", defaultfaq)
    const showAllHandlert = () => {

    }
    return (
        <>
        {
            FaqSlice?.loading==true && (
                <Loader/>
            )
        }
            <ContainerBox>
                <div>
                    <BackArrow
                        clickHandler={() => {
                            navigate(-1)
                        }} />
                    <h2 className={CommonStyle.mb10}>{StrConstent.faqs.heading}</h2>
                    <p className={CommonStyle.mb20}>{StrConstent.faqs.description}</p>
                    <TabContext value={value}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" className={`${CommonStyle.tabsContent} ${classes.tabContainer} ${CommonStyle.mb20}`} variant="scrollable" scrollButtons={false}>
                            {
                                CategoryFaqSlice?.value?.data?.faq_categories?.map((data) => {
                                    return <Tab label={data?.faq_categorie_name} value={data?.faq_categorie_id} />
                                })
                            }

                            {/* <Tab label="Item Two" value="2" />
                            <Tab label="Item Three" value="3" /> */}
                        </TabList>
                        <TabPanel value={value} className={CommonStyle.p0}>
                            <div className={classes.frequentlyQues}>
                                {FaqSlice?.value?.data?.faqs?.map((data) => (
                                    <Accordion className={`${CommonStyle.accordionContent} ${classes.accordionContainer}`}>
                                        <AccordionSummary expandIcon={<CustomExpandIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                            <h6 className={CommonStyle.fw700}>{data?.faq_title}</h6>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data?.faq_description
                                                }}
                                            ></p>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                                }
                            </div>
                            {/* <CustomButton label="view full list of faq" clickHandler={showAllHandlert} /> */}
                        </TabPanel>
                    </TabContext>
                </div>
            </ContainerBox>
        </>
    )
}
export default withStyles(Styles)(Faqs);