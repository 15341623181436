import React, { useRef } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Container } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import blackLogo from '../../images/blackLogo.svg';
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import { useEffect } from "react";
import { Footer_Height_success } from "../../redux/reducers/FooterHeight";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
const Footer = (props) => {
    const { classes } = props;
    const location = useLocation();
    const companydata = JSON.parse(localStorage.getItem("companydata"))
    const dispatch = useDispatch()
    const moveToCustomMenu = (url) => {
        // window.location.href = url
        window.open(url, "_blank");
        // cancelHandler();
    };
    const { t } = useTranslation();
    const componentRef = useRef(null);
    useEffect(() => {
        if (componentRef.current) {
            const height = componentRef.current.clientHeight;
            dispatch(Footer_Height_success(componentRef.current.clientHeight))
        }
    })
    return (
        <>
            <div className={`${classes.footerOuter} ${location.pathname === "/" ? classes.bottomPadding : ''} ${location.pathname === "/product-items" ? classes.bottomPadding : ''} ${location.pathname === "/cart" ? classes.bottomPadding : ''} ${location.pathname === "/cancel" ? classes.bottomPadding : ''} ${location.pathname === "/proceed-to-pay" ? classes.bottomPadding : ''} ${location.pathname === "/select-outlet" ? classes.bottomPadding : ''} ${location.pathname === "/select-outlet/id" ? classes.bottomPadding : ''} ${location.pathname === "/voucher-details" ? classes.bottomPadding : ''}`} ref={componentRef}>
                <Container maxWidth="xl">
                    <ul className={classes.footerInner}>
                        <li>
                            <ul className={classes.links}>
                                <li>
                                    <h6>{t("Support")}</h6>
                                </li>
                                <li>
                                    <NavLink to="">{t("Support")}</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/faqs">{t("FAQs")}</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/get-in-touch">{t("contact us")}</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className={classes.links}>
                                <li>
                                    <h6>{t("About")}</h6>
                                </li>
                                <li>
                                    <NavLink to="/our-story">{t("our story")}</NavLink>
                                </li>
                                <li>
                                    <NavLink to="terms-of-use">{t("terms of use")}</NavLink>
                                </li>
                                <li>
                                    <NavLink to="privacy-policy">{t("privacy policy")}</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className={classes.address}>
                            <ul>
                                <li onClick={() =>
                                    moveToCustomMenu(
                                        companydata?.facebook_link
                                    )
                                }>
                                    <NavLink to={""}><FacebookRoundedIcon /></NavLink>
                                </li>
                                <li
                                    onClick={() =>
                                        moveToCustomMenu(
                                            companydata?.instagram_link
                                        )
                                    }>
                                    <NavLink to=""><InstagramIcon /></NavLink>
                                </li>
                                <li
                                    onClick={() =>
                                        moveToCustomMenu(
                                            companydata?.linked_link
                                        )
                                    }>
                                    <NavLink to=""><LinkedInIcon /></NavLink>
                                </li>
                            </ul>
                            <NavLink to="/" className={classes.logo}>
                                <img src={companydata?.footer_logo} alt="Not Found" />
                            </NavLink>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: companydata?.description,
                                }}></p>
                            {/* <p>{companydata?.description}</p> */}
                            {/* <p>The Saturday Café is a project by Saturday Café, part of the Saturday Café Group. */}
                            {/* We are a home-grown specialty coffee company founded in 2011, and Singapore’s first certified B-Corporation.</p> */}
                        </li>
                    </ul>
                </Container>
            </div>
        </>
    )
}
export default withStyles(Styles)(Footer);