import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const LocationlatlongSlice = createSlice({
    name: 'latlonglocation',
    initialState,
    reducers: {
        location_latlong_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        location_latlong_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
        },
        location_latlong_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        Clear_location_latlong: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
        
    }
})

// Action creators are generated for each case reducer function
export const { location_latlong_request, location_latlong_success ,location_latlong_failure,Clear_location_latlong} = LocationlatlongSlice.actions

export default LocationlatlongSlice.reducer