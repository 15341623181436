import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"
import { TimeSlot_Check_failure, TimeSlot_Check_request, TimeSlot_Check_success } from "../../reducers/TimeSlotCheck/TimeSlotCheck";
import { Headers } from "../../../APIHandler/HeaderAPI";

export const TimeSlotCheckAPI = (payload) => async (dispatch, getState) => {
    dispatch(TimeSlot_Check_request())
    const payloads = payload
    const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));

    // console.log("timeslotpayload", payload?.timeslot)
    try {

        const { data } = await axios.post(`${APIURL.timeslotcheck}`, {
            order_time_slot: payload?.timeslot,
            outlet_id: payload?.outlet_id
        }, {
            headers: {
                "url":Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(TimeSlot_Check_success(data))


    } catch (error) {
        dispatch(TimeSlot_Check_failure(error?.response?.data))
    }
}
