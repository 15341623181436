import axios from "axios"
import { APIURL } from "../../../APIHandler/APIURL"

import { Headers } from "../../../APIHandler/HeaderAPI"
import { Add_Address_failure, Add_Address_request, Add_Address_success } from "../../reducers/Addaddress/AddaddressSlice"
import { Get_Address_failure, Get_Address_request, Get_Address_success } from "../../reducers/Addaddress/GetAddressSlice"
import { Update_Address_failure, Update_Address_request, Update_Address_success } from "../../reducers/Addaddress/UpdateAdressSlice"


export const UpdateAddressAPI = (payload,id) => async (dispatch, getState) => {
    dispatch(Update_Address_request())

   const Logindetails = JSON.parse(localStorage.getItem("Logindetails"));
// console.log("payloadpayload",payload)

    try {
        const addtocart= localStorage.getItem("addtocart")

        const { data } = await axios.patch(`${APIURL.updateaddaddress}/${id}`,payload, {
            headers: {
                "url": Headers?.url,
                "mode":Headers?.mode,
                "outlet":Headers?.outlet,
                "language":Headers?.language,
                "Content-type": "application/json",
                Authorization: `Bearer ${Logindetails?.data?.user_data?.auth_token}`
            }
        })
        dispatch(Update_Address_success(data))
     

    } catch (error) {
        dispatch(Update_Address_failure(error?.response?.data))
        // console.log("otpverified",error?.response?.data)
    }
}