

export const Styles = Theme => ({ 
    sliderItemTop : {
        display : 'flex !important',
        padding : '10px 14px',
        '& > div ' : {
            height : '96px'
        },
        

    },
    allProdImg : {
        width : '107px',
        height : '105px',
        '@media(max-width: 1440px)' : {
            width: '90px',
            height: '90px'
        }
    },
    rounded20 : {
        borderRadius : '20px',
    },
    fdProdIcon : {
        width : '35px',
        height : '35px',
        marginBottom : '10px'
    },
    borderDash : {
        borderBottom : '1px dashed rgba(0, 0, 0, 0.10)',
        margin : '10px 0'
    },
    prodImg : {
        width : '94px',
        height : '96px'
    },
    sliderHeading : {
        fontSize : '22px',
        WebkitLineClamp: 1, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical' ,
        '@media(max-width: 1440px)' : {
            fontSize: '18px'
        } 
    },
    sliderContent : {
        fontSize : '12px',
        margin : '10px 0',
        WebkitLineClamp: 2, /* Number of lines to display */
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical' 
    },
    itemContainer : {
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        position : 'relative',
        backgroundColor : '#fff',
        margin: '10px 0',
        '&::before, &::after' : {
            content: '""',
            top: '58%',
            transform: 'rotate(90deg)',
            height: '30px',
            width: '33px',
            backgroundColor: '#f7f7f7',
            position: 'absolute',
            zIndex: '9',
        },
        '&::before' : {
            left: '-7px',
            borderRadius: '150px 150px 0 0',
            boxShadow :'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.1)'
        },
        '&::after' : {
            right: '-4px',
            borderRadius: '0 0 150px 150px',
            boxShadow : 'inset 0px -4px 4px 0px rgba(0, 0, 0, 0.1)'
        }
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        width: '46px',
        transform : 'translateY(-50%)',
        '@media(max-width: 1369px)' : {
            top : '-27px'
        }
    },
    nextArrow: {
        right: '-6%',
        '@media(max-width: 1369px)' : { 
            right : '10px'
        }
    },
    prevArrow: {
        left: '-6%', 
        '@media(max-width: 1369px)' : { 
            left: 'auto',
            right : '100px'
        }
    },
    allItemTop : {
        padding : '20px',
        display : 'flex !important',
        '& > div' : {
            height : '105px'
        }
    },
    flexDimension : {
        display : 'flex',
        justifyContent: 'center',
        alignItems : 'center'
    },
    digit : {
        backgroundColor : '#fff',
        height : '31px',
        width  : '31px',
        border: '1px solid #000',
        borderRadius : '14px',
        right : '0',
        bottom : '-5px',
        '& p' : {
            backgroundColor : '#000',
            color: '#fff',
            width: '25px',
            height : '25px',
            borderRadius : '14px'
        },
        '& before' : {
            content : ''
        }
    }
})