import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const CartItemAddedSlice = createSlice({
    name: 'CartItemAdded',
    initialState,
    reducers: {
        Cart_Item_Added_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Cart_Item_Added_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Cart_Item_Added_failure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.value = {};
        },
        Cart_add_clear_item: (state, action) => {
            state.error = {};
            state.loading = false;
            state.value = {};
        },
        
    }
})

// Action creators are generated for each case reducer function
export const { Cart_Item_Added_failure, Cart_Item_Added_success ,Cart_Item_Added_request,Cart_add_clear_item} = CartItemAddedSlice.actions

export default CartItemAddedSlice.reducer