import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { useNavigate } from 'react-router';
import { Styles } from "./Style";
import { useTheme } from '@mui/material/styles';
import Textarea from '@mui/joy/Textarea';
import { FormLabel } from "@mui/material";
import CommonStyle from "../../Common/CommonCss/Common.module.css";
import StrConstent from "../../Common/StrConstent";
import BackArrow from '../../Components/BackArrow/BackArrow';
import ContainerBox from "../../Components/ContainerBox/ContainerBox";
import EnquirySelectBox from "./EnquirySelectBox";
import { NavLink } from "react-router-dom";
import CustomButton from "../../KioskComponents/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { GetintouchAPI } from "../../redux/Action/getintouch/Getintouch";
import { validateEmail, validatePhone } from "../../Common/Validations";
import Loader from "../../Components/Loader/Loader";
import AlertMessage from "../../Components/Alertmsg/AlertMessage";
import { Clear_GetinTouch_Cart } from "../../redux/reducers/getinTouch/GetinTouch";
const GetInTouch = (props) => {
    const { classes } = props;
    let navigate = useNavigate();
    const theme = useTheme();
    const [firstname, setfirstname] = useState("")
    const [firstnameerror, setfirstnameerror] = useState(false)
    const [Lastname, setLastname] = useState("")
    const [Lastnameerror, setLastnameerror] = useState(false)
    const [Email, setEmail] = useState("")
    const [Emailerror, setEmailerror] = useState(false)
    const [Emailformateerror, setEmailformateerror] = useState(false)
    const [Numbers, setNumber] = useState("")
    const [Numbererror, setNumbererror] = useState(false)
    const [Numbererrorformate, setNumbererrorformate] = useState(false)
    const [Typeofenquiry, setTypeofenquiry] = useState("")
    const [Typeofenquiryerror, setTypeofenquiryerror] = useState(false)
    const dispatch = useDispatch();
    const [successmsg, setsuccessmsg] = useState("")
    const GetinTouchSlice = useSelector(state => state?.GetinTouchSlice)
    const SubmitHandle = () => {
        if (firstname == "" && Lastname == "" && Email == "" && Numbers == "" && validatePhone(Numbers) && Typeofenquiry?.length == 0) {
            setfirstnameerror(true)
            setLastnameerror(true)
            setEmailerror(true)
            setNumbererror(true)
            setTypeofenquiryerror(true)
        }
        else if (firstname == "" || Lastname == "" || Email == "" || Numbers == "" || validatePhone(Numbers) || Typeofenquiry?.length == 0) {
            if (firstname == "") {
                setfirstnameerror(true)
            }
            if (Lastname == "") {
                setLastnameerror(true)
            }
            if (Email == "") {
                setEmailerror(true)
            }
            if (Numbers == "") {
                setNumbererror(true)
            }
            if (validatePhone(Numbers)) {
                setNumbererrorformate(true)
            }
            if (validateEmail(Email)) {
                setEmailformateerror(true)
            }
            if (Typeofenquiry?.length == 0) {
                setTypeofenquiryerror(true)
            }
        }
        else if (firstname != "" && Lastname != "" && Email != "" && Numbers != "" && Typeofenquiry?.length > 0) {

            const obj = {
                first_name: firstname,
                last_name: Lastname,
                email: Email,
                phone_number: Numbers,
                type: Typeofenquiry[0]
            }
            dispatch(GetintouchAPI(obj))
        }
        // GetintouchAPI
    }

    useEffect(() => {
        if (GetinTouchSlice?.value?.message) {
            setsuccessmsg(true)
            setfirstname("")
            setLastname("")
            setEmail("")
            setNumber("")
            setTypeofenquiry("")
            // navigate("/")
            setTimeout(() => {
                dispatch(Clear_GetinTouch_Cart())
                
            }, 3000)

        }
        console.log("GetinTouchSlice", GetinTouchSlice)

    }, [GetinTouchSlice])
    const snackHandler = () => {
        setsuccessmsg(false)
    }
    return (
        <>
            {
                GetinTouchSlice?.loading == true && (
                    <Loader />
                )
            }
            {
                GetinTouchSlice?.value?.message && (
                    <AlertMessage
                        closeHandler={snackHandler}
                        url={"./images/error.svg"}
                        description={GetinTouchSlice?.value?.message}
                        title={"Success"}
                        type={"Success"}
                        open={successmsg}
                    />
                )
            }
            <ContainerBox>
                <BackArrow clickHandler={() => navigate("-1")} />
                <h2 className={CommonStyle.mb20}>{StrConstent.getInTouch.heading}</h2>
                <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.itemStart} ${classes.getInTouchContainer} `}>
                    <div className={classes.leftSection}>
                        <FormLabel>{StrConstent.getInTouch.formLabel.name}*</FormLabel>
                        <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.mb10} ${classes.fields} rw`}>
                            <div className={CommonStyle.fromControl}>
                                <input type="text" name="" placeholder="First name" value={firstname} onChange={(e) => {
                                    setfirstname(e.target.value)
                                    setfirstnameerror(false)
                                }} />
                                {firstnameerror == true &&
                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        This field is Required.
                                    </p>
                                }
                            </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.pl20}`}>
                                <input type="text" name="" placeholder="Last name" value={Lastname} onChange={(e) => {
                                    setLastname(e.target.value)
                                    setLastnameerror(false)
                                }} />
                                {Lastnameerror == true &&
                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        This field is Required.
                                    </p>
                                }
                            </div>
                        </div>
                        <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.mb10} ${classes.fields} rw`}>
                            <div className={CommonStyle.fromControl}>
                                <FormLabel>{StrConstent.getInTouch.formLabel.email}*</FormLabel>
                                <input type="text" name="" placeholder="Enter your email address" value={Email} onChange={(e) => {
                                    setEmail(e.target.value)
                                    setEmailerror(false)
                                    setEmailformateerror(false)
                                }} />
                                {Emailerror == true &&
                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        This field is Required.
                                    </p>
                                }
                                {Emailformateerror == true && Emailerror == false &&
                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        Please Add valide Email.
                                    </p>
                                }
                            </div>
                            <div className={`${CommonStyle.fromControl} ${CommonStyle.pl20}`}>
                                <FormLabel>{StrConstent.getInTouch.formLabel.phoneNumber}*</FormLabel>
                                <input type="number" name="" placeholder="Enter your contact number" value={Numbers} onChange={(e) => {
                                    setNumber(e.target.value)
                                    setNumbererror(false)
                                    setNumbererrorformate(false)
                                }} />
                                {Numbererror == true &&
                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        This field is Required.
                                    </p>
                                }
                                {Numbererror == false && Numbererrorformate == true &&
                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        please add valide phone number.
                                    </p>
                                }
                            </div>
                        </div>
                        <div className={`${CommonStyle.flexSpaceBetween} ${classes.fields} rw`}>
                            <div className={CommonStyle.fromControl}>
                                <FormLabel>{StrConstent.getInTouch.formLabel.typeOfEnquiry}*</FormLabel>
                                <div className={classes.selectBox}>
                                    <EnquirySelectBox Typeofenquiry={Typeofenquiry} setTypeofenquiry={setTypeofenquiry} setTypeofenquiryerror={setTypeofenquiryerror} />
                                </div>
                                {Typeofenquiryerror == true &&
                                    <p className={`${CommonStyle.errorMessage} ${CommonStyle.mt5} ${CommonStyle.mb20}`}>
                                        This field is Required.
                                    </p>
                                }
                            </div>
                        </div>
                        {/* <div className={`${CommonStyle.flexSpaceBetween} ${CommonStyle.mb10} ${classes.textFields} ${CommonStyle.mb20}`}>
                            <div className={CommonStyle.fromControl}>
                                <Textarea minRows={2} placeholder={StrConstent.getInTouch.placeholderTextarea.heading} />
                            </div>
                        </div> */}
                        <CustomButton label="Submit" clickHandler={SubmitHandle} />
                    </div>
                    <div className={classes.rightSection}>
                        <ul>
                            <li>
                                <h4>{StrConstent.getInTouch.address}</h4>
                                <p>{StrConstent.getInTouch.addressTxt}</p>
                            </li>
                            <li>
                                <h4>{StrConstent.getInTouch.call}</h4>
                                <p>{StrConstent.getInTouch.callTxt}</p>
                                <NavLink className={CommonStyle.links}>{StrConstent.getInTouch.tel}: +65 6274 8193</NavLink>
                            </li>
                            <li>
                                <h4>{StrConstent.getInTouch.emailUs}</h4>
                                <NavLink className={CommonStyle.links}>{StrConstent.getInTouch.emailTxt}</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

            </ContainerBox>
        </>
    )
}

export default withStyles(Styles)(GetInTouch);