import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {},
    loading: false,
    error: {}
}

export const FaqSlice = createSlice({
    name: 'Faq',
    initialState,
    reducers: {

        Faq_request: (state, action) => {
            state.value = {};
            state.loading = true;
        },
        Faq_success: (state, action) => {

            state.value = action.payload;
            state.loading = false;
            state.error = {};
        },
        Faq_failure: (state, action) => {
            state.value = {};
            state.error = action.payload;
            state.loading = false;
        },
        Clear_Faq_Cart: (state, action) => {
            state.value = {};
            state.error = {};
            state.loading = false;
        },
    }
})

// Action creators are generated for each case reducer function
export const {  Faq_request, Faq_success,Faq_failure, Clear_Faq_Cart } = FaqSlice.actions

export default FaqSlice.reducer