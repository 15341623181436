import ColorTheme from "../../Common/CommonCss/ColorTheme";

export const Styles = Theme => ({
    userProfile: {
        maxWidth: '110px',
        position: 'relative',
        marginBottom: '25px',
    },
    userImg: {
        height: '85px',
        width: '85px',
        border: '1.52px solid rgba(63, 68, 68, 0.75)',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '5px',
        "@media(max-width:640px)": {
            height: '65px',
            width: '65px',
        },
        "& > img": {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        }
    },
    verfiyContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '5px',
    },
    editProfile: {
        position: 'relative',
        top: '10px',
        float: 'right',
        cursor: 'pointer !important',
        "& svg":{
            color: ColorTheme.secondaryColor,   
        }
    },
    uploadFile: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        opacity: '0',
    },
    chips: {
        width: '30px',
        padding: '6px 8px !important',
        background: ColorTheme.yellow,
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    chipsBorder: {
        marginLeft: '10px',
        background: ColorTheme.transparent,
        border: `1px solid ${ColorTheme.yellow}`,
    },
    fieldContainer: {
        "@media(max-width:768px)": {
            display: 'block',
            marginTop: '0 !important',
        },
        "& > div": {
            width: '50%',
            "@media(max-width:768px)": {
                width: '100%',
                margin: '10px 0 25px',
            },
            "&:first-child": {
                marginRight: '20px',
                "@media(max-width:768px)": {
                    marginRight: '0',
                }
            }
        },
    },
    firstName: {
        "@media(max-width:375px)": {
            display: 'block',
        },
        "& > div": {
            width: '100%',
            marginBottom: '15px',
            "@media(max-width:375px)": {
                marginBottom: '25px',
            },
            "&:first-child": {
                marginRight: '20px',
                "@media(max-width:375px)": {
                    marginRight: '0',
                },
            }
        },
    },
    mobileField: {
        position: 'relative',
        "& input": {
            paddingRight: '50px',
        },
        "& img": {
            position: 'absolute',
            right: '20px',
            top: '50%',
            transform: 'translateY(-10px)',
        }
    },
    changeNumberModal:{
        textAlign:'center',
        "& button":{
            "&:first-child":{
                margin:'15px 0 0',
            }
        }
    }
})