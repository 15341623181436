import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
 
}

export const SugarlevelSlice = createSlice({
  name: 'sugarlevel',
  initialState,
  reducers: {

    Sugar_success: (state, action) => {

      state.value = action.payload;

    }
  }
})

// Action creators are generated for each case reducer function
export const {Sugar_success } = SugarlevelSlice.actions

export default SugarlevelSlice.reducer